import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { BankAccountFirestoreService } from './bank-accounts-firestore.service';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  constructor(private bankAccountFirestoreService: BankAccountFirestoreService) {}

  create(bankAccount: IBankAccount): Promise<string> {
    return this.bankAccountFirestoreService.create(bankAccount);
  }

  createWithId(id: string, bankAccount: IBankAccount): Promise<string> {
    return this.bankAccountFirestoreService.createWithId(id, bankAccount);
  }

  update(bankAccount: IBankAccount): Promise<string> {
    return this.bankAccountFirestoreService.update(bankAccount);
  }

  delete(id: string): Promise<void> {
    return this.bankAccountFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IBankAccount> {
    return this.bankAccountFirestoreService.docValue$(id);
  }

  getAll(): Observable<IBankAccount[]> {
    return this.bankAccountFirestoreService.collectionValues$();
  }

  getAllForInvoicing(): Observable<IBankAccount[]> {
    return this.bankAccountFirestoreService.collectionValues$(ref =>
      ref.where('enableForInvoicing', '==', true)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.bankAccountFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IBankAccount[]> {
    return this.bankAccountFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
