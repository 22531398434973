<div *ngIf="airlineId" class="form-group">
    <label [innerHtml]="label"></label>
    <div class="form-control fake-input" [class.disabled]="disabled">
        <span class="fake-input-text" [innerHtml]="airlineTitle"></span>
        <button type="button" class="btn btn-sm btn-clear" (click)="clearValue()" *ngIf="!disabled">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
</div>

<fieldset *ngIf="!airlineId">
    <legend>{{ label }}</legend>

    <div class="form-group">
        <label for="airlineCountryCode">Pays</label>
        <select
            [disabled]="disabled"
            class="form-select"
            id="airlineCountryCode"
            name="airlineCountryCode"
            [(ngModel)]="airlineCountryCode"
            (change)="updateAirlineCountry()"
        >
            <option
                [value]="country.value"
                *ngFor="let country of countriesList"
                [innerHtml]="country.title"
            ></option>
        </select>
    </div>

    <div class="placeholder w-100" *ngIf="loadingAirlines"></div>

    <div *ngIf="!loadingAirlines">
        <div class="alert alert-warning" *ngIf="airlineCountryCode && !airlinesList.length">
            Ce pays n'a aucune compagnie aérienne active.
        </div>

        <div class="form-group" *ngIf="airlineCountryCode && airlinesList.length">
            <label for="airlineId">Compagnie aérienne</label>

            <select
                [disabled]="disabled"
                class="form-select"
                id="airlineId"
                name="airlineId"
                [(ngModel)]="airlineId"
                (change)="updatedAirline()"
            >
                <option
                    [value]="airline.id"
                    *ngFor="let airline of airlinesList"
                    [innerHtml]="airline.title"
                ></option>
            </select>
        </div>
    </div>
</fieldset>
