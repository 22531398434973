import { Component, OnInit, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';

import { RemoteService } from '../services/remote.service';
import { UserService } from '../services/user/user.service';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from '../services/loader/loader.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  form: FormGroup;
  currentEmail: string;
  userCred: firebase.auth.UserCredential;

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  showPassword: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private loaderService: LoaderService
  ) {}

  get email(): FormControl {
    return this.form.get('email') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  get confirmPassword(): FormControl {
    return this.form.get('confirmPassword') as FormControl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [
        '',
        [Validators.required, Validators.minLength(6), this.matchValue('password')]
      ]
    });

    this.activatedRoute.params.subscribe(() => {
      if (this.activatedRoute.snapshot.queryParams.email) {
        this.currentEmail = this.activatedRoute.snapshot.queryParams.email;
      } else {
        this.currentEmail = window.prompt('Veuillez founir votre email pour confirmation');
      }

      this.email.setValue(this.currentEmail);

      if (this.userService.isSignInWithEmailLink(this.router.url)) {
        // Signin user and remove the email localStorage
        this.userService
          .signInWithEmailLink(this.currentEmail, this.router.url)
          .then((userCred: firebase.auth.UserCredential) => {
            this.userCred = userCred;
          })
          .catch(err => {
            console.log(err);
            alert(err.message);
            this.zone.run(() => {
              this.router.navigate(['/']);
            });
          });
      } else {
        console.log('Is not sign in with email link');
        console.log('Error avec le lien de connexion');
        this.zone.run(() => {
          this.router.navigate(['/']);
        });
      }
    });
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.form.disable();

      this.loaderService.presentLoader();

      this.userCred.user
        .updatePassword(this.password.value)
        .then(() => {
          this.form.enable();
          this.form.reset();

          this.remoteService
            .signOut()
            .then(async () => {
              await this.loaderService.hideLoaderOnSuccess();

              this.router.navigate(['/login']);
            })
            .catch(err => {
              alert(err.message);
            });
        })
        .catch(async err => {
          this.form.enable();

          await this.loaderService.hideLoaderOnFailure(err.message);
        });
    }
  }

  private matchValue(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  toggleType(): void {
    this.showPassword = !this.showPassword;
  }
}
