import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { EncaissementTypeFirestoreService } from './encaissement-types-firestore.service';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EncaissementTypeService {
  constructor(private encaissementTypeFirestoreService: EncaissementTypeFirestoreService) {}

  create(encaissementType: IEncaissementType): Promise<string> {
    return this.encaissementTypeFirestoreService.create(encaissementType);
  }

  update(encaissementType: IEncaissementType): Promise<string> {
    return this.encaissementTypeFirestoreService.update(encaissementType);
  }

  delete(id: string): Promise<void> {
    return this.encaissementTypeFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEncaissementType> {
    return this.encaissementTypeFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEncaissementType[]> {
    return this.encaissementTypeFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.encaissementTypeFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEncaissementType[]> {
    return this.encaissementTypeFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
