import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { getDisplayedEnquiryRefTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnAlign,
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import {
  getDepartDate,
  getItineraryVolumeList,
  getItineraryWeightList,
  getPassengersTotalLabelList,
  getReturnDate
} from 'src/app/interfaces/enquiry-itinerary.interface';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-enquiries-list-for-airline',
  templateUrl: './enquiries-list-for-airline.component.html',
  styleUrls: ['./enquiries-list-for-airline.component.scss']
})
export class EnquiriesListForAirlineComponent
  extends DataList<IEnquiry>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() airlineId: string | null = null;

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'airline-enquiries-list';
  columns: IDataListColumn[] = [
    {
      title: '<strong>№ contrat</strong> /<br />№ demande',
      field: 'refEnquiryTitle',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        if (item.refContractTitle) {
          html +=
            '<div><strong>' + getDisplayedEnquiryRefTitle(item, 'refContract') + '</strong></div>';
        }

        if (item.refEnquiryTitle) {
          html += '<div>' + getDisplayedEnquiryRefTitle(item, 'refEnquiry') + '</div>';
        }

        return html;
      }
    },
    {
      title: 'Nature',
      field: 'type',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return getEnumEnquiryTypeLabel(item.type);
      }
    },
    {
      title: 'Statut',
      field: 'status',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        let classArr: string[] = ['badge'];

        switch (item.status) {
          case EnumEnquiryStatus.draft:
            classArr.push('bg-primary');
            break;
          case EnumEnquiryStatus.confirmed:
            classArr.push('bg-success');
            break;
          case EnumEnquiryStatus.cancelled:
            classArr.push('bg-danger');
            break;
        }

        return (
          '<span class="' +
          classArr.join(' ') +
          '"> ' +
          getEnumEnquiryStatusLabel(item.status) +
          '</span>'
        );
      }
    },
    {
      title: 'Routing',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += itinerary.title;
          }

          html += itinerary.routing;

          if (getReturnDate(itinerary)) {
            html += '&nbsp;';
          }

          html += '</div>';
        }

        return html;
      }
    },
    {
      title: 'Dates de vol',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += '&nbsp;';
          }

          if (getDepartDate(itinerary)) {
            html += '<div>' + format(getDepartDate(itinerary), 'dd/MM/yy') + '</div>';
          }

          if (getReturnDate(itinerary)) {
            html += '<div>' + format(getReturnDate(itinerary), 'dd/MM/yy') + '</div>';
          }

          html += '</div>';
        }

        return html;
      }
    },
    {
      title: 'PAX ou kg / m<sup>3</sup>',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += '&nbsp;';
          }

          if (item.type === EnumEnquiryType.cargo) {
            html +=
              getItineraryWeightList(itinerary)?.join('|') +
              ' kg / ' +
              getItineraryVolumeList(itinerary)?.join('|') +
              ' m<sup>3</sup>';
          } else {
            html += getPassengersTotalLabelList(itinerary)?.join('|') + ' PAX';
          }

          if (getReturnDate(itinerary)) {
            html += '&nbsp;';
          }

          html += '</div>';
        }

        return html;
      }
    },
    {
      title: 'Reçu par',
      field: 'receivedBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return item.receivedBy && this.usersObj[item.receivedBy]
          ? this.usersObj[item.receivedBy].initials
          : '';
      }
    },
    {
      title: 'Traité par',
      field: 'processedBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return item.processedBy && this.usersObj[item.processedBy]
          ? this.usersObj[item.processedBy].initials
          : '';
      }
    },
    {
      title: 'Date de création',
      field: 'created',
      notSortable: true,
      type: EnumDataListColumnType.date,
      align: EnumDataListColumnAlign.center
    }
  ];

  orderBy: IDataListOrderBy | null = { field: 'refEnquiryTitleWithoutPrefix', direction: 'desc' };

  isLogged: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private enquiryService: EnquiryService,
    private userService: UserService,
    private remoteService: RemoteService
  ) {
    super();

    this.setDataService(this.enquiryService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.updatedAirlineId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['airlineId'] && !changes['airlineId'].isFirstChange()) {
      this.updatedAirlineId();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedAirlineId(): void {
    if (this.isLogged) {
      if (this.airlineId) {
        this.conditions = [
          {
            field: 'airlineIds',
            operator: 'array-contains',
            value: this.airlineId
          }
        ];

        this.resetAndLoad();
      }

      this.loadUsers();
    } else {
      setTimeout(() => {
        this.updatedAirlineId();
      }, 500);
    }
  }

  rowCls(item: IEnquiry): string {
    return 'enquiry-' + item.type;
  }

  loadUsers(): void {
    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        for (const user of users) {
          this.usersObj[user.id] = user;
        }
      })
    );
  }
}
