import { Component, OnDestroy, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { UserGroupService } from 'src/app/services/user-groups/user-groups.service';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import {
  EnumDataListColumnAlign,
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { DataList } from 'src/app/components/data-list/data-list.class';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent extends DataList<IUser> implements OnInit, OnDestroy {
  userGroupsObj: { [id: string]: IUserGroup | null } = {};
  loadingUserGroups: boolean = false;

  EnumAcl = EnumAcl;

  columns: IDataListColumn[] = [
    {
      title: 'Prénom',
      field: 'firstname'
    },
    {
      title: 'Nom',
      field: 'lastname'
    },
    {
      title: 'Initiales',
      field: 'initials'
    },
    {
      title: 'Email',
      field: 'email',
      type: EnumDataListColumnType.email
    },
    {
      title: 'Groupe',
      field: 'userGroupId',
      type: EnumDataListColumnType.function,
      resolve: (item: IUser): string => {
        return this.userGroupsObj[item.userGroupId]?.title || '';
      }
    },
    {
      title: 'Date de création',
      field: 'created',
      type: EnumDataListColumnType.date,
      align: EnumDataListColumnAlign.center
    },
    {
      title: 'Désactivé ?',
      field: 'isDisabled',
      type: EnumDataListColumnType.boolean,
      align: EnumDataListColumnAlign.center
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'firstname', direction: 'asc' };

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private userGroupService: UserGroupService,
    private userService: UserService
  ) {
    super();

    this.setDataService(this.userService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.usersList);

    this.resetAndLoad();
    this.loadUserGroups();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyPagination();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadUserGroups(): void {
    this.loadingUserGroups = true;

    this.subscriptions.add(
      this.userGroupService.getAll().subscribe((userGroups: IUserGroup[]) => {
        for (const userGroup of userGroups) {
          this.userGroupsObj[userGroup.id] = userGroup;
        }

        this.loadingUserGroups = false;
      })
    );
  }

  rowCls(user: IUser): string {
    return user.isDisabled ? 'tr-muted' : '';
  }
}
