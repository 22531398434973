import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss']
})
export class ListInvoicesComponent implements OnInit, OnDestroy {
  getEnquiryBreadcrumbTitle = getEnquiryBreadcrumbTitle;

  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumInvoiceType = EnumInvoiceType;
  EnumAcl = EnumAcl;

  currentPagination: string = 'invoices-list';
  enquiryId: string;
  enquiry: IEnquiry;
  usersObj: { [key: string]: IUser } = {};

  tabs: {
    title: string;
    value: string;
    paginationName: string;
    confirmedEnquiryOnly: boolean;
  }[] = [
    {
      title: "Vue d'ensemble",
      value: 'invoices-overview',
      paginationName: 'invoices-dashboard-overview-list',
      confirmedEnquiryOnly: true
    },
    {
      title: 'Proformas',
      value: 'invoices-proforma',
      paginationName: 'invoices-dashboard-proforma-list',
      confirmedEnquiryOnly: false
    },
    {
      title: 'Factures',
      value: 'invoices-definitive',
      paginationName: 'invoices-dashboard-definitive-list',
      confirmedEnquiryOnly: true
    },
    {
      title: 'Avoirs',
      value: 'invoices-credit-note',
      paginationName: 'invoices-dashboard-credit-note-list',
      confirmedEnquiryOnly: true
    },
    {
      title: 'Factures annulées',
      value: 'invoices-cancelled',
      paginationName: 'invoices-dashboard-cancelled-list',
      confirmedEnquiryOnly: true
    },
    {
      title: "Factures d'achat",
      value: 'invoices-purchase',
      paginationName: 'invoices-dashboard-purchase-list',
      confirmedEnquiryOnly: true
    }
  ];
  activeTabIndex: number = 0;
  enquiryInvoices: IInvoice[] = [];
  loadingEnquiryInvoices: boolean = false;

  subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private enquiryService: EnquiryService,
    private router: Router,
    private loaderService: LoaderService,
    private invoiceService: InvoiceService,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.invoicesList);

    this.activatedRoute.params.subscribe(async () => {
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      this.loadEnquiry();
      this.loadEnquiryInvoices();

      this.setBreadcrumbsItems();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectActiveTab(tabIndex: number): void {
    if (this.tabs[tabIndex]) {
      this.activeTabIndex = tabIndex;
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          this.setBreadcrumbsItems();
        })
      );
    }
  }

  loadEnquiryInvoices(): void {
    if (this.enquiryId) {
      this.loadingEnquiryInvoices = true;

      this.subscriptions.add(
        this.invoiceService.getAllForEnquiry(this.enquiryId).subscribe((invoices: IInvoice[]) => {
          this.enquiryInvoices = invoices;

          this.loadingEnquiryInvoices = false;
        })
      );
    }
  }

  async addInvoice(invoiceType: EnumInvoiceType): Promise<void> {
    if (this.enquiryId) {
      let countInvoicesSameType: number = 0;

      for (const invoice of this.enquiryInvoices) {
        if (invoice.invoiceType === invoiceType) {
          countInvoicesSameType++;
        }
      }

      if (!countInvoicesSameType) {
        this.redirectToAddUrl(invoiceType);
      } else {
        if (
          confirm(
            'Il existe au moins une facture de ce type pour ce dossier, souhaitez-vous continuer ?'
          )
        ) {
          this.redirectToAddUrl(invoiceType);
        }
      }
    } else {
      this.redirectToAddUrl(invoiceType);
    }
  }

  redirectToAddUrl(invoiceType: EnumInvoiceType): void {
    let url: string | null = null;

    switch (invoiceType) {
      case EnumInvoiceType.proforma:
        url = '/admin/invoices/add-proforma';
        break;
      case EnumInvoiceType.proformaCreditNote:
        url = '/admin/invoices/add-credit-note-proforma';
        break;
      case EnumInvoiceType.creditNote:
        url = '/admin/invoices/add-credit-note-invoice';
        break;
      case EnumInvoiceType.definitive:
        url = '/admin/invoices/add-final-invoice';
        break;
      case EnumInvoiceType.purchase:
        url = '/admin/invoices/add-purchase-invoice';
        break;
      case EnumInvoiceType.purchaseCreditNote:
        url = '/admin/invoices/add-purchase-credit-note';
        break;
    }

    if (this.enquiryId) {
      url += '/' + this.enquiryId;
    }

    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  async requestInvoice(invoiceType: EnumInvoiceType): Promise<void> {
    if (this.enquiryId) {
      let countInvoicesSameType: number = 0;

      for (const invoice of this.enquiryInvoices) {
        if (invoice.invoiceType === invoiceType) {
          countInvoicesSameType++;
        }
      }

      if (!countInvoicesSameType) {
        this.redirectToRequestUrl(invoiceType);
      } else {
        if (
          confirm(
            'Il existe au moins une facture de ce type pour ce dossier, souhaitez-vous continuer ?'
          )
        ) {
          this.redirectToRequestUrl(invoiceType);
        }
      }
    } else {
      this.redirectToRequestUrl(invoiceType);
    }
  }

  redirectToRequestUrl(invoiceType: EnumInvoiceType): void {
    let url: string | null = null;

    switch (invoiceType) {
      case EnumInvoiceType.creditNote:
        url = '/admin/invoices/request-credit-note-invoice';
        break;
      case EnumInvoiceType.definitive:
        url = '/admin/invoices/request-final-invoice';
        break;
    }

    if (this.enquiryId) {
      url += '/enquiry/' + this.enquiryId;
    }

    if (url) {
      this.router.navigateByUrl(url);
    }
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    if (this.enquiry) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        text: getEnquiryBreadcrumbTitle(this.enquiry),
        url: '/admin/enquiries/' + this.enquiry.id
      });

      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices/enquiry/' + this.enquiry.id
      });
    } else {
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices'
      });
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }
}
