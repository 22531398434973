import { Component, Input, OnInit } from '@angular/core';

import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { ActivatedRoute } from '@angular/router';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { Subscription } from 'rxjs';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { AirlineSupervisorService } from 'src/app/services/airline-supervisors/airline-supervisors.service';
import { DataList } from 'src/app/components/data-list/data-list.class';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';

@Component({
  selector: 'app-list-airline-supervisors',
  templateUrl: './list-airline-supervisors.component.html',
  styleUrls: ['./list-airline-supervisors.component.scss']
})
export class ListAirlineSupervisorsComponent
  extends DataList<IAirlineSupervisor>
  implements OnInit
{
  @Input() airlineId: string | null = null;

  EnumAcl = EnumAcl;

  airlinesObj: { [id: string]: IAirline | null } = {};

  columns: IDataListColumn[] = [];
  orderBy: IDataListOrderBy | null = null;

  private subscriptions = new Subscription();

  constructor(
    private aclService: AclService,
    private activatedRoute: ActivatedRoute,
    private airlineService: AirlineService,
    private airlineSupervisorService: AirlineSupervisorService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super();

    this.setDataService(this.airlineSupervisorService);
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async () => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      await this.aclService.checkAclAccess(EnumAcl.airlineSupervisorsList);

      if (this.airlineId) {
        this.conditions = [
          {
            field: 'airlineId',
            operator: '==',
            value: this.airlineId
          }
        ];

        this.loadAirline(this.airlineId);
      }

      this.refreshColumns();

      this.resetAndLoad();
    });
  }

  afterLoadData(): void {
    for (const item of this.data) {
      this.loadAirline(item.airlineId);
    }
  }

  refreshColumns(): void {
    this.columns = [];

    this.columns.push({
      title: 'Prénom',
      field: 'firstname',
      notSortable: !!this.airlineId
    });

    this.columns.push({
      title: 'Nom',
      field: 'lastname',
      notSortable: !!this.airlineId
    });

    if (!this.airlineId) {
      this.columns.push({
        title: 'Compagnie aérienne',
        field: 'airlineId',
        type: EnumDataListColumnType.function,
        notSortable: !!this.airlineId,
        resolve: (item: IAirlineSupervisor): string => {
          return item.airlineId && this.airlinesObj[item.airlineId]
            ? this.airlinesObj[item.airlineId].title
            : '';
        }
      });
    }

    this.columns.push({
      title: 'Date de création',
      field: 'created',
      type: EnumDataListColumnType.date,
      notSortable: !!this.airlineId
    });
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;

          if (this.airlinesObj[airlineId]) {
            this.breadcrumbsService.setCurrentItem({
              id: this.airlinesObj[airlineId].id,
              text: this.airlinesObj[airlineId].title
            });
          }

          this.refreshColumns();
        })
      );
    }
  }
}
