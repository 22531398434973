<div class="modal-header" *ngIf="inModal">
    <h1
        class="modal-title fs-5"
        id="enquiry-flight-edit-modalLabel"
        [innerText]="enquiryFlight ? 'Édition d\'un leg' : 'Ajout d\'un leg'"
    ></h1>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        #btnCloseModal
    ></button>
</div>
<div [class.modal-body]="inModal">
    <form
        (submit)="submitForm()"
        [formGroup]="form"
        (keydown.enter)="submitForm()"
        class="main-form"
    >
        <div class="row flight-itinerary mb-4">
            <div class="col-lg-4">
                <div class="flight-itinerary-airport">
                    <fa-icon [icon]="faPlaneDeparture"></fa-icon>
                    <app-airport-picker
                        inputName="airportDepartId"
                        [defaultValue]="airportDepartId.value"
                        [disabled]="form.disabled"
                        [isInvalid]="
                            (form.touched || airportDepartId.touched) &&
                            airportDepartId.status === 'INVALID'
                                ? 'danger'
                                : ''
                        "
                        (setValueToFormControl)="setValueToFormControl($event)"
                    >
                    </app-airport-picker>

                    <div
                        *ngIf="
                            (form.touched || airportDepartId.touched) &&
                            airportDepartId.errors &&
                            airportDepartId.errors['required']
                        "
                        class="text-danger"
                    >
                        Ce champ est obligatoire.
                    </div>

                    <a
                        [routerLink]="'/admin/airports/' + airportDepartId.value"
                        target="_blank"
                        *ngIf="airportDepartId.value"
                        [class.text-muted]="!airportDepartIataCode.value"
                    >
                        {{ airportDepartIataCode.value || "Code IATA inconnu" }}
                        <fa-icon [icon]="faExternalLink"></fa-icon>
                    </a>
                </div>

                <ng-container *ngIf="airportDepartId.value">
                    <div class="flight-itinerary-datetime">
                        <div class="form-group">
                            <label for="departDateTimeLocal">Date et heure de départ</label>

                            <div
                                class="input-group"
                                [class.mb-1]="timeField === 'local'"
                                *ngFor="let timeField of ['local', 'utc']"
                            >
                                <input
                                    class="form-control"
                                    [id]="'departDateTime' + capitalize(timeField)"
                                    [formControlName]="'departDateTime' + capitalize(timeField)"
                                    type="datetime-local"
                                    [max]="
                                        getDateTimeField('arrival', timeField).value
                                            ? getDateTimeField('arrival', timeField).value
                                            : ''
                                    "
                                    min="2000-01-01"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getDateTimeField('depart', timeField).touched) &&
                                        getDateTimeField('depart', timeField).status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedDateTime('depart', timeField)"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getDateTimeField('depart', timeField).touched) &&
                                            getDateTimeField('depart', timeField).errors &&
                                            getDateTimeField('depart', timeField).errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getDateTimeField('depart', timeField).touched) &&
                                            getDateTimeField('depart', timeField).errors &&
                                            getDateTimeField('depart', timeField).errors[
                                                'dateBelowMinimum'
                                            ]
                                        "
                                    >
                                        La date doit être supérieure à
                                        {{
                                            getDateTimeField("depart", timeField).errors[
                                                "dateMinimum"
                                            ] | date : "dd/MM/yyyy"
                                        }}.
                                    </div>
                                </div>

                                <span
                                    class="input-group-text"
                                    rel="tooltip"
                                    title="Heure locale"
                                    *ngIf="timeField === 'local'"
                                    >LT</span
                                >
                                <span class="input-group-text" *ngIf="timeField === 'utc'"
                                    >UTC</span
                                >
                            </div>
                        </div>
                    </div>

                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="departSlotEnabled"
                            formControlName="departSlotEnabled"
                            (change)="updatedSlotEnabled('depart')"
                        />
                        <label class="form-check-label" for="departSlotEnabled">Slot</label>
                    </div>

                    <div
                        class="form-group placeholder-glow mt-2"
                        *ngIf="getSlotEnabled('depart').value"
                    >
                        <div class="input-group">
                            <input
                                type="time"
                                class="form-control"
                                formControlName="departSlotTime"
                                [class.is-invalid]="
                                    (form.touched || getSlotTime('depart').touched) &&
                                    getSlotTime('depart').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getSlotTime('depart').touched) &&
                                        getSlotTime('depart').errors &&
                                        getSlotTime('depart').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>

                            <span class="input-group-text" rel="tooltip" title="Heure locale"
                                >LT</span
                            >
                        </div>
                    </div>

                    <ng-container
                        *ngIf="
                            ![EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="departTerminalEnabled"
                                formControlName="departTerminalEnabled"
                                (change)="getTerminalEnabled('depart')"
                            />
                            <label class="form-check-label" for="departTerminalEnabled"
                                >Terminal</label
                            >
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getTerminalEnabled('depart').value"
                        >
                            <input
                                type="text"
                                class="form-control"
                                formControlName="departTerminal"
                                [class.is-invalid]="
                                    (form.touched || getTerminal('depart').touched) &&
                                    getTerminal('depart').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getTerminal('depart').touched) &&
                                        getTerminal('depart').errors &&
                                        getTerminal('depart').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            [EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="fbo-switch">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="departFboEnabled"
                                    formControlName="departFboEnabled"
                                    (change)="updatedFboEnabled('depart')"
                                />
                                <label class="form-check-label" for="departFboEnabled">FBO</label>
                            </div>
                            <button
                                type="button"
                                class="btn btn-icon"
                                (click)="addFbo('depart')"
                                *ngIf="
                                    hasAclAccess(EnumAcl.fbosAdd) && getFboEnabled('depart').value
                                "
                                rel="tooltip"
                                title="Ajout FBO"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getFboEnabled('depart').value"
                        >
                            <div
                                class="placeholder w-100"
                                *ngIf="loadingFbosForAirport[airportDepartId.value]"
                            ></div>
                            <div
                                class="input-group"
                                *ngIf="!loadingFbosForAirport[airportDepartId.value]"
                            >
                                <select
                                    class="form-select"
                                    formControlName="departFboId"
                                    [class.is-invalid]="
                                        (form.touched || getFboId('depart').touched) &&
                                        getFboId('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.name"
                                        *ngFor="let item of fbosObj[airportDepartId.value]"
                                    ></option>
                                </select>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getFboId('depart').touched) &&
                                            getFboId('depart').errors &&
                                            getFboId('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editFbo('depart')"
                                    *ngIf="
                                        hasAclAccess(EnumAcl.fbosEdit) && getFboId('depart').value
                                    "
                                    rel="tooltip"
                                    title="Éditer FBO"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            ![EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="handling-switch">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="departHandlingEnabled"
                                    formControlName="departHandlingEnabled"
                                    (change)="updatedHandlingEnabled('depart')"
                                />
                                <label class="form-check-label" for="departHandlingEnabled"
                                    >Handling</label
                                >
                            </div>
                            <button
                                type="button"
                                class="btn btn-icon"
                                (click)="addHandling('depart')"
                                *ngIf="
                                    hasAclAccess(EnumAcl.handlingsAdd) &&
                                    getHandlingEnabled('depart').value
                                "
                                rel="tooltip"
                                title="Ajout handling"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getHandlingEnabled('depart').value"
                        >
                            <div
                                class="placeholder w-100"
                                *ngIf="loadingHandlingsForAirport[airportDepartId.value]"
                            ></div>
                            <div
                                class="input-group"
                                *ngIf="!loadingHandlingsForAirport[airportDepartId.value]"
                            >
                                <select
                                    class="form-select"
                                    formControlName="departHandlingId"
                                    [class.is-invalid]="
                                        (form.touched || getHandlingId('depart').touched) &&
                                        getHandlingId('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.name"
                                        *ngFor="let item of handlingsObj[airportDepartId.value]"
                                    ></option>
                                </select>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getHandlingId('depart').touched) &&
                                            getHandlingId('depart').errors &&
                                            getHandlingId('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editHandling('depart')"
                                    *ngIf="
                                        hasAclAccess(EnumAcl.handlingsEdit) &&
                                        getHandlingId('depart').value
                                    "
                                    rel="tooltip"
                                    title="Éditer handling"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="departAgentEnabled"
                            formControlName="departAgentEnabled"
                            (change)="updatedAgentEnabled('depart')"
                        />
                        <label class="form-check-label" for="departAgentEnabled"
                            >Représentant départ</label
                        >
                    </div>

                    <ng-container *ngIf="getAgentEnabled('depart').value">
                        <div class="form-group mt-2">
                            <select
                                class="form-select"
                                formControlName="departAgentType"
                                [class.is-invalid]="
                                    (form.touched || getAgentType('depart').touched) &&
                                    getAgentType('depart').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (change)="updatedAgentType('depart')"
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="agentType"
                                    [innerText]="getEnquiryFlightAgentTypeLabel(agentType)"
                                    *ngFor="let agentType of agentTypes"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getAgentType('depart').touched) &&
                                        getAgentType('depart').errors &&
                                        getAgentType('depart').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div
                            class="form-group placeholder-glow"
                            *ngIf="
                                getAgentType('depart').value === EnumEnquiryFlightAgentType.internal
                            "
                        >
                            <div class="placeholder w-100" *ngIf="loadingUsers"></div>
                            <select
                                class="form-select"
                                formControlName="departAgentUserId"
                                [class.is-invalid]="
                                    (form.touched || getAgentUserId('depart').touched) &&
                                    getAgentUserId('depart').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (change)="updatedAgentUserId('depart')"
                                *ngIf="!loadingUsers"
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="user.id"
                                    [innerText]="getUserFullname(user) + ' (' + user.initials + ')'"
                                    *ngFor="let user of users"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getAgentUserId('depart').touched) &&
                                        getAgentUserId('depart').errors &&
                                        getAgentUserId('depart').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="
                                getAgentType('depart').value === EnumEnquiryFlightAgentType.external
                            "
                        >
                            <div class="form-group">
                                <label for="departAgentUserFullName">Nom</label>
                                <input
                                    id="departAgentUserFullName"
                                    type="text"
                                    class="form-control"
                                    formControlName="departAgentUserFullName"
                                    [class.is-invalid]="
                                        (form.touched || getAgentUserFullName('depart').touched) &&
                                        getAgentUserFullName('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getAgentUserFullName('depart').touched) &&
                                            getAgentUserFullName('depart').errors &&
                                            getAgentUserFullName('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="departAgentCompany">Société</label>
                                <input
                                    id="departAgentCompany"
                                    type="text"
                                    class="form-control"
                                    formControlName="departAgentCompany"
                                    [class.is-invalid]="
                                        (form.touched || getAgentCompany('depart').touched) &&
                                        getAgentCompany('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentCompany('depart').touched) &&
                                            getAgentCompany('depart').errors &&
                                            getAgentCompany('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="departAgentPhone">Téléphone</label>
                                <input
                                    id="departAgentPhone"
                                    type="text"
                                    class="form-control"
                                    formControlName="departAgentPhone"
                                    [class.is-invalid]="
                                        (form.touched || getAgentPhone('depart').touched) &&
                                        getAgentPhone('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentPhone('depart').touched) &&
                                            getAgentPhone('depart').errors &&
                                            getAgentPhone('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="departAgentEmail">Email</label>
                                <input
                                    id="departAgentEmail"
                                    type="text"
                                    class="form-control"
                                    formControlName="departAgentEmail"
                                    [class.is-invalid]="
                                        (form.touched || getAgentEmail('depart').touched) &&
                                        getAgentEmail('depart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentEmail('depart').touched) &&
                                            getAgentEmail('depart').errors &&
                                            getAgentEmail('depart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="col-lg-4">
                <div class="flight-time mt-4 mb-2">
                    <div class="form-group">
                        <label for="flightNumber">Numéro de vol</label>
                        <input
                            type="text"
                            id="flightNumber"
                            formControlName="flightNumber"
                            class="form-control"
                            [class.is-invalid]="
                                (form.touched || flightNumber.touched) &&
                                flightNumber.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || flightNumber.touched) &&
                                    flightNumber.errors &&
                                    flightNumber.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="flightradar24Url">URL flightradar24</label>
                        <input
                            type="url"
                            id="flightradar24Url"
                            formControlName="flightradar24Url"
                            class="form-control"
                            [class.is-invalid]="
                                (form.touched || flightradar24Url.touched) &&
                                flightradar24Url.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="https://www.flightradar24.com/"
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || flightradar24Url.touched) &&
                                    flightradar24Url.errors &&
                                    flightradar24Url.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="adsbexchangeUrl">URL adsbexchange</label>
                        <input
                            type="url"
                            id="adsbexchangeUrl"
                            formControlName="adsbexchangeUrl"
                            class="form-control"
                            [class.is-invalid]="
                                (form.touched || adsbexchangeUrl.touched) &&
                                adsbexchangeUrl.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            placeholder="https://globe.adsbexchange.com/"
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || adsbexchangeUrl.touched) &&
                                    adsbexchangeUrl.errors &&
                                    adsbexchangeUrl.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <fa-icon [icon]="faClock"></fa-icon>
                    <span
                        [innerText]="convertMinutesToTime(flyTimeInMin.value, 'h ', 'min')"
                        *ngIf="flyTimeInMin.value > 0"
                    ></span>
                    <div class="alert alert-danger text-center" *ngIf="flyTimeInMin.value < 0">
                        Le temps de vol est impossible à calculer. Vérifiez les heures de départ et
                        arrivée.
                    </div>
                    <div class="alert alert-danger text-center" *ngIf="flyTimeInMin.value === 0">
                        Le temps de vol est impossible à calculer car l'heure de départ et d'arrivée
                        sont identiques.
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="flight-itinerary-airport">
                    <fa-icon [icon]="faPlaneArrival"></fa-icon>
                    <app-airport-picker
                        inputName="airportArrivalId"
                        [defaultValue]="airportArrivalId.value"
                        [disabled]="form.disabled"
                        [isInvalid]="
                            (form.touched || airportArrivalId.touched) &&
                            airportArrivalId.status === 'INVALID'
                                ? 'danger'
                                : ''
                        "
                        (setValueToFormControl)="setValueToFormControl($event)"
                    >
                    </app-airport-picker>

                    <div
                        *ngIf="
                            (form.touched || airportArrivalId.touched) &&
                            airportArrivalId.errors &&
                            airportArrivalId.errors['required']
                        "
                        class="text-danger"
                    >
                        Ce champ est obligatoire.
                    </div>

                    <a
                        [routerLink]="'/admin/airports/' + airportArrivalId.value"
                        target="_blank"
                        *ngIf="airportArrivalId.value"
                        [class.text-muted]="!airportArrivalIataCode.value"
                    >
                        {{ airportArrivalIataCode.value || "Code IATA inconnu" }}
                        <fa-icon [icon]="faExternalLink"></fa-icon>
                    </a>
                </div>
                <ng-container *ngIf="airportArrivalId.value">
                    <div class="flight-itinerary-datetime">
                        <div class="form-group">
                            <label for="arrivalDateTimeLocal">Date et heure d'arrivée</label>

                            <div
                                class="input-group"
                                [class.mb-1]="timeField === 'local'"
                                *ngFor="let timeField of ['local', 'utc']"
                            >
                                <input
                                    class="form-control"
                                    [id]="'arrivalDateTime' + capitalize(timeField)"
                                    [formControlName]="'arrivalDateTime' + capitalize(timeField)"
                                    type="datetime-local"
                                    [min]="
                                        getDateTimeField('depart', timeField).value
                                            ? getDateTimeField('depart', timeField).value
                                            : '2000-01-01'
                                    "
                                    [class.is-invalid]="
                                        (form.touched ||
                                            getDateTimeField('arrival', timeField).touched) &&
                                        getDateTimeField('arrival', timeField).status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedDateTime('arrival', timeField)"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getDateTimeField('arrival', timeField).touched) &&
                                            getDateTimeField('arrival', timeField).errors &&
                                            getDateTimeField('arrival', timeField).errors[
                                                'required'
                                            ]
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getDateTimeField('arrival', timeField).touched) &&
                                            getDateTimeField('arrival', timeField).errors &&
                                            getDateTimeField('arrival', timeField).errors[
                                                'dateBelowMinimum'
                                            ]
                                        "
                                    >
                                        La date doit être supérieure à
                                        {{
                                            getDateTimeField("arrival", timeField).errors[
                                                "dateMinimum"
                                            ] | date : "dd/MM/yyyy"
                                        }}.
                                    </div>
                                </div>

                                <span
                                    class="input-group-text"
                                    rel="tooltip"
                                    title="Heure locale"
                                    *ngIf="timeField === 'local'"
                                    >LT</span
                                >
                                <span class="input-group-text" *ngIf="timeField === 'utc'"
                                    >UTC</span
                                >
                            </div>
                        </div>
                    </div>

                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="arrivalSlotEnabled"
                            formControlName="arrivalSlotEnabled"
                            (change)="updatedSlotEnabled('arrival')"
                        />
                        <label class="form-check-label" for="arrivalSlotEnabled">Slot</label>
                    </div>

                    <div
                        class="form-group placeholder-glow mt-2"
                        *ngIf="getSlotEnabled('arrival').value"
                    >
                        <div class="input-group">
                            <input
                                type="time"
                                class="form-control"
                                formControlName="arrivalSlotTime"
                                [class.is-invalid]="
                                    (form.touched || getSlotTime('arrival').touched) &&
                                    getSlotTime('arrival').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getSlotTime('arrival').touched) &&
                                        getSlotTime('arrival').errors &&
                                        getSlotTime('arrival').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <span class="input-group-text" rel="tooltip" title="Heure locale"
                                >LT</span
                            >
                        </div>
                    </div>

                    <ng-container
                        *ngIf="
                            ![EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="arrivalTerminalEnabled"
                                formControlName="arrivalTerminalEnabled"
                                (change)="getTerminalEnabled('arrival')"
                            />
                            <label class="form-check-label" for="arrivalTerminalEnabled"
                                >Terminal</label
                            >
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getTerminalEnabled('arrival').value"
                        >
                            <input
                                type="text"
                                class="form-control"
                                formControlName="arrivalTerminal"
                                [class.is-invalid]="
                                    (form.touched || getTerminal('arrival').touched) &&
                                    getTerminal('arrival').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getTerminal('arrival').touched) &&
                                        getTerminal('arrival').errors &&
                                        getTerminal('arrival').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            [EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="fbo-switch">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="arrivalFboEnabled"
                                    formControlName="arrivalFboEnabled"
                                    (change)="updatedFboEnabled('arrival')"
                                />
                                <label class="form-check-label" for="arrivalFboEnabled">FBO</label>
                            </div>
                            <button
                                type="button"
                                class="btn btn-icon"
                                (click)="addFbo('arrival')"
                                *ngIf="
                                    hasAclAccess(EnumAcl.fbosAdd) && getFboEnabled('arrival').value
                                "
                                rel="tooltip"
                                title="Ajout fbo"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getFboEnabled('arrival').value"
                        >
                            <div
                                class="placeholder w-100"
                                *ngIf="loadingFbosForAirport[airportArrivalId.value]"
                            ></div>
                            <div
                                class="input-group"
                                *ngIf="!loadingFbosForAirport[airportArrivalId.value]"
                            >
                                <select
                                    class="form-select"
                                    formControlName="arrivalFboId"
                                    [class.is-invalid]="
                                        (form.touched || getFboId('arrival').touched) &&
                                        getFboId('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.name"
                                        *ngFor="let item of fbosObj[airportArrivalId.value]"
                                    ></option>
                                </select>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getFboId('arrival').touched) &&
                                            getFboId('arrival').errors &&
                                            getFboId('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editFbo('arrival')"
                                    *ngIf="
                                        hasAclAccess(EnumAcl.fbosEdit) && getFboId('arrival').value
                                    "
                                    rel="tooltip"
                                    title="Éditer fbo"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            ![EnumEnquiryType.business, EnumEnquiryType.helico].includes(
                                enquiry?.type
                            )
                        "
                    >
                        <div class="fbo-switch">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="arrivalHandlingEnabled"
                                    formControlName="arrivalHandlingEnabled"
                                    (change)="updatedHandlingEnabled('arrival')"
                                />
                                <label class="form-check-label" for="arrivalHandlingEnabled"
                                    >Handling</label
                                >
                            </div>
                            <button
                                type="button"
                                class="btn btn-icon"
                                (click)="addHandling('arrival')"
                                *ngIf="
                                    hasAclAccess(EnumAcl.handlingsAdd) &&
                                    getHandlingEnabled('arrival').value
                                "
                                rel="tooltip"
                                title="Ajout Handling"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </div>

                        <div
                            class="form-group placeholder-glow mt-2"
                            *ngIf="getHandlingEnabled('arrival').value"
                        >
                            <div
                                class="placeholder w-100"
                                *ngIf="loadingHandlingsForAirport[airportArrivalId.value]"
                            ></div>
                            <div
                                class="input-group"
                                *ngIf="!loadingHandlingsForAirport[airportArrivalId.value]"
                            >
                                <select
                                    class="form-select"
                                    formControlName="arrivalHandlingId"
                                    [class.is-invalid]="
                                        (form.touched || getHandlingId('arrival').touched) &&
                                        getHandlingId('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                >
                                    <option [ngValue]="null">-- Choisissez --</option>
                                    <option
                                        [value]="item.id"
                                        [innerText]="item.name"
                                        *ngFor="let item of handlingsObj[airportArrivalId.value]"
                                    ></option>
                                </select>
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getHandlingId('arrival').touched) &&
                                            getHandlingId('arrival').errors &&
                                            getHandlingId('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    (click)="editHandling('arrival')"
                                    *ngIf="
                                        hasAclAccess(EnumAcl.fbosEdit) &&
                                        getHandlingId('arrival').value
                                    "
                                    rel="tooltip"
                                    title="Éditer handling"
                                >
                                    <fa-icon [icon]="faEdit"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="arrivalAgentEnabled"
                            formControlName="arrivalAgentEnabled"
                            (change)="updatedAgentEnabled('arrival')"
                        />
                        <label class="form-check-label" for="arrivalAgentEnabled"
                            >Représentant arrivée</label
                        >
                    </div>

                    <ng-container *ngIf="getAgentEnabled('arrival').value">
                        <div class="form-group mt-2">
                            <select
                                class="form-select"
                                formControlName="arrivalAgentType"
                                [class.is-invalid]="
                                    (form.touched || getAgentType('arrival').touched) &&
                                    getAgentType('arrival').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (change)="updatedAgentType('arrival')"
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="agentType"
                                    [innerText]="getEnquiryFlightAgentTypeLabel(agentType)"
                                    *ngFor="let agentType of agentTypes"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getAgentType('arrival').touched) &&
                                        getAgentType('arrival').errors &&
                                        getAgentType('arrival').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                        <div
                            class="form-group placeholder-glow"
                            *ngIf="
                                getAgentType('arrival').value ===
                                EnumEnquiryFlightAgentType.internal
                            "
                        >
                            <div class="placeholder w-100" *ngIf="loadingUsers"></div>
                            <select
                                class="form-select"
                                formControlName="arrivalAgentUserId"
                                [class.is-invalid]="
                                    (form.touched || getAgentUserId('arrival').touched) &&
                                    getAgentUserId('arrival').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (change)="updatedAgentUserId('arrival')"
                                *ngIf="!loadingUsers"
                            >
                                <option [ngValue]="null">-- Choisissez --</option>
                                <option
                                    [value]="user.id"
                                    [innerText]="getUserFullname(user)"
                                    *ngFor="let user of users"
                                ></option>
                            </select>
                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getAgentUserId('arrival').touched) &&
                                        getAgentUserId('arrival').errors &&
                                        getAgentUserId('arrival').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="
                                getAgentType('arrival').value ===
                                EnumEnquiryFlightAgentType.external
                            "
                        >
                            <div class="form-group">
                                <label for="arrivalAgentUserFullName">Nom</label>
                                <input
                                    id="arrivalAgentUserFullName"
                                    type="text"
                                    class="form-control"
                                    formControlName="arrivalAgentUserFullName"
                                    [class.is-invalid]="
                                        (form.touched || getAgentUserFullName('arrival').touched) &&
                                        getAgentUserFullName('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getAgentUserFullName('arrival').touched) &&
                                            getAgentUserFullName('arrival').errors &&
                                            getAgentUserFullName('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="arrivalAgentCompany">Société</label>
                                <input
                                    id="arrivalAgentCompany"
                                    type="text"
                                    class="form-control"
                                    formControlName="arrivalAgentCompany"
                                    [class.is-invalid]="
                                        (form.touched || getAgentCompany('arrival').touched) &&
                                        getAgentCompany('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentCompany('arrival').touched) &&
                                            getAgentCompany('arrival').errors &&
                                            getAgentCompany('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="arrivalAgentPhone">Téléphone</label>
                                <input
                                    id="arrivalAgentPhone"
                                    type="text"
                                    class="form-control"
                                    formControlName="arrivalAgentPhone"
                                    [class.is-invalid]="
                                        (form.touched || getAgentPhone('arrival').touched) &&
                                        getAgentPhone('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentPhone('arrival').touched) &&
                                            getAgentPhone('arrival').errors &&
                                            getAgentPhone('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="arrivalAgentEmail">Email</label>
                                <input
                                    id="arrivalAgentEmail"
                                    type="text"
                                    class="form-control"
                                    formControlName="arrivalAgentEmail"
                                    [class.is-invalid]="
                                        (form.touched || getAgentEmail('arrival').touched) &&
                                        getAgentEmail('arrival').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />
                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getAgentEmail('arrival').touched) &&
                                            getAgentEmail('arrival').errors &&
                                            getAgentEmail('arrival').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="isAircraftGroundedOnArrival"
                            formControlName="isAircraftGroundedOnArrival"
                        />
                        <label class="form-check-label" for="isAircraftGroundedOnArrival"
                            >Reste en immobilisation</label
                        >
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="card mb-4">
                    <div class="card-header">Détails appareil</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="countryCode">Compagnie</label>
                                    <app-airline-autocomplete
                                        (setValueToFormControl)="setValueToFormControl($event)"
                                        inputName="airlineId"
                                        [defaultValue]="
                                            airlineId.value && airlinesObj[airlineId.value]
                                                ? airlinesObj[airlineId.value]
                                                : null
                                        "
                                        [disabled]="form.disabled"
                                        placeholder="Recherchez une compagnie aérienne"
                                    ></app-airline-autocomplete>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || airlineId.touched) &&
                                                airlineId.errors &&
                                                airlineId.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="airlineId.value">
                                <div class="form-group">
                                    <label for="aircraftModelId">Appareil</label>

                                    <div
                                        class="placeholder w-100"
                                        *ngIf="loadingAircraftsCompiled"
                                    ></div>
                                    <div
                                        *ngIf="!loadingAircraftsCompiled && !aircraftModels.length"
                                        class="alert alert-warning alert-sm text-center"
                                    >
                                        Aucun appareil trouvé
                                    </div>
                                    <select
                                        class="form-select"
                                        id="aircraftModelId"
                                        formControlName="aircraftModelId"
                                        [class.is-invalid]="
                                            (form.touched || aircraftModelId.touched) &&
                                            aircraftModelId.status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updatedAircraftModelId()"
                                        *ngIf="!loadingAircraftsCompiled && aircraftModels.length"
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            *ngFor="let aircraftModel of aircraftModels"
                                            [value]="aircraftModel.id"
                                            [innerText]="aircraftModel.title"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || aircraftModelId.touched) &&
                                                aircraftModelId.errors &&
                                                aircraftModelId.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="aircraftModelId.value">
                                <div class="form-group">
                                    <label for="aircraftId">Immatriculation</label>

                                    <div class="placeholder w-100" *ngIf="loadingAircrafts"></div>
                                    <div
                                        *ngIf="!loadingAircrafts && !aircrafts.length"
                                        class="alert alert-warning alert-sm text-center"
                                    >
                                        Aucun appareil trouvé
                                    </div>
                                    <select
                                        class="form-select"
                                        id="aircraftId"
                                        formControlName="aircraftId"
                                        [class.is-invalid]="
                                            (form.touched || aircraftId.touched) &&
                                            aircraftId.status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (change)="updatedAircraftId()"
                                        *ngIf="!loadingAircrafts && aircrafts.length"
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            *ngFor="let aircraft of aircrafts"
                                            [value]="aircraft.id"
                                            [innerText]="
                                                aircraft.registration !== ''
                                                    ? aircraft.registration
                                                    : 'Immatriculation inconnu (id : ' +
                                                      aircraft.id +
                                                      ')'
                                            "
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || aircraftId.touched) &&
                                                aircraftId.errors &&
                                                aircraftId.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card mb-4">
                    <div class="card-header">Détails emport</div>
                    <div class="card-body">
                        <div
                            class="form-check form-switch d-flex justify-content-center align-items-center gap-2"
                            *ngIf="isFirst"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="isFerryFlight"
                                formControlName="isFerryFlight"
                                (change)="updateIsFerryFlight()"
                            />
                            <label class="form-check-label" for="isFerryFlight"
                                >Vol de positionnement</label
                            >
                        </div>
                        <ng-container *ngIf="!isFerryFlight.value">
                            <ng-container
                                *ngIf="enquiryFlight?.enquiryType !== EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="pax">Pax</label>

                                    <input
                                        type="number"
                                        class="form-control"
                                        id="pax"
                                        formControlName="pax"
                                        [class.is-invalid]="
                                            (form.touched || pax.touched) &&
                                            pax.status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || pax.touched) &&
                                                pax.errors &&
                                                pax.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container
                                *ngIf="enquiryFlight?.enquiryType === EnumEnquiryType.cargo"
                            >
                                <div class="form-group">
                                    <label for="weight">Poids</label>

                                    <div class="input-group">
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="weight"
                                            formControlName="weight"
                                            [class.is-invalid]="
                                                (form.touched || weight.touched) &&
                                                weight.status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || weight.touched) &&
                                                    weight.errors &&
                                                    weight.errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>

                                        <span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="volume">Volume</label>
                                    <div class="input-group">
                                        <input
                                            type="number"
                                            class="form-control"
                                            id="volume"
                                            formControlName="volume"
                                            [class.is-invalid]="
                                                (form.touched || volume.touched) &&
                                                volume.status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || volume.touched) &&
                                                    volume.errors &&
                                                    volume.errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>

                                        <span class="input-group-text">m³</span>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div
                class="card-header"
                [innerText]="
                    [EnumEnquiryType.cargo, EnumEnquiryType.commercial].includes(enquiry?.type)
                        ? 'Détails opérateur'
                        : 'Détails équipage'
                "
            ></div>
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                            <label for="opsH24Phone">OPS H24</label>
                            <input
                                id="opsH24Phone"
                                class="form-control"
                                type="text"
                                formControlName="opsH24Phone"
                                [class.is-invalid]="
                                    (form.touched || opsH24Phone.touched) &&
                                    opsH24Phone.status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || opsH24Phone.touched) &&
                                        opsH24Phone.errors &&
                                        opsH24Phone.errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="[EnumEnquiryType.business].includes(enquiry?.type)">
                        <div class="col-md-6 col-lg-4" *ngIf="airlineId.value">
                            <div class="form-group placeholder-glow">
                                <label for="captainId" class="d-flex justify-content-between"
                                    >Commandant de bord

                                    <button
                                        type="button"
                                        class="btn btn-icon"
                                        (click)="addCaptain()"
                                    >
                                        <fa-icon [icon]="faPlusCircle"></fa-icon>
                                    </button>
                                </label>
                                <div class="placeholder w-100" *ngIf="loadingCrewsForAirline"></div>
                                <div
                                    class="alert alert-warning alert-sm text-center"
                                    *ngIf="
                                        !loadingCrewsForAirline &&
                                        !crewsByType[EnumCrewType.captain]?.length
                                    "
                                >
                                    Aucun commandant
                                </div>
                                <div
                                    class="input-group"
                                    *ngIf="
                                        !loadingCrewsForAirline &&
                                        crewsByType[EnumCrewType.captain]?.length
                                    "
                                >
                                    <select
                                        id="captainId"
                                        class="form-select"
                                        formControlName="captainId"
                                        [class.is-invalid]="
                                            (form.touched || captainId.touched) &&
                                            captainId.status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            [value]="item.id"
                                            [innerText]="item.lastname + ' ' + item.firstname"
                                            *ngFor="let item of crewsByType[EnumCrewType.captain]"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || captainId.touched) &&
                                                captainId.errors &&
                                                captainId.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        (click)="editCaptain()"
                                        *ngIf="hasAclAccess(EnumAcl.crewsEdit) && captainId.value"
                                        rel="tooltip"
                                        title="Éditer commandant de bord"
                                    >
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4" *ngIf="airlineId.value">
                            <div class="form-group placeholder-glow">
                                <label for="coPilotId" class="d-flex justify-content-between"
                                    >Copilote

                                    <button
                                        type="button"
                                        class="btn btn-icon"
                                        (click)="addCoPilot()"
                                    >
                                        <fa-icon [icon]="faPlusCircle"></fa-icon>
                                    </button>
                                </label>
                                <div class="placeholder w-100" *ngIf="loadingCrewsForAirline"></div>
                                <div
                                    class="alert alert-warning alert-sm text-center"
                                    *ngIf="
                                        !loadingCrewsForAirline &&
                                        !crewsByType[EnumCrewType.coPilot]?.length
                                    "
                                >
                                    Aucun copilote
                                </div>
                                <div
                                    class="input-group"
                                    *ngIf="
                                        !loadingCrewsForAirline &&
                                        crewsByType[EnumCrewType.coPilot]?.length
                                    "
                                >
                                    <select
                                        id="coPilotId"
                                        class="form-select"
                                        formControlName="coPilotId"
                                        [class.is-invalid]="
                                            (form.touched || coPilotId.touched) &&
                                            coPilotId.status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    >
                                        <option [ngValue]="null">-- Choisissez --</option>
                                        <option
                                            [value]="item.id"
                                            [innerText]="item.lastname + ' ' + item.firstname"
                                            *ngFor="let item of crewsByType[EnumCrewType.coPilot]"
                                        ></option>
                                    </select>

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || coPilotId.touched) &&
                                                coPilotId.errors &&
                                                coPilotId.errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        (click)="editCoPilot()"
                                        *ngIf="hasAclAccess(EnumAcl.crewsEdit) && coPilotId.value"
                                        rel="tooltip"
                                        title="Éditer copilote"
                                    >
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3" *ngIf="airlineId.value">
                            <div class="form-group placeholder-glow">
                                <div class="d-flex gap-4">
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="stewardessEnabled"
                                            formControlName="stewardessEnabled"
                                            (change)="updatedStewardessEnabled()"
                                        />
                                        <label class="form-check-label" for="stewardessEnabled"
                                            >Hôtesse(s)</label
                                        >
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-icon"
                                        (click)="addStewardess()"
                                        *ngIf="stewardessEnabled.value"
                                    >
                                        <fa-icon [icon]="faPlusCircle"></fa-icon>
                                    </button>
                                </div>
                                <ng-container *ngIf="stewardessEnabled.value">
                                    <div
                                        class="placeholder w-100"
                                        *ngIf="loadingCrewsForAirline"
                                    ></div>

                                    <div
                                        class="alert alert-warning alert-sm text-center mb-1"
                                        *ngIf="
                                            !loadingCrewsForAirline &&
                                            !crewsByType[EnumCrewType.stewardess]?.length
                                        "
                                    >
                                        Aucune hôtesse
                                    </div>

                                    <div
                                        class="form-check"
                                        *ngFor="let crew of crewsByType[EnumCrewType.stewardess]"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [id]="'crew-' + crew.id"
                                            [checked]="stewardessIds.value.includes(crew.id)"
                                            (change)="toggleStewardessId(crew.id)"
                                        />
                                        <label
                                            class="form-check-label"
                                            [attr.for]="'crew-' + crew.id"
                                            [innerText]="crew.firstname + ' ' + crew.lastname"
                                        >
                                        </label>

                                        <button
                                            type="button"
                                            class="btn btn-icon"
                                            (click)="editStewardess(crew.id)"
                                        >
                                            <fa-icon [icon]="faEdit"></fa-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-6 col-lg-3" *ngIf="airlineId.value">
                        <div class="form-group placeholder-glow">
                            <div class="d-flex gap-4">
                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="supervisorEnabled"
                                        formControlName="supervisorEnabled"
                                        (change)="updatedSupervisorEnabled()"
                                    />
                                    <label class="form-check-label" for="supervisorEnabled"
                                        >Superviseur(s)</label
                                    >
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-icon"
                                    (click)="addSupervisor()"
                                    *ngIf="supervisorEnabled.value"
                                >
                                    <fa-icon [icon]="faPlusCircle"></fa-icon>
                                </button>
                            </div>

                            <ng-container *ngIf="supervisorEnabled.value">
                                <div
                                    class="placeholder w-100"
                                    *ngIf="loadingSupervisorsForAirline"
                                ></div>

                                <div
                                    class="alert alert-warning alert-sm text-center"
                                    *ngIf="!loadingSupervisorsForAirline && !supervisors.length"
                                >
                                    Aucun superviseur
                                </div>

                                <div class="form-check" *ngFor="let supervisor of supervisors">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [id]="'supervisor-' + supervisor.id"
                                        [checked]="supervisorIds.value.includes(supervisor.id)"
                                        (change)="toggleSupervisorId(supervisor.id)"
                                    />
                                    <label
                                        class="form-check-label"
                                        [attr.for]="'supervisor-' + supervisor.id"
                                        [innerText]="
                                            supervisor.firstname + ' ' + supervisor.lastname
                                        "
                                    >
                                    </label>

                                    <button
                                        type="button"
                                        class="btn btn-icon"
                                        (click)="editSupervisor(supervisor.id)"
                                    >
                                        <fa-icon [icon]="faEdit"></fa-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="comment">Remarque</label>
            <textarea
                rows="3"
                class="form-control"
                id="comment"
                formControlName="comment"
                [class.is-invalid]="
                    (form.touched || comment.touched) && comment.status === 'INVALID'
                        ? 'danger'
                        : ''
                "
            ></textarea>

            <div class="invalid-tooltip">
                <div
                    *ngIf="
                        (form.touched || comment.touched) &&
                        comment.errors &&
                        comment.errors['required']
                    "
                >
                    Ce champ est obligatoire.
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between" *ngIf="inModal">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="form.disabled || !form.valid"
        (click)="submitForm()"
    >
        Sauvegarder
    </button>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalFbo>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingFbo ? 'Édition d\'un FBO' : 'Ajout d\'un FBO'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-fbo-edit
                [inModal]="true"
                [defaultAirportId]="
                    form.get('airport' + capitalize(currentAirportEdited) + 'Id').value
                "
                [fboId]="editingFbo?.id || null"
                *ngIf="currentAirportEdited"
                (dismissModal)="dismissFboModal($event)"
            ></app-fbo-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalHandling>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingFbo ? 'Édition d\'un handling' : 'Ajout d\'un handling'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-handling-edit
                [inModal]="true"
                [defaultAirportId]="
                    form.get('airport' + capitalize(currentAirportEdited) + 'Id').value
                "
                [handlingId]="editingHandling?.id || null"
                *ngIf="currentAirportEdited"
                (dismissModal)="dismissHandlingModal($event)"
            ></app-handling-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalCaptain>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingCaptain
                            ? 'Édition d\'un commandant de bord'
                            : 'Ajout d\'un commandant de bord'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingCaptain?.id || null"
                [defaultAirlineId]="airlineId.value"
                [defaultCrewType]="EnumCrewType.captain"
                *ngIf="airlineId.value"
                (dismissModal)="dismissCaptainModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalCoPilot>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="editingCoPilot ? 'Édition d\'un copilote' : 'Ajout d\'un copilote'"
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingCoPilot?.id || null"
                [defaultAirlineId]="airlineId.value"
                [defaultCrewType]="EnumCrewType.coPilot"
                *ngIf="airlineId.value"
                (dismissModal)="dismissCoPilotModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalStewardess>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingStewardess ? 'Édition d\'une hôtesse' : 'Ajout d\'une hôtesse'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-crew-edit
                [inModal]="true"
                [crewId]="editingStewardess?.id || null"
                [defaultAirlineId]="airlineId.value"
                [defaultCrewType]="EnumCrewType.stewardess"
                *ngIf="airlineId.value"
                (dismissModal)="dismissStewardessModal($event)"
            ></app-crew-edit>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalSupervisor>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    [innerText]="
                        editingSupervisor ? 'Édition d\'un superviseur' : 'Ajout d\'un superviseur'
                    "
                ></h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-airline-supervisor-edit
                [inModal]="true"
                [airlineSupervisorId]="editingSupervisor?.id || null"
                [defaultAirlineId]="airlineId.value"
                *ngIf="airlineId.value"
                (dismissModal)="dismissSupervisorModal($event)"
            ></app-airline-supervisor-edit>
        </div>
    </div>
</div>
