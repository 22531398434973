<div class="row">
    <div class="col-md-8">
        <div class="card mb-4">
            <div class="card-header">Profil</div>
            <div class="card-body">
                <ul *ngIf="loading" class="list-unstyled mb-0">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="placeholder w-100"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && user">
                    <ul class="list-unstyled mb-0">
                        <li><strong>Nom prénom</strong> : {{ getUserFullname(user) }}</li>
                        <li><strong>Initiales</strong> : {{ user.initials }}</li>
                        <li>
                            <strong>Email</strong> :
                            <a
                                [href]="'mailto:' + user.email"
                                target="_blank"
                                [innerHtml]="user.email"
                                *ngIf="user.email"
                            ></a>
                        </li>
                        <li>
                            <strong>Téléphone</strong> :
                            <a
                                [href]="'tel:' + user.phone"
                                target="_blank"
                                [innerHtml]="user.phone"
                                *ngIf="user.phone"
                            ></a>
                        </li>
                        <li>
                            <strong>Groupe d'utilisateurs</strong> :
                            {{ userGroup ? userGroup.title : "" }}
                        </li>
                        <li>
                            <strong>Signature d'email</strong> : <br />
                            <div [innerHtml]="user.emailSignatureHtml | safeHtml"></div>
                        </li>
                        <li>
                            <strong>Email en CC dans les envois d'email</strong> :
                            {{ user.emailInCc ? "Oui" : "Non" }}
                        </li>
                        <li><strong>Créé le :</strong> {{ user.created | date : "dd/MM/yyyy" }}</li>
                        <li>
                            <strong>Couleur assigné :</strong>
                            <span class="color" [style.background-color]="user.primaryColor"></span>
                        </li>
                        <li>
                            <strong>Affiché dans les statistiques :</strong>
                            {{ user.displayInStatistics ? "Oui" : "Non" }}
                        </li>
                        <li>
                            <strong>Mot de passe défini par l'utilisateur :</strong>
                            {{ user.userAuthUID ? "Oui" : "Non" }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header">Envoi d'emails</div>
            <div class="card-body">
                <ul *ngIf="loading" class="list-unstyled mb-0">
                    <li *ngFor="let number of [].constructor(2)">
                        <div class="placeholder w-100"></div>
                    </li>
                </ul>
                <div *ngIf="!loading && user">
                    <ul class="list-unstyled mb-0">
                        <li>
                            <strong>Signature d'email</strong> : <br />
                            <div [innerHtml]="user.emailSignatureHtml | safeHtml"></div>
                        </li>
                        <li>
                            <strong>Email en CC dans les envois d'email</strong> :
                            {{ user.emailInCc ? "Oui" : "Non" }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card mb-4">
            <div class="card-header">Pipedrive</div>
            <div class="card-body">
                <ul *ngIf="loading" class="list-unstyled mb-0">
                    <li *ngFor="let number of [].constructor(4)">
                        <div class="placeholder w-100"></div>
                    </li>
                </ul>
                <ng-container *ngIf="!loading">
                    <div class="alert alert-info text-center mb-0" *ngIf="!user?.pipedriveId">
                        L'utilisateur n'est lié à aucun compte Pipedrive.
                    </div>
                    <ul class="list-unstyled mb-0" *ngIf="user?.pipedriveId">
                        <li><strong>ID Pipedrive</strong> : {{ user?.pipedriveId }}</li>
                        <li *ngIf="user?.pipedriveId">
                            <strong>Utilisateur</strong> :
                            <a
                                [href]="
                                    'https://artheauaviation2.pipedrive.com/users/details/' +
                                    user?.pipedriveId
                                "
                                target="_blank"
                                *ngIf="pipedriveUser"
                                ><fa-icon [icon]="faExternalLinkAlt" class="me-1"></fa-icon
                                >{{ pipedriveUser.name }}</a
                            >
                            <span *ngIf="!pipedriveUser">Inconnu</span>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </div>

        <div class="btn-group-vertical" role="group" *ngIf="user">
            <a
                [routerLink]="'/admin/users/' + userId + '/edit'"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.usersEdit)"
                ><fa-icon [icon]="faEdit"></fa-icon> Modifier</a
            >
            <a
                class="btn btn-primary"
                [routerLink]="'/admin/statistics/user/' + userId"
                *ngIf="hasAclAccess(EnumAcl.usersStatistics)"
                ><fa-icon [icon]="faChartSimple"></fa-icon> Statistiques</a
            >
            <button
                type="button"
                (click)="resendEmailSetPassword()"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.usersSendEmailResetPassword)"
            >
                <fa-icon [icon]="faKey"></fa-icon> Envoyer email pour mot de passe
            </button>
            <button
                type="button"
                (click)="toggleDisable()"
                class="btn"
                [class.btn-warning]="!user.isDisabled"
                [class.btn-success]="user.isDisabled"
                *ngIf="hasAclAccess(EnumAcl.usersDisable)"
                [disabled]="disabling"
            >
                <ng-container *ngIf="!user.isDisabled">
                    <fa-icon [icon]="faUserXmark"></fa-icon>
                    {{ disabling ? "Désactivation en cours ..." : "Désactivation" }}
                </ng-container>
                <ng-container *ngIf="user.isDisabled">
                    <fa-icon [icon]="faUserCheck"></fa-icon>
                    {{ disabling ? "Activation en cours ..." : "Activation" }}
                </ng-container>
            </button>
            <button
                type="button"
                (click)="delete()"
                class="btn btn-danger"
                *ngIf="hasAclAccess(EnumAcl.usersDelete)"
            >
                <fa-icon [icon]="faTrash"></fa-icon>
                Supprimer
            </button>
        </div>
    </div>
</div>
