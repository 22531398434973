<div class="row">
    <div class="col-md-4 col-lg-3">
        <div class="card mb-4">
            <div class="card-header text-center placeholder-glow">
                <div class="placeholder w-100" *ngIf="!client"></div>
                <div class="card-title" [innerText]="client.name" *ngIf="client"></div>
            </div>
            <div class="card-body placeholder-glow" *ngIf="loading">
                <div class="placeholder w-100" *ngFor="let number of [].constructor(5)"></div>
            </div>
            <div class="card-body" *ngIf="!loading && client">
                <dl>
                    <dt>Adresse</dt>
                    <dd [innerHtml]="client.address"></dd>
                    <dt>Pipedrive</dt>
                    <dd>
                        <div class="btn-group">
                            <a
                                class="btn btn-secondary btn-sm"
                                [href]="
                                    'https://artheauaviation2.pipedrive.com/organization/' +
                                    clientId
                                "
                                target="_blank"
                                >Ouvrir <fa-icon [icon]="faExternalLink"></fa-icon
                            ></a>
                            <button
                                type="button"
                                (click)="importFromPipedrive()"
                                class="btn btn-secondary btn-sm"
                            >
                                Rafraichir <fa-icon [icon]="faRefresh"></fa-icon>
                            </button>
                        </div>
                    </dd>
                    <dt>Date d'ajout</dt>
                    <dd [innerHtml]="client.add_time | date : 'dd/MM/yyyy HH:mm'"></dd>
                    <dt>Dernière mise à jour</dt>
                    <dd [innerHtml]="client.update_time | date : 'dd/MM/yyyy HH:mm'"></dd>
                </dl>

                <div class="btn-toolbar d-flex gap-2 justify-content-center">
                    <a
                        class="btn btn-primary btn-sm"
                        [routerLink]="'/admin/clients/' + clientId + '/statistics'"
                        *ngIf="hasAclAccess(EnumAcl.clientsStatistics)"
                        ><fa-icon [icon]="faChartSimple"></fa-icon
                    ></a>
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-danger btn-sm"
                        *ngIf="hasAclAccess(EnumAcl.clientsDelete)"
                    >
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header">Addresse de facturation</div>
            <div class="card-body" *ngIf="loadingClientBillingInfos">
                <div class="placeholder w-100" *ngFor="let number of [].constructor(14)"></div>
            </div>
            <div class="card-body" *ngIf="!loadingClientBillingInfos">
                <div class="alert alert-warning text-center mb-0" *ngIf="!clientBillingInfos">
                    Aucun adresse de facturation pour ce client.
                </div>
                <dl *ngIf="clientBillingInfos" class="mb-0">
                    <dt>Nom du client sur la facture</dt>
                    <dd
                        [class.text-muted]="!clientBillingInfos.clientName"
                        [innerText]="clientBillingInfos.clientName || 'Vide'"
                    ></dd>
                    <dt>Rue</dt>
                    <dd
                        [class.text-muted]="!clientBillingInfos.street"
                        [innerText]="clientBillingInfos.street || 'Vide'"
                    ></dd>
                    <dt>Code postal</dt>
                    <dd
                        [class.text-muted]="!clientBillingInfos.postalCode"
                        [innerText]="clientBillingInfos.postalCode || 'Vide'"
                    ></dd>
                    <dt>Ville</dt>
                    <dd
                        [class.text-muted]="!clientBillingInfos.city"
                        [innerText]="clientBillingInfos.city || 'Vide'"
                    ></dd>
                    <dt>Pays</dt>
                    <dd
                        [class.text-muted]="
                            !clientBillingInfos.countryCode ||
                            !countries[clientBillingInfos.countryCode]
                        "
                        [innerText]="countries[clientBillingInfos.countryCode] || 'Vide'"
                    ></dd>
                    <dt>TVA Intracommunautaire</dt>
                    <dd
                        [class.text-muted]="!clientBillingInfos.tvaNumber"
                        [innerText]="clientBillingInfos.tvaNumber || 'Vide'"
                    ></dd>
                    <dt>Siret</dt>
                    <dd
                        class="mb-0"
                        [class.text-muted]="!clientBillingInfos.siret"
                        [innerText]="clientBillingInfos.siret || 'Vide'"
                    ></dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-lg-9">
        <ul class="nav nav-pills mb-4">
            <li class="nav-item" *ngFor="let tab of tabs">
                <button
                    type="button"
                    class="nav-link"
                    [class.active]="currentTab === tab.value"
                    [attr.aria-current]="currentTab === tab.value ? 'page' : ''"
                    [innerText]="tab.title"
                    (click)="updateCurrentTab(tab.value)"
                ></button>
            </li>
        </ul>

        <div *ngIf="currentTab === EnumTab.enquiries">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <select
                            class="form-select"
                            id="filterByPeriod"
                            [(ngModel)]="filterByPeriod"
                            (change)="updateFilterPeriod()"
                        >
                            <option [ngValue]="null">Toutes les périodes</option>
                            <option
                                [value]="filter.value"
                                *ngFor="let filter of filtersByPeriod"
                                [innerHtml]="filter.title"
                            ></option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <select
                            class="form-select"
                            id="enquiryStatus"
                            [(ngModel)]="enquiryStatus"
                            required
                            (change)="enquiryStatusChanged()"
                        >
                            <option [ngValue]="null">Tous les statuts</option>
                            <option
                                [value]="status"
                                *ngFor="let status of getEnquiryStatuses()"
                                [innerText]="getEnumEnquiryStatusLabel(status)"
                            ></option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4 text-end">
                    <a
                        [routerLink]="'/admin/enquiries/add-to-client/' + clientId"
                        class="btn btn-primary mb-4"
                        *ngIf="hasAclAccess(EnumAcl.enquiriesAdd)"
                        >+ Nouvelle demande</a
                    >
                </div>
            </div>

            <app-enquiries-list-for-client
                [clientId]="clientId"
                [enquiryStatus]="enquiryStatus"
                [filterByPeriod]="filterByPeriod"
            ></app-enquiries-list-for-client>
        </div>

        <div *ngIf="currentTab === EnumTab.invoices">
            <div class="text-end mb-4">
                <a
                    [routerLink]="'/admin/invoices/add/add-to-client/' + clientId"
                    class="btn btn-primary"
                    *ngIf="hasAclAccess(EnumAcl.invoicesAddFinalInvoice)"
                    >+ Nouvelle facture</a
                >
            </div>

            <app-invoices-list-for-client [clientId]="clientId"></app-invoices-list-for-client>
        </div>

        <div *ngIf="currentTab === EnumTab.flightBriefs">
            <div class="mb-4"></div>
            <app-flight-briefs-list-for-client
                [clientId]="clientId"
            ></app-flight-briefs-list-for-client>
        </div>
    </div>
</div>
