<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [openItemEvent]="true"
    [orderBy]="orderBy"
    [currentPage]="currentPage"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    (openItem)="openItem.emit($event)"
></app-data-list>
