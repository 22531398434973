<div class="btn-toolbar mb-4" role="toolbar">
    <a routerLink="/admin/users/add" class="btn btn-primary" *ngIf="hasAclAccess(EnumAcl.usersAdd)"
        >+ Nouvel utilisateur</a
    >
</div>

<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [rowUrl]="'/admin/users/'"
    [orderBy]="orderBy"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    [currentPage]="currentPage"
></app-data-list>
