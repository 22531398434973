import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import {
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { DataList } from 'src/app/components/data-list/data-list.class';
import { UserGroupService } from 'src/app/services/user-groups/user-groups.service';

@Component({
  selector: 'app-list-user-groups',
  templateUrl: './list-user-groups.component.html',
  styleUrls: ['./list-user-groups.component.scss']
})
export class ListUserGroupsComponent extends DataList<IUserGroup> implements OnInit {
  EnumAcl = EnumAcl;

  columns: IDataListColumn[] = [
    {
      title: 'Nom',
      field: 'title'
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'title', direction: 'asc' };

  constructor(private aclService: AclService, private userGroupService: UserGroupService) {
    super();

    this.setDataService(this.userGroupService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.userGroupsList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
