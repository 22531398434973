import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { DataList } from 'src/app/components/data-list/data-list.class';

@Component({
  selector: 'app-list-flight-checklist-templates',
  templateUrl: './list-flight-checklist-templates.component.html',
  styleUrls: ['./list-flight-checklist-templates.component.scss']
})
export class ListFlightChecklistTemplatesComponent
  extends DataList<IFlightChecklistTemplate>
  implements OnInit
{
  EnumAcl = EnumAcl;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;

  columns: IDataListColumn[] = [
    {
      title: 'Département',
      field: 'enquiryType',
      type: EnumDataListColumnType.function,
      resolve: (item: IFlightChecklistTemplate): string => {
        return getEnumEnquiryTypeLabel(item.enquiryType);
      }
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'enquiryType', direction: 'asc' };

  constructor(
    public flightChecklistTemplateService: FlightChecklistTemplateService,
    private aclService: AclService
  ) {
    super();

    this.setDataService(this.flightChecklistTemplateService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.flightChecklistTemplatesList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
