import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IFbo, getFboFullAddress } from 'src/app/interfaces/fbo.interface';
import { Subscription } from 'rxjs';
import { FboService } from 'src/app/services/fbos/fbos.service';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { AirportService } from 'src/app/services/airports/airports.service';

@Component({
  selector: 'app-fbo-view',
  templateUrl: './fbo-view.component.html',
  styleUrls: ['./fbo-view.component.scss']
})
export class FboViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getFboFullAddress = getFboFullAddress;

  loading: boolean = false;
  fboId: string;
  fbo: IFbo | null = null;
  isLogged: boolean = false;

  fbosObj: { [id: string]: IFbo | null } = {};
  airportsObj: { [id: string]: IAirport | null } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private fboService: FboService,
    private airportService: AirportService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.fboId = this.activatedRoute.snapshot.paramMap.get('fboId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadFbo();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFbo(): void {
    this.subscriptions.add(
      this.fboService.getFromId(this.fboId).subscribe((fbo: IFbo) => {
        this.fbo = fbo;

        if (this.fbo?.airportId) {
          this.loadAirport(this.fbo.airportId);
        }
      })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.fbosDelete)) {
      const result = confirm(
        "La suppression d'un FBO sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        const redirectUrl: string = '/admin/fbos/airport/' + this.fbo.airportId;

        this.fboService
          .delete(this.fboId)
          .then(() => {
            this.router.navigate([redirectUrl]);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirport(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport | null) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }
}
