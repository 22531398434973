<div class="text-end mb-4" *ngIf="hasAclAccess(EnumAcl.backupsRestore) && !loading">
    <button type="button" class="btn btn-primary" (click)="forceBackup()">
        Forcer la sauvegarde
    </button>
</div>

<div class="row">
    <div class="col-12 col-md-6">
        <div class="card mb-4">
            <div class="table-responsive no-border">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Fichiers base de données</th>
                            <th scope="col" class="text-center">Restauré ?</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="loading">
                        <tr *ngFor="let number of [].constructor(10)">
                            <td>
                                <div class="placeholder w-100"></div>
                            </td>
                            <td>
                                <div class="placeholder w-100"></div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!loading">
                        <tr *ngIf="!backupFiles.firestore.length" class="table-warning text-center">
                            <td colspan="2">Aucune sauvegarde de la base de données</td>
                        </tr>
                        <tr
                            *ngFor="let file of backupFiles.firestore"
                            [class.disabled]="
                                form.value.firestoreFile &&
                                form.value.firestoreFile !== file.fileName
                            "
                        >
                            <td [innerText]="file.fileNameLocalised"></td>
                            <td class="text-center">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addToRestore('firestoreFile', file.fileName)"
                                    *ngIf="!form.value.firestoreFile"
                                    [disabled]="form.disabled || restoring"
                                >
                                    +
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="clearToRestore('firestoreFile')"
                                    *ngIf="form.value.firestoreFile === file.fileName"
                                    [disabled]="form.disabled || restoring"
                                >
                                    Retirer
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6">
        <div class="card mb-4">
            <div class="table-responsive no-border">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Fichiers connexions utilisateurs</th>
                            <th scope="col" class="text-center">Restauré ?</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="loading">
                        <tr *ngFor="let number of [].constructor(10)">
                            <td>
                                <div class="placeholder w-100"></div>
                            </td>
                            <td>
                                <div class="placeholder w-100"></div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!loading">
                        <tr *ngIf="!backupFiles.auth.length" class="table-warning text-center">
                            <td colspan="2">
                                Aucune sauvegarde des données de connexion utilisateurs
                            </td>
                        </tr>
                        <tr
                            *ngFor="let file of backupFiles.auth"
                            [class.disabled]="
                                form.value.authFile && form.value.authFile !== file.fileName
                            "
                        >
                            <td [innerText]="file.fileNameLocalised"></td>
                            <td class="text-center">
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="addToRestore('authFile', file.fileName)"
                                    *ngIf="!form.value.authFile"
                                    [disabled]="form.disabled || restoring"
                                >
                                    +
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    (click)="clearToRestore('authFile')"
                                    *ngIf="form.value.authFile === file.fileName"
                                    [disabled]="form.disabled || restoring"
                                >
                                    Retirer
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info text-center mt-4" *ngIf="!loading">
    Les heures sont convertis vers votre fuseau horaire.<br />
    Sélectionnez les sauvegardes que vous souhaitez restorer.

    <div class="mt-4" *ngIf="hasAclAccess(EnumAcl.backupsRestore)">
        <button
            type="button"
            class="btn btn-primary btn-lg"
            [disabled]="form.disabled || !form.valid || restoring"
            [innerText]="restoring ? 'Demande de restauration ...' : 'Restaurer'"
            (click)="restoreBackup()"
        ></button>
    </div>
</div>
