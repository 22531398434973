import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { DataListModule } from '../data-list/data-list.module';
import { AirlineFleetComponent } from './airline-fleet.component';
import { AircraftsCompiledListForAirlineModule } from '../aircrafts-compiled-list-for-airline/aircrafts-compiled-list-for-airline.module';
import { AircraftsListForAirlineModule } from '../aircrafts-list-for-airline/aircrafts-list-for-airline.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    DataListModule,
    AircraftsCompiledListForAirlineModule,
    AircraftsListForAirlineModule
  ],
  declarations: [AirlineFleetComponent],
  exports: [AirlineFleetComponent]
})
export class AirlineFleetModule {}
