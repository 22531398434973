import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AirportFirestoreService } from './airports-firestore.service';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { RemoteService } from '../remote.service';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AirportService {
  constructor(
    private airportFirestoreService: AirportFirestoreService,
    private remoteService: RemoteService
  ) {}

  create(Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.create(Airport);
  }

  createWithId(id: string, Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.createWithId(id, Airport);
  }

  update(Airport: IAirport): Promise<string> {
    return this.airportFirestoreService.update(Airport);
  }

  delete(id: string): Promise<void> {
    return this.airportFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAirport | null> {
    return this.airportFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAirport[]> {
    return this.airportFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.airportFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IAirport[]> {
    return this.airportFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  async getAirportTimezone(airport: IAirport, date: Date): Promise<object> {
    const timestamp: number = date.getTime() / 1000;

    if (isNaN(timestamp)) {
      Promise.reject('Invalid date to fetch timezone.');
    } else {
      const url: string =
        'https://us-central1-outil-prod-aa.cloudfunctions.net/getTimezoneData?lat=' +
        airport.latitude +
        '&lng=' +
        airport.longitude +
        '&timestamp=' +
        timestamp +
        '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';

      const result: {
        success: boolean;
        data: object;
        err: any;
      } = await this.remoteService.httpGet(url);
      if (result.success) {
        return result.data;
      } else {
        Promise.reject(result.err ? result.err : null);
      }
    }
  }
}
