import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { Subscription } from 'rxjs';
import { AirportService } from 'src/app/services/airports/airports.service';
import { getContinentLabel, getCountryLabel } from 'src/app/enums/continent-code.enum';

@Component({
  selector: 'app-airport-view',
  templateUrl: './airport-view.component.html',
  styleUrls: ['./airport-view.component.scss']
})
export class AirportViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getContinentLabel = getContinentLabel;
  getCountryLabel = getCountryLabel;

  loading: boolean = false;
  airportId: string;
  airport: IAirport | null = null;
  isLogged: boolean = false;

  airportsObj: { [id: string]: IAirport | null } = {};

  isLieuDit = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private airportService: AirportService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.isLieuDit = !!window.location.href.match('lieux-dits');
      this.airportId = this.activatedRoute.snapshot.paramMap.get('airportId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadAirport();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadAirport(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('airports', this.airportId);

    this.airport = doc as IAirport;

    if (this.airport?.helicopterTransfers) {
      for (const helicopterTransfer of this.airport.helicopterTransfers) {
        this.loadAirportForTransfer(helicopterTransfer.airportDestinationId);
      }
    }

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.airportsDelete)) {
      const result = confirm(
        this.isLieuDit
          ? "La suppression d'un lieu-dit sera permanente. Êtes-vous sûr de vouloir continuer?"
          : "La suppression d'un aéroport sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('airports', this.airportId)
          .then(() => {
            this.router.navigate([this.isLieuDit ? '/admin/lieux-dits' : '/admin/airports']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirportForTransfer(airportId: string): void {
    if (typeof this.airportsObj[airportId] === 'undefined') {
      this.airportsObj[airportId] = null;

      this.subscriptions.add(
        this.airportService.getFromId(airportId).subscribe((airport: IAirport) => {
          this.airportsObj[airportId] = airport;
        })
      );
    }
  }
}
