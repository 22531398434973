<div class="text-end">
    <a
        [routerLink]="airlineId ? '/admin/crews/add/airline/' + airlineId : '/admin/crews/add'"
        class="btn btn-primary mb-4"
        *ngIf="hasAclAccess(EnumAcl.crewsAdd)"
        [innerText]="'+ Nouveau équipage'"
    ></a>
</div>

<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [rowUrl]="'/admin/crews/'"
    [orderBy]="orderBy"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    [currentPage]="currentPage"
></app-data-list>
