import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import firebase from 'firebase/compat/app';

import moment from 'moment';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  EnumEnquiryCancelledReason,
  getEnumEnquiryCancelledReasonLabel
} from 'src/app/enums/enquiry-cancelled-reason.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IFindCondition } from 'src/app/services/firestore/firestore.service';

@Component({
  selector: 'app-statistics-clients',
  templateUrl: './statistics-clients.component.html',
  styleUrls: ['./statistics-clients.component.scss']
})
export class StatisticsClientsComponent implements OnInit {
  loading: boolean = false;
  clientId: string;
  client: IPipedriveOrganization | null = null;
  isLogged: boolean = false;
  enquiries: Array<IEnquiry> = [];
  stats: {
    counts: Array<{
      title: string;
      status: string;
      badgeCls: string;
      value: number;
    }>;
    countTypes: Array<{
      title: string;
      type: string;
      value: number;
    }>;
    countCancelledReasons: Array<{
      title: string;
      type: string;
      value: number;
    }>;
    countTotal: number;
    countTotalConfirmed: number;
    ca: number;
    margin: number;
    marginPercent: number;
    ratioEnquiryConfirmed: number;
  } = {
    counts: [
      {
        title: 'Demandes confirmées',
        status: 'confirmed',
        badgeCls: 'bg-success',
        value: 0
      },
      {
        title: 'Demandes en cours',
        status: 'draft',
        badgeCls: 'bg-primary',
        value: 0
      },
      {
        title: 'Demandes annulées',
        status: 'cancelled',
        badgeCls: 'bg-danger',
        value: 0
      }
    ],
    countTypes: [],
    countCancelledReasons: [],
    countTotal: 0,
    countTotalConfirmed: 0,
    ca: 0,
    margin: 0,
    marginPercent: 0,
    ratioEnquiryConfirmed: 0
  };
  start: string;
  end: string;
  today: string;
  filtersByPeriod: Array<{
    title: string;
    value: string;
  }> = [];
  filterByPeriod: string;

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    for (const enquiryType of Object.values(EnumEnquiryType)) {
      this.stats.countTypes.push({
        title: getEnumEnquiryTypeLabel(enquiryType),
        type: enquiryType,
        value: 0
      });
    }

    for (const cancelledReason of Object.values(EnumEnquiryCancelledReason)) {
      this.stats.countCancelledReasons.push({
        title: getEnumEnquiryCancelledReasonLabel(cancelledReason),
        type: cancelledReason,
        value: 0
      });
    }

    this.today = moment().format('YYYY-MM-DD');

    this.generateFilterPeriodOptions();

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async () => {
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');

      await this.aclService.checkAclAccess(EnumAcl.clientsStatistics);

      this.loadData();
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadClient();
      this.fetchEnquiries();
    } else {
      setTimeout(async () => {
        await this.loadData();
      }, 500);
    }
  }

  async loadClient(): Promise<void> {
    this.loading = true;

    this.client = await this.pipedriveService.getOrganization(this.clientId);

    this.loading = false;
  }

  async fetchEnquiries(): Promise<void> {
    this.loading = true;

    const conditions: IFindCondition[] = [];

    if (this.start) {
      conditions.push({
        field: 'created',
        operator: '>',
        value: moment(this.start).toDate()
      });
    }

    if (this.end) {
      conditions.push({
        field: 'created',
        operator: '<',
        value: moment(this.end).toDate()
      });
    }

    this.enquiries = await this.remoteService.getAllClientsEnquiries(this.clientId, conditions);

    this.setStats();

    this.loading = false;
  }

  resetStats(): void {
    for (const count of this.stats.counts) {
      count.value = 0;
    }

    for (const field of [
      'countTotal',
      'countTotalConfirmed',
      'ca',
      'margin',
      'marginPercent',
      'ratioEnquiryConfirmed'
    ]) {
      this.stats[field] = 0;
    }
  }

  setStats(): void {
    this.loading = true;
    this.resetStats();

    const countsStatusIndex: object = {};
    for (let i = 0; i < this.stats.counts.length; i++) {
      countsStatusIndex[this.stats.counts[i].status] = i;
    }
    const countsTypeIndex: object = {};
    for (let i = 0; i < this.stats.countTypes.length; i++) {
      countsTypeIndex[this.stats.countTypes[i].type] = i;
    }
    const countsCancelledReasonsIndex: object = {};
    let i = 0;
    for (const cancelledReason of Object.values(EnumEnquiryCancelledReason)) {
      countsCancelledReasonsIndex[cancelledReason] = i++;
    }

    let margin: Array<number> = [];
    let marginPercent: Array<number> = [];
    for (const enquiry of this.enquiries) {
      if (typeof countsStatusIndex[enquiry.status] !== 'undefined') {
        this.stats.counts[countsStatusIndex[enquiry.status]].value++;
      }

      if (typeof countsTypeIndex[enquiry.type] !== 'undefined') {
        this.stats.countTypes[countsTypeIndex[enquiry.type]].value++;
      }

      if (
        enquiry.reasonCancelled &&
        enquiry.reasonCancelled.reason &&
        typeof countsCancelledReasonsIndex[enquiry.reasonCancelled.reason] !== 'undefined'
      ) {
        this.stats.countCancelledReasons[
          countsCancelledReasonsIndex[enquiry.reasonCancelled.reason]
        ].value++;
      }

      // if (enquiry.cotationConfirmeds.length) {
      //   this.stats.countTotalConfirmed++;
      // }

      // for (const cotationConfirmed of enquiry.cotationConfirmeds) {
      //   if (cotationConfirmed.cotation.sellingPrice) {
      //     this.stats.ca += cotationConfirmed.cotation.sellingPrice;
      //   }

      //   if (cotationConfirmed.cotation.margin) {
      //     margin.push(cotationConfirmed.cotation.margin);
      //   }
      //   if (cotationConfirmed.cotation.marginPercent) {
      //     marginPercent.push(cotationConfirmed.cotation.marginPercent);
      //   }
      // }
    }

    if (this.enquiries.length) {
      this.stats.countTotal = this.enquiries.length;

      if (this.stats.countTotal > 0) {
        this.stats.ratioEnquiryConfirmed = Math.round(
          (this.stats.countTotalConfirmed / this.stats.countTotal) * 100
        );
      }

      if (margin.length) {
        this.stats.margin = margin.reduce((a, b) => a + b, 0);
      }
      if (marginPercent.length) {
        this.stats.marginPercent =
          Math.round((marginPercent.reduce((a, b) => a + b, 0) / marginPercent.length) * 100) / 100;
      }
    }

    this.loading = false;
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  updateFilterPeriod(): void {
    if (!this.filterByPeriod) {
      this.start = null;
      this.end = null;
    } else if (this.filterByPeriod !== 'custom') {
      if (this.filterByPeriod.match('quarter')) {
        const filterByPeriodSplitted: string[] = this.filterByPeriod
          .replace('quarter-', '')
          .split('-');
        const year: number = parseInt(filterByPeriodSplitted[0]);
        const quarter: number = parseInt(filterByPeriodSplitted[1]);

        this.start = moment(year + '-' + this.addZeroToDigit((quarter - 1) * 3 + 1) + '-01').format(
          'YYYY-MM-DD'
        );
        this.end = moment(this.start).endOf('quarter').format('YYYY-MM-DD');
      } else {
        this.start = this.filterByPeriod;

        this.end = moment(this.start).endOf('month').format('YYYY-MM-DD');
      }
    }

    this.fetchEnquiries();
  }

  private addZeroToDigit(digit: number): string {
    return digit < 10 ? '0' + digit : digit.toString();
  }

  customDatesChanged(): void {
    this.fetchEnquiries();
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = moment().get('year');

    for (let dec = 0; dec <= 1; dec++) {
      const lastQuarter: number = dec === 0 ? moment().quarter() : 4;
      const year: number = currentYear - dec;

      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'quarter-' + year + '-' + i
        });
      }
    }

    const beginningOfMonth = moment().startOf('month');

    const startDate = moment(beginningOfMonth.get('year') - 2 + '-12-31');

    for (var m = beginningOfMonth; m.isAfter(startDate); m.add(-1, 'month')) {
      let title: string = m.format('MMMM YY');

      title = title.charAt(0).toUpperCase() + title.slice(1);

      this.filtersByPeriod.push({
        title: title,
        value: m.format('YYYY-MM-01')
      });
    }
  }
}
