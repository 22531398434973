<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div [class.row]="!inModal" class="justify-content-center mb-4">
        <div [class.col-lg-8]="!inModal">
            <div [class.card]="!inModal">
                <div [class.card-body]="!inModal">
                    <div class="form-group">
                        <label for="title">Nom</label>
                        <input
                            class="form-control"
                            id="title"
                            formControlName="title"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['title'].touched) &&
                                form.controls['title'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="updateTitle()"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['title'].touched) &&
                                    form.controls['title'].errors &&
                                    form.controls['title'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="slug">Slug</label>
                        <input
                            [readonly]="simulateurAircraftModelId"
                            class="form-control"
                            id="slug"
                            formControlName="slug"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['slug'].touched) &&
                                form.controls['slug'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['slug'].touched) &&
                                    form.controls['slug'].errors &&
                                    form.controls['slug'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                        <p class="text-muted">
                            Une fois sauvegardé, l'id ne peut plus être changé. Celui-ci est utilisé
                            pour les scripts d'importation automatique d'airfleets.
                        </p>
                    </div>

                    <div class="form-group">
                        <label for="speedInKmh">Vitesse en km/h</label>
                        <input
                            class="form-control"
                            id="speedInKmh"
                            formControlName="speedInKmh"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['speedInKmh'].touched) &&
                                form.controls['speedInKmh'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['speedInKmh'].touched) &&
                                    form.controls['speedInKmh'].errors &&
                                    form.controls['speedInKmh'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="paxMax">Passagers maximum</label>
                        <input
                            class="form-control"
                            id="paxMax"
                            formControlName="paxMax"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['paxMax'].touched) &&
                                form.controls['paxMax'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['paxMax'].touched) &&
                                    form.controls['paxMax'].errors &&
                                    form.controls['paxMax'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="rangeMaxInKms">Autonomie maximum en kms</label>
                        <input
                            class="form-control"
                            id="rangeMaxInKms"
                            formControlName="rangeMaxInKms"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['rangeMaxInKms'].touched) &&
                                form.controls['rangeMaxInKms'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['rangeMaxInKms'].touched) &&
                                    form.controls['rangeMaxInKms'].errors &&
                                    form.controls['rangeMaxInKms'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="flyTimeInHours">Durée de vol maximum en heures</label>
                        <input
                            class="form-control"
                            id="flyTimeInHours"
                            formControlName="flyTimeInHours"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['flyTimeInHours'].touched) &&
                                form.controls['flyTimeInHours'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['flyTimeInHours'].touched) &&
                                    form.controls['flyTimeInHours'].errors &&
                                    form.controls['flyTimeInHours'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="priceBypriceByHourPaxByHour">Prix par heure en euros</label>
                        <input
                            class="form-control"
                            id="priceByHour"
                            formControlName="priceByHour"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['priceByHour'].touched) &&
                                form.controls['priceByHour'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['priceByHour'].touched) &&
                                    form.controls['priceByHour'].errors &&
                                    form.controls['priceByHour'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="priceByPax">Prix par heure par passager en euros</label>
                        <input
                            class="form-control"
                            id="priceByPax"
                            formControlName="priceByPax"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched || form.controls['priceByPax'].touched) &&
                                form.controls['priceByPax'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['priceByPax'].touched) &&
                                    form.controls['priceByPax'].errors &&
                                    form.controls['priceByPax'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div>
                        <label for="imageUrlInput">Image</label>
                        <div class="image-upload-container">
                            <div
                                class="image-upload-content"
                                [class.no-image]="!form.value['imageUrl']"
                                (click)="triggerInputFile('imageUrl')"
                            >
                                <div class="image-container" *ngIf="form.value['imageUrl']">
                                    <img [src]="form.value.imageUrl" />
                                </div>
                                <div class="image-upload-legend">
                                    <span *ngIf="!form.disabled">
                                        {{
                                            form.value.imageUrl
                                                ? "Éditer l'image"
                                                : "Ajouter une image"
                                        }}
                                    </span>
                                    <span *ngIf="form.disabled">...</span>
                                </div>
                                <input
                                    type="file"
                                    id="imageUrlInput"
                                    class="form-control"
                                    (change)="changeInputFile('imageUrl', $event)"
                                    accept="image/*"
                                />
                            </div>

                            <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                (click)="deleteImage('imageUrl')"
                                [disabled]="form.disabled"
                                *ngIf="form.value.imageUrl"
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="minHardSurfaceRunwayInMeters"
                            >Longueur de piste minimum en mètres</label
                        >
                        <input
                            class="form-control"
                            id="minHardSurfaceRunwayInMeters"
                            formControlName="minHardSurfaceRunwayInMeters"
                            min="0"
                            type="number"
                            [class.is-invalid]="
                                (form.touched ||
                                    form.controls['minHardSurfaceRunwayInMeters'].touched) &&
                                form.controls['minHardSurfaceRunwayInMeters'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        form.controls['minHardSurfaceRunwayInMeters'].touched) &&
                                    form.controls['minHardSurfaceRunwayInMeters'].errors &&
                                    form.controls['minHardSurfaceRunwayInMeters'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fireCategories">Catégorie incendie</label>
                        <div class="form-check" *ngFor="let fireCategory of fireCategories">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                [id]="'fire-category-' + fireCategory.value"
                                [checked]="form.value.fireCategories?.includes(fireCategory.value)"
                                (click)="toggleFireCategories(fireCategory.value)"
                            />
                            <label
                                class="form-check-label"
                                [attr.for]="'fire-category-' + fireCategory.value"
                            >
                                <strong>Catégorie incendie {{ fireCategory.value }}</strong>
                                <div [innerText]="fireCategory.description"></div>
                            </label>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['fireCategories'].touched) &&
                                    form.controls['fireCategories'].errors &&
                                    form.controls['fireCategories'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="description">Description</label>
                        <quill-editor
                            formControlName="description"
                            [class.is-invalid]="
                                (form.touched || form.controls['description'].touched) &&
                                form.controls['description'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></quill-editor>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['description'].touched) &&
                                    form.controls['description'].errors &&
                                    form.controls['description'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4" *ngIf="!inModal">
                        <div class="col">
                            <button
                                type="button"
                                [routerLink]="
                                    simulateurAircraftModelId
                                        ? '/admin/simulateur-aircraft-models/' +
                                          simulateurAircraftModelId
                                        : '/admin/simulateur-aircraft-models'
                                "
                                class="btn btn-secondary"
                                [disabled]="form.disabled || sending"
                            >
                                Annuler
                            </button>
                        </div>
                        <div class="col text-end">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                                [disabled]="form.disabled || !form.valid || sending"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
