import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { HandlingFirestoreService } from './handlings-firestore.service';
import { IHandling } from 'src/app/interfaces/handling.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class HandlingService {
  constructor(private handlingFirestoreService: HandlingFirestoreService) {}

  create(Handling: IHandling): Promise<string> {
    return this.handlingFirestoreService.create(Handling);
  }

  createWithId(id: string, Handling: IHandling): Promise<string> {
    return this.handlingFirestoreService.createWithId(id, Handling);
  }

  update(Handling: IHandling): Promise<string> {
    return this.handlingFirestoreService.update(Handling);
  }

  delete(id: string): Promise<void> {
    return this.handlingFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IHandling> {
    return this.handlingFirestoreService.docValue$(id);
  }

  getAll(): Observable<IHandling[]> {
    return this.handlingFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.handlingFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IHandling[]> {
    return this.handlingFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getForAirport(airportId: string): Observable<IHandling[]> {
    return this.handlingFirestoreService.collectionValues$(ref =>
      ref.where('airportId', '==', airportId)
    );
  }
}
