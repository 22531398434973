<div class="card mb-4">
    <div class="table-responsive no-border">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        [class.sortable]="!column.notSortable"
                        [class.text-start]="column.align === EnumDataListColumnAlign.start"
                        [class.text-center]="column.align === EnumDataListColumnAlign.center"
                        [class.text-end]="column.align === EnumDataListColumnAlign.end"
                        *ngFor="let column of columns"
                        [class.is-sorted]="orderBy && orderBy.field == column.field"
                        [class.asc]="
                            orderBy && orderBy.field == column.field && orderBy.direction === 'asc'
                        "
                        [class.desc]="
                            orderBy && orderBy.field == column.field && orderBy.direction === 'desc'
                        "
                        (click)="sortField(column)"
                        [innerHtml]="column.title"
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="loading" class="placeholder-glow">
                <tr *ngFor="let number of [].constructor(20)">
                    <td *ngFor="let column of columns">
                        <div class="placeholder w-100"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!loading">
                <tr *ngIf="!data?.length" class="table-warning text-center">
                    <td [attr.colspan]="columns.length">Aucun donnée n'a été trouvée.</td>
                </tr>
                <tr
                    *ngFor="let item of data"
                    [routerLink]="rowUrl ? rowUrl + item.id : undefined"
                    (click)="openItemEvent ? openItem.emit(item) : undefined"
                    class="clickable {{ rowCls(item) }}"
                >
                    <ng-container *ngFor="let column of columns">
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.date"
                            [innerHtml]="item[column.field] | date : 'dd/MM/yyyy'"
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.datetime"
                            [innerHtml]="item[column.field] | date : 'dd/MM/yyyy HH:mm'"
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.boolean"
                            [innerHtml]="item[column.field] ? 'Oui' : 'Non'"
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.email"
                            [innerHtml]="item[column.field]"
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.function"
                            [innerHtml]="column.resolve ? column.resolve(item) : ''"
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.price"
                            [innerHtml]="
                                item[column.field]
                                    ? formatPrice(item[column.field], item.currency)
                                    : ''
                            "
                        ></td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="column.type === EnumDataListColumnType.image"
                        >
                            <img
                                [src]="item[column.field]"
                                *ngIf="item[column.field]"
                                style="max-width: 100px"
                            />
                        </td>
                        <td
                            [class.text-start]="column.align === EnumDataListColumnAlign.start"
                            [class.text-center]="column.align === EnumDataListColumnAlign.center"
                            [class.text-end]="column.align === EnumDataListColumnAlign.end"
                            *ngIf="!column.type || column.type === EnumDataListColumnType.text"
                            [innerHtml]="item[column.field]"
                        ></td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer">
        <nav aria-label="Liste navigation">
            <ul class="pagination justify-content-center mb-0">
                <li class="page-item" [class.disabled]="loading || currentPage === 0">
                    <button
                        type="button"
                        class="page-link"
                        (click)="prevPage.emit()"
                        [disabled]="loading"
                    >
                        <fa-icon [icon]="faArrowLeft"></fa-icon>
                    </button>
                </li>
                <li class="page-item disabled">
                    <span class="page-link" [innerHtml]="currentPage + 1"></span>
                </li>
                <li
                    class="page-item"
                    [class.disabled]="
                        loading || (data && (!data.length || data.length < itemsPerPage))
                    "
                >
                    <button
                        type="button"
                        class="page-link"
                        (click)="nextPage.emit()"
                        [disabled]="loading"
                    >
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</div>
