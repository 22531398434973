<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="date" [innerText]="'QUOTATION.DATE' | translate"></label>
                <input
                    class="form-control"
                    id="date"
                    formControlName="date"
                    type="date"
                    [class.is-invalid]="
                        (form.touched || date.touched) && date.status === 'INVALID' ? 'danger' : ''
                    "
                    min="2000-01-01"
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || date.touched) && date.errors && date.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                    <div
                        *ngIf="
                            (form.touched || date.touched) &&
                            date.errors &&
                            date.errors['dateBelowMinimum']
                        "
                    >
                        La date doit être supérieure à
                        {{ date.errors && date.errors["dateMinimum"] | date : "dd/MM/yyyy" }}.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="language">Langue</label>
                <select
                    class="form-select"
                    id="language"
                    formControlName="language"
                    [class.is-invalid]="
                        (form.touched || language.touched) && language.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                    (change)="changedLanguage()"
                >
                    <option
                        [value]="language"
                        *ngFor="let language of getLanguages()"
                        [innerText]="getLanguageLabel(language)"
                    ></option>
                </select>

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || language.touched) &&
                            language.errors &&
                            language.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="title" [innerText]="'QUOTATION.QUOTATION_NUMBER' | translate"></label>
                <div class="form-control fake-input disabled">
                    <span class="fake-input-text" [innerText]="title.value"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="form-group">
                <label for="clientTitle">Nom du client</label>
                <input
                    class="form-control"
                    id="clientTitle"
                    formControlName="clientTitle"
                    type="text"
                    [class.is-invalid]="
                        (form.touched || clientTitle.touched) && clientTitle.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || clientTitle.touched) &&
                            clientTitle.errors &&
                            clientTitle.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header text-center">
                    <div class="card-title">Contact du client</div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="contactFirstname">Prénom</label>
                                <input
                                    class="form-control"
                                    id="contactFirstname"
                                    formControlName="contactFirstname"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched || contactFirstname.touched) &&
                                        contactFirstname.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || contactFirstname.touched) &&
                                            contactFirstname.errors &&
                                            contactFirstname.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="contactLastname">Nom</label>
                                <input
                                    class="form-control"
                                    id="contactLastname"
                                    formControlName="contactLastname"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched || contactLastname.touched) &&
                                        contactLastname.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || contactLastname.touched) &&
                                            contactLastname.errors &&
                                            contactLastname.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group mb-sm-0">
                                <label for="contactLastname">Téléphone</label>
                                <input
                                    class="form-control"
                                    id="contactPhone"
                                    formControlName="contactPhone"
                                    type="tel"
                                    [class.is-invalid]="
                                        (form.touched || contactPhone.touched) &&
                                        contactPhone.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || contactPhone.touched) &&
                                            contactPhone.errors &&
                                            contactPhone.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-sm-0">
                                <label for="contactEmail">Email</label>
                                <input
                                    class="form-control"
                                    id="contactEmail"
                                    formControlName="contactEmail"
                                    type="email"
                                    [class.is-invalid]="
                                        (form.touched || contactEmail.touched) &&
                                        contactEmail.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || contactEmail.touched) &&
                                            contactEmail.errors &&
                                            contactEmail.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="companyTitle">Entreprise</label>
                <input
                    class="form-control"
                    id="companyTitle"
                    formControlName="companyTitle"
                    type="text"
                    [class.is-invalid]="
                        (form.touched || companyTitle.touched) && companyTitle.status === 'INVALID'
                            ? 'danger'
                            : ''
                    "
                />

                <div class="invalid-tooltip">
                    <div
                        *ngIf="
                            (form.touched || companyTitle.touched) &&
                            companyTitle.errors &&
                            companyTitle.errors['required']
                        "
                    >
                        Ce champ est obligatoire.
                    </div>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header text-center">
                    <div class="card-title">Agent Artheau</div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="agentFirstname">Prénom</label>
                                <input
                                    class="form-control"
                                    id="agentFirstname"
                                    formControlName="agentFirstname"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched || agentFirstname.touched) &&
                                        agentFirstname.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || agentFirstname.touched) &&
                                            agentFirstname.errors &&
                                            agentFirstname.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="agentLastname">Nom</label>
                                <input
                                    class="form-control"
                                    id="agentLastname"
                                    formControlName="agentLastname"
                                    type="text"
                                    [class.is-invalid]="
                                        (form.touched || agentLastname.touched) &&
                                        agentLastname.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || agentLastname.touched) &&
                                            agentLastname.errors &&
                                            agentLastname.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group mb-sm-0">
                                <label for="agentPhone">Téléphone</label>
                                <input
                                    class="form-control"
                                    id="agentPhone"
                                    formControlName="agentPhone"
                                    type="tel"
                                    [class.is-invalid]="
                                        (form.touched || agentPhone.touched) &&
                                        agentPhone.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || agentPhone.touched) &&
                                            agentPhone.errors &&
                                            agentPhone.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-sm-0">
                                <label for="agentEmail">Email</label>
                                <input
                                    class="form-control"
                                    id="agentEmail"
                                    formControlName="agentEmail"
                                    type="email"
                                    [class.is-invalid]="
                                        (form.touched || agentEmail.touched) &&
                                        agentEmail.status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || agentEmail.touched) &&
                                            agentEmail.errors &&
                                            agentEmail.errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">
            <div class="card-title">Appareil aérien</div>
        </div>
        <div class="card-body">
            <div class="row" *ngIf="!aircraftId.value">
                <div class="col-md-6">
                    <div class="form-group mb-md-0">
                        <label for="aircraftId">Recherche avec son immatriculation</label>
                        <div class="input-group">
                            <input
                                type="search"
                                id="aircraftId"
                                class="form-control"
                                [(ngModel)]="queryAircraftRegistration"
                                [ngModelOptions]="{ standalone: true }"
                            />
                            <button
                                class="btn btn-primary"
                                type="button"
                                [disabled]="!queryAircraftRegistration.length"
                                (click)="searchAircraftByRegistration()"
                            >
                                <fa-icon [icon]="faSearch"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-md-0 placeholder-glow">
                        <label for="aircraftModelId">Ou sélectionnez un modèle</label>
                        <div class="placeholder w-100" *ngIf="loadingAircraftModels"></div>
                        <select
                            class="form-select"
                            id="aircraftModelId"
                            formControlName="aircraftModelId"
                            [class.is-invalid]="
                                (form.touched || form.get('aircraftModelId').touched) &&
                                form.get('aircraftModelId').status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (change)="updatedAircraftModelId()"
                            *ngIf="!loadingAircraftModels"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="aircraftModel.id"
                                *ngFor="let aircraftModel of aircraftModels"
                                [innerText]="aircraftModel.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.get('aircraftModelId').touched) &&
                                    form.get('aircraftModelId').errors &&
                                    form.get('aircraftModelId').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center" *ngIf="aircraft">
                <div class="col-md-6">
                    <strong>Immatriculation :</strong>
                    {{ aircraft.registration }}<br />
                    <strong>Opérateur :</strong>
                    {{ aircraft.airlineTitle }}<br />
                    <strong>Modèle :</strong>
                    {{ aircraft.aircraftModelTitle || aircraft.model }}
                </div>
                <div class="col-md-6 text-end">
                    <div class="btn-group">
                        <a
                            [routerLink]="'/admin/aircrafts/' + aircraft.id"
                            target="_blank"
                            class="btn btn-primary"
                        >
                            <fa-icon [icon]="faEye"></fa-icon
                        ></a>
                        <button type="button" class="btn btn-danger" (click)="removeAircraftId()">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">
            <div class="card-title" [innerText]="'QUOTATION.FLIGHT_PLAN' | translate"></div>
        </div>
        <ul class="list-group list-group-flush" formArrayName="trips">
            <li class="list-group-item list-group-item-warning text-center" *ngIf="!trips.length">
                Aucun leg pour ce devis
            </li>
            <li
                class="list-group-item"
                [formGroupName]="i"
                *ngFor="let trip of trips.controls; let i = index"
            >
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label
                                [attr.for]="'trip-' + i + '-date'"
                                [innerText]="'QUOTATION.DATE' | translate"
                            ></label>
                            <input
                                class="form-control"
                                [id]="'trip-' + i + '-date'"
                                formControlName="date"
                                type="date"
                                [class.is-invalid]="
                                    (form.touched || getTripField(i, 'date').touched) &&
                                    getTripField(i, 'date').status == 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (keyup)="updateLegDate(i)"
                                (change)="updateLegDate(i)"
                                min="2000-01-01"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || getTripField(i, 'date').touched) &&
                                        getTripField(i, 'date').errors &&
                                        getTripField(i, 'date').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                                <div
                                    *ngIf="
                                        (form.touched || getTripField(i, 'date').touched) &&
                                        getTripField(i, 'date').errors &&
                                        getTripField(i, 'date').errors['dateBelowMinimum']
                                    "
                                >
                                    La date doit être supérieure à
                                    {{
                                        form.get("trips").get(i.toString()).get("date").errors &&
                                            form.get("trips").get(i.toString()).get("date").errors[
                                                "dateMinimum"
                                            ] | date : "dd/MM/yyyy"
                                    }}.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="enquiryType.value !== EnumEnquiryType.cargo">
                        <div class="form-group">
                            <label
                                [attr.for]="'trip-' + i + '-flyTimeInHours'"
                                [innerText]="'QUOTATION.FLIGHT_TIME' | translate"
                            ></label>
                            <div class="form-control fake-input disabled">
                                <span
                                    class="fake-input-text"
                                    [innerText]="getTripField(i, 'flyTimeInHours').value || '??:??'"
                                ></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="enquiryType.value !== EnumEnquiryType.cargo">
                        <div class="form-group">
                            <label
                                [attr.for]="'trip-' + i + '-passengersTotal'"
                                [innerText]="'QUOTATION.PASSENGERS' | translate"
                            ></label>
                            <input
                                class="form-control"
                                [id]="'trip-' + i + '-passengersTotal'"
                                formControlName="passengersTotal"
                                type="text"
                                min="1"
                                step="1"
                                [class.is-invalid]="
                                    (form.touched || getTripField(i, 'passengersTotal').touched) &&
                                    getTripField(i, 'passengersTotal').status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched ||
                                            getTripField(i, 'passengersTotal').touched) &&
                                        getTripField(i, 'passengersTotal').errors &&
                                        getTripField(i, 'passengersTotal').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="enquiryType.value === EnumEnquiryType.cargo">
                        <div class="form-group">
                            <label
                                [attr.for]="'trip-' + i + '-weight'"
                                [innerText]="'QUOTATION.CARGO' | translate"
                            ></label>
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    [id]="'trip-' + i + '-weight'"
                                    formControlName="weight"
                                    type="text"
                                    min="1"
                                    step="1"
                                    [class.is-invalid]="
                                        (form.touched || getTripField(i, 'weight').touched) &&
                                        getTripField(i, 'weight').status == 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || getTripField(i, 'weight').touched) &&
                                            getTripField(i, 'weight').errors &&
                                            getTripField(i, 'weight').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <span class="input-group-text">kg</span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex align-items-center justify-content-end"
                        [class.col-md-6]="enquiryType.value === EnumEnquiryType.cargo"
                        [class.col-md-3]="enquiryType.value !== EnumEnquiryType.cargo"
                    >
                        <div class="btn-group mb-2">
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="moveTripUp(i)"
                                [disabled]="form.disabled || i === 0"
                                *ngIf="trips.length > 1"
                            >
                                <fa-icon [icon]="faArrowUp"></fa-icon>
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                (click)="moveTripDown(i)"
                                [disabled]="form.disabled || i >= trips.length - 1"
                                *ngIf="trips.length > 1"
                            >
                                <fa-icon [icon]="faArrowDown"></fa-icon>
                            </button>
                            <button
                                type="button"
                                (click)="removeTrip(i)"
                                class="btn btn-danger btn-sm"
                                [disabled]="form.disabled"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <fieldset>
                            <div
                                class="form-group"
                                [class.mb-0]="enquiryType.value === EnumEnquiryType.cargo"
                            >
                                <label [attr.for]="'trip-' + i + '-airportDepart'">Départ</label>

                                <app-airport-picker
                                    [inputName]="'trips.' + i + '.airportDepart'"
                                    [defaultValue]="form && form.value['trips'][i]['airportDepart']"
                                    [disabled]="form.disabled"
                                    [isInvalid]="
                                        (form.touched ||
                                            getTripField(i, 'airportDepart').touched) &&
                                        getTripField(i, 'airportDepart').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (setValueToFormControl)="setValueToFormControl($event)"
                                    [noMargin]="true"
                                >
                                </app-airport-picker>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'airportDepart').touched) &&
                                            getTripField(i, 'airportDepart').errors &&
                                            getTripField(i, 'airportDepart').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div
                                class="form-group mb-0"
                                *ngIf="enquiryType.value !== EnumEnquiryType.cargo"
                            >
                                <label
                                    [attr.for]="'trip-' + i + '-time'"
                                    [innerText]="'QUOTATION.TIME_DEPARTURE' | translate"
                                ></label>
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        [id]="'trip-' + i + '-time'"
                                        formControlName="time"
                                        type="time"
                                        [class.is-invalid]="
                                            (form.touched || getTripField(i, 'time').touched) &&
                                            getTripField(i, 'time').status == 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="
                                            setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)
                                        "
                                        (change)="
                                            setEstimatedArrivalTimeIfNotProvidedForTrip(i, true)
                                        "
                                    />
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched || getTripField(i, 'time').touched) &&
                                                getTripField(i, 'time').errors &&
                                                getTripField(i, 'time').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                    <span class="input-group-text"
                                        >GMT
                                        {{ getTripField(i, "airportDepartTimezoneGmt").value }}
                                    </span>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-md-6">
                        <fieldset>
                            <div
                                class="form-group"
                                [class.mb-0]="enquiryType.value === EnumEnquiryType.cargo"
                            >
                                <label [attr.for]="'trip-' + i + '-airportDestination'"
                                    >Arrivé</label
                                >
                                <app-airport-picker
                                    [inputName]="'trips.' + i + '.airportDestination'"
                                    [defaultValue]="
                                        form && form.value['trips'][i]['airportDestination']
                                    "
                                    [disabled]="form.disabled"
                                    [isInvalid]="
                                        (form.touched ||
                                            getTripField(i, 'airportDestination').touched) &&
                                        getTripField(i, 'airportDestination').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (setValueToFormControl)="setValueToFormControl($event)"
                                    [noMargin]="true"
                                >
                                </app-airport-picker>

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                getTripField(i, 'airportDestination').touched) &&
                                            getTripField(i, 'airportDestination').errors &&
                                            getTripField(i, 'airportDestination').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>

                            <div
                                class="form-group mb-0"
                                *ngIf="enquiryType.value !== EnumEnquiryType.cargo"
                            >
                                <label
                                    [attr.for]="'trip-' + i + '-arrivalTime'"
                                    [innerText]="'QUOTATION.TIME_ARRIVAL' | translate"
                                ></label>
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        [id]="'trip-' + i + '-arrivalTime'"
                                        formControlName="arrivalTime"
                                        type="time"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getTripField(i, 'arrivalTime').touched) &&
                                            getTripField(i, 'arrivalTime').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                        (keyup)="tripTimesUpdated(i)"
                                        (change)="tripTimesUpdated(i)"
                                    />
                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getTripField(i, 'arrivalTime').touched) &&
                                                getTripField(i, 'arrivalTime').errors &&
                                                getTripField(i, 'arrivalTime').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                    <span class="input-group-text"
                                        >GMT
                                        {{ getTripField(i, "airportDestinationTimezoneGmt").value }}
                                    </span>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div
                    class="alert alert-info text-center mt-2"
                    *ngIf="
                        enquiryType.value !== EnumEnquiryType.cargo &&
                        aircraftModelId.value &&
                        aircraftModelsObj[aircraftModelId.value] &&
                        getTripField(i, 'arrivalTime').value !== getArrivalTimeEstimation(i)
                    "
                >
                    L'heure d'arrivée estimée pour
                    <strong [innerText]="aircraftModelsObj[aircraftModelId.value].title"></strong>
                    est de {{ getArrivalTimeEstimation(i) }}.<br />
                    <button
                        type="button"
                        class="btn btn-info btn-sm"
                        (click)="applyEstimatedArrivalTimeToTrip(i)"
                    >
                        Appliquer
                    </button>
                </div>
            </li>
        </ul>
        <div
            class="card-footer text-center d-flex align-items-center justify-content-center"
            *ngIf="!trips.length && enquiry?.itineraries.length"
        >
            <div class="form-group mb-0">
                <div class="input-group">
                    <select
                        class="form-select"
                        id="pick-itinerary"
                        [(ngModel)]="selectedItineraryId"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        <option [ngValue]="null">Choisissez l'itinéraire concerné</option>
                        <option
                            [value]="itinerary.id"
                            *ngFor="let itinerary of enquiry.itineraries"
                            [innerText]="itinerary.title + ' : ' + getItineraryRouting(itinerary)"
                        ></option>
                    </select>
                    <button
                        type="button"
                        (click)="addTripAndPrefill()"
                        [disabled]="!selectedItineraryId"
                        class="btn btn-primary"
                    >
                        <fa-icon [icon]="faPlus"></fa-icon> Pré-remplir
                    </button>
                </div>
            </div>
        </div>
        <div class="card-footer text-center" *ngIf="trips.length || !enquiry?.itineraries.length">
            <button type="button" (click)="addTrip()" class="btn btn-primary btn-sm">
                <fa-icon [icon]="faPlus"></fa-icon> Ajout leg
            </button>
        </div>
    </div>

    <div class="form-group">
        <textarea
            formControlName="observationsTrips"
            class="form-control"
            id="observationsTrips"
            rows="6"
        ></textarea>
    </div>

    <div id="images">
        <div id="images-col-1">
            <img [src]="form.value.googleMapStaticUrl" *ngIf="form.value.googleMapStaticUrl" />
        </div>
        <div id="images-col-2">
            <div class="image-upload-container" *ngFor="let imageField of ['image1', 'image2']">
                <div
                    class="image-upload-content"
                    [class.no-image]="!form.value[imageField + 'Url']"
                    (click)="openImageModal(imageField)"
                >
                    <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                        <div
                            class="image"
                            [id]="imageField"
                            [style.background-image]="'url(' + form.value[imageField + 'Url'] + ')'"
                            [style.webkitTransform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                            [style.transform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                        ></div>
                    </div>
                    <div class="image-upload-legend">
                        <span *ngIf="!form.disabled">
                            {{
                                form.value[imageField + "Url"]
                                    ? "Éditer l'image"
                                    : "Ajouter une image"
                            }}
                        </span>
                        <span *ngIf="form.disabled">...</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="images-col-3">
            <div class="image-upload-container" *ngFor="let imageField of ['image3']">
                <div
                    class="image-upload-content"
                    [class.no-image]="!form.value[imageField + 'Url']"
                    (click)="openImageModal(imageField)"
                >
                    <div class="image-container" *ngIf="form.value[imageField + 'Url']">
                        <div
                            class="image"
                            [id]="imageField"
                            [style.background-image]="'url(' + form.value[imageField + 'Url'] + ')'"
                            [style.webkitTransform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                            [style.transform]="
                                'scale(' + form.value[imageField + 'ZoomLevel'] + ')'
                            "
                        ></div>
                    </div>
                    <div class="image-upload-legend">
                        <span *ngIf="form.enabled">
                            {{
                                form.value[imageField + "Url"]
                                    ? "Éditer l'image"
                                    : "Ajouter une image"
                            }}
                        </span>
                        <span *ngIf="!form.enabled">...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">
            <div class="card-title" [innerText]="'QUOTATION.OBSERVATIONS' | translate"></div>
        </div>
        <div class="card-body">
            <div class="form-group mb-0">
                <textarea
                    formControlName="notes"
                    class="form-control"
                    id="notes"
                    rows="10"
                ></textarea>
            </div>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">
            <div class="card-title" [innerText]="'QUOTATION.AIRCRAFT.TITLE' | translate"></div>
        </div>
        <ul class="list-group list-group-flush" formArrayName="aircraftInfos">
            <li
                class="list-group-item list-group-item-warning text-center"
                *ngIf="!aircraftInfos.length"
            >
                Aucun donnée saisie
            </li>
            <li
                class="list-group-item"
                [formGroupName]="i"
                *ngFor="let aircraftInfo of aircraftInfos.controls; let i = index"
            >
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div
                            class="form-group"
                            [class.mb-0]="!getAircraftInfoField(i, 'hasSubTitle').value"
                            [class.mb-1]="getAircraftInfoField(i, 'hasSubTitle').value"
                            *ngIf="getAircraftInfoField(i, 'hasTitle').value"
                        >
                            <input
                                class="form-control"
                                formControlName="title"
                                type="text"
                                [class.is-edited]="isItemFieldEdited(aircraftInfo.value, 'title')"
                            />
                        </div>
                        <div
                            class="form-group mb-0"
                            *ngIf="getAircraftInfoField(i, 'hasSubTitle').value"
                        >
                            <input
                                class="form-control form-control-bold"
                                formControlName="subTitle"
                                type="text"
                                (keyup)="updatedSubTitle('aircraftInfos', i)"
                                [class.is-edited]="
                                    isItemFieldEdited(aircraftInfo.value, 'subTitle')
                                "
                            />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group mb-0">
                            <input
                                class="form-control"
                                [class.form-control-bold]="
                                    getAircraftInfoField(i, 'type').value ===
                                    EnumQuotationValueType.price
                                "
                                formControlName="value"
                                type="text"
                                [placeholder]="
                                    getAircraftInfoField(i, 'placeholder').value
                                        ? 'Ex: ' + getAircraftInfoField(i, 'placeholder').value
                                        : ''
                                "
                                [class.is-edited]="isItemFieldEdited(aircraftInfo.value, 'value')"
                            />
                        </div>
                    </div>
                    <div class="col-md-1 text-end">
                        <button
                            type="button"
                            (click)="removeAircraftInfo(i)"
                            class="btn btn-danger btn-sm"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="card-footer text-center">
            <button type="button" (click)="addAircraftInfo()" class="btn btn-primary btn-sm">
                <fa-icon [icon]="faPlus"></fa-icon> Ajout
            </button>
        </div>
    </div>

    <div class="card mb-4">
        <div class="card-header text-center">
            <div class="card-title" [innerText]="'QUOTATION.PRICE.TITLE' | translate"></div>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div class="form-group mb-2">
                            <label
                                class="d-block text-center"
                                for="totalAmountPayment"
                                [innerText]="'QUOTATION.TOTAL_AMOUNT' | translate"
                            ></label>
                            <div class="input-group">
                                <input
                                    class="form-control text-end"
                                    id="totalAmountPayment"
                                    formControlName="totalAmountPayment"
                                    type="number"
                                    min="0"
                                    step="1"
                                    [class.is-invalid]="
                                        (form.touched || form.get('totalAmountPayment').touched) &&
                                        form.get('totalAmountPayment').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (keyup)="updatedTotalAmountPayment()"
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('totalAmountPayment').touched) &&
                                            form.get('totalAmountPayment').errors &&
                                            form.get('totalAmountPayment').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <select
                                    class="form-select"
                                    id="totalAmountPaymentCurrency"
                                    formControlName="totalAmountPaymentCurrency"
                                    [class.is-invalid]="
                                        (form.touched ||
                                            form.get('totalAmountPaymentCurrency').touched) &&
                                        form.get('totalAmountPaymentCurrency').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                    (change)="updatedTotalAmountPayment()"
                                >
                                    <option
                                        [value]="currency"
                                        *ngFor="let currency of getCurrencies()"
                                        [innerText]="
                                            getEnumCurrencySymbol(currency) +
                                            ' (' +
                                            getEnumCurrencyLabel(currency) +
                                            ')'
                                        "
                                    ></option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="list-group list-group-flush" formArrayName="priceInfos">
            <li
                class="list-group-item list-group-item-warning text-center"
                *ngIf="!priceInfos.length"
            >
                Aucun donnée saisie
            </li>
            <li
                class="list-group-item"
                [formGroupName]="i"
                *ngFor="let priceInfo of priceInfos.controls; let i = index"
            >
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div
                            class="form-group"
                            [class.mb-0]="!getPriceInfoField(i, 'hasSubTitle').value"
                            [class.mb-1]="getPriceInfoField(i, 'hasSubTitle').value"
                            *ngIf="getPriceInfoField(i, 'hasTitle').value"
                        >
                            <input
                                class="form-control"
                                formControlName="title"
                                type="text"
                                [class.is-edited]="isItemFieldEdited(priceInfo.value, 'title')"
                            />
                        </div>
                        <div
                            class="form-group mb-0"
                            *ngIf="getPriceInfoField(i, 'hasSubTitle').value"
                        >
                            <input
                                class="form-control form-control-bold"
                                formControlName="subTitle"
                                type="text"
                                (keyup)="updatedSubTitle('priceInfos', i)"
                                [class.is-edited]="isItemFieldEdited(priceInfo.value, 'subTitle')"
                            />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group mb-0">
                            <div class="input-group">
                                <input
                                    class="form-control"
                                    [class.form-control-bold]="
                                        getPriceInfoField(i, 'type').value ===
                                        EnumQuotationValueType.price
                                    "
                                    formControlName="value"
                                    type="text"
                                    [placeholder]="
                                        getPriceInfoField(i, 'placeholder').value
                                            ? 'Ex: ' + getPriceInfoField(i, 'placeholder').value
                                            : ''
                                    "
                                    (keyup)="updatedPriceValue(i)"
                                    [class.is-edited]="isItemFieldEdited(priceInfo.value, 'value')"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 text-end">
                        <button
                            type="button"
                            (click)="removePriceInfo(i)"
                            class="btn btn-danger btn-sm"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
        </ul>

        <div class="card-footer text-center">
            <button type="button" (click)="addPriceInfo()" class="btn btn-primary btn-sm">
                <fa-icon [icon]="faPlus"></fa-icon> Ajout
            </button>
        </div>
    </div>

    <div id="services" class="mb-4" formArrayName="servicesSections">
        <div
            [formGroupName]="i"
            *ngFor="let servicesSection of servicesSections.controls; let i = index"
            class="card mb-4"
        >
            <div class="card-header">
                <div class="form-group mb-0">
                    <div class="input-group">
                        <input
                            class="form-control"
                            formControlName="title"
                            type="text"
                            placeholder="Nom de la section"
                            [class.is-invalid]="
                                (form.touched || getServicesSectionField(i, 'title').touched) &&
                                getServicesSectionField(i, 'title').status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            [class.is-edited]="isItemFieldEdited(servicesSection.value, 'title')"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || getServicesSectionField(i, 'title').touched) &&
                                    getServicesSectionField(i, 'title').errors &&
                                    getServicesSectionField(i, 'title').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                        <button
                            type="button"
                            (click)="removeServicesSection(i)"
                            class="btn btn-danger"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>

            <ul class="list-group list-group-flush" formArrayName="services">
                <li
                    class="list-group-item list-group-item-warning text-center"
                    *ngIf="!getServicesInSection(i).length"
                >
                    Aucun service pour cette section.
                </li>
                <li
                    class="list-group-item"
                    [formGroupName]="j"
                    *ngFor="let service of getServicesInSection(i).controls; let j = index"
                >
                    <div class="input-group">
                        <div class="input-group-text">
                            <input type="checkbox" formControlName="checked" />
                        </div>
                        <input
                            class="form-control"
                            formControlName="title"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || getServiceFieldInSection(i, j, 'title').touched) &&
                                getServiceFieldInSection(i, j, 'title').status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            [class.is-edited]="isItemFieldEdited(service.value, 'title')"
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        getServiceFieldInSection(i, j, 'title').touched) &&
                                    getServiceFieldInSection(i, j, 'title').errors &&
                                    getServiceFieldInSection(i, j, 'title').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                        <input
                            class="form-control text-end"
                            formControlName="comment"
                            type="text"
                            [class.is-invalid]="
                                (form.touched ||
                                    getServiceFieldInSection(i, j, 'comment').touched) &&
                                getServiceFieldInSection(i, j, 'comment').status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            [class.is-edited]="isItemFieldEdited(service.value, 'comment')"
                        />
                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched ||
                                        getServiceFieldInSection(i, j, 'comment').touched) &&
                                    getServiceFieldInSection(i, j, 'comment').errors &&
                                    getServiceFieldInSection(i, j, 'comment').errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                        <button
                            type="button"
                            (click)="removeServiceFromSection(i, j)"
                            class="btn btn-danger"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </li>
            </ul>

            <div class="card-footer text-center">
                <button
                    type="button"
                    (click)="addServiceToSection(i)"
                    class="btn btn-primary btn-sm"
                >
                    <fa-icon [icon]="faPlus"></fa-icon> Ajout service
                </button>
            </div>
        </div>

        <div class="text-center mt-4">
            <button type="button" (click)="addServicesSection()" class="btn btn-primary">
                <fa-icon [icon]="faPlus"></fa-icon> Ajout section
            </button>
        </div>
    </div>

    <ng-container
        *ngIf="[EnumEnquiryType.business, EnumEnquiryType.helico].includes(enquiryType.value)"
    >
        <div class="card mb-4">
            <div class="card-header text-center">
                <div
                    class="card-title"
                    [innerText]="'QUOTATION.PAYMENT_CONDITIONS_TITLE' | translate"
                ></div>
            </div>

            <ul class="list-group list-group-flush" formGroupName="paymentConditionsSettings">
                <li class="list-group-item" formGroupName="firstDeposit">
                    <div class="form-group">
                        <label
                            for="payment-conditions-first-deposit-percent"
                            [innerText]="'QUOTATION.PERCENT_FIRST_DEPOSIT' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                                id="payment-conditions-first-deposit-percent"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        paymentConditionsSettings.value.firstDeposit.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="payment-conditions-first-deposit-text"
                        />
                    </div>
                </li>
                <li class="list-group-item" formGroupName="lastDeposit">
                    <div class="form-group">
                        <label
                            for="payment-conditions-last-deposit-percent"
                            [innerText]="'QUOTATION.PERCENT_BALANCE' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        paymentConditionsSettings.value.lastDeposit.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="payment-conditions-last-deposit-days-before"
                            [innerText]="
                                'QUOTATION.BALANCE_HOW_MANY_DAYS_BEFORE_FLIGHT' | translate
                            "
                        ></label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            formControlName="daysBefore"
                            class="form-control"
                            id="payment-conditions-last-deposit-days-before"
                        />
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="payment-conditions-last-deposit-text"
                        />
                    </div>
                </li>
            </ul>
        </div>
        <div class="card mb-4">
            <div class="card-header text-center">
                <div
                    class="card-title"
                    [innerText]="'QUOTATION.CANCELLING_CONDITIONS_TITLE' | translate"
                ></div>
            </div>

            <ul class="list-group list-group-flush" formGroupName="cancellingConditionsSettings">
                <li class="list-group-item" formGroupName="firstStep">
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-first-step-percent"
                            [innerText]="'QUOTATION.PERCENT_FIRST_DEPOSIT' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                                id="cancelling-conditions-first-step-percent"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        cancellingConditionsSettings.value.firstStep.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-first-step-days-before"
                            [innerText]="
                                'QUOTATION.BALANCE_HOW_MANY_DAYS_BEFORE_FLIGHT' | translate
                            "
                        ></label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            formControlName="daysBefore"
                            class="form-control"
                            id="cancelling-conditions-first-step-days-before"
                        />
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="cancelling-conditions-first-step-text"
                        />
                    </div>
                </li>
                <li class="list-group-item" formGroupName="secondStep">
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-second-step-percent"
                            [innerText]="'QUOTATION.PERCENT_SECOND_DEPOSIT' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                                id="cancelling-conditions-second-step-percent"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        cancellingConditionsSettings.value.secondStep.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <label
                        for="cancelling-conditions-second-step-days-before-start"
                        class="mb-2"
                        [innerText]="
                            'QUOTATION.BALANCE_BETWEEN_X_AND_Y_DAYS_BEFORE_FLIGHT' | translate
                        "
                    ></label>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="cancelling-conditions-second-step-days-before-start"
                                    >X</label
                                >
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    formControlName="daysBeforeStart"
                                    class="form-control"
                                    id="cancelling-conditions-second-step-days-before-start"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="cancelling-conditions-second-step-days-before-end"
                                    >Y</label
                                >
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    formControlName="daysBeforeEnd"
                                    class="form-control"
                                    id="cancelling-conditions-second-step-days-before-end"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="cancelling-conditions-second-step-text"
                        />
                    </div>
                </li>
                <li class="list-group-item" formGroupName="thirdStep">
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-third-step-percent"
                            [innerText]="'QUOTATION.PERCENT_THIRD_DEPOSIT' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                                id="cancelling-conditions-third-step-percent"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        cancellingConditionsSettings.value.thirdStep.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <label for="cancelling-conditions-third-step-days-before-start" class="mb-2">{{
                        "QUOTATION.BALANCE_BETWEEN_X_AND_Y_HOURS_BEFORE_FLIGHT" | translate
                    }}</label>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="cancelling-conditions-third-step-days-before-start"
                                    >X</label
                                >
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    formControlName="daysBeforeStart"
                                    class="form-control"
                                    id="cancelling-conditions-third-step-days-before-start"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="cancelling-conditions-third-step-hours-before-end"
                                    >Y</label
                                >
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    formControlName="hoursBeforeEnd"
                                    class="form-control"
                                    id="cancelling-conditions-third-step-hours-before-end"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="cancelling-conditions-third-step-text"
                        />
                    </div>
                </li>
                <li class="list-group-item" formGroupName="fourthStep">
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-fourth-step-percent"
                            [innerText]="'QUOTATION.PERCENT_BALANCE' | translate"
                        ></label>
                        <div class="input-group">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                step="1"
                                formControlName="percent"
                                class="form-control"
                                id="cancelling-conditions-fourth-step-percent"
                            />
                            <span class="input-group-text"
                                >% =
                                {{
                                    getValuePercentOfTotalAmount(
                                        cancellingConditionsSettings.value.fourthStep.percent
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="cancelling-conditions-fourth-step-days-hours-before"
                            [innerText]="
                                'QUOTATION.BALANCE_HOW_MANY_HOURS_BEFORE_FLIGHT' | translate
                            "
                        ></label>
                        <input
                            type="number"
                            min="0"
                            step="1"
                            formControlName="hoursBefore"
                            class="form-control"
                            id="cancelling-conditions-fourth-step-hours-before"
                        />
                    </div>
                    <div class="form-group">
                        <input
                            type="text"
                            formControlName="text"
                            class="form-control"
                            id="cancelling-conditions-fourth-step-text"
                        />
                    </div>
                </li>
            </ul>
        </div>
    </ng-container>

    <div class="card mb-4" *ngIf="[EnumEnquiryType.commercial].includes(enquiryType.value)">
        <div class="card-header">
            <div
                class="card-title text-center"
                [innerText]="'QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS' | translate"
            ></div>
        </div>
        <div class="card-body">
            <div class="form-group mb-0">
                <textarea
                    formControlName="garantiesAndEngagements"
                    class="form-control"
                    id="garantiesAndEngagements"
                    rows="5"
                ></textarea>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-between mt-4 mb-4">
        <button
            type="button"
            [routerLink]="
                quotationId
                    ? '/admin/quotations/' + quotationId
                    : enquiryId
                    ? '/admin/quotations/enquiry/' + enquiryId
                    : '/admin/quotations'
            "
            class="btn btn-secondary"
            [disabled]="form.disabled"
        >
            Annuler
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="form.disabled || !form.valid">
            Sauvegarder
        </button>
    </div>
</form>

<div class="modal" tabindex="-1" role="dialog" #modalImage>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Sélection image #{{ selectedImageField?.replace("image", "") }}
                </h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div
                    class="card mb-4"
                    *ngIf="
                        (!aircraftModelId.value || !aircraftModelsObj[aircraftModelId.value]) &&
                        selectedImageField
                    "
                >
                    <div class="card-header">Image utilisée</div>
                    <div class="card-body">
                        <div class="row justify-content-center align-items-center">
                            <div
                                class="col-md-6"
                                *ngIf="form.get(selectedImageField + 'Url').value"
                            >
                                <img [src]="form.get(selectedImageField + 'Url').value" />
                            </div>
                            <div class="col-md-6">
                                <div class="btn-group-vertical">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        [innerText]="
                                            form.get(selectedImageField + 'Url').value
                                                ? 'Éditer'
                                                : 'Ajouter'
                                        "
                                        (click)="editSelectedImage()"
                                    ></button>

                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        *ngIf="form.get(selectedImageField + 'Url').value"
                                        (click)="deleteSelectedImage()"
                                    >
                                        Supprimer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4" *ngIf="aircraft">
                    <div class="card-header">Appareil</div>
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <strong>Immatriculation :</strong>
                                {{ aircraft.registration }}<br />
                                <strong>Opérateur :</strong>
                                {{ aircraft.airlineTitle }}<br />
                                <strong>Modèle :</strong>
                                {{ aircraft.aircraftModelTitle || aircraft.model }}
                            </div>
                            <div class="col-md-6 text-end">
                                <a
                                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                                    target="_blank"
                                    class="btn btn-primary"
                                    >Ouvrir <fa-icon [icon]="faExternalLink"></fa-icon
                                ></a>
                            </div>
                        </div>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let imageUrl of aircraft.imageUrls">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img [src]="imageUrl" />
                                </div>
                                <div class="col-md-6">
                                    <button
                                        type="button"
                                        class="btn me-2 mb-2"
                                        [class.btn-primary]="
                                            form.get(selectedImageField + 'Url')?.value !== imageUrl
                                        "
                                        [class.btn-success]="
                                            form.get(selectedImageField + 'Url')?.value === imageUrl
                                        "
                                        [innerText]="
                                            form.get(selectedImageField + 'Url')?.value === imageUrl
                                                ? 'Déselectionner'
                                                : 'Sélectionner'
                                        "
                                        (click)="toggleSelectedImageValue(imageUrl)"
                                    ></button>

                                    <div
                                        class="badge me-2"
                                        [class.text-bg-secondary]="
                                            !aircraft.imageOutsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraft.imageOutsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraft.imageOutsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image extérieure par défaut
                                    </div>
                                    <div
                                        class="badge"
                                        [class.text-bg-secondary]="
                                            !aircraft.imageInsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraft.imageInsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraft.imageInsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image intérieure par défaut
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    class="card mb-4"
                    *ngIf="aircraftModelId.value && aircraftModelsObj[aircraftModelId.value]"
                >
                    <div class="card-header">
                        Image pour le modèle {{ aircraftModelsObj[aircraftModelId.value].title }}
                    </div>
                    <ul class="list-group list-group-flush">
                        <li
                            class="list-group-item"
                            *ngFor="let field of ['imageUrl', 'imageInsideUrl', 'imagePlanUrl']"
                        >
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <label *ngIf="field === 'imageUrl'">Image extérieur</label>
                                    <label *ngIf="field === 'imageInsideUrl'"
                                        >Image intérieur</label
                                    >
                                    <label *ngIf="field === 'imagePlanUrl'">Image plan</label>
                                    <img
                                        [src]="aircraftModelsObj[aircraftModelId.value][field]"
                                        *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <button
                                        type="button"
                                        class="btn me-2 mb-2"
                                        [class.btn-primary]="
                                            form.get(selectedImageField + 'Url')?.value !==
                                            aircraftModelsObj[aircraftModelId.value][field]
                                        "
                                        [class.btn-success]="
                                            form.get(selectedImageField + 'Url')?.value ===
                                            aircraftModelsObj[aircraftModelId.value][field]
                                        "
                                        *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                        [innerText]="
                                            form.get(selectedImageField + 'Url')?.value ===
                                            aircraftModelsObj[aircraftModelId.value][field]
                                                ? 'Déselectionner'
                                                : 'Sélectionner'
                                        "
                                        (click)="
                                            toggleSelectedImageValue(
                                                aircraftModelsObj[aircraftModelId.value][field]
                                            )
                                        "
                                    ></button>
                                    <div class="btn-group-vertical">
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            [innerText]="
                                                aircraftModelsObj[aircraftModelId.value][field]
                                                    ? 'Éditer'
                                                    : 'Ajouter'
                                            "
                                            (click)="editImageToAircraftModel(field)"
                                        ></button>

                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            *ngIf="aircraftModelsObj[aircraftModelId.value][field]"
                                            (click)="deleteImageFromAircraftModel(field)"
                                        >
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <input
                    type="file"
                    id="aircraftModelImageInput"
                    class="form-control"
                    (change)="changeInputFileForAircraftModel($event)"
                    accept="image/*"
                />

                <input
                    type="file"
                    id="imageUrlInput"
                    class="form-control"
                    (change)="changeInputFile($event)"
                    accept="image/*"
                />
            </div>
        </div>
    </div>
</div>
