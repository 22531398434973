import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { format } from 'date-fns';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryStatus } from 'src/app/enums/enquiry-status.enum';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { IStats } from 'src/app/interfaces/stats.interface';
import { addZeroToDigit } from 'src/app/misc.utils';
import { AclService } from 'src/app/services/acl.service';
import { RemoteService } from 'src/app/services/remote.service';
import { StatsService } from 'src/app/services/stats/stats.service';

interface ReportLine {
  id?: string;
  title: string;
  priceNetTTC: number;
  priceSell: number;
  margeBrut: number;
  margeBrutPercent: number;
  countEnquiries: number;
  countConfirmed: number;
  ratioConversion: number;
  deltaMarge: number;
  deltaObjectif: number;
  deltaObjectifPercent: number;
}

@Component({
  selector: 'app-forecast-reports',
  templateUrl: './forecast-reports.component.html',
  styleUrls: ['./forecast-reports.component.scss']
})
export class ForecastReportsComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  yearsList: {
    title: string;
    value: number;
  }[] = [];
  firstYear: number = 2013;

  currentYear: number = parseInt(format(new Date(), 'yyyy'));
  loading: boolean = false;
  data: {
    months: {
      content: ReportLine[];
      total: ReportLine;
    };
    trimesters: {
      content: ReportLine[];
      total: ReportLine;
    };
    years: {
      content: ReportLine[];
      total: ReportLine;
    };
    goals: object;
  } = {
    months: {
      content: [],
      total: null
    },
    trimesters: {
      content: [],
      total: null
    },
    years: {
      content: [],
      total: null
    },
    goals: {}
  };
  enquiries: Array<IEnquiry> = [];
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  previousReports: Array<object> = [];
  previousReportsByYear: object = {};

  yearlyStats: IStats[] = [];
  stats: IStats[] = [];

  private subscriptions = new Subscription();
  private subscriptionsStats = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private statsService: StatsService
  ) {
    this.generateYearsList();

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      goalYear: [0],
      goalTrimester: [0],
      goalMonth: [0]
    });

    await this.aclService.checkAclAccess(EnumAcl.reportsDisplay);

    this.form.disable();

    this.activatedRoute.params.subscribe(() => {
      this.currentYear = parseInt(this.activatedRoute.snapshot.paramMap.get('year'));

      if (!this.currentYear) {
        this.currentYear = moment().get('year');
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsStats.unsubscribe();
  }

  generateYearsList(): void {
    this.yearsList = [];
    for (let year = this.currentYear; year >= this.firstYear; year--) {
      this.yearsList.push({
        title: year + ' (Année ' + (year - this.firstYear + 1) + ')',
        value: year
      });
    }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.resetData();

      this.loadStats();
      this.loadAllYearlyStats();
      await this.loadPreviousReports();
      // await this.updateReport();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadStats(): Promise<void> {
    this.stats = [];

    if (this.subscriptionsStats) {
      this.subscriptionsStats.unsubscribe();
      this.subscriptionsStats = new Subscription();
    }

    this.subscriptionsStats.add(
      this.statsService.getAllForYear(this.currentYear).subscribe((stats: IStats[]) => {
        this.stats = stats;

        this.updateLinesFromStats();
      })
    );
  }

  async loadAllYearlyStats(): Promise<void> {
    this.yearlyStats = [];

    this.subscriptions.add(
      this.statsService.getAllYearly().subscribe((stats: IStats[]) => {
        this.yearlyStats = stats;

        this.updateLinesFromYearlyStats();
      })
    );
  }

  updateLinesFromYearlyStats(): void {
    for (const stat of this.yearlyStats) {
      for (const period in this.data) {
        if (this.data[period].content) {
          for (const line of this.data[period].content) {
            if (line.id === stat.id) {
              line.priceNetTTC = stat.priceNetTTC;
              line.priceSell = stat.priceSell;
              line.margeBrut = stat.margeBrut;
              line.margeBrutPercent =
                stat.priceNetTTC > 0
                  ? this.roundNumber((stat.margeBrut / stat.priceNetTTC) * 100)
                  : 0;
              line.countEnquiries = stat.countEnquiries;
              line.countConfirmed = stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                ? stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                : 0;
              line.ratioConversion =
                stat.countEnquiries > 0 && stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                  ? Math.round(
                      (stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] /
                        stat.countEnquiries) *
                        100
                    )
                  : 0;
              line.deltaMarge = stat.deltaMarge;
              line.deltaObjectif = 0;
              line.deltaObjectifPercent = 0;
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.updateTotal('years');
    }, 1000);
  }

  updateLinesFromStats(): void {
    for (const stat of this.stats) {
      for (const period in this.data) {
        if (this.data[period].content) {
          for (const line of this.data[period].content) {
            if (line.id === stat.id) {
              line.priceNetTTC = stat.priceNetTTC;
              line.priceSell = stat.priceSell;
              line.margeBrut = stat.margeBrut;
              line.margeBrutPercent =
                stat.priceNetTTC > 0
                  ? this.roundNumber((stat.margeBrut / stat.priceNetTTC) * 100)
                  : 0;
              line.countEnquiries = stat.countEnquiries;
              line.countConfirmed = stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                ? stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                : 0;
              line.ratioConversion =
                stat.countEnquiries > 0 && stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed]
                  ? Math.round(
                      (stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] /
                        stat.countEnquiries) *
                        100
                    )
                  : 0;
              line.deltaMarge = stat.deltaMarge;
              line.deltaObjectif = 0;
              line.deltaObjectifPercent = 0;
            }
          }
        }
      }
    }

    setTimeout(() => {
      this.updateTotal();
    }, 1000);
  }

  async loadPreviousReports(): Promise<void> {
    const previousReports: Array<object> = await this.remoteService.getAllDocuments('reports', {
      field: 'created',
      direction: 'desc'
    });

    for (const previousReport of previousReports) {
      this.previousReportsByYear[previousReport['id']] = previousReport;

      if (previousReport['id'] === this.currentYear.toString()) {
        for (const field of ['goalMonth', 'goalTrimester', 'goalYear']) {
          this.form.get(field).setValue(previousReport[field]);
        }

        if (previousReport['data']) {
          this.data.months.content = previousReport['data'];

          for (const i in this.data.months.content) {
            this.data.months.content[i].title = this.capitalizeFirstLetter(
              moment().month(parseInt(i)).format('MMMM')
            );

            this.data.months.content[i].margeBrutPercent = 0;
            this.data.months.content[i].ratioConversion = 0;
            this.data.months.content[i].deltaMarge = 0;
            this.data.months.content[i].deltaObjectif = 0;
            this.data.months.content[i].deltaObjectifPercent = 0;

            const trimesterIndex: number = this.getTrimesterNumberFromMonth(parseInt(i) + 1) - 1;

            if (this.data.months.content[i].countEnquiries) {
              this.data.trimesters.content[trimesterIndex].countEnquiries +=
                this.data.months.content[i].countEnquiries;
            }
            if (this.data.months.content[i].countConfirmed) {
              this.data.trimesters.content[trimesterIndex].countConfirmed +=
                this.data.months.content[i].countConfirmed;
            }
            if (this.data.months.content[i].priceNetTTC) {
              this.data.trimesters.content[trimesterIndex].priceNetTTC +=
                this.data.months.content[i].priceNetTTC;
            }
            if (this.data.months.content[i].priceSell) {
              this.data.trimesters.content[trimesterIndex].priceSell +=
                this.data.months.content[i].priceSell;
            }
            if (this.data.months.content[i].margeBrut) {
              this.data.trimesters.content[trimesterIndex].margeBrut +=
                this.data.months.content[i].margeBrut;
            }
          }
        }
      }

      const yearIndex: number =
        parseInt(previousReport['id']) - this.yearsList[this.yearsList.length - 1].value;

      if (previousReport['data']) {
        for (const monthData of previousReport['data']) {
          for (const field in monthData) {
            if (typeof this.data.years.content[yearIndex][field] === 'undefined') {
              this.data.years.content[yearIndex][field] = 0;
            }

            this.data.years.content[yearIndex][field] += monthData[field];
          }
        }
      }

      this.data.years.content[yearIndex].title = previousReport['id'];
      this.data.years.content[yearIndex].margeBrutPercent = 0;
      this.data.years.content[yearIndex].ratioConversion = 0;
      this.data.years.content[yearIndex].deltaMarge = 0;
      this.data.years.content[yearIndex].deltaObjectif = 0;
      this.data.years.content[yearIndex].deltaObjectifPercent = 0;

      for (const field of ['goalMonth', 'goalTrimester', 'goalYear']) {
        if (typeof this.data.goals[parseInt(previousReport['id'])] === 'undefined') {
          this.data.goals[parseInt(previousReport['id'])] = {};
        }

        this.data.goals[parseInt(previousReport['id'])][field.replace('goal', '').toLowerCase()] =
          previousReport[field];
      }
    }

    this.form.enable();
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign(
        this.previousReportsByYear[this.currentYear.toString()]
          ? this.previousReportsByYear[this.currentYear.toString()]
          : {},
        this.form.value
      );

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      if (this.currentYear) {
        this.remoteService
          .addDocumentToCollectionWithId('reports', this.currentYear.toString(), data)
          .then(() => {
            this.sending = false;
            this.form.enable();

            this.loadData();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        alert('Aucune année de sélectionnée');

        this.form.enable();
      }
    }
  }

  resetData(): void {
    this.data.months.content = [];
    for (let i = 0; i < 12; i++) {
      this.data.months.content.push({
        id: this.currentYear + '-' + addZeroToDigit(i + 1) + '-allusers',
        title: this.capitalizeFirstLetter(moment().month(i).format('MMMM')),
        priceNetTTC: 0,
        priceSell: 0,
        margeBrut: 0,
        margeBrutPercent: 0,
        countEnquiries: 0,
        countConfirmed: 0,
        ratioConversion: 0,
        deltaMarge: 0,
        deltaObjectif: 0,
        deltaObjectifPercent: 0
      } as ReportLine);
    }

    this.data.months.total = {
      priceNetTTC: 0,
      priceSell: 0,
      margeBrut: 0,
      margeBrutPercent: 0,
      countEnquiries: 0,
      countConfirmed: 0,
      ratioConversion: 0,
      deltaMarge: 0,
      deltaObjectif: 0,
      deltaObjectifPercent: 0
    } as ReportLine;

    this.data.trimesters.content = [];
    for (let i = 1; i <= 4; i++) {
      this.data.trimesters.content.push({
        id: 'q' + i + '-' + this.currentYear + '-allusers',
        title: 'T' + i,
        priceNetTTC: 0,
        priceSell: 0,
        margeBrut: 0,
        margeBrutPercent: 0,
        countEnquiries: 0,
        countConfirmed: 0,
        ratioConversion: 0,
        deltaMarge: 0,
        deltaObjectif: 0,
        deltaObjectifPercent: 0
      } as ReportLine);
    }

    this.data.trimesters.total = {
      priceNetTTC: 0,
      priceSell: 0,
      margeBrut: 0,
      margeBrutPercent: 0,
      countEnquiries: 0,
      countConfirmed: 0,
      ratioConversion: 0,
      deltaMarge: 0,
      deltaObjectif: 0,
      deltaObjectifPercent: 0
    } as ReportLine;

    this.data.years.content = [];
    for (const year of this.yearsList) {
      this.data.years.content.push({
        id: year.toString() + '-allusers',
        title: year.toString(),
        priceNetTTC: 0,
        priceSell: 0,
        margeBrut: 0,
        margeBrutPercent: 0,
        countEnquiries: 0,
        countConfirmed: 0,
        ratioConversion: 0,
        deltaMarge: 0,
        deltaObjectif: 0,
        deltaObjectifPercent: 0
      } as ReportLine);
    }

    this.data.years.total = {
      priceNetTTC: 0,
      priceSell: 0,
      margeBrut: 0,
      margeBrutPercent: 0,
      countEnquiries: 0,
      countConfirmed: 0,
      ratioConversion: 0,
      deltaMarge: 0,
      deltaObjectif: 0,
      deltaObjectifPercent: 0
    } as ReportLine;
  }

  changeYear(): void {
    this.loadData();
  }

  updateTotal(forceType: string | null = null): void {
    for (const type in this.data) {
      if (!forceType || forceType === type) {
        for (const field in this.data[type].total) {
          this.data[type].total[field] = 0;
        }

        const typeSingular: string = type.slice(0, -1);

        if (typeof this.data[type].content !== 'undefined') {
          for (const index in this.data[type].content) {
            const yearToCompare: number =
              type === 'years' ? parseInt(index) + this.yearsList[0].value - 1 : this.currentYear;

            this.data[type].content[index].margeBrutPercent =
              this.data[type].content[index].priceNetTTC > 0
                ? this.roundNumber(
                    (this.data[type].content[index].margeBrut /
                      this.data[type].content[index].priceNetTTC) *
                      100
                  )
                : 0;
            this.data[type].content[index].ratioConversion =
              this.data[type].content[index].countEnquiries > 0
                ? Math.round(
                    (this.data[type].content[index].countConfirmed /
                      this.data[type].content[index].countEnquiries) *
                      100
                  )
                : 0;

            if (type === 'years') {
              this.data[type].content[index].deltaMarge =
                parseInt(index) > 0
                  ? this.roundNumber(
                      this.data[type].content[parseInt(index)].margeBrut -
                        this.data[type].content[parseInt(index) - 1].margeBrut
                    )
                  : 0;
              this.data[type].content[index].deltaObjectif =
                typeof this.data.goals[yearToCompare + 1] !== 'undefined' &&
                this.data.goals[yearToCompare + 1][typeSingular]
                  ? this.roundNumber(
                      this.data[type].content[index].margeBrut -
                        this.data.goals[yearToCompare + 1][typeSingular]
                    )
                  : 0;
              this.data[type].content[index].deltaObjectifPercent =
                this.data[type].content[index].margeBrut > 0 &&
                typeof this.data.goals[yearToCompare + 1] !== 'undefined' &&
                this.data.goals[yearToCompare + 1][typeSingular]
                  ? this.roundNumber(
                      (this.data[type].content[index].margeBrut /
                        this.data.goals[yearToCompare + 1][typeSingular]) *
                        100
                    )
                  : 0;
            } else {
              if (
                typeof this.previousReportsByYear[(this.currentYear - 1).toString()] !== 'undefined'
              ) {
                if (
                  this.previousReportsByYear[(this.currentYear - 1).toString()].data &&
                  this.previousReportsByYear[(this.currentYear - 1).toString()].data[index]
                ) {
                  this.data[type].content[index].deltaMarge =
                    this.data[type].content[index].margeBrut -
                    this.previousReportsByYear[(this.currentYear - 1).toString()].data[index]
                      .margeBrut;
                }
              }
              this.data[type].content[index].deltaObjectif =
                typeof this.data.goals[yearToCompare] !== 'undefined' &&
                this.data.goals[yearToCompare][typeSingular]
                  ? this.data[type].content[index].margeBrut -
                    this.data.goals[yearToCompare][typeSingular]
                  : 0;
              this.data[type].content[index].deltaObjectifPercent =
                this.data[type].content[index].margeBrut > 0 &&
                typeof this.data.goals[yearToCompare] !== 'undefined' &&
                this.data.goals[yearToCompare][typeSingular]
                  ? this.roundNumber(
                      (this.data[type].content[index].margeBrut /
                        this.data.goals[yearToCompare][typeSingular]) *
                        100
                    )
                  : 0;
            }

            this.data[type].total.priceNetTTC += this.data[type].content[index].priceNetTTC;
            this.data[type].total.priceSell += this.data[type].content[index].priceSell;
            this.data[type].total.margeBrut += this.data[type].content[index].margeBrut;
            this.data[type].total.countEnquiries += this.data[type].content[index].countEnquiries;
            this.data[type].total.countConfirmed += this.data[type].content[index].countConfirmed;
          }

          this.data[type].total.margeBrutPercent =
            this.data[type].total.priceNetTTC > 0
              ? this.roundNumber(
                  (this.data[type].total.margeBrut / this.data[type].total.priceNetTTC) * 100
                )
              : 0;
          this.data[type].total.ratioConversion =
            this.data[type].total.countEnquiries > 0
              ? this.roundNumber(
                  (this.data[type].total.countConfirmed / this.data[type].total.countEnquiries) *
                    100
                )
              : 0;

          if (type === 'years') {
            this.data[type].total.deltaMarge =
              this.data[type].total.margeBrut - this.data['years'].content[0].margeBrut;
          } else {
            this.data[type].total.deltaMarge =
              typeof this.data['years'].content[this.currentYear - 1 - this.yearsList[0].value] !==
              'undefined'
                ? this.data[type].total.margeBrut -
                  this.data['years'].content[this.currentYear - 1 - this.yearsList[0].value]
                    .margeBrut
                : 0;
          }

          if (type !== 'years') {
            this.data[type].total.deltaObjectif =
              this.data.goals[this.currentYear] && this.data.goals[this.currentYear].year
                ? this.data[type].total.margeBrut - this.data.goals[this.currentYear].year
                : 0;
            this.data[type].total.deltaObjectifPercent =
              this.data.goals[this.currentYear] && this.data.goals[this.currentYear].year > 0
                ? this.roundNumber(
                    (this.data[type].total.margeBrut / this.data.goals[this.currentYear].year) * 100
                  )
                : 0;
          }
        }
      }
    }
  }

  private capitalizeFirstLetter(str: string): string {
    return `${str[0].toUpperCase()}${str.substring(1)}`;
  }

  getTrimesterNumberFromMonth(month: number): number {
    if (month <= 3) {
      return 1;
    } else if (month <= 6) {
      return 2;
    } else if (month <= 9) {
      return 3;
    } else if (month <= 12) {
      return 4;
    }
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
