<div class="btn-toolbar mb-4 justify-content-between" role="toolbar">
    <a
        routerLink="/admin/aircraft-models/add"
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.aircraftModelsAdd)"
        >+ Nouveau modèle d'avion</a
    >
    <a
        routerLink="/admin/statistics/aircraft-models"
        class="btn btn-primary"
        *ngIf="hasAclAccess(EnumAcl.aircraftModelsStatistics)"
        >Statistiques</a
    >
</div>

<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [rowUrl]="'/admin/aircraft-models/'"
    [orderBy]="orderBy"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    [currentPage]="currentPage"
></app-data-list>
