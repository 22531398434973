<div
    class="offcanvas offcanvas-start"
    data-bs-backdrop="false"
    tabindex="-1"
    id="offcanvas-admin-menu"
    aria-labelledby="offcanvas-admin-menuLabel"
    *ngIf="displaySidebar"
    [class.menu-collapsed]="menuCollapsed"
    [class.collapsing-animation]="menuCollapsedAnimation"
>
    <div class="offcanvas-header">
        <a class="offcanvas-brand" routerLink="/admin"
            ><img src="assets/img/logo-artheau-transparent.png" height="40" /> <em>SIMPLY</em>fly</a
        >

        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            (click)="menuExpand()"
        >
            <fa-icon [icon]="faChevronLeft" *ngIf="!menuCollapsed"></fa-icon>
            <fa-icon [icon]="faChevronRight" *ngIf="menuCollapsed"></fa-icon>
        </button>
        <div class="menu-inner-shadow"></div>
    </div>
    <div class="offcanvas-body" id="sidebar-body">
        <div class="offcanvas-body-content">
            <div class="input-group input-group-search flex-nowrap mb-3">
                <span class="input-group-text" id="addon-wrapping"
                    ><fa-icon [icon]="faSearch"></fa-icon
                ></span>
                <input
                    type="search"
                    class="form-control"
                    placeholder="Recherche"
                    [(ngModel)]="searchQuery"
                    (keyup)="searchKeyUp.next($event.srcElement.value)"
                    (search)="searchKeyUp.next($event.srcElement.value)"
                />
            </div>
            <ng-container *ngIf="searchQuery">
                <ng-container *ngFor="let searchKey of searchKeys">
                    <div class="list-group-header" [innerText]="searchKey.title"></div>
                    <ul class="list-group mb-4" *ngIf="searchLoading[searchKey.value]">
                        <li
                            class="list-group-item placeholder-glow"
                            *ngFor="let item of [].constructor(5)"
                        >
                            <div class="placeholder w-100"></div>
                        </li>
                    </ul>
                    <div class="list-group mb-4" *ngIf="!searchLoading[searchKey.value]">
                        <div
                            class="list-group-item list-group-item-warning text-center"
                            *ngIf="!searchOptions[searchKey.value].length"
                        >
                            Aucun résultat à afficher
                        </div>
                        <button
                            type="button"
                            (click)="openItem(searchKey.value, item)"
                            class="list-group-item list-group-item-action"
                            *ngFor="let item of searchOptions[searchKey.value]"
                        >
                            <div
                                *ngIf="searchKey.value === EnumSearchKey.enquiries"
                                class="d-flex justify-content-between"
                            >
                                {{ getDisplayedEnquiryRefTitle(item, "refEnquiry") }}
                                <strong
                                    *ngIf="item.refContractTitle"
                                    [innerText]="getDisplayedEnquiryRefTitle(item, 'refContract')"
                                ></strong>
                            </div>
                            <div *ngIf="searchKey.value === EnumSearchKey.invoices">
                                <div [innerText]="item.ref"></div>
                                {{ formatPrice(item.amountTtcTotal, item.currency) }}
                            </div>
                            <div *ngIf="searchKey.value === EnumSearchKey.airports">
                                <div *ngIf="item.iataCode">
                                    <strong [innerText]="item.iataCode"></strong>
                                </div>
                                {{ item.title }}
                            </div>
                            <div *ngIf="searchKey.value === EnumSearchKey.clients">
                                {{ item.name }}
                            </div>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <nav *ngIf="!searchQuery.length">
            <app-sidebar-menu [menuLinks]="menuLinks"></app-sidebar-menu>
        </nav>

        <div class="staging-info" *ngIf="isStaging">Environnement : Préproduction</div>
    </div>
</div>

<div
    class="main-container"
    [class.has-sidebar]="displaySidebar"
    [class.has-top-menu]="displayTopMenu"
    [class.has-menu-collapsed]="menuCollapsed"
    [class.is-login]="isLoginPage"
>
    <div class="container-fluid">
        <app-header-menu *ngIf="displayTopMenu"></app-header-menu>

        <main>
            <div [class.d-none]="!hideLoading">
                <router-outlet></router-outlet>
            </div>

            <div class="text-center" id="main-loader" [class.d-none]="hideLoading">
                <div class="spinner-border" role="status"></div>
                Chargement ...
            </div>
        </main>
    </div>
</div>

<app-loader></app-loader>
