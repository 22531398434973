<div class="row">
    <div class="col-md-4">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && flightChecklistTemplate">
                <h3
                    [innerHtml]="getEnumEnquiryTypeLabel(flightChecklistTemplate.enquiryType)"
                    class="text-center"
                ></h3>

                <div class="text-center mt-4">
                    <div class="btn-group">
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-danger"
                            *ngIf="hasAclAccess(EnumAcl.flightChecklistTemplatesDelete)"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <a
                            [routerLink]="
                                '/admin/flight-checklist-templates/' +
                                flightChecklistTemplate.id +
                                '/edit'
                            "
                            class="btn btn-primary"
                            *ngIf="hasAclAccess(EnumAcl.flightChecklistTemplatesEdit)"
                        >
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-8">
        <div class="card mb-4" *ngIf="loading">
            <ul class="list-group list-group-flush">
                <li class="list-group-item" *ngFor="let number of [].constructor(5)">
                    <div class="placeholder w-100"></div>
                </li>
            </ul>
        </div>
        <div class="accordion" id="accordion-modules" *ngIf="!loading && flightChecklistTemplate">
            <div
                class="accordion-item"
                *ngFor="let module of flightChecklistTemplate.modules; let i = index"
            >
                <h2 class="accordion-header">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse-' + i"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse-' + i"
                    >
                        {{ module.title }}

                        <span
                            class="badge rounded-pill text-bg-primary ms-2"
                            *ngIf="module.isSharedAmongAllLegs"
                            >Commun à tous les legs</span
                        >
                    </button>
                </h2>
                <div
                    [id]="'collapse-' + i"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordion-modules"
                >
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let field of module.fields">
                            <div class="row">
                                <div class="col-sm-6" [innerText]="field.text"></div>
                                <div
                                    class="col-sm-6"
                                    [innerText]="
                                        getFlightChecklistTemplateFieldTypeLabel(field.type)
                                    "
                                ></div>
                            </div>

                            <ul
                                class="list-group mt-2"
                                *ngIf="field.type === EnumFlightChecklistTemplateFieldType.select"
                            >
                                <li
                                    class="list-group-item"
                                    [class.list-group-item-warning]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.warning
                                    "
                                    [class.list-group-item-danger]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.danger
                                    "
                                    [class.list-group-item-success]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.success
                                    "
                                    [class.list-group-item-info]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.info
                                    "
                                    [class.list-group-item-primary]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.primary
                                    "
                                    [class.list-group-item-secondary]="
                                        option.status ===
                                        EnumFlightChecklistTemplateFieldOptionStatus.secondary
                                    "
                                    *ngFor="let option of field.options"
                                >
                                    {{ option.text }}
                                    <div *ngIf="field.defaultOptionId === option.id">
                                        <em>(Valeur par défaut)</em>
                                    </div>
                                    <div *ngIf="field.finalOptionId === option.id">
                                        <em>(Finale)</em>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
