<div class="row">
    <div class="col-md-4 col-lg-3">
        <div class="card mb-4">
            <div class="card-header text-center placeholder-glow">
                <div class="placeholder w-100" *ngIf="!airline"></div>
                <div class="card-title" [innerText]="airline.title" *ngIf="airline"></div>
            </div>
            <div class="card-body placeholder-glow" *ngIf="loading">
                <div class="placeholder w-100" *ngFor="let number of [].constructor(5)"></div>
            </div>
            <div class="card-body" *ngIf="!loading && airline">
                <dl>
                    <dt>Continent</dt>
                    <dd>{{ getContinentLabel(airline.continentCode) }}</dd>
                    <dt>Pays</dt>
                    <dd>{{ getCountryLabel(airline.countryCode) }}</dd>
                    <ng-container *ngIf="airline.isNationalCompany">
                        <dt>Est une compagnie nationale</dt>
                    </ng-container>
                    <ng-container *ngIf="airline.mustBeContacted">
                        <dt>Doit absolument être contactée</dt>
                    </ng-container>
                    <ng-container *ngIf="airline.isBlacklisted">
                        <dt>Est blacklistée</dt>
                        <dt>Raison du black listage</dt>
                        <dd [innerText]="airline.reasonBlackListed"></dd>
                    </ng-container>
                    <ng-container *ngIf="airline.companyCreationYear">
                        <dt>Année de création</dt>
                        <dd [innerText]="airline.companyCreationYear"></dd>
                    </ng-container>
                    <ng-container *ngIf="airline.companyClosureYear">
                        <dt>Année de fermeture</dt>
                        <dd [innerText]="airline.companyClosureYear"></dd>
                    </ng-container>
                    <dt>Est active ?</dt>
                    <dd>
                        <span
                            class="badge text-light"
                            [class.bg-success]="airline.isActive"
                            [class.bg-danger]="!airline.isActive"
                            [innerText]="airline.isActive ? 'Oui' : 'Non'"
                        ></span>
                    </dd>
                    <ng-container *ngIf="airline.codes">
                        <dt>Codes</dt>
                        <dd [innerText]="airline.codes"></dd>
                    </ng-container>
                    <ng-container *ngIf="airline.callsign">
                        <dt>Callsign</dt>
                        <dd [innerText]="airline.callsign"></dd>
                    </ng-container>
                    <ng-container *ngIf="airline.website">
                        <dt>Site web</dt>
                        <dd>
                            <a
                                [href]="airline.website"
                                target="_blank"
                                [innerText]="airline.website"
                            ></a>
                        </dd>
                    </ng-container>
                    <ng-container *ngIf="getAirlineAddress(airline)">
                        <dt>Adresse</dt>
                        <dd>
                            <address [innerHtml]="getAirlineAddress(airline)"></address>
                        </dd>
                    </ng-container>
                    <ng-container *ngIf="airline.tvaNumber">
                        <dt>Numéro de TVA</dt>
                        <dd [innerText]="airline.tvaNumber"></dd>
                    </ng-container>
                    <ng-container *ngIf="airline.url">
                        <dt>Airfleets</dt>
                        <dd>
                            <a [href]="airline.url" target="_blank" *ngIf="airline.url">Lien</a>
                            <div *ngIf="airline.airfleetTitle">
                                Nom dans Airfleets: {{ airline.airfleetTitle }}
                            </div>
                        </dd>
                    </ng-container>
                </dl>
                <p
                    *ngIf="airline.history && airline.history?.length"
                    [innerHtml]="'Historique : ' + airline.history"
                ></p>
                <p
                    *ngIf="airline.fusionsTransfers?.length"
                    [innerHtml]="'Fusion/Transfert vers : ' + airline.fusionsTransfers.join(', ')"
                ></p>
                <p
                    *ngIf="airline.previousNames?.length"
                    [innerHtml]="'Dans le passé, voir aussi : ' + airline.previousNames.join(', ')"
                ></p>

                <div class="text-center">
                    <div class="btn-group">
                        <button
                            type="button"
                            (click)="delete()"
                            class="btn btn-danger btn-sm"
                            *ngIf="hasAclAccess(EnumAcl.airlinesDelete)"
                        >
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                        <a
                            [routerLink]="'/admin/airlines/' + airline.id + '/edit'"
                            class="btn btn-primary btn-sm"
                            *ngIf="hasAclAccess(EnumAcl.airlinesEdit)"
                            ><fa-icon [icon]="faEdit"></fa-icon
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-lg-9">
        <div class="row" *ngIf="!loading && airline && airline.description">
            <div class="col-md-6">
                <strong>Description de la compagnie</strong><br />
                <div class="placeholder w-100" *ngIf="loading"></div>
                <p [innerHtml]="airline.description" class="description"></p>
            </div>
        </div>

        <ul class="nav nav-pills mb-2">
            <li class="nav-item" *ngFor="let tab of tabs">
                <button
                    type="button"
                    class="nav-link"
                    [class.active]="currentTab === tab.value"
                    [attr.aria-current]="currentTab === tab.value ? 'page' : ''"
                    [innerText]="tab.title"
                    (click)="updateCurrentTab(tab.value)"
                    [disabled]="loading"
                ></button>
            </li>
        </ul>

        <div *ngIf="currentTab === EnumTab.airlineSupervisors">
            <app-list-airline-supervisors [airlineId]="airlineId"></app-list-airline-supervisors>
        </div>

        <div *ngIf="currentTab === EnumTab.crews">
            <app-list-crews [airlineId]="airlineId"></app-list-crews>
        </div>

        <div *ngIf="currentTab === EnumTab.fleet">
            <app-airline-fleet [airline]="airline" *ngIf="airline"></app-airline-fleet>
        </div>

        <div *ngIf="currentTab === EnumTab.invoices">
            <app-invoices-list-for-airline [airlineId]="airlineId"></app-invoices-list-for-airline>
        </div>

        <div *ngIf="currentTab === EnumTab.enquiries">
            <app-enquiries-list-for-airline
                [airlineId]="airlineId"
            ></app-enquiries-list-for-airline>
        </div>

        <div *ngIf="currentTab === EnumTab.documents">
            <div class="card mb-4">
                <div class="card-header">Documentation compagnie</div>
                <div class="list-group list-group-flush">
                    <ng-container *ngIf="loading"
                        ><div
                            class="list-group-item placeholder-glow"
                            *ngFor="let number of [].constructor(3)"
                        >
                            <div class="placeholder w-100"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loading">
                        <div
                            class="list-group-item list-group-item-warning text-center"
                            *ngIf="!airline?.documentsUrl?.length"
                        >
                            Aucun document
                        </div>
                        <a
                            class="list-group-item list-group-item-action"
                            [href]="documentUrl.url"
                            *ngFor="let documentUrl of airline?.documentsUrl"
                            target="_blank"
                            [innerText]="documentUrl.title"
                        >
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="currentTab === EnumTab.contacts">
            <div class="accordion mb-4" *ngIf="loading">
                <div class="accordion-item" *ngFor="let number of [].constructor(3)">
                    <div class="accordion-header placeholder-glow" id="heading-{{ number }}">
                        <button
                            class="accordion-button collapsed gap-2"
                            type="button"
                            [disabled]="true"
                        >
                            <div class="placeholder w-100"></div>
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="alert alert-info text-center"
                *ngIf="!loading && airline && !airline.companyContacts?.length"
            >
                Aucun contact pour cette compagnie aérienne.
            </div>

            <div
                class="accordion mb-4"
                id="contacts"
                *ngIf="!loading && airline && airline.companyContacts?.length"
            >
                <div
                    class="accordion-item"
                    *ngFor="let companyContact of airline.companyContacts; let i = index"
                >
                    <div class="accordion-header" id="heading-{{ i }}">
                        <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse-' + i"
                            aria-expanded="false"
                            [attr.aria-controls]="'collapse-' + i"
                        >
                            {{ companyContact.title }}
                        </button>
                    </div>

                    <div
                        id="collapse-{{ i }}"
                        class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'heading-' + i"
                        data-parent="#contacts"
                    >
                        <ul class="list-group list-group-flush">
                            <li
                                class="list-group-item list-group-item-warning text-center"
                                *ngIf="!companyContact.contacts.length"
                            >
                                Aucun contact
                            </li>
                            <li
                                class="list-group-item"
                                *ngFor="let contact of companyContact.contacts; let j = index"
                            >
                                <div *ngIf="contact.lastname || contact.firstname">
                                    <strong
                                        [innerText]="contact.lastname + ' ' + contact.firstname"
                                    ></strong>
                                </div>
                                <div *ngIf="contact.fonction">{{ contact.fonction }}</div>
                                <div *ngIf="contact.phone">
                                    Tel :
                                    <a
                                        [href]="'tel:' + contact.phone"
                                        [innerText]="contact.phone"
                                    ></a>
                                </div>
                                <div *ngIf="contact.mobile">
                                    Portable :
                                    <a
                                        [href]="'tel:' + contact.mobile"
                                        [innerText]="contact.mobile"
                                    ></a>
                                </div>
                                <div *ngIf="contact.email">
                                    Email :
                                    <a
                                        [href]="'mailto:' + contact.email"
                                        [innerText]="contact.email"
                                    ></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
