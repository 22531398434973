import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';

@Component({
  selector: 'app-aircrafts-list-for-airline',
  templateUrl: './aircrafts-list-for-airline.component.html',
  styleUrls: ['./aircrafts-list-for-airline.component.scss']
})
export class AircraftsListForAirlineComponent
  extends DataList<IAircraft>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() airlineId: string | null = null;

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'airline-aircrafts-list';
  columns: IDataListColumn[] = [
    {
      title: 'MSN',
      field: 'serialNumber'
    },
    {
      title: 'Modèle',
      field: 'aircraftModelId',
      type: EnumDataListColumnType.function,
      resolve: (item: IAircraft): string => {
        let html: string = '';

        if (item.aircraftModelId && this.aircraftModelsObj[item.aircraftModelId]) {
          html += this.aircraftModelsObj[item.aircraftModelId].title;
        } else if (item.model) {
          html += item.model;
        } else {
          html += item.aircraftModelId;
        }

        if (item.type) {
          html += '<br>' + item.type;
        }

        return html;
      }
    },
    {
      title: 'Immatriculation',
      field: 'registration'
    },
    {
      title: 'Date de livraison',
      field: 'firstFlightDate',
      type: EnumDataListColumnType.function,
      resolve: (item: IAircraft): string => {
        return item.firstFlightDate || 'Inconnu';
      }
    },
    {
      title: 'Capacité',
      field: 'seatTotal'
    },
    {
      title: 'Cargo ?',
      field: 'isCargo',
      type: EnumDataListColumnType.boolean
    },
    {
      title: "Date d'ajout",
      field: 'created',
      type: EnumDataListColumnType.date
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'created', direction: 'desc' };

  isLogged: boolean = false;

  aircraftModelsObj: { [id: string]: IAircraftModel | null } = {};

  private subscriptions = new Subscription();

  constructor(
    private aircraftService: AircraftService,
    private remoteService: RemoteService,
    private aircraftModelService: AircraftModelService
  ) {
    super();

    this.setDataService(this.aircraftService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.updatedAirlineId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['airlineId'] && !changes['airlineId'].isFirstChange()) {
      this.updatedAirlineId();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedAirlineId(): void {
    if (this.isLogged) {
      this.conditions = [];

      if (this.airlineId) {
        this.conditions.push({
          field: 'airlineId',
          operator: '==',
          value: this.airlineId
        });
      }

      this.resetAndLoad();
    } else {
      setTimeout(() => {
        this.updatedAirlineId();
      }, 500);
    }
  }

  afterLoadData(): void {
    for (const item of this.data) {
      if (item.aircraftModelId) {
        this.loadAircraftModel(item.aircraftModelId);
      }
    }
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (typeof this.aircraftModelsObj[aircraftModelId] === 'undefined') {
      this.aircraftModelsObj[aircraftModelId] = null;
    }

    this.subscriptions.add(
      this.aircraftModelService
        .getFromId(aircraftModelId)
        .subscribe((aircraftModel: IAircraftModel | null) => {
          this.aircraftModelsObj[aircraftModelId] = aircraftModel;
        })
    );
  }
}
