<div class="row justify-content-between mb-4">
    <div class="col-md-6 col-lg-7">
        <div class="form-group">
            <label for="user" *ngIf="!isMyStats">Filtrer par utilisateur</label>
            <div class="input-group">
                <select
                    class="form-select"
                    id="user-id"
                    [(ngModel)]="userId"
                    (change)="updatedUser()"
                    *ngIf="!isMyStats"
                >
                    <option [ngValue]="null">Tous les utilisateurs</option>
                    <ng-container *ngFor="let user of users">
                        <option
                            [value]="user.id"
                            [innerHtml]="getUserFullname(user)"
                            *ngIf="user.displayInStatistics"
                        ></option>
                    </ng-container>
                </select>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="editUserGoals()"
                    *ngIf="hasAclAccess(EnumAcl.usersUserGoals) && user"
                >
                    Éditer objectifs
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-5">
        <div class="row">
            <div class="col-sm">
                <div class="form-group">
                    <label for="filterByPeriod">Filtrer par période</label>
                    <div class="input-group">
                        <select
                            class="form-select"
                            id="filterByPeriod"
                            [(ngModel)]="filterByStatId"
                            (change)="updateFilterByStat()"
                        >
                            <option
                                [value]="filter.value"
                                *ngFor="let filter of filtersByPeriod"
                                [innerHtml]="filter.title"
                            ></option>
                        </select>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="exportStatistics()"
                            [disabled]="!filterByStatId"
                            *ngIf="hasAclAccess(EnumAcl.usersStatisticsExport)"
                        >
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="user && user.goals && user.goals[currentYear]">
    <div class="col-sm-8">
        <div class="row">
            <div class="col-sm-6" *ngFor="let quarterNumber of [1, 2, 3, 4]">
                <div class="card card-statistics mb-4">
                    <div class="card-header">
                        Objectif Q{{ quarterNumber }} {{ currentYear }}
                        <span
                            class="float-end text-muted"
                            [innerText]="
                                formatPrice(user.goals[currentYear]['q' + quarterNumber].goal)
                            "
                        ></span>
                    </div>
                    <div class="card-body" [class.disabled]="loading">
                        <label class="mb-0">Prévisionnel</label>
                        <span
                            class="float-end"
                            [innerText]="
                                formatPrice(
                                    user.goals[currentYear]['q' + quarterNumber].provisional
                                )
                            "
                        ></span>
                        <div
                            class="progress mt-2 mb-2"
                            [class.danger]="
                                getPercentFromGoal(
                                    user.goals[currentYear]['q' + quarterNumber],
                                    'provisional'
                                ) < 100
                            "
                            [class.success]="
                                getPercentFromGoal(
                                    user.goals[currentYear]['q' + quarterNumber],
                                    'provisional'
                                ) >= 100
                            "
                        >
                            <div
                                class="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                [style.width]="
                                    getPercent(
                                        user.goals[currentYear]['q' + quarterNumber],
                                        'provisional'
                                    ) + '%'
                                "
                            >
                                {{
                                    getPercentFromGoal(
                                        user.goals[currentYear]["q" + quarterNumber],
                                        "provisional"
                                    )
                                }}%
                            </div>
                        </div>
                        <label class="mb-0">Réel</label>
                        <span
                            class="float-end"
                            [innerText]="
                                formatPrice(user.goals[currentYear]['q' + quarterNumber].actual)
                            "
                        ></span>
                        <div
                            class="progress mt-2 mb-2"
                            [class.danger]="
                                getPercentFromGoal(
                                    user.goals[currentYear]['q' + quarterNumber],
                                    'actual'
                                ) < 100
                            "
                            [class.success]="
                                getPercentFromGoal(
                                    user.goals[currentYear]['q' + quarterNumber],
                                    'actual'
                                ) >= 100
                            "
                        >
                            <div
                                class="progress-bar"
                                role="progressbar"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                [style.width]="
                                    getPercent(
                                        user.goals[currentYear]['q' + quarterNumber],
                                        'actual'
                                    ) + '%'
                                "
                            >
                                {{
                                    getPercentFromGoal(
                                        user.goals[currentYear]["q" + quarterNumber],
                                        "actual"
                                    )
                                }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card card-statistics mb-4" *ngIf="user.goals[currentYear].year">
            <div class="card-header">
                Objectif {{ currentYear }}
                <span
                    class="float-end text-muted"
                    [innerText]="formatPrice(user.goals[currentYear].year.goal)"
                ></span>
            </div>
            <div class="card-body" [class.disabled]="loading">
                <label class="mb-0">Prévisionnel</label>
                <span
                    class="float-end"
                    [innerText]="formatPrice(user.goals[currentYear].year.provisional)"
                ></span>
                <div
                    class="progress mt-2 mb-2"
                    [class.danger]="
                        getPercentFromGoal(user.goals[currentYear].year, 'provisional') < 100
                    "
                    [class.success]="
                        getPercentFromGoal(user.goals[currentYear].year, 'provisional') >= 100
                    "
                >
                    <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [style.width]="
                            getPercent(user.goals[currentYear].year, 'provisional') + '%'
                        "
                    >
                        {{ getPercentFromGoal(user.goals[currentYear].year, "provisional") }}%
                    </div>
                </div>
                <label class="mb-0">Réel</label>
                <span
                    class="float-end"
                    [innerText]="formatPrice(user.goals[currentYear].year.actual)"
                ></span>
                <div
                    class="progress mt-2 mb-2"
                    [class.danger]="
                        getPercentFromGoal(user.goals[currentYear].year, 'actual') < 100
                    "
                    [class.success]="
                        getPercentFromGoal(user.goals[currentYear].year, 'actual') >= 100
                    "
                >
                    <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [style.width]="getPercent(user.goals[currentYear].year, 'actual') + '%'"
                    >
                        {{ getPercentFromGoal(user.goals[currentYear].year, "actual") }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-3 left-sidebar">
        <div class="row">
            <div class="col-sm-6 col-lg-5 col-xl-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Nombre de demandes par statuts</div>
                    <table class="table table-sm">
                        <tbody>
                            <tr
                                class="clickable"
                                *ngFor="let enquiryStatus of getEnumEnquiryStatus()"
                                [routerLink]="
                                    '/admin/enquiries/user/' +
                                    (user?.id ?? 'all') +
                                    '/status/' +
                                    enquiryStatus +
                                    '/period/' +
                                    filterByStatId +
                                    '/type/all/cancelled-reason/all'
                                "
                            >
                                <td
                                    class="text-start"
                                    [innerText]="getEnumEnquiryStatusLabel(enquiryStatus)"
                                ></td>
                                <td class="text-end">
                                    <span
                                        class="badge"
                                        [class.bg-success]="
                                            enquiryStatus === EnumEnquiryStatus.confirmed
                                        "
                                        [class.bg-primary]="
                                            enquiryStatus === EnumEnquiryStatus.draft
                                        "
                                        [class.bg-danger]="
                                            enquiryStatus === EnumEnquiryStatus.cancelled
                                        "
                                        [innerHtml]="
                                            currentStat?.countEnquiriesByStatus[enquiryStatus] || 0
                                        "
                                    ></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-6 col-lg-5 col-xl-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Nombre de demandes par type</div>
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th class="text-center">Créés</th>
                                <th class="text-center">Confirmées</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let enquiryType of getEnumEnquiryType()"
                                [class]="'clickable enquiry-' + enquiryType"
                                [routerLink]="
                                    '/admin/enquiries/user/' +
                                    (user?.id ?? 'all') +
                                    '/status/all' +
                                    '/period/' +
                                    filterByStatId +
                                    '/type/' +
                                    enquiryType +
                                    '/cancelled-reason/all'
                                "
                            >
                                <td
                                    class="text-start"
                                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                                ></td>
                                <td class="text-center">
                                    <strong
                                        [innerText]="
                                            currentStat?.countEnquiriesByType[enquiryType] || 0
                                        "
                                    ></strong>
                                </td>
                                <td class="text-center">
                                    <strong
                                        [innerText]="
                                            currentStat?.countEnquiriesConfirmedByType[
                                                enquiryType
                                            ] || 0
                                        "
                                    ></strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12 col-lg-5 col-xl-12">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Raisons d'annulation des demandes</div>
                    <table class="table table-sm">
                        <tbody>
                            <tr
                                *ngFor="let cancelledReason of getEnumEnquiryCancelledReason()"
                                class="clickable"
                                [routerLink]="
                                    '/admin/enquiries/user/' +
                                    (user?.id ?? 'all') +
                                    '/status/' +
                                    EnumEnquiryStatus.cancelled +
                                    '/period/' +
                                    filterByStatId +
                                    '/type/all/cancelled-reason/' +
                                    cancelledReason
                                "
                            >
                                <td
                                    class="text-start"
                                    [innerText]="
                                        getEnumEnquiryCancelledReasonLabel(cancelledReason)
                                    "
                                ></td>
                                <td class="text-end">
                                    <span
                                        class="badge bg-danger"
                                        [innerText]="
                                            currentStat?.countEnquiryesByCancelledReasons[
                                                cancelledReason
                                            ] || 0
                                        "
                                    ></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-9">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Total de demandes</div>
                    <div class="card-body big-number" [class.disabled]="loading">
                        <div>
                            {{ currentStat?.countEnquiries || 0 }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Chiffre d'affaires</div>
                    <div
                        class="card-body big-number"
                        [innerHtml]="formatPrice(currentStat?.priceSell || 0)"
                        [class.disabled]="loading"
                    ></div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Marge</div>
                    <div class="card-body big-number" [class.disabled]="loading">
                        {{ formatPrice(currentStat?.margeBrut) }} <br />{{
                            getMarginPercent(currentStat?.margeBrut, currentStat?.priceNetTTC) || 0
                        }}%
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="card card-statistics mb-4">
                    <div class="card-header">Ratio D/C</div>
                    <div class="card-body big-number" [class.disabled]="loading">
                        {{ getRatioEnquiryConfirmed(currentStat) }}%
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="btn-group mb-2 float-end">
                            <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                                *ngIf="!loadingGraph && chartStats.length"
                                (click)="downloadGraphSpreadsheet()"
                            >
                                <fa-icon [icon]="faFileExcel"></fa-icon>
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                                *ngIf="!loadingGraph && chartStats.length"
                                (click)="downloadGraph()"
                            >
                                <fa-icon [icon]="faImage"></fa-icon>
                            </button>
                        </div>
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic radio toggle button group"
                        >
                            <ng-container *ngFor="let chartTab of chartTabs">
                                <input
                                    type="radio"
                                    class="btn-check"
                                    name="chart-tab"
                                    [id]="'chart-tab-' + chartTab.value"
                                    autocomplete="off"
                                    [checked]="chartSelected === chartTab.value"
                                    (change)="selectChart(chartTab.value)"
                                    [disabled]="!chartStats.length || loadingGraph"
                                />
                                <label
                                    class="btn btn-primary"
                                    [attr.for]="'chart-tab-' + chartTab.value"
                                    >{{ chartTab.title }}
                                </label>
                            </ng-container>
                        </div>
                    </div>
                    <div class="card-body card-chart" [class.disabled]="loadingGraph">
                        <div class="text-center">
                            <div class="spinner-border" role="status" *ngIf="loadingGraph">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div
                            class="alert alert-warning text-center"
                            *ngIf="!loadingGraph && !chartStats.length"
                        >
                            Aucune donnée à afficher
                        </div>

                        <canvas
                            #enquiriesReceivedChart
                            [class.no-data]="!loadingGraph && !chartStats.length"
                            [class.has-data]="!loadingGraph && chartStats.length"
                            [class.loading]="loadingGraph"
                        ></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card card-statistics mb-4">
            <div class="card-header">
                Meilleurs clients

                <select
                    class="form-select float-end"
                    [(ngModel)]="bestClientsCount"
                    *ngIf="currentStat?.bestClients?.length >= 5"
                >
                    <ng-container *ngFor="let option of filterTopNumber; let i = index">
                        <option
                            [value]="option.value"
                            [innerText]="option.title"
                            *ngIf="
                                option.value <= currentStat?.bestClients?.length ||
                                (i > 0 &&
                                    filterTopNumber[i - 1].value <=
                                        currentStat?.bestClients?.length)
                            "
                        ></option>
                    </ng-container>
                </select>
            </div>
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestClients', 'clientTitle')"
                            >
                                Nom
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortClientsBy.field !== 'clientTitle'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortClientsBy.field === 'clientTitle' &&
                                        sortClientsBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortClientsBy.field === 'clientTitle' &&
                                        sortClientsBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>

                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestClients', 'margeBrut')"
                            >
                                Marge
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortClientsBy.field !== 'margeBrut'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortClientsBy.field === 'margeBrut' &&
                                        sortClientsBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortClientsBy.field === 'margeBrut' &&
                                        sortClientsBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                class="text-end clickable"
                                (click)="sortStatBest('bestClients', 'priceSell')"
                            >
                                Chiffre d'affaire
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortClientsBy.field !== 'priceSell'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortClientsBy.field === 'priceSell' &&
                                        sortClientsBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortClientsBy.field === 'priceSell' &&
                                        sortClientsBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestClients', 'countConfirmed')"
                            >
                                Demandes confirmées

                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortClientsBy.field !== 'countConfirmed'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortClientsBy.field === 'countConfirmed' &&
                                        sortClientsBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortClientsBy.field === 'countConfirmed' &&
                                        sortClientsBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="loading">
                        <tr *ngFor="let number of [].constructor(5)">
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!loading">
                        <tr *ngIf="!currentStat?.bestClients?.length">
                            <td colspan="4" class="text-center">Aucune donnée</td>
                        </tr>
                        <tr
                            *ngFor="
                                let client of currentStat?.bestClients?.slice(0, bestClientsCount)
                            "
                        >
                            <td class="text-start" [class.disabled]="loading">
                                <a
                                    [routerLink]="'/admin/clients/' + client.clientId"
                                    [innerText]="client.clientTitle"
                                ></a>
                            </td>
                            <td class="text-center" [class.disabled]="loading">
                                {{ formatPrice(client.margeBrut) }} <br />{{
                                    getMarginPercent(client.margeBrut, client.priceNetTTC) || 0
                                }}%
                            </td>
                            <td
                                class="text-end"
                                [class.disabled]="loading"
                                [innerHtml]="formatPrice(client.priceSell || 0)"
                            ></td>
                            <td
                                class="text-center"
                                [class.disabled]="loading"
                                [innerHtml]="getCountConfirmed(client)"
                            ></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card card-statistics mb-4">
            <div class="card-header">
                Meilleures compagnies aériennes
                <select
                    class="form-select float-end"
                    [(ngModel)]="bestAirlinesCount"
                    *ngIf="currentStat?.bestAirlines?.length >= 5"
                >
                    <ng-container *ngFor="let option of filterTopNumber; let i = index">
                        <option
                            [value]="option.value"
                            [innerText]="option.title"
                            *ngIf="
                                option.value <= currentStat?.bestAirlines?.length ||
                                (i > 0 &&
                                    filterTopNumber[i - 1].value <=
                                        currentStat?.bestAirlines?.length)
                            "
                        ></option>
                    </ng-container>
                </select>
            </div>
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestAirlines', 'airlineTitle')"
                            >
                                Nom
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortAirlinesBy.field !== 'airlineTitle'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortAirlinesBy.field === 'airlineTitle' &&
                                        sortAirlinesBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortAirlinesBy.field === 'airlineTitle' &&
                                        sortAirlinesBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestAirlines', 'margeBrut')"
                            >
                                Marge
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortAirlinesBy.field !== 'margeBrut'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortAirlinesBy.field === 'margeBrut' &&
                                        sortAirlinesBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortAirlinesBy.field === 'margeBrut' &&
                                        sortAirlinesBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                class="text-end clickable"
                                (click)="sortStatBest('bestAirlines', 'priceSell')"
                            >
                                Chiffre d'affaire
                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortAirlinesBy.field !== 'priceSell'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortAirlinesBy.field === 'priceSell' &&
                                        sortAirlinesBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortAirlinesBy.field === 'priceSell' &&
                                        sortAirlinesBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                class="text-center clickable"
                                (click)="sortStatBest('bestAirlines', 'countConfirmed')"
                            >
                                Nombre de vols

                                <fa-icon
                                    [icon]="faSort"
                                    *ngIf="sortAirlinesBy.field !== 'countConfirmed'"
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortUp"
                                    *ngIf="
                                        sortAirlinesBy.field === 'countConfirmed' &&
                                        sortAirlinesBy.direction === 'asc'
                                    "
                                ></fa-icon>
                                <fa-icon
                                    [icon]="faSortDown"
                                    *ngIf="
                                        sortAirlinesBy.field === 'countConfirmed' &&
                                        sortAirlinesBy.direction === 'desc'
                                    "
                                ></fa-icon>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="loading">
                        <tr *ngFor="let number of [].constructor(5)">
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                            <td [class.disabled]="loading">
                                <div class="placeholder w-100"></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!loading">
                        <tr *ngIf="!currentStat?.bestAirlines?.length">
                            <td colspan="4" class="text-center">Aucune donnée</td>
                        </tr>
                        <tr
                            *ngFor="
                                let airline of currentStat?.bestAirlines?.slice(
                                    0,
                                    bestAirlinesCount
                                )
                            "
                        >
                            <td class="text-start" [class.disabled]="loading">
                                <a
                                    [routerLink]="'/admin/airlines/' + airline.airlineId"
                                    [innerText]="airline.airlineTitle"
                                ></a>
                            </td>
                            <td class="text-center" [class.disabled]="loading">
                                {{ formatPrice(airline.margeBrut) }} <br />{{
                                    getMarginPercent(airline.margeBrut, airline.priceNetTTC) || 0
                                }}%
                            </td>
                            <td
                                class="text-end"
                                [class.disabled]="loading"
                                [innerHtml]="formatPrice(airline.priceSell || 0)"
                            ></td>
                            <td
                                class="text-center"
                                [class.disabled]="loading"
                                [innerHtml]="getCountConfirmed(airline)"
                            ></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalUserGoals>
    <div class="modal-dialog modal-dialog-centered modallg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Édition des objectifs de l'utilisateur</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form
                    (submit)="saveUserGoals()"
                    [formGroup]="form"
                    (keydown.enter)="saveUserGoals()"
                    class="main-form"
                >
                    <ul class="list-group">
                        <li
                            class="list-group-item"
                            *ngFor="let quarterNumber of [1, 2, 3, 4]"
                            [formGroupName]="'q' + quarterNumber"
                        >
                            <h5 [innerText]="'Q' + quarterNumber + ' ' + currentYear"></h5>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="provisional">Prévisionnel</label>
                                        <input
                                            class="form-control"
                                            id="provisional"
                                            formControlName="provisional"
                                            type="number"
                                            min="0"
                                            required
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    getQuarterField(quarterNumber, 'provisional')
                                                        .touched) &&
                                                getQuarterField(quarterNumber, 'provisional')
                                                    .status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getQuarterField(
                                                            quarterNumber,
                                                            'provisional'
                                                        ).touched) &&
                                                    getQuarterField(quarterNumber, 'provisional')
                                                        .errors &&
                                                    getQuarterField(quarterNumber, 'provisional')
                                                        .errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="actual">Réel</label>
                                        <input
                                            class="form-control"
                                            id="actual"
                                            formControlName="actual"
                                            type="number"
                                            min="0"
                                            required
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    getQuarterField(quarterNumber, 'actual')
                                                        .touched) &&
                                                getQuarterField(quarterNumber, 'actual').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getQuarterField(quarterNumber, 'actual')
                                                            .touched) &&
                                                    getQuarterField(quarterNumber, 'actual')
                                                        .errors &&
                                                    getQuarterField(quarterNumber, 'actual').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="goal">Objectif</label>
                                        <input
                                            class="form-control"
                                            id="goal"
                                            formControlName="goal"
                                            type="number"
                                            min="0"
                                            required
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    getQuarterField(quarterNumber, 'goal')
                                                        .touched) &&
                                                getQuarterField(quarterNumber, 'goal').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        getQuarterField(quarterNumber, 'goal')
                                                            .touched) &&
                                                    getQuarterField(quarterNumber, 'goal').errors &&
                                                    getQuarterField(quarterNumber, 'goal').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button type="button" class="btn btn-primary" (click)="saveUserGoals()">
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>
