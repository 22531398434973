<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="form-group">
                        <label for="name">Libellé sur le compte</label>
                        <input
                            class="form-control"
                            id="libelle"
                            formControlName="libelle"
                            type="text"
                            required
                            (keyup)="getSuggestionInvoices()"
                            [class.is-invalid]="
                                (form.touched || form.controls['libelle'].touched) &&
                                form.controls['libelle'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['libelle'].touched) &&
                                    form.controls['libelle'].errors &&
                                    form.controls['libelle'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="encaissementTypeId">Type d'encaissement</label>
                        <select
                            class="form-select"
                            id="encaissementTypeId"
                            formControlName="encaissementTypeId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['encaissementTypeId'].touched) &&
                                form.controls['encaissementTypeId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="encaissementType.id"
                                *ngFor="let encaissementType of encaissementTypesList"
                                [innerHtml]="encaissementType.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['encaissementTypeId'].touched) &&
                                    form.controls['encaissementTypeId'].errors &&
                                    form.controls['encaissementTypeId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="amount">Montant</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="amount"
                                formControlName="amount"
                                type="number"
                                required
                                [class.is-invalid]="
                                    (form.touched || form.controls['amount'].touched) &&
                                    form.controls['amount'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                                (keyup)="getSuggestionInvoices()"
                            />

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (form.touched || form.controls['amount'].touched) &&
                                        form.controls['amount'].errors &&
                                        form.controls['amount'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                            <span
                                class="input-group-text"
                                [innerText]="getEnumCurrencySymbol(form.value.currency)"
                            ></span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="currency">Devise</label>
                        <select
                            class="form-select"
                            id="currency"
                            formControlName="currency"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['currency'].touched) &&
                                form.controls['currency'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="getSuggestionInvoices()"
                        >
                            <option
                                [value]="currency"
                                *ngFor="let currency of getCurrencies()"
                                [innerHtml]="
                                    getEnumCurrencySymbol(currency) +
                                    ' (' +
                                    getEnumCurrencyLabel(currency) +
                                    ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['currency'].touched) &&
                                    form.controls['currency'].errors &&
                                    form.controls['currency'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="bankAccountId">Compte bancaire</label>
                        <select
                            class="form-select"
                            id="bankAccountId"
                            formControlName="bankAccountId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['bankAccountId'].touched) &&
                                form.controls['bankAccountId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="bankAccount.id"
                                *ngFor="let bankAccount of bankAccountsList"
                                [innerHtml]="bankAccount.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['bankAccountId'].touched) &&
                                    form.controls['bankAccountId'].errors &&
                                    form.controls['bankAccountId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="date">Date</label>
                        <input
                            class="form-control"
                            id="date"
                            formControlName="date"
                            type="date"
                            (keyup)="getSuggestionInvoices()"
                            (change)="getSuggestionInvoices()"
                            [class.is-invalid]="
                                (form.touched || form.controls['date'].touched) &&
                                form.controls['date'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['date'].touched) &&
                                    form.controls['date'].errors &&
                                    form.controls['date'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="invoiceIds">Facture / Avoir</label>
                        <app-invoice-ref-picker
                            inputName="invoiceIds"
                            (setValueToFormControl)="setValueToFormControl($event)"
                            [clearOnSelect]="true"
                            [disabled]="form.disabled"
                            [hidePaidInvoice]="true"
                        ></app-invoice-ref-picker>

                        <div class="suggestion-invoices" *ngIf="suggestionInvoices.length">
                            <label *ngIf="suggestionInvoices.length <= 1">Facture suggérée</label>
                            <label *ngIf="suggestionInvoices.length > 1">Factures suggérées</label>
                            <div class="list-group">
                                <button
                                    type="button"
                                    class="list-group-item list-group-item-info list-group-item-action"
                                    *ngFor="let suggestionInvoice of suggestionInvoices"
                                    (click)="selectSuggestedInvoice(suggestionInvoice)"
                                >
                                    <span class="icon">+</span>
                                    Facture {{ suggestionInvoice.invoice.ref }} :
                                    {{
                                        formatPrice(
                                            suggestionInvoice.invoice.amountTtcTotal,
                                            suggestionInvoice.invoice.currency
                                        )
                                    }}<br />
                                    Raison(s) :
                                    <ul *ngIf="suggestionInvoice.reasons.length">
                                        <li
                                            *ngFor="let reason of suggestionInvoice.reasons"
                                            class="reason"
                                            [innerText]="getSuggestionInvoiceReasonLabel(reason)"
                                        ></li>
                                    </ul>
                                </button>
                            </div>
                        </div>

                        <ul class="list-group" *ngIf="form.value.invoiceIds.length">
                            <li
                                class="list-group-item placeholder-glow d-flex justify-content-between align-items-center gap-2"
                                *ngFor="let invoiceId of form.value.invoiceIds"
                            >
                                <div
                                    *ngIf="!invoicesObj[invoiceId]"
                                    class="placeholder w-100"
                                ></div>
                                <div *ngIf="invoicesObj[invoiceId]">
                                    <a
                                        [routerLink]="'/admin/invoices/' + invoiceId"
                                        target="_blank"
                                    >
                                        {{ invoicesObj[invoiceId].ref }}
                                        <fa-icon [icon]="faExternalLink"></fa-icon> </a
                                    ><br />
                                    {{
                                        formatPrice(
                                            invoicesObj[invoiceId].amountTtcTotal,
                                            invoicesObj[invoiceId].currency
                                        )
                                    }}
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    (click)="removeInvoiceId(invoiceId)"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div class="form-check form-switch mb-3">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="is-reconciled"
                            formControlName="isReconciled"
                        />
                        <label class="form-check-label" for="is-reconciled">Est pointé ?</label>
                    </div>

                    <div class="form-group">
                        <label for="comment">Commentaire</label>
                        <textarea
                            class="form-control"
                            id="comment"
                            formControlName="comment"
                            [class.is-invalid]="
                                (form.touched || form.controls['comment'].touched) &&
                                form.controls['comment'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['comment'].touched) &&
                                    form.controls['comment'].errors &&
                                    form.controls['comment'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button
                        type="button"
                        [routerLink]="
                            encaissementId
                                ? '/admin/encaissements/' + encaissementId
                                : '/admin/encaissements'
                        "
                        class="btn btn-secondary btn-block mb-4"
                        [disabled]="form.disabled"
                    >
                        Annuler
                    </button>
                </div>
                <div class="col text-end">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block mb-4"
                        [disabled]="form.disabled || !form.valid"
                    >
                        Sauvegarder
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
