import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { ICrawlerUrl } from 'src/app/interfaces/crawler-url.interface';
import { map } from 'rxjs/operators';
import { CrawlerUrlFirestoreService } from './crawler-url-firestore.service';
import { EnumCrawlerUrlSource } from 'src/app/enums/crawler-url-source.enum';
import { EnumCrawlerUrlStatus } from 'src/app/enums/crawler-url-status.enum';
import { IBatchOperationsParams, IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CrawlerUrlService {
  constructor(private crawlerUrlFirestoreService: CrawlerUrlFirestoreService) {}

  create(crawlerUrl: ICrawlerUrl): Promise<string> {
    return this.crawlerUrlFirestoreService.create(crawlerUrl);
  }

  update(crawlerUrl: ICrawlerUrl): Promise<string> {
    return this.crawlerUrlFirestoreService.update(crawlerUrl);
  }

  delete(id: string): Promise<void> {
    return this.crawlerUrlFirestoreService.delete(id);
  }

  deleteByStatus(status: EnumCrawlerUrlStatus): Promise<void> {
    return this.crawlerUrlFirestoreService.deleteWhere(ref => ref.where('status', '==', status));
  }

  deleteBySource(source: EnumCrawlerUrlSource): Promise<void> {
    return this.crawlerUrlFirestoreService.deleteWhere(ref => ref.where('source', '==', source));
  }

  getFromId(id: string): Observable<ICrawlerUrl> {
    return this.crawlerUrlFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICrawlerUrl[]> {
    return this.crawlerUrlFirestoreService.collectionValues$();
  }

  getAllByStatus(status: EnumCrawlerUrlStatus): Observable<ICrawlerUrl[]> {
    return this.crawlerUrlFirestoreService.collectionValues$(ref =>
      ref.where('status', '==', status)
    );
  }

  getByUrl(url: string): Observable<ICrawlerUrl[]> {
    return this.crawlerUrlFirestoreService.collectionValues$(ref => ref.where('url', '==', url));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.crawlerUrlFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<ICrawlerUrl[]> {
    return this.crawlerUrlFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  batchOperations(batchOperationsParams: IBatchOperationsParams): Promise<void> {
    return this.crawlerUrlFirestoreService.batchOperations(batchOperationsParams);
  }
}
