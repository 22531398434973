<app-crawler-alert *ngIf="hasAclAccess(EnumAcl.crawlerUrlsAlert)"></app-crawler-alert>

<ul class="nav nav-pills" id="dashboard-tab" role="tablist" *ngIf="tabs.length > 1">
    <li class="nav-item" role="presentation" *ngFor="let tab of tabs; let i = index">
        <button
            type="button"
            [class.active]="i === activeTabIndex"
            class="nav-link"
            [attr.id]="tab.value + '-tab'"
            data-bs-toggle="tab"
            (click)="selectActiveTab(i)"
            role="tab"
            [attr.aria-controls]="tab.value"
            [attr.aria-selected]="i === activeTabIndex"
            [innerText]="tab.title"
        ></button>
    </li>
</ul>

<div class="tab-content" id="dashboard-tab-content" *ngIf="tabs.length">
    <div
        [class.active]="tabs[activeTabIndex].value === 'enquiries'"
        class="tab-pane fade show"
        id="enquiries"
        role="tabpanel"
        aria-labelledby="enquiries-tab"
    >
        <div class="btn-toolbar">
            <a
                routerLink="/admin/enquiries/add"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.enquiriesAdd)"
                >Nouvelle demande</a
            >
        </div>

        <app-enquiries-list-with-filters></app-enquiries-list-with-filters>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-proforma'"
        class="tab-pane fade show"
        id="invoices"
        role="tabpanel"
        aria-labelledby="invoices-tab"
    >
        <a
            routerLink="/admin/invoices/add-proforma"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddProforma)"
            >+ Nouvelle facture proforma</a
        >

        <app-invoices-list
            [invoiceType]="EnumInvoiceType.proforma"
            *ngIf="tabs[activeTabIndex].value === 'invoices-proforma'"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-all-without-proforma'"
        class="tab-pane fade show"
        id="all"
        role="tabpanel"
        aria-labelledby="all-tab"
    >
        <a
            routerLink="/admin/invoices/add-final-invoice"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddFinalInvoice)"
            >+ Nouvelle facture définitive</a
        >

        <app-invoices-list
            invoiceType="all-without-proforma"
            *ngIf="tabs[activeTabIndex].value === 'invoices-all-without-proforma'"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-definitive'"
        class="tab-pane fade show"
        id="definitive"
        role="tabpanel"
        aria-labelledby="definitive-tab"
    >
        <a
            routerLink="/admin/invoices/add-final-invoice"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddFinalInvoice)"
            >+ Nouvelle facture définitive</a
        >

        <app-invoices-list
            invoiceType="definitive"
            *ngIf="tabs[activeTabIndex].value === 'invoices-definitive'"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-credit-note'"
        class="tab-pane fade show"
        id="credit-note"
        role="tabpanel"
        aria-labelledby="credit-note-tab"
    >
        <a
            routerLink="/admin/invoices/add-credit-note-invoice"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)"
            >+ Nouvel avoir définitif</a
        >

        <app-invoices-list
            invoiceType="credit-note"
            *ngIf="tabs[activeTabIndex].value === 'invoices-credit-note'"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-cancelled'"
        class="tab-pane fade show"
        id="cancelled"
        role="tabpanel"
        aria-labelledby="cancelled-tab"
    >
        <app-invoices-list
            invoiceType="cancelled"
            *ngIf="tabs[activeTabIndex].value === 'invoices-cancelled'"
        ></app-invoices-list>
    </div>

    <div
        [class.active]="tabs[activeTabIndex].value === 'invoices-purchase'"
        class="tab-pane fade show"
        id="purchase"
        role="tabpanel"
        aria-labelledby="purchase-tab"
    >
        <div class="btn-group">
            <a
                routerLink="/admin/invoices/add-purchase-invoice"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.invoicesAddPurchaseInvoice)"
                >+ Nouvelle facture d'achat</a
            >
            <a
                routerLink="/admin/invoices/add-purchase-credit-note"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.invoicesAddPurchaseCreditNote)"
                >+ Nouvel avoir reçu</a
            >
        </div>

        <app-invoices-list
            invoiceType="purchase"
            *ngIf="tabs[activeTabIndex].value === 'invoices-purchase'"
        ></app-invoices-list>
    </div>
</div>
