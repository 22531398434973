import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { PipedriveService } from '../../../services/pipedrive.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IClientBillingInfos } from 'src/app/interfaces/client-billing-infos.interface';

import countries from '../../../countries_fr.json';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {
  faChartSimple,
  faExternalLink,
  faRefresh,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  getYear,
  isAfter,
  startOfMonth
} from 'date-fns';
import { addZeroToDigit } from 'src/app/misc.utils';
import { fr } from 'date-fns/locale';

enum EnumTab {
  enquiries = 'enquiries',
  invoices = 'invoices',
  flightBriefs = 'flightBriefs'
}

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent implements OnInit {
  EnumTab = EnumTab;

  getEnumEnquiryStatusLabel = getEnumEnquiryStatusLabel;

  loading: boolean = false;
  loadingUsers: boolean = false;
  clientId: string | null = null;
  client: IPipedriveOrganization | null = null;
  isLogged: boolean = false;

  faTrash = faTrash;
  faExternalLink = faExternalLink;
  faRefresh = faRefresh;
  faChartSimple = faChartSimple;

  loadingClientBillingInfos: boolean = false;
  clientBillingInfos: IClientBillingInfos | null = null;

  EnumAcl = EnumAcl;
  countries = countries;

  tabs: {
    title: string;
    value: EnumTab;
  }[] = [
    {
      title: 'Dossiers',
      value: EnumTab.enquiries
    },
    {
      title: 'Factures',
      value: EnumTab.invoices
    },
    {
      title: 'Flight briefs',
      value: EnumTab.flightBriefs
    }
  ];
  currentTab: EnumTab = EnumTab.enquiries;

  enquiryStatus: EnumEnquiryStatus | null = null;
  filterByPeriod: string | null = null;
  start: string | null = null;
  end: string | null = null;
  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];

  today: string;

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private breadcrumbsService: BreadcrumbsService,
    private loaderService: LoaderService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.today = format(new Date(), 'yyyy-MM-dd');

    this.generateFilterPeriodOptions();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');

      this.loadData();
    });
  }

  getEnquiryStatuses(): EnumEnquiryStatus[] {
    return Object.values(EnumEnquiryStatus);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadClient();
      this.loadClientBillingInfos();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadClient(): Promise<void> {
    this.loading = true;

    try {
      this.client = await this.pipedriveService.getOrganization(this.clientId);

      if (this.client) {
        this.breadcrumbsService.setCurrentItem({
          text: this.client.name,
          id: this.client.id
        });
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: client-view.component.ts:77 ~ ClientViewComponent ~ loadClient ~ err:',
        err
      );
    } finally {
      this.loading = false;
    }
  }

  async loadClientBillingInfos(): Promise<void> {
    this.loadingClientBillingInfos = true;

    try {
      this.clientBillingInfos = (await this.remoteService.getDocument(
        'clientBillingInfos',
        this.clientId
      )) as IClientBillingInfos | null;
    } catch (err) {
      console.log(
        '🚀 ~ file: client-view.component.ts:95 ~ ClientViewComponent ~ loadClientBillingInfos ~ err:',
        err
      );
    } finally {
      this.loadingClientBillingInfos = false;
    }
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.clientsDelete)) {
      const result = confirm(
        'La suppression du client sera permanent. Il sera toujours sauvegardé dans Pipedrive. Êtes-vous sûr de vouloir continuer?'
      );

      if (result) {
        this.loaderService.presentLoader();

        this.remoteService
          .deleteDocumentInCollection('pipedriveOrganizations', this.clientId)
          .then(async () => {
            await this.loaderService.hideLoaderOnSuccess();

            this.router.navigate(['/admin/clients']);
          })
          .catch(async err => {
            await this.loaderService.hideLoaderOnSuccess(err);
          });
      }
    }
  }

  async importFromPipedrive(): Promise<void> {
    try {
      if (this.clientId) {
        this.loaderService.presentLoader();

        this.client = await this.pipedriveService.getOrganization(this.clientId.toString(), true);

        if (this.client) {
          await this.remoteService.setDocumentToCollection(
            'pipedriveOrganizations',
            this.clientId.toString(),
            JSON.parse(JSON.stringify(this.client))
          );

          try {
            const clientBillingInfosDoc: object = await this.remoteService.getDocument(
              'clientBillingInfos',
              this.clientId.toString()
            );

            if (clientBillingInfosDoc) {
              await this.remoteService.updateDocumentToCollection(
                'clientBillingInfos',
                this.clientId.toString(),
                {
                  clientName: this.client.name
                }
              );
            }
          } catch (err: any) {}

          const persons: IPipedrivePerson[] =
            await this.pipedriveService.getPersonsFromOrganization(this.clientId.toString());

          for (const person of persons) {
            await this.remoteService.setDocumentToCollection(
              'pipedrivePersons',
              person.id.toString(),
              JSON.parse(JSON.stringify(person))
            );
          }

          await this.loaderService.hideLoaderOnSuccess();

          window.location.reload();
        } else {
          await this.loaderService.hideLoaderOnFailure('Client not found');
        }
      }
    } catch (err: any) {
      await this.loaderService.hideLoaderOnFailure(err);
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  updateCurrentTab(tab: EnumTab): void {
    this.currentTab = tab;
  }

  enquiryStatusChanged(): void {}

  updateFilterPeriod(): void {
    if (!this.filterByPeriod) {
      this.start = null;
      this.end = null;
    } else if (this.filterByPeriod !== 'custom') {
      if (this.filterByPeriod.match('quarter')) {
        const filterByPeriodSplitted: string[] = this.filterByPeriod
          .replace('quarter-', '')
          .split('-');
        const year: number = parseInt(filterByPeriodSplitted[0]);
        const quarter: number = parseInt(filterByPeriodSplitted[1]);

        this.start = year + '-' + addZeroToDigit((quarter - 1) * 3 + 1) + '-01';
        this.end = format(endOfQuarter(new Date(this.start)), 'yyyy-MM-dd');
      } else {
        this.start = this.filterByPeriod;

        this.end = format(endOfMonth(new Date(this.start)), 'yyyy-MM-dd');
      }
    }

    console.log({ start: this.start, end: this.end });
    // this.fetchEnquiries();
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = getYear(new Date());

    const maxDec: number = currentYear - 2021;
    const beginningOfMonth: Date = startOfMonth(new Date());
    const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

    for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
      let title: string = format(m, 'MMMM yy', {
        locale: fr
      });
      title = title.charAt(0).toUpperCase() + title.slice(1);
      this.filtersByPeriod.push({
        title: title,
        value: format(m, 'yyyy-MM-01')
      });
    }
    for (let dec = 0; dec <= maxDec; dec++) {
      const lastQuarter: number = dec === 0 ? getQuarter(new Date()) : 4;
      const year: number = currentYear - dec;
      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'quarter-' + year + '-' + i
        });
      }
    }
    for (let year = currentYear; year >= 2021; year--) {
      this.filtersByPeriod.push({
        title: 'Année ' + year,
        value: 'year-' + year
      });
    }
  }
}
