<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <form
                    (submit)="submitForm()"
                    [formGroup]="form"
                    (keydown.enter)="submitForm()"
                    class="main-form"
                >
                    <div class="form-group">
                        <label for="oldPassword">Ancien mot de passe</label>
                        <input
                            class="form-control"
                            id="oldPassword"
                            formControlName="oldPassword"
                            type="password"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['oldPassword'].touched) &&
                                form.controls['oldPassword'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['oldPassword'].touched) &&
                                    form.controls['oldPassword'].errors &&
                                    form.controls['oldPassword'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['oldPassword'].touched) &&
                                    form.controls['oldPassword'].errors &&
                                    form.controls['oldPassword'].errors['minlength']
                                "
                            >
                                Ce champ doit faire au moins
                                {{
                                    form.controls["oldPassword"].errors["minlength"][
                                        "requiredLength"
                                    ]
                                }}
                                caractères.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="newPassword">Nouveau mot de passe</label>
                        <input
                            class="form-control"
                            id="newPassword"
                            formControlName="newPassword"
                            type="password"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['newPassword'].touched) &&
                                form.controls['newPassword'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['newPassword'].touched) &&
                                    form.controls['newPassword'].errors &&
                                    form.controls['newPassword'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['newPassword'].touched) &&
                                    form.controls['newPassword'].errors &&
                                    form.controls['newPassword'].errors['minlength']
                                "
                            >
                                Ce champ doit faire au moins
                                {{
                                    form.controls["newPassword"].errors["minlength"][
                                        "requiredLength"
                                    ]
                                }}
                                caractères.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="confirmPassword">Confirmer nouveau mot de passe</label>
                        <input
                            class="form-control"
                            id="confirmPassword"
                            formControlName="confirmPassword"
                            type="password"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['confirmPassword'].touched) &&
                                form.controls['confirmPassword'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['confirmPassword'].touched) &&
                                    form.controls['confirmPassword'].errors &&
                                    form.controls['confirmPassword'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['confirmPassword'].touched) &&
                                    form.controls['confirmPassword'].errors &&
                                    form.controls['confirmPassword'].errors['minlength']
                                "
                            >
                                Ce champ doit faire au moins
                                {{
                                    form.controls["confirmPassword"].errors["minlength"][
                                        "requiredLength"
                                    ]
                                }}
                                caractères.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button
                                type="button"
                                routerLink="/admin/my-account"
                                class="btn btn-secondary btn-block"
                                [disabled]="form.disabled || sending"
                            >
                                Annuler
                            </button>
                        </div>
                        <div class="col">
                            <button
                                type="submit"
                                class="btn btn-primary btn-block"
                                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                                [disabled]="form.disabled || !form.valid || sending"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
