import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { ExportRequestsService } from 'src/app/services/export-requests/export-requests.service';
import {
  IExportRequest,
  getExportRequestStatusLabel,
  getExportRequestLabel,
  EnumExportRequestStatus
} from 'src/app/interfaces/export-request.interface';

@Component({
  selector: 'app-export-request-view',
  templateUrl: './export-request-view.component.html',
  styleUrls: ['./export-request-view.component.scss']
})
export class ExportRequestViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;
  EnumExportRequestStatus = EnumExportRequestStatus;

  getExportRequestLabel = getExportRequestLabel;
  getExportRequestStatusLabel = getExportRequestStatusLabel;

  loading: boolean = false;
  exportRequestId: string;
  exportRequest: IExportRequest;
  isLogged: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private exportRequestsService: ExportRequestsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.exportRequestId = this.activatedRoute.snapshot.paramMap.get('exportRequestId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadExportRequest();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadExportRequest(): void {
    this.loading = true;

    this.subscriptions.add(
      this.exportRequestsService
        .getFromId(this.exportRequestId)
        .subscribe((exportRequest: IExportRequest) => {
          this.exportRequest = exportRequest;

          this.loading = false;
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.exportRequestsDelete)) {
      const result = confirm('La suppression sera permanente. Êtes-vous sûr de vouloir continuer?');

      if (result) {
        this.exportRequestsService
          .delete(this.exportRequestId)
          .then(() => {
            this.router.navigate(['/admin/export-requests']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async download(): Promise<void> {
    if (this.exportRequest) {
      const url: string | null = await this.exportRequestsService.getDownloadUrl(
        this.exportRequest.id
      );

      if (url) {
        window.open(url, '_blank');
      } else {
        alert('Fichier innacessible');
      }
    }
  }
}
