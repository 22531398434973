<ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let period of getPeriods()">
        <button
            class="nav-link"
            [class.active]="currentPeriod === period"
            [id]="'client-period-' + period"
            type="button"
            [attr.aria-selected]="currentPeriod === period"
            [innerText]="getPeriodLabel(period)"
            (click)="changeCurrentPeriod(period)"
        ></button>
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let period of getPeriods()"
        [class.show]="currentPeriod === period"
        [class.active]="currentPeriod === period"
        [id]="'client-period-' + period"
        role="tabpanel"
        [attr.aria-labelledby]="'client-period-' + period"
        tabindex="0"
    >
        <div class="card mb-4" *ngIf="loading || loadingYearsOnly">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-end">Marge</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Nb</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Clotûrés</th>
                                <th class="text-end">CA</th>
                                <th class="text-center">Parts</th>
                                <th class="text-center">Marges</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr *ngFor="let row of [].constructor(12)">
                                <td *ngFor="let number of [].constructor(9)">
                                    <div class="placeholder w-100"></div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="loading">
                            <tr>
                                <th *ngFor="let number of [].constructor(9)">
                                    <div class="placeholder w-100"></div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!(loading || loadingYearsOnly)">
            <div class="card mb-4" *ngFor="let financialReportClientAllAndClients of getData()">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th [innerText]="financialReportClientAllAndClients.label"></th>
                                    <th class="text-end">Marge</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Nb</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Clotûrés</th>
                                    <th class="text-end">CA</th>
                                    <th class="text-center">Parts</th>
                                    <th class="text-center">Marges</th>
                                </tr>
                            </thead>
                            <tbody
                                *ngIf="
                                    !financialReportClientAllAndClients.financialReportClients
                                        .length
                                "
                            >
                                <tr class="text-muted">
                                    <td
                                        [attr.colspan]="
                                            currentPeriod !== EnumPeriod.months ? 12 : 11
                                        "
                                        class="text-center"
                                    >
                                        Aucune donnée à afficher
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let financialReportClient of financialReportClientAllAndClients.financialReportClients
                                    "
                                >
                                    <td [innerText]="financialReportClient.clientTitle"></td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportClient.marginHT || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportClientAllAndClients.virtualFields[
                                                financialReportClient.clientId
                                            ][
                                                EnumFinancialReportClientVirtualField
                                                    .marginHTPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="financialReportClient.nbContracts || 0"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportClientAllAndClients.virtualFields[
                                                financialReportClient.clientId
                                            ][
                                                EnumFinancialReportClientVirtualField
                                                    .nbContractsPercent
                                            ] || 0) + '%'
                                        "
                                    ></td>
                                    <td class="text-center">
                                        {{ financialReportClient.nbCompleted || 0 }}
                                        ({{ financialReportClient.completedPercent || 0 }}%)
                                    </td>
                                    <td
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportClient.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportClientAllAndClients.virtualFields[
                                                financialReportClient.clientId
                                            ][EnumFinancialReportClientVirtualField.caPercent] ||
                                                0) + '%'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerText]="
                                            (financialReportClient.marginPercent || 0) + '%'
                                        "
                                    ></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportClientAllAndClients.all?.marginHT ||
                                                    0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportClientAllAndClients.virtualFields
                                                    .all[
                                                    EnumFinancialReportClientVirtualField
                                                        .marginHTPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>

                                    <th
                                        class="text-center"
                                        [innerText]="
                                            financialReportClientAllAndClients.all?.nbContracts || 0
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportClientAllAndClients.virtualFields
                                                    .all[
                                                    EnumFinancialReportClientVirtualField
                                                        .nbContractsPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th class="text-center">
                                        {{
                                            financialReportClientAllAndClients.all?.nbCompleted || 0
                                        }}
                                        ({{
                                            financialReportClientAllAndClients.all
                                                ?.completedPercent || 0
                                        }}%)
                                    </th>
                                    <th
                                        class="text-end"
                                        [innerText]="
                                            formatPrice(
                                                financialReportClientAllAndClients.all?.ca || 0,
                                                currentCurrency ?? getDefaultCurrency()
                                            )
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            roundNumber(
                                                financialReportClientAllAndClients.virtualFields
                                                    .all[
                                                    EnumFinancialReportClientVirtualField.caPercent
                                                ],
                                                0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [innerText]="
                                            (financialReportClientAllAndClients.all
                                                ?.marginPercent || 0) + '%'
                                        "
                                    ></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
