import { Component, Input, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ICrew, getCrewTypeLabel } from 'src/app/interfaces/crew.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { Subscription } from 'rxjs';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { DataList } from 'src/app/components/data-list/data-list.class';
import { CrewService } from 'src/app/services/crews/crews.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';

@Component({
  selector: 'app-list-crews',
  templateUrl: './list-crews.component.html',
  styleUrls: ['./list-crews.component.scss']
})
export class ListCrewsComponent extends DataList<ICrew> implements OnInit {
  @Input() airlineId: string | null = null;

  EnumAcl = EnumAcl;

  getCrewTypeLabel = getCrewTypeLabel;

  airlinesObj: { [id: string]: IAirline | null } = {};

  columns: IDataListColumn[] = [];
  orderBy: IDataListOrderBy | null = null;

  private subscriptions = new Subscription();

  constructor(
    public paginationService: PaginationService,
    private aclService: AclService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private airlineService: AirlineService,
    private crewService: CrewService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super();

    this.setDataService(this.crewService);
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async () => {
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      await this.aclService.checkAclAccess(EnumAcl.crewsList);

      if (this.airlineId) {
        this.conditions = [
          {
            field: 'airlineId',
            operator: '==',
            value: this.airlineId
          }
        ];

        this.loadAirline(this.airlineId);
      }

      this.refreshColumns();

      this.resetAndLoad();
    });
  }

  afterLoadData(): void {
    for (const item of this.data) {
      this.loadAirline(item.airlineId);
    }
  }

  refreshColumns(): void {
    this.columns = [];

    this.columns.push({
      title: 'Prénom',
      field: 'firstname',
      notSortable: !!this.airlineId
    });

    this.columns.push({
      title: 'Nom',
      field: 'lastname',
      notSortable: !!this.airlineId
    });

    this.columns.push({
      title: 'Type',
      field: 'type',
      notSortable: !!this.airlineId,
      type: EnumDataListColumnType.function,
      resolve: (item: ICrew): string => {
        return getCrewTypeLabel(item.type);
      }
    });

    if (!this.airlineId) {
      this.columns.push({
        title: 'Compagnie aérienne',
        field: 'airlineId',
        type: EnumDataListColumnType.function,
        notSortable: !!this.airlineId,
        resolve: (item: ICrew): string => {
          return item.airlineId && this.airlinesObj[item.airlineId]
            ? this.airlinesObj[item.airlineId].title
            : '';
        }
      });
    }

    this.columns.push({
      title: 'Date de création',
      field: 'created',
      type: EnumDataListColumnType.date,
      notSortable: !!this.airlineId
    });
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;

          if (this.airlinesObj[airlineId]) {
            this.breadcrumbsService.setCurrentItem({
              id: this.airlinesObj[airlineId].id,
              text: this.airlinesObj[airlineId].title
            });
          }

          this.refreshColumns();
        })
      );
    }
  }
}
