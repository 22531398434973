<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th scope="col" *ngIf="!hideEnquiryColumns">N° Dossier</th>
                <th scope="col" *ngIf="!hideEnquiryColumns">Client/Agence</th>
                <th scope="col">Date du vol</th>
                <th scope="col">Avion</th>
                <th scope="col">Opérateur</th>
                <th scope="col">Routing</th>
                <th scope="col">ETD GMT</th>
                <th scope="col">ETD LT</th>
                <th scope="col" class="text-center">
                    PAX <br />
                    Poids/Volume
                </th>
                <th scope="col" class="text-center">Remarque</th>
                <th
                    scope="col"
                    class="text-center"
                    *ngIf="hasAclAccess(EnumAcl.flightChecklistsView)"
                >
                    Checklist
                </th>
            </tr>
        </thead>
        <tbody *ngIf="loading">
            <tr *ngFor="let number of [].constructor(10)">
                <td
                    *ngFor="
                        let number2 of [].constructor(
                            hideEnquiryColumns
                                ? hasAclAccess(EnumAcl.flightChecklistsView)
                                    ? 9
                                    : 8
                                : hasAclAccess(EnumAcl.flightChecklistsView)
                                ? 11
                                : 10
                        )
                    "
                >
                    <div class="placeholder w-100"></div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="!loading">
            <tr *ngIf="!enquiryFlights.length" class="table-warning text-center">
                <td
                    [attr.colspan]="
                        hideEnquiryColumns
                            ? hasAclAccess(EnumAcl.flightChecklistsView)
                                ? 9
                                : 8
                            : hasAclAccess(EnumAcl.flightChecklistsView)
                            ? 11
                            : 10
                    "
                >
                    Aucun vol n'a été trouvé pour ces dates.
                </td>
            </tr>
            <tr *ngFor="let enquiryFlight of enquiryFlights">
                <td *ngIf="!hideEnquiryColumns">
                    <a
                        [routerLink]="'/admin/enquiries/' + enquiryFlight.enquiryId"
                        [innerText]="enquiryFlight.enquiryRefContractTitle"
                    ></a>
                </td>
                <td *ngIf="!hideEnquiryColumns">
                    <a
                        [routerLink]="'/admin/clients/' + enquiryFlight.clientId"
                        [innerText]="enquiryFlight.clientName"
                    ></a>
                </td>
                <td
                    [innerText]="
                        enquiryFlight.departDateLocal | date : 'EE dd MMM yyyy' : undefined : 'fr'
                    "
                ></td>
                <td [innerText]="enquiryFlight.aircraftModelTitle"></td>
                <td>
                    <a
                        [routerLink]="'/admin/airlines/' + enquiryFlight.airlineId"
                        [innerText]="enquiryFlight.airlineTitle"
                    ></a>
                </td>
                <td>
                    <a
                        [routerLink]="'/admin/airports/' + enquiryFlight.airportDepartId"
                        [innerText]="
                            enquiryFlight.airportDepartIataCode ?? enquiryFlight.airportDepartTitle
                        "
                    ></a>
                    -
                    <a
                        [routerLink]="'/admin/airports/' + enquiryFlight.airportArrivalId"
                        [innerText]="
                            enquiryFlight.airportArrivalIataCode ??
                            enquiryFlight.airportArrivalTitle
                        "
                    ></a>
                </td>
                <td [innerText]="enquiryFlight.departTimeUtc"></td>
                <td [innerText]="enquiryFlight.departTimeLocal"></td>
                <td
                    class="text-center"
                    [innerText]="enquiryFlight.pax + ' pax'"
                    *ngIf="enquiryFlight.enquiryType !== EnumEnquiryType.cargo"
                ></td>
                <td
                    class="text-center"
                    [innerHtml]="
                        enquiryFlight.weight + 'kg - ' + enquiryFlight.volume + 'm<sup>3</sup>'
                    "
                    *ngIf="enquiryFlight.enquiryType === EnumEnquiryType.cargo"
                ></td>
                <td class="text-center">
                    <div [innerText]="enquiryFlight.comment"></div>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="editComment(enquiryFlight)"
                    >
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                </td>
                <td class="text-center" *ngIf="hasAclAccess(EnumAcl.flightChecklistsView)">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        (click)="viewFlightChecklist(enquiryFlight)"
                    >
                        <fa-icon [icon]="faEye"></fa-icon>
                        <span
                            class="ms-2"
                            [innerText]="
                                getFinishedCheckOnTotalForFlight(
                                    flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType],
                                    flightChecklistByEnquiryFlight[enquiryFlight.id]
                                )
                            "
                            *ngIf="flightChecklistTemplateByEnquiryType[enquiryFlight.enquiryType]"
                        ></span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div
    class="modal fade"
    id="set-comment-modal"
    tabindex="-1"
    aria-labelledby="set-comment-modal-label"
    aria-hidden="true"
    #modalComment
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="set-comment-modal-label">Remarque</h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form (submit)="submitFormComment()" [formGroup]="formComment" class="main-form">
                    <div class="form-group mb-0">
                        <textarea
                            formControlName="comment"
                            class="form-control"
                            rows="2"
                        ></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="formComment.disabled || !formComment.valid"
                    (click)="submitFormComment()"
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="checklist-modal"
    tabindex="-1"
    aria-labelledby="checklist-modal-label"
    aria-hidden="true"
    #modalChecklist
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="checklist-modal-label">
                    Checklist
                    <span
                        class="ms-1"
                        *ngIf="flightChecklistTemplate && flightChecklist"
                        [innerText]="
                            getFinishedCheckOnTotalForFlight(
                                flightChecklistTemplate,
                                flightChecklist
                            )
                        "
                    ></span>
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <app-enquiry-flight-checklist
                [enquiryFlight]="selectedFlight"
            ></app-enquiry-flight-checklist>
        </div>
    </div>
</div>
