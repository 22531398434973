import { Component, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
moment.locale('fr');

import { RemoteService } from './services/remote.service';
import { AclService } from './services/acl.service';
import { EnumAcl } from './enums/acl.enum';

import { environment } from '../environments/environment';
import { IUserGroup } from './interfaces/user-group.interface';
import {
  faBuilding,
  faCalendar,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faCogs,
  faDollar,
  faFileInvoice,
  faHome,
  faPlane,
  faPlaneDeparture,
  faReceipt,
  faSearch,
  faUsers,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { IMenuLink } from './components/sidebar-menu/sidebar-menu.component';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  mergeMap,
  of,
  Subject,
  Subscription
} from 'rxjs';
import { getDisplayedEnquiryRefTitle, IEnquiry } from './interfaces/enquiry.interface';
import { AlgoliaService } from './services/algolia.service';
import { LoaderService } from './services/loader/loader.service';
import { IInvoice } from './interfaces/invoice.interface';
import { formatPrice } from './misc.utils';
import { IAirport } from './interfaces/airport.interface';
import { IClient } from './interfaces/client.interface';
import { PipedriveService } from './services/pipedrive.service';
import { IPipedriveOrganization } from './interfaces/pipedrive.interface';

enum EnumSearchKey {
  enquiries = 'enquiries',
  invoices = 'invoices',
  airports = 'airports',
  clients = 'clients'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  @ViewChild('menuCloseBtn', { static: false }) menuCloseBtnElement: ElementRef;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSearch = faSearch;

  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  formatPrice = formatPrice;

  EnumSearchKey = EnumSearchKey;

  displaySidebar: boolean = false;
  displayTopMenu: boolean = false;
  menuCollapsed: boolean = false;
  menuCollapsedAnimation: boolean = false;
  currentRoute: string = '/';

  hideLoading: boolean = false;
  currentUrl: string;
  isLogged: boolean = false;
  isLoginPage: boolean = false;

  menuLinks: IMenuLink[] = [];

  isStaging: boolean = false;

  searchKeyUp: Subject<string> = new Subject<string>();
  searchQuery: string = '';
  searchOptions: {
    total: number;
    enquiries: IEnquiry[];
    invoices: IInvoice[];
    airports: IAirport[];
    clients: IPipedriveOrganization[];
  } = {
    total: 0,
    enquiries: [],
    invoices: [],
    airports: [],
    clients: []
  };
  searchLoading: { [searchKey: string]: boolean } = {
    enquiries: true,
    invoices: true,
    airports: true,
    clients: true
  };

  searchKeys: {
    title: string;
    value: EnumSearchKey;
    count: number;
    exactMatch: boolean;
  }[] = [
    {
      title: 'Dossiers',
      value: EnumSearchKey.enquiries,
      count: 0,
      exactMatch: false
    },
    {
      title: 'Factures',
      value: EnumSearchKey.invoices,
      count: 0,
      exactMatch: false
    },
    {
      title: 'Clients',
      value: EnumSearchKey.clients,
      count: 0,
      exactMatch: false
    },
    {
      title: 'Aéroports',
      value: EnumSearchKey.airports,
      count: 0,
      exactMatch: false
    }
  ];

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private remoteService: RemoteService,
    private aclService: AclService,
    private zone: NgZone,
    private translateService: TranslateService,
    private algoliaService: AlgoliaService,
    private loaderService: LoaderService,
    private pipedriveService: PipedriveService
  ) {
    if (environment.environmentType === 'staging') {
      this.isStaging = true;
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('en');

    let browserLang = this.translateService.getBrowserLang(),
      currentLang = browserLang.match(/en|fr/) ? browserLang : 'en';
    this.translateService.use(currentLang);

    this.currentRoute = typeof window !== 'undefined' ? window.location.pathname : '/';
    this.updatedCurrentUrl();

    this.refreshMenuLinks();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        console.log(event.error);
      }

      this.updatedCurrentUrl();
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof RoutesRecognized) {
        this.currentUrl = event.url;

        // An event triggered when the Router parses the URL and the routes are recognized.
        this.displaySidebar =
          (this.currentUrl.match('admin') === null && this.currentUrl.match('public') === null) ||
          (!this.isLogged && this.currentUrl.match('public') !== null);
      }
    });
    this.remoteService.isLoggedObservable.subscribe((isLogged: boolean) => {
      if (isLogged != null) {
        this.isLogged = isLogged;

        this.zone.run(() => {
          this.hideLoading = true;

          this.checkAndRedirectToAdmin(isLogged);

          this.refreshMenuLinks();
        });
      }
    });
    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      if (userGroup) {
        this.refreshMenuLinks();
      }
    });

    this.subscriptions.add(
      this.searchKeyUp
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          mergeMap((search: string) => of(search).pipe(delay(500)))
        )
        .subscribe(() => {
          this.search();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkAndRedirectToAdmin(isLogged: boolean): void {
    if (this.currentUrl.match('set-password')) {
      return;
    }

    if (this.currentUrl) {
      if (!isLogged && this.currentUrl.match('admin')) {
        this.zone.run(() => {
          this.router.navigate(['/login']);
        });
      } else if (isLogged && !this.currentUrl.match('admin') && !this.currentUrl.match('public')) {
        this.zone.run(() => {
          this.router.navigate(['/admin']);
        });
      }
    } else {
      setTimeout(() => {
        this.checkAndRedirectToAdmin(isLogged);
      }, 300);
    }
  }

  openMenuItem(menuItem: { title: string; url: string | false }): void {
    if (menuItem.url) {
      if (window.innerWidth < 992) {
        this.menuCloseBtnElement.nativeElement.click();
      }

      this.router.navigate([menuItem.url]);
    }
  }

  updatedCurrentUrl(): void {
    this.displaySidebar = false;
    this.displayTopMenu = true;
    this.isLoginPage = false;

    if (this.currentRoute.match('/admin') || this.currentRoute.match('/public')) {
      this.displaySidebar = true;
    }

    const urlsToHideTopMenu: string[] = ['/login', '/password-forgotten', '/set-password'];

    if (this.currentRoute === '/') {
      this.displayTopMenu = false;
      this.isLoginPage = true;
    }

    for (const url of urlsToHideTopMenu) {
      if (this.currentRoute.match(url)) {
        this.displayTopMenu = false;
        this.isLoginPage = true;
        break;
      }
    }
  }

  menuExpand(): void {
    if (window.innerWidth >= 992) {
      this.menuCollapsedAnimation = true;
      this.menuCollapsed = !this.menuCollapsed;

      setTimeout(() => {
        this.menuCollapsedAnimation = false;
      }, 1000);
    }
  }

  refreshMenuLinks(): void {
    this.menuLinks = [];

    this.menuLinks.push({
      iconLeft: faHome,
      text: 'Tableau de bord',
      href: '/admin'
    });
    if (this.aclService.hasAclAccess(EnumAcl.flightsCalendarDashboard)) {
      this.menuLinks.push({
        iconLeft: faCalendar,
        text: 'Calendrier des vols',
        href: '/admin/flight-calendar'
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.usersStatistics)) {
      this.menuLinks.push({
        iconLeft: faChartLine,
        text: 'Statistiques',
        href: '/admin/statistics'
      });
    } else if (this.aclService.hasAclAccess(EnumAcl.usersUserOwnStatistics)) {
      this.menuLinks.push({
        iconLeft: faChartLine,
        text: 'Mes statistiques',
        href: '/admin/statistics/current-user'
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.enquiryPaymentsSummaryDisplay)) {
      this.menuLinks.push({
        iconLeft: faDollar,
        text: 'Finance',
        href: '/admin/finance'
      });
    } else if (this.aclService.hasAclAccess(EnumAcl.enquiryPaymentsSummaryOwnedEnquiries)) {
      this.menuLinks.push({
        iconLeft: faDollar,
        text: 'Finance',
        href: '/admin/finance'
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.clientsList)) {
      this.menuLinks.push({
        iconLeft: faUserTie,
        text: 'Clients',
        href: '/admin/clients'
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.airlinesList)) {
      this.menuLinks.push({
        iconLeft: faBuilding,
        text: 'Compagnies aériennes',
        href: '/admin/airlines'
      });
    }
    if (this.aclService.hasAclAccess(EnumAcl.usersList)) {
      this.menuLinks.push({
        iconLeft: faUsers,
        text: 'Utilisateurs',
        href: '/admin/users'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.invoicesList)) {
      this.menuLinks.push({
        iconLeft: faFileInvoice,
        text: 'Factures',
        href: '/admin/invoices'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.encaissementsList)) {
      this.menuLinks.push({
        iconLeft: faReceipt,
        text: 'Encaissements',
        href: '/admin/encaissements'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.airportsList)) {
      this.menuLinks.push({
        iconLeft: faPlaneDeparture,
        text: 'Aéroports',
        href: '/admin/airports/'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.lieuxDitsList)) {
      this.menuLinks.push({
        iconLeft: faPlaneDeparture,
        text: 'Lieux-dits',
        href: '/admin/lieux-dits'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.aircraftModelsList)) {
      this.menuLinks.push({
        iconLeft: faPlane,
        text: "Modèles d'avion",
        href: '/admin/aircraft-models'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.aircraftsList)) {
      this.menuLinks.push({
        iconLeft: faPlane,
        text: 'Appareils aériens',
        href: '/admin/aircrafts'
      });
    }

    const settingsChildrenLinks: IMenuLink[] = [];

    if (this.aclService.hasAclAccess(EnumAcl.simulateurAircraftModelsList)) {
      settingsChildrenLinks.push({
        text: "Modèles d'avion du simulateur",
        href: '/admin/simulateur-aircraft-models'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.emailTemplatesList)) {
      settingsChildrenLinks.push({
        text: "Templates d'email",
        href: '/admin/email-templates'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.userGroupsList)) {
      settingsChildrenLinks.push({
        text: "Groupes d'utilisateurs",
        href: '/admin/user-groups'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.encaissementTypesList)) {
      settingsChildrenLinks.push({
        text: "Types d'encaissements",
        href: '/admin/encaissement-types'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.bankAccountsList)) {
      settingsChildrenLinks.push({
        text: 'Comptes bancaires',
        href: '/admin/bank-accounts'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.flightBriefCateringsList)) {
      settingsChildrenLinks.push({
        text: 'Caterings des flight briefs',
        href: '/admin/flight-brief-caterings'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.crawlerUrlsList)) {
      settingsChildrenLinks.push({
        text: 'Scripts extracteur de données externes',
        href: '/admin/crawler-urls'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.backupsList)) {
      settingsChildrenLinks.push({
        text: 'Sauvegardes automatiques',
        href: '/admin/backups'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.countriesList)) {
      settingsChildrenLinks.push({
        text: 'Pays',
        href: '/admin/countries'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.flightChecklistTemplatesList)) {
      settingsChildrenLinks.push({
        text: 'Modèles de checklist des vols',
        href: '/admin/flight-checklist-templates'
      });
    }

    if (this.aclService.hasAclAccess(EnumAcl.settingsPipedrive)) {
      settingsChildrenLinks.push({
        text: 'Configuration Simplyfly',
        href: '/admin/settings'
      });
    }

    if (settingsChildrenLinks.length) {
      if (settingsChildrenLinks.length === 1) {
        this.menuLinks.push(Object.assign({ children: [] }, settingsChildrenLinks[0]));
      } else {
        this.menuLinks.push({
          iconLeft: faCogs,
          text: 'Paramètres',
          children: settingsChildrenLinks
        });
      }
    }
  }

  async search(): Promise<void> {
    this.searchOptions.total = 0;
    for (const searchKey of this.searchKeys) {
      searchKey.count = 0;
      searchKey.exactMatch = false;

      this.searchLoading[searchKey.value] = true;
      this.searchOptions[searchKey.value] = [];
    }

    try {
      const enquiries: IEnquiry[] = await this.algoliaService.searchEnquiries(this.searchQuery);

      for (const enquiry of enquiries.slice(0, 5)) {
        this.searchOptions.enquiries.push(enquiry);
        this.searchOptions.total++;
      }

      this.searchLoading[EnumSearchKey.enquiries] = false;

      const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
        this.searchQuery,
        ['ref'],
        []
      );

      for (const invoice of invoices.slice(0, 5)) {
        this.searchOptions.invoices.push(invoice);
        this.searchOptions.total++;
      }

      this.searchLoading[EnumSearchKey.invoices] = false;

      const airports: IAirport[] = await this.algoliaService.searchAirports(this.searchQuery);

      for (const airport of airports.slice(0, 5)) {
        this.searchOptions.airports.push(airport);
        this.searchOptions.total++;
      }

      this.searchLoading[EnumSearchKey.airports] = false;

      const clients: IPipedriveOrganization[] = await this.pipedriveService.searchOrganization(
        this.searchQuery
      );

      for (const client of clients.slice(0, 5)) {
        this.searchOptions.clients.push(client);
        this.searchOptions.total++;
      }

      this.searchLoading[EnumSearchKey.clients] = false;
    } catch (err) {
      console.log('🚀 ~ file: app.component.ts:444 ~ AppComponent ~ search ~ err:', err);
    } finally {
      for (const searchKey of this.searchKeys) {
        this.searchLoading[searchKey.value] = false;

        for (const searchKey of this.searchKeys) {
          switch (searchKey.value) {
            case EnumSearchKey.enquiries:
              searchKey.count = this.searchOptions.enquiries.length;

              for (const enquiry of this.searchOptions.enquiries) {
                if (
                  getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry').toLocaleLowerCase() ===
                    this.searchQuery.toLocaleLowerCase() ||
                  (enquiry.refContractTitle &&
                    getDisplayedEnquiryRefTitle(enquiry, 'refContract').toLocaleLowerCase() ===
                      this.searchQuery.toLocaleLowerCase())
                ) {
                  searchKey.exactMatch = true;
                }
              }
              break;
            case EnumSearchKey.invoices:
              searchKey.count = this.searchOptions.invoices.length;

              for (const invoice of this.searchOptions.invoices) {
                if (invoice.ref.toLocaleLowerCase() === this.searchQuery.toLocaleLowerCase()) {
                  searchKey.exactMatch = true;
                }
              }
              break;
            case EnumSearchKey.airports:
              searchKey.count = this.searchOptions.airports.length;

              for (const airport of this.searchOptions.airports) {
                if (airport.iataCode.toLocaleLowerCase() === this.searchQuery.toLocaleLowerCase()) {
                  searchKey.exactMatch = true;
                }
              }
              break;
            case EnumSearchKey.clients:
              searchKey.count = this.searchOptions.clients.length;

              for (const client of this.searchOptions.clients) {
                if (client.name.toLocaleLowerCase() === this.searchQuery.toLocaleLowerCase()) {
                  searchKey.exactMatch = true;
                }
              }
              break;
          }
        }
      }

      this.searchKeys.sort((a, b) => {
        if (a.exactMatch) {
          return -1;
        } else if (a.value === EnumSearchKey.enquiries && a.count > 0) {
          return -1;
        } else if (a.value === EnumSearchKey.airports && b.count > 0) {
          return 1;
        } else if (a.count > b.count && a.value !== EnumSearchKey.airports) {
          return -1;
        } else {
          return 1;
        }
      });
    }
  }

  openItem(searchKey: EnumSearchKey, item: IEnquiry): void {
    this.searchQuery = '';

    let url: string | null = null;
    switch (searchKey) {
      case EnumSearchKey.enquiries:
        url = '/admin/enquiries/' + item.id;
        break;
      case EnumSearchKey.invoices:
        url = '/admin/invoices/' + item.id;
        break;
      case EnumSearchKey.airports:
        url = '/admin/airports/' + item.id;
        break;
      case EnumSearchKey.clients:
        url = '/admin/clients/' + item.id;
        break;
    }

    if (url) {
      this.loaderService.presentLoader();

      this.router.navigateByUrl(url);

      setTimeout(() => {
        this.loaderService.hideLoaderOnSuccess();
      }, 1000);
    }
  }
}
