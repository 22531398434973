import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnAlign,
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';
import { getInvoiceDaysLate, IInvoice } from 'src/app/interfaces/invoice.interface';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import { getDisplayedEnquiryRefTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

@Component({
  selector: 'app-invoices-list-for-client',
  templateUrl: './invoices-list-for-client.component.html',
  styleUrls: ['./invoices-list-for-client.component.scss']
})
export class InvoicesListForClientComponent
  extends DataList<IInvoice>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() clientId: string | null = null;

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'client-invoices-list';
  columns: IDataListColumn[] = [
    {
      title: 'Numéro de facture',
      field: 'ref',
      notSortable: true
    },
    {
      title: 'Type',
      field: 'invoiceType',
      type: EnumDataListColumnType.function,
      resolve: (item: IInvoice): string => {
        return getEnumInvoiceTypeLabel(item.invoiceType);
      },
      notSortable: true
    },
    {
      title: 'Date de facture',
      field: 'issueDate',
      type: EnumDataListColumnType.date,
      notSortable: true
    },
    {
      title: "Date d'échéance",
      field: 'dueDate',
      type: EnumDataListColumnType.date,
      notSortable: true
    },
    {
      title: 'Retard',
      field: 'late',
      type: EnumDataListColumnType.function,
      resolve: (item: IInvoice): string => {
        let html = '';

        if (getInvoiceDaysLate(item) > 0 && !item.isFullyPaid) {
          html +=
            '<span class="badge bg-danger text-light">' +
            getInvoiceDaysLate(item) +
            ' jours</span>';
        }

        return html;
      },
      notSortable: true
    },
    {
      title: 'Dossier',
      field: 'enquiryId',
      type: EnumDataListColumnType.function,
      resolve: (item: IInvoice): string => {
        return this.enquiriesObj && this.enquiriesObj[item.enquiryId]
          ? getDisplayedEnquiryRefTitle(this.enquiriesObj[item.enquiryId], 'refContract')
          : '';
      },
      notSortable: true
    },
    {
      title: 'Libellé',
      field: 'invoiceObject',
      notSortable: true
    },
    {
      title: 'Envoyé ?',
      field: 'sentToClient',
      type: EnumDataListColumnType.boolean,
      notSortable: true
    },
    {
      title: 'Montant HT',
      field: 'amountHtTotal',
      type: EnumDataListColumnType.price,
      notSortable: true
    },
    {
      title: 'Montant TVA',
      field: 'amountTvaTotal',
      type: EnumDataListColumnType.price,
      notSortable: true
    },
    {
      title: 'Montant TTC',
      field: 'amountTtcTotal',
      type: EnumDataListColumnType.price,
      notSortable: true
    },
    {
      title: 'Date du réglement',
      field: 'paymentsDate',
      type: EnumDataListColumnType.date,
      notSortable: true
    },
    {
      title: 'Compte bancaire',
      field: 'bankAccount',
      type: EnumDataListColumnType.function,
      resolve: (item: IInvoice): string => {
        return item.bankAccount && this.bankAccountsObj[item.bankAccount]
          ? this.bankAccountsObj[item.bankAccount].name
          : '';
      },
      notSortable: true
    },
    {
      title: 'Montant reglé',
      field: 'amountPaid',
      type: EnumDataListColumnType.price,
      notSortable: true
    },
    {
      title: 'Montant restant',
      field: 'amountLeft',
      type: EnumDataListColumnType.price,
      notSortable: true
    },
    {
      title: 'Notes internes',
      field: 'comment',
      notSortable: true
    }
  ];

  orderBy: IDataListOrderBy | null = { field: 'created', direction: 'desc' };

  isLogged: boolean = false;

  enquiriesObj: { [id: string]: IEnquiry | null } = {};
  bankAccountsObj: { [id: string]: IBankAccount | null } = {};

  private subscriptions = new Subscription();

  constructor(
    private invoiceService: InvoiceService,
    private enquiryService: EnquiryService,
    private remoteService: RemoteService,
    private bankAccountService: BankAccountService
  ) {
    super();

    this.setDataService(this.invoiceService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.updatedClientId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientId'] && !changes['clientId'].isFirstChange()) {
      this.updatedClientId();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedClientId(): void {
    if (this.isLogged) {
      if (this.clientId) {
        this.conditions = [
          {
            field: 'clientId',
            operator: '==',
            value: this.clientId
          }
        ];

        this.resetAndLoad();
      }

      this.loadBankAccounts();
    } else {
      setTimeout(() => {
        this.updatedClientId();
      }, 500);
    }
  }

  afterLoadData(): void {
    for (const item of this.data) {
      this.loadEnquiry(item.enquiryId);
    }
  }

  loadEnquiry(enquiryId: string): void {
    if (typeof this.enquiriesObj[enquiryId] === 'undefined') {
      this.enquiriesObj[enquiryId] = null;

      this.subscriptions.add(
        this.enquiryService.getFromId(enquiryId).subscribe((enquiry: IEnquiry | null) => {
          this.enquiriesObj[enquiryId] = enquiry;
        })
      );
    }
  }

  loadBankAccounts(): void {
    this.subscriptions.add(
      this.bankAccountService.getAllForInvoicing().subscribe((bankAccounts: IBankAccount[]) => {
        for (const bankAccount of bankAccounts) {
          this.bankAccountsObj[bankAccount.id] = bankAccount;
        }
      })
    );
  }
}
