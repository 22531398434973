import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../../services/pagination.service';
import { AclService } from '../../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { DataList } from 'src/app/components/data-list/data-list.class';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { getEnquiryTargetLabel } from 'src/app/enums/enquiry-target.enum';

@Component({
  selector: 'app-list-aircraft-models',
  templateUrl: './list-aircraft-models.component.html',
  styleUrls: ['./list-aircraft-models.component.scss']
})
export class ListAircraftModelsComponent extends DataList<IAircraftModel> implements OnInit {
  columns: IDataListColumn[] = [
    {
      title: 'Nom',
      field: 'title'
    },
    {
      title: 'Famille',
      field: 'family'
    },
    {
      title: 'Cible',
      field: 'target',
      type: EnumDataListColumnType.function,
      resolve: (item: IAircraftModel): string => {
        return getEnquiryTargetLabel(item.target);
      }
    },
    {
      title: 'Image extérieur',
      field: 'imageUrl',
      type: EnumDataListColumnType.image
    },
    {
      title: 'Image intérieur',
      field: 'imageInsideUrl',
      type: EnumDataListColumnType.image
    },
    {
      title: 'Image plan',
      field: 'imagePlanUrl',
      type: EnumDataListColumnType.image
    },
    {
      title: 'Vitesse (kts)',
      field: 'speedInKts'
    },
    {
      title: 'Autonomie (kms)',
      field: 'rangeInKms'
    },
    {
      title: 'Poids (kg)',
      field: 'weight'
    },
    {
      title: 'Volume (m<sup>3</sup>)',
      field: 'volume'
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'title', direction: 'asc' };

  EnumAcl = EnumAcl;

  constructor(private aclService: AclService, private aircraftModelService: AircraftModelService) {
    super();

    this.setDataService(this.aircraftModelService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.aircraftModelsList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
