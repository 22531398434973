import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AirlineSupervisorFirestoreService } from './airline-supervisors-firestore.service';
import { IAirlineSupervisor } from 'src/app/interfaces/airline-supervisor.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AirlineSupervisorService {
  constructor(private airlineSupervisorFirestoreService: AirlineSupervisorFirestoreService) {}

  create(AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.create(AirlineSupervisor);
  }

  createWithId(id: string, AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.createWithId(id, AirlineSupervisor);
  }

  update(AirlineSupervisor: IAirlineSupervisor): Promise<string> {
    return this.airlineSupervisorFirestoreService.update(AirlineSupervisor);
  }

  delete(id: string): Promise<void> {
    return this.airlineSupervisorFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAirlineSupervisor> {
    return this.airlineSupervisorFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAirlineSupervisor[]> {
    return this.airlineSupervisorFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.airlineSupervisorFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IAirlineSupervisor[]> {
    return this.airlineSupervisorFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getForAirline(airlineId: string): Observable<IAirlineSupervisor[]> {
    return this.airlineSupervisorFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }
}
