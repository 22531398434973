import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getYear } from 'date-fns';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumPeriod, getPeriodLabel } from '../financial-reports.component';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import {
  getMonthLabelByQuarter,
  getMonthLabel,
  formatPrice,
  roundNumber
} from 'src/app/misc.utils';
import { Subscription } from 'rxjs';
import { IFinancialReportAirline } from 'src/app/interfaces/financial-report-airline.interface';
import { FinancialReportAirlinesService } from 'src/app/services/financial-report-airlines/financial-report-airlines.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

enum EnumFinancialReportAirlineVirtualField {
  marginHTPercent = 'marginHTPercent',
  nbContractsPercent = 'nbContractsPercent',
  caPercent = 'caPercent'
}

interface IFinancialReportAirlineVirtualFields {
  marginHTPercent: number;
  nbContractsPercent: number;
  caPercent: number;
}

interface IFinancialReportAirlineAllAndAirlines {
  label: string;
  all: IFinancialReportAirline | null;
  financialReportAirlines: IFinancialReportAirline[];
  virtualFields: IFinancialReportAirlineVirtualFieldsAllAndAirlines;
}

interface IFinancialReportAirlineVirtualFieldsAllAndAirlines {
  [airlineId: string]: IFinancialReportAirlineVirtualFields | null;
  all: IFinancialReportAirlineVirtualFields | null;
}

@Component({
  selector: 'app-financial-report-airline',
  templateUrl: './financial-report-airline.component.html',
  styleUrls: ['./financial-report-airline.component.scss']
})
export class FinancialReportAirlineComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currentYear') currentYear: number = getYear(new Date());
  @Input('currentCurrency') currentCurrency: EnumCurrency | null = null;
  @Input('currentPeriod') currentPeriod: EnumPeriod = EnumPeriod.months;

  @Output() updateCurrentPeriod: EventEmitter<EnumPeriod> = new EventEmitter();

  getPeriodLabel = getPeriodLabel;
  getMonthLabelByQuarter = getMonthLabelByQuarter;
  getMonthLabel = getMonthLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getUserFullname = getUserFullname;
  formatPrice = formatPrice;
  roundNumber = roundNumber;
  getDefaultCurrency = getDefaultCurrency;

  EnumPeriod = EnumPeriod;
  EnumFinancialReportAirlineVirtualField = EnumFinancialReportAirlineVirtualField;

  loading: boolean = false;
  loadingYearsOnly: boolean = false;

  financialReportAirlines: IFinancialReportAirline[] = [];
  financialReportAirlinesOnlyYears: IFinancialReportAirline[] = [];
  financialReportAirlinesByPeriod: {
    [period: string]: {
      [year: string]: IFinancialReportAirlineAllAndAirlines[];
    };
  } = {};

  subscriptions = new Subscription();
  subscriptionsForYear = new Subscription();

  constructor(
    private financialReportAirlinesService: FinancialReportAirlinesService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentYear || changes.currentCurrency) {
      this.loadAll();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsForYear.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  getData(): IFinancialReportAirlineAllAndAirlines[] {
    switch (this.currentPeriod) {
      case EnumPeriod.years:
        return this.financialReportAirlinesByPeriod[this.currentPeriod].all;
      default:
        return this.financialReportAirlinesByPeriod[this.currentPeriod][this.currentYear];
    }
  }

  async changeCurrentPeriod(newPeriod: EnumPeriod): Promise<void> {
    this.loaderService.presentLoader();

    setTimeout(() => {
      this.currentPeriod = newPeriod;

      this.updateCurrentPeriod.emit(this.currentPeriod);

      setTimeout(() => {
        this.loaderService.hideLoaderImmediately();
      }, 1000);
    }, 300);
  }

  loadAll(): void {
    this.loading = true;

    this.resetSubscriptionForYear();

    this.resetFinancialReportAirlinesDisplayedData(this.currentYear);

    this.loadFinancialReports();
    this.loadFinancialReportAirlinesYearsOnly();
  }

  resetSubscriptionForYear(): void {
    if (this.subscriptionsForYear) {
      this.subscriptionsForYear.unsubscribe();

      this.subscriptionsForYear = new Subscription();
    }
  }

  loadFinancialReportAirlinesYearsOnly(): void {
    this.loadingYearsOnly = true;

    // By years
    this.subscriptionsForYear.add(
      this.financialReportAirlinesService
        .getAllForCurrencyOnlyYears(this.currentCurrency)
        .subscribe((financialReportAirlines: IFinancialReportAirline[]) => {
          this.financialReportAirlinesOnlyYears = financialReportAirlines;

          this.organiseFinancialReportAirlinesYearsOnly();

          this.loadingYearsOnly = false;
        })
    );
  }

  organiseFinancialReportAirlinesYearsOnly(): void {
    this.financialReportAirlinesByPeriod[EnumPeriod.years] = {
      all: []
    };

    const financialReportAirlinesByYearObj: {
      [year: string]: IFinancialReportAirlineAllAndAirlines;
    } = {};

    for (const financialReportAirline of this.financialReportAirlinesOnlyYears) {
      if (typeof financialReportAirlinesByYearObj[financialReportAirline.year] === 'undefined') {
        const virtualFieldsByEnquiryAirlines: IFinancialReportAirlineVirtualFieldsAllAndAirlines = {
          all: {} as IFinancialReportAirlineVirtualFields
        };

        virtualFieldsByEnquiryAirlines[financialReportAirline.airlineId] =
          {} as IFinancialReportAirlineVirtualFields;

        for (const field in virtualFieldsByEnquiryAirlines) {
          for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
            virtualFieldsByEnquiryAirlines[field][virtualField] = 0;
          }
        }

        financialReportAirlinesByYearObj[financialReportAirline.year] = {
          label: financialReportAirline.year.toString(),
          all: null,
          financialReportAirlines: [],
          virtualFields: {
            all: {} as IFinancialReportAirlineVirtualFields
          }
        };

        for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
          financialReportAirlinesByYearObj[financialReportAirline.year].virtualFields.all[
            virtualField
          ] = 0;
        }
      }

      if (financialReportAirline.airlineId) {
        financialReportAirlinesByYearObj[financialReportAirline.year].financialReportAirlines.push(
          this.cloneVariable(financialReportAirline)
        );

        financialReportAirlinesByYearObj[financialReportAirline.year].virtualFields[
          financialReportAirline.airlineId
        ] = {} as IFinancialReportAirlineVirtualFields;

        for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
          financialReportAirlinesByYearObj[financialReportAirline.year].virtualFields[
            financialReportAirline.airlineId
          ][virtualField] = 0;
        }
      } else {
        financialReportAirlinesByYearObj[financialReportAirline.year].all = financialReportAirline;
      }
    }

    this.financialReportAirlinesByPeriod[EnumPeriod.years].all = Object.values(
      financialReportAirlinesByYearObj
    ).reverse();

    for (let financialReportAirlineAllAndAirlines of this.financialReportAirlinesByPeriod[
      EnumPeriod.years
    ].all) {
      financialReportAirlineAllAndAirlines = this.setVirtualFieldsForFinancialReportAirline(
        financialReportAirlineAllAndAirlines
      );

      financialReportAirlineAllAndAirlines.financialReportAirlines.sort((a, b) =>
        a.airlineTitle.toLocaleLowerCase() < b.airlineTitle.toLocaleLowerCase() ? -1 : 1
      );
    }

    this.loadingYearsOnly = false;
  }

  getPercentage(value: number, total: number): number {
    if (total !== 0) {
      return roundNumber((value * 100) / total);
    }

    return 0;
  }

  loadFinancialReports(): void {
    this.subscriptionsForYear.add(
      this.financialReportAirlinesService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialReportAirlines: IFinancialReportAirline[]) => {
          this.financialReportAirlines = financialReportAirlines;

          this.organiseFinancialReportAirlines(this.currentYear);
        })
    );
  }

  organiseFinancialReportAirlines(year: number): void {
    for (const financialReportAirline of this.financialReportAirlines) {
      if (financialReportAirline.month) {
        if (financialReportAirline.airlineId) {
          this.financialReportAirlinesByPeriod[EnumPeriod.months][year][
            financialReportAirline.month - 1
          ].financialReportAirlines.push(this.cloneVariable(financialReportAirline));
        } else {
          this.financialReportAirlinesByPeriod[EnumPeriod.months][year][
            financialReportAirline.month - 1
          ].all = this.cloneVariable(financialReportAirline);
        }
      } else if (financialReportAirline.quarter) {
        if (financialReportAirline.airlineId) {
          this.financialReportAirlinesByPeriod[EnumPeriod.quarters][year][
            financialReportAirline.quarter - 1
          ].financialReportAirlines.push(this.cloneVariable(financialReportAirline));
        } else {
          this.financialReportAirlinesByPeriod[EnumPeriod.quarters][year][
            financialReportAirline.quarter - 1
          ].all = this.cloneVariable(financialReportAirline);
        }
      }
    }

    for (const period of this.getPeriods()) {
      for (const year in this.financialReportAirlinesByPeriod[period]) {
        for (let financialReportAirlineAllAndAirlines of this.financialReportAirlinesByPeriod[
          period
        ][year]) {
          financialReportAirlineAllAndAirlines.financialReportAirlines.sort((a, b) =>
            a.airlineTitle.toLocaleLowerCase() < b.airlineTitle.toLocaleLowerCase() ? -1 : 1
          );

          financialReportAirlineAllAndAirlines = this.setVirtualFieldsForFinancialReportAirline(
            financialReportAirlineAllAndAirlines
          );
        }
      }
    }

    this.loading = false;
  }

  setVirtualFieldsForFinancialReportAirline(
    financialReportAirlineAllAndAirlines: IFinancialReportAirlineAllAndAirlines
  ): IFinancialReportAirlineAllAndAirlines {
    for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
      switch (virtualField) {
        default:
          const field: string = (virtualField as string).replace('Percent', '');

          for (const financialReportAirline of financialReportAirlineAllAndAirlines.financialReportAirlines) {
            if (
              financialReportAirline[field] &&
              financialReportAirlineAllAndAirlines.all !== null &&
              financialReportAirlineAllAndAirlines.all[field]
            ) {
              if (
                typeof financialReportAirlineAllAndAirlines.virtualFields[
                  financialReportAirline.airlineId
                ] === 'undefined'
              ) {
                financialReportAirlineAllAndAirlines.virtualFields[
                  financialReportAirline.airlineId
                ] = {} as IFinancialReportAirlineVirtualFields;

                for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
                  financialReportAirlineAllAndAirlines.virtualFields[
                    financialReportAirline.airlineId
                  ][virtualField] = 0;
                }
              }

              if (
                typeof financialReportAirlineAllAndAirlines.virtualFields[
                  financialReportAirline.airlineId
                ] === 'undefined'
              ) {
                financialReportAirlineAllAndAirlines.virtualFields[
                  financialReportAirline.airlineId
                ][virtualField as string] = 0;
              }
              financialReportAirlineAllAndAirlines.virtualFields[financialReportAirline.airlineId][
                virtualField as string
              ] = this.getPercentage(
                financialReportAirline[field],
                financialReportAirlineAllAndAirlines.all[field]
              );
            }
          }

          break;
      }
    }

    for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
      for (const financialReportAirline of financialReportAirlineAllAndAirlines.financialReportAirlines) {
        if (
          typeof financialReportAirlineAllAndAirlines.virtualFields.all[virtualField as string] ===
          'undefined'
        ) {
          financialReportAirlineAllAndAirlines.virtualFields.all[virtualField as string] = 0;
        }

        financialReportAirlineAllAndAirlines.virtualFields.all[virtualField as string] +=
          financialReportAirlineAllAndAirlines.virtualFields[financialReportAirline.airlineId][
            virtualField as string
          ];
      }
    }

    return financialReportAirlineAllAndAirlines;
  }

  resetFinancialReportAirlinesDisplayedData(year: number): void {
    for (const period of this.getPeriods()) {
      this.financialReportAirlinesByPeriod[period] = {};
      this.financialReportAirlinesByPeriod[period][year] = [];
      const virtualFieldsByEnquiryAirlines: IFinancialReportAirlineVirtualFieldsAllAndAirlines = {
        all: {} as IFinancialReportAirlineVirtualFields
      };
      for (const field in virtualFieldsByEnquiryAirlines) {
        for (const virtualField of Object.values(EnumFinancialReportAirlineVirtualField)) {
          virtualFieldsByEnquiryAirlines[field][virtualField] = 0;
        }
      }
      let countItems: number = 1;
      switch (period) {
        case EnumPeriod.months:
          countItems = 12;
          break;
        case EnumPeriod.quarters:
          countItems = 4;
          break;
      }
      for (let i = 1; i <= countItems; i++) {
        this.financialReportAirlinesByPeriod[period][year].push({
          label:
            period === EnumPeriod.quarters
              ? this.getMonthLabelByQuarter(i) + ' ' + this.currentYear
              : getMonthLabel(i) + ' ' + this.currentYear,
          all: null,
          financialReportAirlines: [],
          virtualFields: this.cloneVariable(virtualFieldsByEnquiryAirlines)
        });
      }
    }
  }

  cloneVariable(variable: any): any {
    return JSON.parse(JSON.stringify(variable));
  }
}
