import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FlightChecklistTemplateFirestoreService } from './flight-checklist-templates-firestore.service';
import { IFlightChecklistTemplate } from 'src/app/interfaces/flight-checklist-template.interface';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FlightChecklistTemplateService {
  constructor(
    private flightChecklistTemplateFirestoreService: FlightChecklistTemplateFirestoreService
  ) {}

  create(flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.create(flightChecklistTemplate);
  }

  createWithId(id: string, flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.createWithId(id, flightChecklistTemplate);
  }

  update(flightChecklistTemplate: IFlightChecklistTemplate): Promise<string> {
    return this.flightChecklistTemplateFirestoreService.update(flightChecklistTemplate);
  }

  delete(id: string): Promise<void> {
    return this.flightChecklistTemplateFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFlightChecklistTemplate> {
    return this.flightChecklistTemplateFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFlightChecklistTemplate[]> {
    return this.flightChecklistTemplateFirestoreService.collectionValues$();
  }

  getOneForEnquiryType(enquiryType: EnumEnquiryType): Observable<IFlightChecklistTemplate> {
    return this.flightChecklistTemplateFirestoreService
      .collectionValues$(ref => ref.where('enquiryType', '==', enquiryType))
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.flightChecklistTemplateFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IFlightChecklistTemplate[]> {
    return this.flightChecklistTemplateFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
