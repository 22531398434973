import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';

import { RemoteService } from '../services/remote.service';

import algoliasearch from 'algoliasearch/lite';
import { IEnquiry } from '../interfaces/enquiry.interface';
import { IAirline } from '../interfaces/airline.interface';
import { IAirport } from '../interfaces/airport.interface';
import { IInvoice } from '../interfaces/invoice.interface';

// DEV
// const client = algoliasearch('DLJZX1UN8E', '63d9a7a444b4a3e0fad0a908c1d1fcf2');
// const prefix = ''
// PROD
const client = algoliasearch('CAZTQQH695', '7c9be9082ab41f5c4e581e54c3d31539');
const prefix = '';

export interface IAlgoliaFacetValuesResponse {
  count: number;
  highlighted: string;
  value: string;
}

export interface IAlgoliaFilter {
  field: string;
  type: string;
  operator?: '=' | '!=' | '<' | '<=' | '>' | '>=' | 'in';
  value: string | number | boolean | string[];
}

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {
  constructor(private remoteService: RemoteService) {}

  private search(
    collectionName: string,
    query: string,
    restrictSearchableAttributes: string[] = [],
    filters: string = null,
    typoTolerance: boolean = true
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const index = client.initIndex(prefix + collectionName);

      const params: object = {};

      if (restrictSearchableAttributes.length) {
        params['restrictSearchableAttributes'] = restrictSearchableAttributes;
      }

      if (filters && filters != '') {
        params['filters'] = filters;
      }

      params['typoTolerance'] = typoTolerance;

      index
        .search(query, params)
        .then(({ hits }) => {
          let objects: object[] = [];

          for (let hit of hits) {
            objects.push(hit);
          }

          resolve(objects);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  searchAirlines(
    query: string,
    filters: {
      field: string;
      type: string;
      value: any;
    }[] = []
  ): Promise<IAirline[]> {
    return new Promise((resolve, reject) => {
      let filtersFormatted: string[] = [];

      for (let filter of filters) {
        switch (filter.type) {
          case 'boolean':
            filtersFormatted.push(filter.field + '=' + (filter.value ? '1' : '0'));
            break;
          default:
            filtersFormatted.push(filter.field + ':' + filter.value);
            break;
        }
      }

      this.search('airlines', query, ['title'], filtersFormatted.join(' AND '))
        .then((results: object[]) => {
          const airlines: IAirline[] = [];

          for (let result of results) {
            for (let field of ['created', 'modified']) {
              result = this.convertToFirestoreTimestamp(field, result);
            }
            result['id'] = result['objectID'];

            airlines.push(result as IAirline);
          }

          resolve(airlines);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  searchEnquiries(
    query: string,
    fields: string[] = ['refEnquiryTitleDisplayed', 'refContractTitleDisplayed'],
    filters: {
      field: string;
      type: string;
      value: any;
    }[] = []
  ): Promise<IEnquiry[]> {
    return new Promise((resolve, reject) => {
      let filtersFormatted: string[] = [];

      for (let filter of filters) {
        switch (filter.type) {
          case 'boolean':
            filtersFormatted.push(filter.field + '=' + (filter.value ? '1' : '0'));
            break;
          default:
            filtersFormatted.push(filter.field + ':' + filter.value);
            break;
        }
      }

      this.search('enquiries', query, fields, filtersFormatted.join(' AND '))
        .then((results: object[]) => {
          let enquiries: IEnquiry[] = [];

          for (let result of results) {
            for (let field of ['created', 'modified']) {
              result = this.convertToFirestoreTimestamp(field, result);
            }
            result['id'] = result['objectID'];

            enquiries.push(result as IEnquiry);
          }

          resolve(enquiries);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  searchAirports(query: string): Promise<IAirport[]> {
    return new Promise((resolve, reject) => {
      let filtersFormatted: string[] = [];

      this.search(
        'airports',
        query,
        ['title', 'iataCode', 'countryCode'],
        filtersFormatted.join(' AND ')
      )
        .then((results: object[]) => {
          let airports: IAirport[] = [];

          for (let result of results) {
            for (let field of ['created', 'modified']) {
              result = this.convertToFirestoreTimestamp(field, result);
            }
            result['id'] = result['objectID'];

            airports.push(result as IAirport);
          }

          resolve(airports);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  searchInvoices(
    query: string,
    fields: string[] = ['ref'],
    filters: IAlgoliaFilter[] = []
  ): Promise<IInvoice[]> {
    return new Promise((resolve, reject) => {
      const filtersFormatted: string[] = [];

      for (let filter of filters) {
        const filtersFormattedOr: string[] = [];
        const prefix: string = filter.operator === '!=' ? 'NOT ' : '';

        let field: string = prefix + filter.field;
        let operator: string = filter.operator;
        let value: (string | number | boolean)[] = [];

        if (operator === 'in' && Array.isArray(filter.value)) {
          value = filter.value;
        } else {
          switch (filter.type) {
            case 'boolean':
              value.push(filter.value ? 'true' : 'false');
              break;
            case 'date':
              field = prefix + filter.field + '_timestamp';
              value.push(filter.value as number);
              break;
            default:
              value.push(filter.value.toString());
              break;
          }
        }

        if (['=', '!=', 'in'].includes(operator)) {
          operator = ':';
        }

        for (const option of value) {
          filtersFormattedOr.push(
            field + operator + (typeof option === 'string' ? '"' + option + '"' : option)
          );
        }

        filtersFormatted.push(filtersFormattedOr.join(' OR '));
      }

      this.search('invoices', query, fields, filtersFormatted.join(' AND '), false)
        .then((results: object[]) => {
          let invoices: IInvoice[] = [];

          for (let result of results) {
            for (let field of [
              'created',
              'modified',
              'requestDate',
              'generatedDate',
              'dueDate',
              'issueDate'
            ]) {
              result = this.convertToFirestoreTimestamp(field, result);
            }
            result['id'] = result['objectID'];

            invoices.push(result as IInvoice);
          }

          resolve(invoices);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  clearCache(): void {
    client.clearCache();
  }

  facetSearch(
    collectionName: string,
    field: string,
    query: string
  ): Promise<IAlgoliaFacetValuesResponse[]> {
    return new Promise((resolve, reject) => {
      const index = client.initIndex(prefix + collectionName);

      const params: object = {};

      index
        .searchForFacetValues(field, query)
        .then(({ facetHits }) => {
          let objects: IAlgoliaFacetValuesResponse[] = [];

          for (let facetHit of facetHits) {
            objects.push(facetHit as IAlgoliaFacetValuesResponse);
          }

          resolve(objects);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private convertToFirestoreTimestamp(fieldName: string, data: object): object {
    if (data[fieldName]) {
      if (data[fieldName]['_seconds']) {
        data[fieldName] = new firebase.firestore.Timestamp(
          data[fieldName]['_seconds'],
          data[fieldName]['_nanoseconds']
        ).toDate();
      } else if (typeof data[fieldName] === 'string') {
        data[fieldName] = new Date(data[fieldName]);
      }
    }

    return data;
  }
}
