import { Component, OnDestroy, OnInit } from '@angular/core';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import * as XLSX from 'xlsx';
import {
  EnumAircraftHasHotCatering,
  EnumAircraftHasStewardess,
  EnumAircraftWifi,
  getAircraftHasHotCateringLabel,
  getAircraftHasStewardessLabel,
  getAircraftWifiLabel,
  IAircraft
} from 'src/app/interfaces/aircraft.interface';
import { faCancel, faCheck, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { Subscription } from 'rxjs';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { chunk, generateRandomId, getContinentTitle, getCountryTitle } from 'src/app/misc.utils';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { EnumAircraftStatus } from 'src/app/enums/aircraft-status.enum';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { IBatchOperationsParams } from 'src/app/services/firestore/firestore.service';

const headers: string[] = [
  "Immatriculation de l'avion",
  'Constructeur',
  "Type d'avion",
  'Catégorie',
  'Nombre de passagers maximum',
  "Nom de l'opérateur",
  'Année de fabrication',
  'Année de refurbishment',
  "Base de l'avion",
  "Nombre de membres d'équipage",
  'Hotesse',
  'Fumeur',
  'Sleeping Configuration',
  'Toilette',
  'Catering Chaud',
  'Pets',
  'Wifi',
  'Satellite Phone',
  'Photo 1',
  'Photo 2',
  'Photo 3',
  'Photo 4',
  'Photo 5',
  'Photo 6',
  'Photo 7'
];

enum EnumImportDataErrorMsg {
  empty = 'empty',
  numberOfHeadersDifferent = 'numberOfHeadersDifferent',
  columnsTitleAreDifferent = 'columnsTitleAreDifferent',
  registrationEmpty = 'registrationEmpty',
  builderMissing = 'builderMissing',
  aircraftModelMissing = 'aircraftModelMissing',
  airlineMissing = 'airlineMissing'
}

interface DataToImport {
  headers: string[];
  lines: string[][];
  errorsByLine: { [lineIndex: string]: EnumImportDataErrorMsg[] };
}

@Component({
  selector: 'app-import-aircrafts',
  templateUrl: './import-aircrafts.component.html',
  styleUrls: ['./import-aircrafts.component.scss']
})
export class ImportAircraftsComponent implements OnInit, OnDestroy {
  isLogged: boolean = false;

  faCheck = faCheck;
  faCancel = faCancel;
  faExternalLink = faExternalLink;

  EnumAcl = EnumAcl;
  EnumImportDataErrorMsg = EnumImportDataErrorMsg;

  getAircraftHasStewardessLabel = getAircraftHasStewardessLabel;
  getAircraftHasHotCateringLabel = getAircraftHasHotCateringLabel;
  getAircraftWifiLabel = getAircraftWifiLabel;

  fileContentLoaded = false;
  currentFile: File;
  dataValid: boolean = false;
  errorInvalidData: EnumImportDataErrorMsg | null = null;
  data: DataToImport = {
    headers: [],
    lines: [],
    errorsByLine: {}
  };

  aircraftsReadyToImport: number = 0;
  aircraftsToImport: IAircraft[] = [];
  fetchingRelatedData: boolean = false;

  aircraftsByRegistration: { [registration: string]: IAircraft | null } = {};
  aircraftsByRegistrationLoaded: { [registration: string]: boolean } = {};

  airlinesByTitle: { [title: string]: IAirline | null } = {};
  airlinesByTitleLoaded: { [title: string]: boolean } = {};

  aircraftModelsByTitle: { [title: string]: IAircraftModel | null } = {};
  aircraftModelsByTitleLoaded: { [title: string]: boolean } = {};

  private subscriptions = new Subscription();

  constructor(
    public remoteService: RemoteService,
    private aclService: AclService,
    private airlineService: AirlineService,
    private aircraftService: AircraftService,
    private aircraftModelService: AircraftModelService,
    private loaderService: LoaderService
  ) {}

  async ngOnInit(): Promise<void> {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    await this.aclService.checkAclAccess(EnumAcl.aircraftsImport);

    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      // this.fetchData()
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileContentLoaded = false;

        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.data = this.excelContentToJson(
          XLSX.utils.sheet_to_csv(ws, {
            FS: ';;'
          })
        );

        this.checkIfDataAreValid();

        this.fileContentLoaded = true;

        if (this.dataValid) {
          this.getAircraftsToImportFromData();
        }
      };

      reader.readAsBinaryString(this.currentFile);
    }
  }

  private excelContentToJson(data: string): DataToImport {
    const lines: string[] = data.split('\n');

    const result: DataToImport = {
      headers: [],
      lines: [],
      errorsByLine: {}
    };

    for (let i = 0; i < lines.length; i++) {
      if (i === 0) {
        result.headers = lines[i].split(';;');
      } else if (i > 0) {
        const cells: string[] = lines[i].split(';;');

        if (cells[0]) {
          result.lines.push(cells);
        }
      }
    }

    return result;
  }

  checkIfDataAreValid(): void {
    this.dataValid = true;
    this.errorInvalidData = null;

    if (this.data?.headers?.length) {
      if (this.data.headers.length !== headers.length) {
        this.dataValid = false;
        this.errorInvalidData = EnumImportDataErrorMsg.numberOfHeadersDifferent;
      } else {
        for (let i = 0; i < this.data.headers.length; i++) {
          if (this.data.headers[i] !== headers[i]) {
            this.dataValid = false;
            this.errorInvalidData = EnumImportDataErrorMsg.columnsTitleAreDifferent;
            break;
          }
        }
      }
    } else {
      this.dataValid = false;
      this.errorInvalidData = EnumImportDataErrorMsg.empty;
    }

    if (this.dataValid) {
      // Check data now
      for (let i = 0; i < this.data.lines.length; i++) {
        if (typeof this.data.errorsByLine[i] === 'undefined') {
          this.data.errorsByLine[i] = [];
        }

        if (!this.data.lines[i][0]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.registrationEmpty);
        }

        if (!this.data.lines[i][1]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.builderMissing);
        }

        if (!this.data.lines[i][2]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.aircraftModelMissing);
        }

        if (!this.data.lines[i][5]) {
          this.data.errorsByLine[i].push(EnumImportDataErrorMsg.airlineMissing);
        }

        if (this.data.errorsByLine[i].length) {
          this.dataValid = false;
        }
      }
    }
  }

  getAircraftsToImportFromData(): void {
    if (this.data) {
      const importId: string = generateRandomId();
      const importDate: Date = new Date();

      this.aircraftsToImport = [];

      for (const line of this.data.lines) {
        const imageUrls: string[] = [];

        for (let i = 18; i <= 24; i++) {
          if (line[i]) {
            imageUrls.push(line[i]);
          }
        }

        const aircraft: IAircraft = {
          importId,
          importDate,
          id: this.aircraftsByRegistration[line[0]]
            ? this.aircraftsByRegistration[line[0]].id
            : null,
          registration: line[0].trim(),
          aircraftModelTitle: line[2].trim(),
          model: null,
          type: null,
          airlineId: this.airlinesByTitle[line[5]] ? this.airlinesByTitle[line[5]].id : null,
          airlineTitle: line[5],
          airlineContinentCode: null,
          airlineContinentTitle: null,
          airlineCountryCode: null,
          airlineCountryTitle: null,
          isPassengers: true,
          isCargo: false,
          seatTotal: parseInt(line[4]),
          status: EnumAircraftStatus.active,
          dateOfManufacture: line[6],
          dateOfRefurbishment: line[7] === '-' ? null : line[7],
          homebase: line[8],
          numberOfCrewMembers: parseInt(line[9]),
          hasStewardess: null,
          isSmokingAllowed: line[11] === 'Oui',
          sleepingConfiguration: line[12],
          hasToilet: line[13] === 'Oui',
          hasHotCatering: null,
          arePetsAllowed: line[15] === 'Oui',
          wifi: null,
          hasSatellitePhone: line[17] === 'Oui',
          imageUrls
        } as IAircraft;

        for (const value of Object.values(EnumAircraftHasStewardess)) {
          if (line[10] === value || line[10] === getAircraftHasStewardessLabel(value)) {
            aircraft.hasStewardess = value;
            break;
          }
        }

        for (const value of Object.values(EnumAircraftHasHotCatering)) {
          if (line[14] === value || line[14] === getAircraftHasHotCateringLabel(value)) {
            aircraft.hasHotCatering = value;
            break;
          }
        }

        for (const value of Object.values(EnumAircraftWifi)) {
          if (line[16] === value || line[16] === getAircraftWifiLabel(value)) {
            aircraft.wifi = value;
            break;
          }
        }

        this.aircraftsToImport.push(aircraft);

        this.loadAirlineWithTitle(line[5]);
        this.loadAircraftWithRegistration(line[0]);
        this.loadAircraftModelWithTitle(line[2]);
      }

      this.refreshAircraftsReadyToImport();
    }
  }

  refreshAircraftsReadyToImport(): void {
    this.fetchingRelatedData =
      Object.values(this.airlinesByTitleLoaded).includes(false) &&
      Object.values(this.aircraftModelsByTitleLoaded).includes(false) &&
      Object.values(this.aircraftsByRegistrationLoaded).includes(false);

    this.aircraftsReadyToImport = 0;

    for (const aircraft of this.aircraftsToImport) {
      if (
        aircraft.airlineId &&
        this.aircraftsByRegistrationLoaded[aircraft.registration] &&
        aircraft.aircraftModelId &&
        this.aircraftModelsByTitleLoaded[aircraft.aircraftModelTitle]
      ) {
        this.aircraftsReadyToImport++;
      }
    }
  }

  loadAirlineWithTitle(airlineTitle: string): void {
    if (typeof this.airlinesByTitle[airlineTitle] === 'undefined') {
      this.airlinesByTitle[airlineTitle] = null;
      this.airlinesByTitleLoaded[airlineTitle] = false;

      this.subscriptions.add(
        this.airlineService.getOneByTitle(airlineTitle).subscribe((airline: IAirline | null) => {
          this.airlinesByTitle[airlineTitle] = airline;
          this.airlinesByTitleLoaded[airlineTitle] = true;

          if (airline) {
            for (const aircraft of this.aircraftsToImport) {
              if (aircraft.airlineTitle === airlineTitle) {
                aircraft.airlineId = airline.id;
                aircraft.airlineContinentCode = airline.continentCode;
                aircraft.airlineContinentTitle = getContinentTitle(airline.continentCode ?? null);
                aircraft.airlineCountryCode = airline.countryCode;
                aircraft.airlineCountryTitle = getCountryTitle(airline.countryCode ?? null);
              }
            }
          }

          this.refreshAircraftsReadyToImport();
        })
      );
    }
  }

  loadAircraftModelWithTitle(aircraftModelTitle: string): void {
    if (typeof this.aircraftModelsByTitle[aircraftModelTitle] === 'undefined') {
      this.aircraftModelsByTitle[aircraftModelTitle] = null;
      this.aircraftModelsByTitleLoaded[aircraftModelTitle] = false;

      this.subscriptions.add(
        this.aircraftModelService
          .getOneByTitle(aircraftModelTitle)
          .subscribe((aircraftModel: IAircraftModel | null) => {
            this.aircraftModelsByTitle[aircraftModelTitle] = aircraftModel;
            this.aircraftModelsByTitleLoaded[aircraftModelTitle] = true;

            if (aircraftModel) {
              for (const aircraft of this.aircraftsToImport) {
                if (aircraft.aircraftModelTitle === aircraftModelTitle) {
                  aircraft.aircraftModelId = aircraftModel.id;
                  aircraft.type = aircraftModel.family;
                  aircraft.model = aircraftModel.family;
                }
              }
            }

            this.refreshAircraftsReadyToImport();
          })
      );
    }
  }

  loadAircraftWithRegistration(registration: string): void {
    if (typeof this.aircraftsByRegistration[registration] === 'undefined') {
      this.aircraftsByRegistration[registration] = null;
      this.aircraftsByRegistrationLoaded[registration] = false;

      this.subscriptions.add(
        this.aircraftService
          .getOneByRegistration(registration)
          .subscribe((aircraft: IAircraft | null) => {
            this.aircraftsByRegistration[registration] = aircraft;
            this.aircraftsByRegistrationLoaded[registration] = true;

            if (aircraft) {
              for (const aircraftToImport of this.aircraftsToImport) {
                if (aircraftToImport.registration === registration) {
                  aircraftToImport.id = aircraft.id;
                }
              }
            }

            this.refreshAircraftsReadyToImport();
          })
      );
    }
  }

  async importAircrafts(): Promise<void> {
    this.loaderService.presentLoader();

    try {
      const airlineIds: string[] = [];
      const batchOperationsParams: IBatchOperationsParams = {
        set: {},
        update: {}
      };

      for (const aircraft of this.aircraftsToImport) {
        if (!aircraft.created) {
          aircraft.created = new Date();
        }

        if (!aircraft.modified) {
          aircraft.modified = new Date();
        }

        if (aircraft.id) {
          batchOperationsParams.update[aircraft.id] = aircraft;
        } else {
          aircraft.id = generateRandomId();
          batchOperationsParams.set[aircraft.id] = aircraft;
        }

        if (aircraft.airlineId && !airlineIds.includes(aircraft.airlineId)) {
          airlineIds.push(aircraft.airlineId);
        }
      }

      const sizeChunkBatch: number = 500;

      for (const field of ['set', 'update']) {
        const chunkedData: string[][] = chunk(
          Object.keys(batchOperationsParams[field]),
          sizeChunkBatch
        );

        for (let i = 0; i < chunkedData.length; i++) {
          await this.loaderService.updateLoaderMessage(
            field === 'set'
              ? 'Ajout ' + (i + 1) + '/' + chunkedData.length
              : 'Mise à jour ' + (i + 1) + '/' + chunkedData.length
          );

          const dataToBatch: { [key: string]: IAircraft } = {};
          for (const docId of chunkedData[i]) {
            dataToBatch[docId] = batchOperationsParams[field][docId];
          }

          switch (field) {
            case 'set':
              await this.aircraftService.batchOperations({
                set: dataToBatch
              });
              break;
            case 'update':
              await this.aircraftService.batchOperations({
                update: dataToBatch
              });
              break;
          }
        }
      }

      // Refresh airlines fleet
      if (airlineIds.length) {
        for (let i = 0; i < airlineIds.length; i++) {
          await this.loaderService.updateLoaderMessage(
            'Mise à jour de la flotte de la compagnie aérienne ' + (i + 1) + '/' + airlineIds.length
          );

          await this.airlineService.refreshFleet(airlineIds[i]);
        }
      }

      await this.loaderService.hideLoaderOnSuccess();
    } catch (err: any) {
      await this.loaderService.hideLoaderOnFailure(err);
    }
  }
}
