import { Injectable } from '@angular/core';
import { IPdfParserSetting } from 'src/app/interfaces/pdf-parser-setting.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class PdfParserSettingFirestoreService extends FirestoreService<IPdfParserSetting> {
  protected basePath = 'pdfParserSettings';
}
