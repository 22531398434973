import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentData,
  Query,
  QueryFn,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStoreData } from 'src/app/interfaces/store-data.interface';
import { AuthFirestoreService } from './auth-firestore.service';

export interface IBatchOperationsParams {
  set?: { [docId: string]: any };
  update?: { [docId: string]: any };
  delete?: { [docId: string]: any };
}

export interface IFindCondition {
  field: string;
  operator: firebase.firestore.WhereFilterOp;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export abstract class FirestoreService<T extends IStoreData> {
  protected currentUserUid: string;
  protected firstDocPaginated: {
    [paginationName: string]: firebase.firestore.DocumentData | null;
  } = {};
  protected lastDocPaginated: { [paginationName: string]: firebase.firestore.DocumentData | null } =
    {};
  protected abstract basePath: string;

  constructor(
    protected firestore: AngularFirestore,
    protected authFirestore: AuthFirestoreService
  ) {
    this.loadCurrentUserId();
  }

  private get collection(): AngularFirestoreCollection<T> {
    return this.firestore.collection(`${this.basePath}`);
  }

  loadCurrentUserId() {
    this.authFirestore.currentUserUid$.subscribe(uid => (this.currentUserUid = uid));
  }

  docValue$(id: string): Observable<T | null> {
    return this.firestore
      .doc<T>(`${this.basePath}/${id}`)
      .valueChanges()
      .pipe(
        map(result => {
          if (result) {
            result.id = id;
          }
          return this.timestampsToDate(result);
        })
      );
  }

  collectionValues$(queryFn?: QueryFn): Observable<T[]> {
    return this.firestore
      .collection<T>(`${this.basePath}`, queryFn)
      .valueChanges({
        idField: 'id'
      })
      .pipe(map(result => this.allTimestampsToDate(result)));
  }

  collectionValuesByIds$(ids: string[], fieldName: string = 'id'): Observable<T[]> {
    const observables = [];
    const chunk = 10;

    for (let i = 0; i < ids.length; i += chunk) {
      const chunkIds = ids.slice(i, i + chunk);
      observables.push(
        this.collectionValues$(ref =>
          ref.where(
            fieldName === 'id' ? firebase.firestore.FieldPath.documentId() : fieldName,
            'in',
            chunkIds
          )
        )
      );
    }

    if (observables.length > 1) {
      return zip(...observables).pipe(map(result => [].concat(...result)));
    } else if (observables.length === 1) {
      return observables[0];
    } else {
      return of([]);
    }
  }

  resetPaginate(paginationName: string = 'list'): void {
    this.firstDocPaginated[paginationName] = null;
    this.lastDocPaginated[paginationName] = null;
  }

  paginate(
    conditions: IFindCondition[],
    orderBy: { field: string; direction: 'asc' | 'desc' },
    itemsPerPage: number = 20,
    paginationName: string = 'list',
    direction: 'prev' | 'next' = 'next'
  ): Observable<T[]> {
    return this.firestore
      .collection<T>(`${this.basePath}`, ref => {
        let refQuery: Query<DocumentData> = orderBy
          ? ref.orderBy(orderBy.field, orderBy.direction)
          : ref;

        switch (direction) {
          case 'prev':
            if (this.firstDocPaginated[paginationName]) {
              refQuery = refQuery.endBefore(this.firstDocPaginated[paginationName]);
            }

            refQuery = refQuery.limitToLast(itemsPerPage);
            break;
          case 'next':
            if (this.lastDocPaginated[paginationName]) {
              refQuery = refQuery.startAfter(this.lastDocPaginated[paginationName]);
            }

            refQuery = refQuery.limit(itemsPerPage);
            break;
          default:
            refQuery = refQuery.limit(itemsPerPage);
            break;
        }

        for (const condition of conditions) {
          refQuery = refQuery.where(condition.field, condition.operator, condition.value);
        }

        return refQuery;
      })
      .get()
      .pipe(
        map((querySnapshot: QuerySnapshot<T>) => {
          const result: T[] = [];
          let firstDocAssigned: boolean = false;
          querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
            if (!firstDocAssigned) {
              this.firstDocPaginated[paginationName] = doc;
              firstDocAssigned = true;
            }
            this.lastDocPaginated[paginationName] = doc;

            const docData: T = doc.data();
            docData.id = doc.id;

            result.push(this.timestampsToDate(docData));
          });

          return result;
        })
      );
  }

  create(value: T): Promise<string> {
    const id = this.firestore.createId();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.removeUndefineds(value);
    return this.collection
      .doc(id)
      .set(
        Object.assign({}, value, {
          id,
          created: timestamp,
          updated: timestamp,
          createBy: this.currentUserUid ?? null,
          updateBy: this.currentUserUid ?? null
        })
      )
      .then(() => id);
  }

  createWithId(id: string, value: T): Promise<string> {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.removeUndefineds(value);
    return this.collection
      .doc(id)
      .set(
        Object.assign({}, value, {
          id,
          created: timestamp,
          updated: timestamp,
          createBy: this.currentUserUid ?? null,
          updateBy: this.currentUserUid ?? null
        })
      )
      .then(() => id);
  }

  update(patch: T): Promise<string> {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.removeUndefineds(patch);

    return this.collection
      .doc(patch.id)
      .update(
        Object.assign({}, patch, {
          created: patch.created
            ? firebase.firestore.Timestamp.fromDate(patch.created)
            : new Date(),
          updated: timestamp,
          updateBy: this.currentUserUid ?? null
        })
      )
      .then(() => patch.id);
  }

  delete(id: string): Promise<void> {
    return this.collection.doc(id).delete();
  }

  deleteWhere(queryFn: QueryFn): Promise<void> {
    return new Promise((resolve, reject) => {
      const sub = this.firestore
        .collection<T>(`${this.basePath}`, queryFn)
        .get()
        .subscribe(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
          const docsBy500: firebase.firestore.QueryDocumentSnapshot<T>[][] = this.chunk(
            querySnapshot.docs,
            500
          );

          for (const docs of docsBy500) {
            const batch = this.firestore.firestore.batch();

            for (const doc of docs) {
              batch.delete(doc.ref);
            }

            await batch.commit();
          }

          resolve();
        });
    });
  }

  paginateLiveChange(
    conditions: IFindCondition[],
    orderBy: { field: string; direction: 'asc' | 'desc' },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next',
    paginationName: string = 'list'
  ): Observable<T[]> {
    return this.firestore
      .collection<T>(`${this.basePath}`, ref => {
        let refQuery = ref.orderBy(orderBy.field, orderBy.direction).limit(itemsPerPage);

        for (const condition of conditions) {
          refQuery = refQuery.where(condition.field, condition.operator, condition.value);
        }

        if (this.lastDocPaginated[paginationName]) {
          refQuery = refQuery.startAfter(this.lastDocPaginated[paginationName]);
        }

        return refQuery;
      })
      .snapshotChanges()
      .pipe(
        map((documentChangeActions: DocumentChangeAction<T>[]) => {
          const result: T[] = [];
          for (const documentChangeAction of documentChangeActions) {
            this.lastDocPaginated[paginationName] = documentChangeAction.payload.doc;

            const docData = documentChangeAction.payload.doc.data();
            docData.id = documentChangeAction.payload.doc.id;

            result.push(this.timestampsToDate(docData));
          }

          return result;
        })
      );
  }

  chunk(array: any[], size: number): any[][] {
    const chunked_arr: any[][] = [];

    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }

    return chunked_arr;
  }

  getDocumentsFromDocId(docsId: string[]): Promise<T[]> {
    return new Promise(async (resolve, reject) => {
      // Remove duplicates for optimization
      docsId = docsId.filter((value, index, self) => self.indexOf(value) === index);

      const chunkedDocsId: string[][] = this.chunk(docsId, 10);

      let results: T[] = [];
      for (const docsIdChunked of chunkedDocsId) {
        results = results.concat(await this.getDocumentsFromDocIdUpTo10(docsIdChunked));
      }

      resolve(results);
    });
  }

  private getDocumentsFromDocIdUpTo10(docsId: string[]): Promise<T[]> {
    return new Promise((resolve, reject) => {
      return this.firestore
        .collection<T>(`${this.basePath}`, ref =>
          ref.where(firebase.firestore.FieldPath.documentId(), 'in', docsId)
        )
        .get()
        .pipe(
          map((querySnapshot: QuerySnapshot<T>) => {
            const result: T[] = [];
            querySnapshot.forEach((doc: firebase.firestore.DocumentData) => {
              const docData = doc.data();
              docData.id = doc.id;

              result.push(this.timestampsToDate(docData));
            });

            return result;
          })
        );
    });
  }

  private removeUndefineds(obj: { [key: string]: any }) {
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  }

  private allTimestampsToDate(allData: T[]): T[] {
    return allData.map(data => this.timestampsToDate(data));
  }

  timestampsToDate(data: any): any {
    for (const field in data) {
      if (data[field] && typeof data[field].seconds !== 'undefined') {
        data[field] = (data[field] as unknown as firebase.firestore.Timestamp).toDate();
      }
    }

    return data;
  }

  async batchOperations(batchOperationsParams: IBatchOperationsParams): Promise<void> {
    const sizeChunkBatch: number = 500;

    for (const typeOfOperation in batchOperationsParams) {
      const keys: string[] = Object.keys(batchOperationsParams[typeOfOperation]);

      if (keys.length) {
        const chunkedData: string[][] = this.chunk(keys, sizeChunkBatch);

        try {
          for (let i = 0; i < chunkedData.length; i++) {
            let batchNumber: number = sizeChunkBatch * i;
            console.log('init batch ' + batchNumber + '/' + chunkedData.length);

            const batch: firebase.firestore.WriteBatch = this.firestore.firestore.batch();

            for (const docId of chunkedData[i]) {
              const docRef = this.collection.doc(docId).ref;

              switch (typeOfOperation) {
                case 'set':
                  batch.set(docRef, batchOperationsParams[typeOfOperation][docId as any] as any);
                  break;
                case 'update':
                  batch.update(docRef, batchOperationsParams[typeOfOperation][docId as any] as any);
                  break;
                case 'delete':
                  batch.delete(docRef);
                  break;
              }
            }

            // Commit the batch
            await batch.commit();
            console.log('batch committed ' + batchNumber + '/' + chunkedData.length);
          }

          console.log('all ' + keys.length + ' items updated to collection ' + this.basePath);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
}
