<div class="text-end mb-4">
    <div class="btn-group">
        <button
            type="button"
            (click)="refreshFleet()"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.airlinesRefreshFleet)"
        >
            <fa-icon [icon]="faRefresh"></fa-icon> Rafraîchir flotte
        </button>
        <a
            [routerLink]="'/admin/aircrafts/add/' + airline?.id"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftsAdd) && airline && airline.isActive"
            >+ Nouvel avion</a
        >
    </div>
</div>

<ul class="nav nav-tabs justify-content-center" role="tablist">
    <li class="nav-item" *ngFor="let tab of tabs">
        <button
            type="button"
            class="nav-link"
            [class.active]="currentTab === tab.value"
            [attr.aria-current]="currentTab === tab.value ? 'page' : ''"
            [innerText]="tab.title"
            (click)="updateCurrentTab(tab.value)"
        ></button>
    </li>
</ul>

<div *ngIf="currentTab === EnumTab.compiledPassengers">
    <app-aircrafts-compiled-list-for-airline
        [airlineId]="airline.id"
        [isCargoOnly]="false"
        (openItem)="openAircraftsCompiled($event)"
    ></app-aircrafts-compiled-list-for-airline>
</div>

<div *ngIf="currentTab === EnumTab.compiledCargo">
    <app-aircrafts-compiled-list-for-airline
        [airlineId]="airline.id"
        [isCargoOnly]="true"
        (openItem)="openAircraftsCompiled($event)"
    ></app-aircrafts-compiled-list-for-airline>
</div>

<div *ngIf="currentTab === EnumTab.aircrafts">
    <app-aircrafts-list-for-airline [airlineId]="airline.id"></app-aircrafts-list-for-airline>
</div>

<div class="modal" tabindex="-1" role="dialog" #modalAircraftCompiled>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Liste des avions</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-hover mb-0">
                    <thead>
                        <tr>
                            <th scope="col">MSN</th>
                            <th scope="col">Modèle</th>
                            <th scope="col">Immatriculation</th>
                            <th scope="col">Date de livraison</th>
                            <th scope="col">Cargo ?</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="loadingAircrafts">
                        <tr *ngFor="let tr of [].constructor(5)">
                            <td *ngFor="let number of [].constructor(6)" class="placeholder-glow">
                                <div class="placeholder w-100"></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!loadingAircrafts">
                        <tr
                            *ngIf="
                                !listAircraftsOfCompiledAircrafts ||
                                !listAircraftsOfCompiledAircrafts.length
                            "
                            class="table-warning text-center"
                        >
                            <td [attr.colspan]="6">Aucun avion n'a été trouvé.</td>
                        </tr>
                        <tr
                            *ngFor="let aircraft of listAircraftsOfCompiledAircrafts"
                            data-bs-dismiss="modal"
                        >
                            <td [innerHtml]="aircraft.serialNumber"></td>
                            <td>
                                <a
                                    [href]="
                                        '/admin/aircraft-models/' +
                                        aircraftModelsObj[aircraft.aircraftModelId].id
                                    "
                                    [innerHtml]="aircraftModelsObj[aircraft.aircraftModelId].title"
                                    *ngIf="aircraftModelsObj[aircraft.aircraftModelId]"
                                ></a>
                                <span *ngIf="!aircraftModelsObj[aircraft.aircraftModelId]"></span>
                                <br /><span [innerHtml]="aircraft.type"></span>
                            </td>
                            <td [innerHtml]="aircraft.registration"></td>
                            <td
                                [innerHtml]="
                                    aircraft.firstFlightDate ? aircraft.firstFlightDate : 'Inconnu'
                                "
                            ></td>
                            <td [innerHtml]="aircraft.isCargo ? 'Oui' : 'Non'"></td>
                            <td>
                                <a
                                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                                    class="btn btn-primary btn-sm"
                                >
                                    <fa-icon [icon]="faEye"></fa-icon>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
