import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faEye, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AclService } from 'src/app/services/acl.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { RemoteService } from 'src/app/services/remote.service';

enum EnumTab {
  compiledPassengers = 'compiledPassengers',
  compiledCargo = 'compiledCargo',
  aircrafts = 'aircrafts'
}

@Component({
  selector: 'app-airline-fleet',
  templateUrl: './airline-fleet.component.html',
  styleUrls: ['./airline-fleet.component.scss']
})
export class AirlineFleetComponent implements OnInit, OnDestroy {
  @Input() airline: IAirline | null = null;
  @ViewChild('modalAircraftCompiled', { static: false }) modalAircraftCompiledElement: ElementRef;

  faRefresh = faRefresh;
  faEye = faEye;

  EnumAcl = EnumAcl;
  EnumEnquiryType = EnumEnquiryType;
  EnumTab = EnumTab;

  tabs: {
    title: string;
    value: EnumTab;
  }[] = [
    {
      title: 'Appareils pour passagers',
      value: EnumTab.compiledPassengers
    },
    {
      title: 'Appareils pour cargo',
      value: EnumTab.compiledCargo
    },
    {
      title: 'Tous les appareils aériens',
      value: EnumTab.aircrafts
    }
  ];
  currentTab: EnumTab = EnumTab.compiledPassengers;

  isLogged: boolean = false;
  aircraftModelsObj: { [id: string]: IAircraftModel } = {};
  listAircraftsOfCompiledAircrafts: IAircraft[] = [];
  loadingAircrafts: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private airlineService: AirlineService,
    private aclService: AclService,
    public paginationService: PaginationService,
    private remoteService: RemoteService
  ) {}

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalAircraftCompiledElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  async refreshFleet(): Promise<void> {
    if (this.airline) {
      this.loaderService.presentLoader();

      try {
        await this.airlineService.refreshFleet(this.airline.id);

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err: any) {
        await this.loaderService.hideLoaderOnFailure(err);
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  updateCurrentTab(tab: EnumTab): void {
    this.currentTab = tab;
  }

  async openAircraftCompiledModal(aircraftsId: string[]): Promise<void> {
    this.loadingAircrafts = true;

    this.listAircraftsOfCompiledAircrafts = [];

    window['$'](this.modalAircraftCompiledElement.nativeElement).modal('show');

    if (aircraftsId.length) {
      this.listAircraftsOfCompiledAircrafts = (await this.remoteService.getDocumentsFromDocId(
        'aircrafts',
        aircraftsId
      )) as IAircraft[];
    }

    this.loadingAircrafts = false;
  }

  openAircraftsCompiled(aircraftsCompiled: IAircraftCompiled): void {
    this.openAircraftCompiledModal(aircraftsCompiled.aircraftsId);
  }
}
