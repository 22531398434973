import { Component, OnInit } from '@angular/core';

import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { EncaissementTypeService } from 'src/app/services/encaissement-types/encaissement-types.service';
import { DataList } from 'src/app/components/data-list/data-list.class';
import {
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';

@Component({
  selector: 'app-list-encaissement-types',
  templateUrl: './list-encaissement-types.component.html',
  styleUrls: ['./list-encaissement-types.component.scss']
})
export class ListEncaissementTypesComponent extends DataList<IEncaissementType> implements OnInit {
  columns: IDataListColumn[] = [
    {
      title: 'Nom',
      field: 'name'
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'name', direction: 'asc' };

  EnumAcl = EnumAcl;

  constructor(
    private aclService: AclService,
    private encaissementTypeService: EncaissementTypeService
  ) {
    super();

    this.setDataService(this.encaissementTypeService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.encaissementTypesList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
