<div class="btn-toolbar mb-4 d-flex justify-content-between" role="toolbar">
    <div class="btn-group">
        <a
            routerLink="/admin/aircrafts/add"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftsAdd)"
            >+ Nouvel appareil</a
        >
        <a
            routerLink="/admin/aircrafts/import"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftsImport)"
            >Import</a
        >
    </div>
    <div class="btn-group">
        <a
            routerLink="/admin/aircraft-models"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftModelsList)"
            >Modèles d'avion</a
        >
        <a
            routerLink="/admin/aircrafts/list-no-capacity"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.aircraftsNoCapacity)"
            >Appareils sans capacité</a
        >
    </div>
</div>

<div class="row justify-content-between">
    <div class="col-md-6">
        <airline-input
            [disabled]="isSearchListing"
            (setValueToFormControl)="setValueToFormControl($event)"
            label="Filtrer par compagnie aérienne"
            inputNameAirlineId="airlineId"
            inputNameAirlineTitle="airlineTitle"
            inputNameAirlineCountryCode="airlineCountryCode"
            inputNameAirlineContinentCode="airlineContinentCode"
            inputNameAirlineCountryTitle="airlineCountryTitle"
            inputNameAirlineContinentTitle="airlineContinentTitle"
        ></airline-input>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label>Filtrer par modèle</label>
            <select
                [(ngModel)]="filters.aircraftModelId"
                class="form-select"
                (change)="fetchData()"
                [disabled]="isSearchListing"
            >
                <option value="">Tous les modèles</option>
                <option
                    [value]="aircraftModel.id"
                    *ngFor="let aircraftModel of aircraftModels"
                    [innerHtml]="aircraftModel.title"
                ></option>
            </select>
        </div>
        <div class="form-group mb-3">
            <input
                type="text"
                class="form-control"
                placeholder="Recherchez par aéroport d'attache..."
                aria-label="Recherche"
                required
                (keyup)="keyUp.next($event.srcElement.value)"
            />
        </div>
    </div>
</div>

<div class="card mb-4" *ngIf="filters.airlineId || filters.aircraftModelId || isSearchListing">
    <div class="table-responsive no-border">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="sortable"
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                        [class.is-sorted]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                            field.code
                        "
                        [class.asc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'asc'
                        "
                        [class.desc]="
                            paginationService.pagination[currentPagination].orderBy.field ==
                                field.code &&
                            paginationService.pagination[currentPagination].orderBy.direction ==
                                'desc'
                        "
                        (click)="paginationService.sortField(currentPagination, field.code)"
                        [innerHtml]="field.title"
                        [class.d-none]="
                            (field.code === 'airlineTitle' && filters.airlineId) ||
                            (field.code === 'type' && filters.aircraftModelId)
                        "
                    ></th>
                </tr>
            </thead>
            <tbody *ngIf="paginationService.loading">
                <tr *ngFor="let number of [].constructor(10)">
                    <td
                        *ngFor="let field of paginationService.pagination[currentPagination].fields"
                    >
                        <div class="placeholder w-100"></div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="isSearchListing">
                <tr *ngIf="!searchedAircrafts.length" class="table-warning text-center">
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length
                        "
                    >
                        Aucun appareil aérien n'a été trouvé.
                    </td>
                </tr>
                <tr
                    *ngFor="let aircraft of searchedAircrafts"
                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                    class="clickable"
                >
                    <td [innerHtml]="aircraft.airlineTitle" [class.d-none]="filters.airlineId"></td>
                    <td [innerHtml]="aircraft.serialNumber"></td>
                    <td [class.d-none]="filters.aircraftModelId">
                        {{
                            aircraftModelsObj[aircraft.model]
                                ? aircraftModelsObj[aircraft.model].title
                                : aircraft.modelTitle?.toUpperCase()
                        }}<br />
                        {{ aircraft.type }}
                    </td>
                    <td [innerHtml]="aircraft.registration"></td>
                    <td
                        [innerHtml]="
                            aircraft.firstFlightDate ? aircraft.firstFlightDate : 'Inconnu'
                        "
                    ></td>
                </tr>
            </tbody>
            <tbody *ngIf="!paginationService.loading && !isSearchListing">
                <tr
                    *ngIf="
                        !paginationService.pagination[currentPagination].data ||
                        !paginationService.pagination[currentPagination].data.length
                    "
                    class="table-warning text-center"
                >
                    <td
                        [attr.colspan]="
                            paginationService.pagination[currentPagination].fields.length
                        "
                    >
                        Aucun avion n'a été trouvé.
                    </td>
                </tr>
                <tr
                    *ngFor="let aircraft of paginationService.pagination[currentPagination].data"
                    [routerLink]="'/admin/aircrafts/' + aircraft.id"
                    class="clickable"
                >
                    <td [innerHtml]="aircraft.airlineTitle" [class.d-none]="filters.airlineId"></td>
                    <td [innerHtml]="aircraft.serialNumber"></td>
                    <td [class.d-none]="filters.aircraftModelId">
                        {{
                            aircraftModelsObj[aircraft.model]
                                ? aircraftModelsObj[aircraft.model].title
                                : aircraft.modelTitle?.toUpperCase()
                        }}<br />
                        {{ aircraft.type }}
                    </td>
                    <td [innerHtml]="aircraft.registration"></td>
                    <td
                        [innerHtml]="
                            aircraft.firstFlightDate ? aircraft.firstFlightDate : 'Inconnu'
                        "
                    ></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<nav
    aria-label="Liste navigation"
    class="mt-4 mb-4"
    *ngIf="
        !paginationService.loading &&
        paginationService.pagination[currentPagination].data &&
        paginationService.pagination[currentPagination].data.length &&
        !isSearchListing
    "
>
    <ul class="pagination justify-content-center">
        <li
            class="page-item"
            [class.disabled]="paginationService.pagination[currentPagination].currentPage === 0"
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadPreviousPage(currentPagination)"
            >
                Précédent
            </button>
        </li>
        <li class="page-item disabled">
            <span
                class="page-link"
                [innerHtml]="paginationService.pagination[currentPagination].currentPage + 1"
            ></span>
        </li>
        <li
            class="page-item"
            [class.disabled]="
                paginationService.pagination[currentPagination].data &&
                (!paginationService.pagination[currentPagination].data.length ||
                    paginationService.pagination[currentPagination].data.length <
                        paginationService.pagination[currentPagination].limit)
            "
        >
            <button
                type="button"
                class="page-link"
                (click)="paginationService.loadNextPage(currentPagination)"
            >
                Suivant
            </button>
        </li>
    </ul>
</nav>
