import { Component, OnInit } from '@angular/core';

import { RemoteService } from '../../services/remote.service';
import { AclService } from '../../services/acl.service';

import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  EnumInvoiceType = EnumInvoiceType;

  currentUser: IUser;
  userId: string;
  usersObj: { [key: string]: IUser } = {};
  clientsObj: { [key: string]: IPipedriveOrganization | null } = {};
  enquiriesObj: { [key: string]: IEnquiry } = {};
  userGroup: IUserGroup | null = null;
  enableEnquiriesTab: boolean = false;
  enableInvoicesTab: boolean = false;
  tabs: {
    title: string;
    value: string;
    paginationName?: string;
  }[] = [];
  activeTabIndex: number = 0;

  EnumAcl = EnumAcl;

  constructor(private remoteService: RemoteService, private aclService: AclService) {
    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      this.userGroup = userGroup;

      this.loadData();
    });
    this.remoteService.userObservable.subscribe((user: IUser) => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    if (this.userGroup) {
      this.tabs = [];

      if (this.hasAclAccess(EnumAcl.enquiriesDashboardList)) {
        this.enableEnquiriesTab = true;
        this.tabs.push({
          title: 'Demandes',
          value: 'enquiries',
          paginationName: 'enquiries-list'
        });
      }

      if (this.hasAclAccess(EnumAcl.invoicesDashboardList)) {
        this.enableInvoicesTab = true;
        this.tabs.push({
          title: "Vue d'ensemble",
          value: 'invoices-all-without-proforma',
          paginationName: 'invoices-dashboard-all-without-proforma-list'
        });
        this.tabs.push({
          title: 'Proformas',
          value: 'invoices-proforma',
          paginationName: 'invoices-dashboard-proforma-list'
        });

        this.tabs.push({
          title: 'Factures',
          value: 'invoices-definitive',
          paginationName: 'invoices-dashboard-definitive-list'
        });

        this.tabs.push({
          title: 'Avoirs',
          value: 'invoices-credit-note',
          paginationName: 'invoices-dashboard-credit-note-list'
        });

        this.tabs.push({
          title: 'Factures annulées',
          value: 'invoices-cancelled',
          paginationName: 'invoices-dashboard-cancelled-list'
        });
        this.tabs.push({
          title: "Factures d'achats",
          value: 'invoices-purchase',
          paginationName: 'invoices-dashboard-purchase-list'
        });
      }

      this.selectActiveTab(0);
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  selectActiveTab(tabIndex: number): void {
    if (this.tabs[tabIndex]) {
      this.activeTabIndex = tabIndex;
    }
  }
}
