import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { PdfParserSettingFirestoreService } from './pdf-parser-settings-firestore.service';
import { IPdfParserSetting } from 'src/app/interfaces/pdf-parser-setting.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class PdfParserSettingService {
  constructor(private pdfParserSettingFirestoreService: PdfParserSettingFirestoreService) {}

  create(IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.pdfParserSettingFirestoreService.create(IPdfParserSetting);
  }

  createWithId(id: string, IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.pdfParserSettingFirestoreService.createWithId(id, IPdfParserSetting);
  }

  update(IPdfParserSetting: IPdfParserSetting): Promise<string> {
    return this.pdfParserSettingFirestoreService.update(IPdfParserSetting);
  }

  delete(id: string): Promise<void> {
    return this.pdfParserSettingFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IPdfParserSetting> {
    return this.pdfParserSettingFirestoreService.docValue$(id);
  }

  getAll(): Observable<IPdfParserSetting[]> {
    return this.pdfParserSettingFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.pdfParserSettingFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IPdfParserSetting[]> {
    return this.pdfParserSettingFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
