import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { InvoiceFirestoreService } from './invoices-firestore.service';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { IBatchOperationsParams, IFindCondition } from '../firestore/firestore.service';
import { FunctionsService } from '../functions/functions.service';
import { IPdfExtractData } from 'src/app/interfaces/pdf-parsed-data.interface';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(
    private invoiceFirestoreService: InvoiceFirestoreService,
    private functionsService: FunctionsService
  ) {}

  create(invoice: IInvoice): Promise<string> {
    return this.invoiceFirestoreService.create(invoice);
  }

  update(invoice: IInvoice): Promise<string> {
    return this.invoiceFirestoreService.update(invoice);
  }

  delete(id: string): Promise<void> {
    return this.invoiceFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IInvoice> {
    return this.invoiceFirestoreService.docValue$(id);
  }

  getAll(): Observable<IInvoice[]> {
    return this.invoiceFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IInvoice[]> {
    return this.invoiceFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId).orderBy('created', 'desc')
    );
  }

  getAllForEnquiryForType(enquiryId: string, type: EnumInvoiceType): Observable<IInvoice[]> {
    return this.invoiceFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId).where('type', '==', type)
    );
  }

  getAllNonProformaForEnquiry(enquiryId: string): Observable<IInvoice[]> {
    return this.invoiceFirestoreService.collectionValues$(ref =>
      ref
        .where('enquiryId', '==', enquiryId)
        .where('status', 'in', [EnumInvoiceType.creditNote, EnumInvoiceType.definitive])
        .orderBy('created', 'asc')
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.invoiceFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IInvoice[]> {
    return this.invoiceFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  batchOperations(batchOperationsParams: IBatchOperationsParams): Promise<void> {
    return this.invoiceFirestoreService.batchOperations(batchOperationsParams);
  }

  parsePdfURL(pdfUrl: string): Promise<IPdfExtractData> {
    return this.functionsService.callFunctionAsPromise('parsePdfURL', { pdfUrl });
  }

  getInvoicePdfUrl(invoiceId: string): Promise<string | null> {
    return this.functionsService.callFunctionAsPromise('getSignedUrlForInvoicePdf', {
      id: invoiceId
    });
  }

  regenerateInvoicePdf(invoiceId: string): Promise<string | null> {
    return this.functionsService.callFunctionAsPromise('regenerateInvoicePdf', {
      id: invoiceId
    });
  }
}
