import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import {
  EnumExportRequestStatus,
  IExportRequest
} from 'src/app/interfaces/export-request.interface';
import { ExportRequestsFirestoreService } from './export-requests-firestore.service';
import { FunctionsService } from '../functions/functions.service';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ExportRequestsService {
  constructor(
    private exportRequestsFirestoreService: ExportRequestsFirestoreService,
    private functionsService: FunctionsService
  ) {}

  create(exportRequest: IExportRequest): Promise<string> {
    exportRequest.status = EnumExportRequestStatus.requested;

    return this.exportRequestsFirestoreService.create(exportRequest);
  }

  update(exportRequest: IExportRequest): Promise<string> {
    return this.exportRequestsFirestoreService.update(exportRequest);
  }

  delete(id: string): Promise<void> {
    return this.exportRequestsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IExportRequest> {
    return this.exportRequestsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IExportRequest[]> {
    return this.exportRequestsFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.exportRequestsFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IExportRequest[]> {
    return this.exportRequestsFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getDownloadUrl(exportRequestId: string): Promise<string | null> {
    return this.functionsService.callFunctionAsPromise('getSignedUrlForExportRequest', {
      id: exportRequestId
    });
  }
}
