import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { faArrowLeft, faExternalLink, faEye } from '@fortawesome/free-solid-svg-icons';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { EnumAircraftClass, IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';

@Component({
  selector: 'app-list-aircrafts-no-capacity',
  templateUrl: './list-aircrafts-no-capacity.component.html',
  styleUrls: ['./list-aircrafts-no-capacity.component.scss']
})
export class ListAircraftsNoCapacityComponent implements OnInit, OnDestroy {
  faArrowLeft = faArrowLeft;
  faExternalLink = faExternalLink;
  faEye = faEye;

  isLogged: boolean = false;
  selectedCountryCode: string | null = null;
  selectedAirlineId: string | null = null;

  EnumAcl = EnumAcl;
  loading: boolean = false;
  loadingMore: boolean = false;
  hasNoMore: boolean = false;

  aircraftModelsObj: { [id: string]: IAircraftModel } = {};
  aircraftModels: IAircraftModel[] = [];

  aircrafts: IAircraft[] = [];

  EnumAircraftClass = EnumAircraftClass;

  aircraftStatusChanges: { [aircraftId: string]: null | 'to-save' | 'saving' } = {};

  private subscription = new Subscription();

  constructor(
    public remoteService: RemoteService,
    private aclService: AclService,
    private aircraftService: AircraftService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        this.aircraftModels = aircraftModels;

        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.aircraftsNoCapacity);

    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.fetchData();

      await this.remoteService.loadAircraftModels();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  fetchData(loadMore: boolean = false): void {
    if (this.selectedAirlineId) {
      this.loading = true;
      this.aircrafts = [];

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = new Subscription();

      this.subscription.add(
        this.aircraftService
          .getAllForAirlineWithZeroCapacity(this.selectedAirlineId)
          .subscribe((aircrafts: IAircraft[]) => {
            this.aircrafts = aircrafts;

            this.loading = false;
          })
      );
    } else if (this.selectedCountryCode) {
      const itemsPerPage: number = 20;

      if (!loadMore) {
        this.hasNoMore = false;
        this.loading = true;
        this.aircraftService.resetPagination('list');
        this.aircrafts = [];

        if (this.subscription) {
          this.subscription.unsubscribe();
        }

        this.subscription = new Subscription();
      } else {
        this.loadingMore = true;
      }

      this.subscription.add(
        this.aircraftService
          .getAllForAirlineCountryCodeWithZeroCapacity(
            'list',
            this.selectedCountryCode,
            itemsPerPage
          )
          .subscribe((aircrafts: IAircraft[]) => {
            if (aircrafts.length < itemsPerPage) {
              this.hasNoMore = true;
            }

            this.aircrafts = this.aircrafts.concat(aircrafts);

            this.loading = false;
            this.loadingMore = false;
          })
      );
    }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      switch (field.name) {
        case 'airlineId':
          this.selectedAirlineId = field.value;
          break;
        case 'airlineCountryCode':
          this.selectedCountryCode = field.value;
          break;
      }
    }

    this.fetchData();
  }

  updatedAircraftSeat(aircraft: IAircraft): void {
    let seatTotal: number = 0;
    const classes: EnumAircraftClass[] = [];
    for (const field of ['seatEconomy', 'seatPremiumEconomy', 'seatBusiness', 'seatFirst']) {
      if (aircraft[field] > 0) {
        seatTotal += aircraft[field];

        switch (field) {
          case 'seatEconomy':
            classes.push(EnumAircraftClass.economy);
            break;
          case 'seatPremiumEconomy':
            classes.push(EnumAircraftClass.premiumEconomy);
            break;
          case 'seatBusiness':
            classes.push(EnumAircraftClass.business);
            break;
          case 'seatFirst':
            classes.push(EnumAircraftClass.first);
            break;
        }
      }
    }

    if (seatTotal !== aircraft.seatTotal) {
      aircraft.seatTotal = seatTotal;
      aircraft.classes = classes;

      this.aircraftStatusChanges[aircraft.id] = 'to-save';
    }
  }

  async saveAircraft(aircraft: IAircraft): Promise<void> {
    this.aircraftStatusChanges[aircraft.id] = 'saving';

    try {
      await this.aircraftService.update(aircraft);

      delete this.aircraftStatusChanges[aircraft.id];
    } catch (err) {
      console.log(err);
    }
  }
}
