import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { Subscription } from 'rxjs';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';

@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.scss']
})
export class BankAccountEditComponent implements OnInit, OnDestroy {
  EnumCurrency = EnumCurrency;
  EnumAcl = EnumAcl;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  bankAccount: IBankAccount;
  bankAccountId: string;

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private bankAccountService: BankAccountService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      accountLabel: ['', [Validators.required]],
      iban: ['', [Validators.required]],
      bic: ['', [Validators.required]],
      defaultCurrency: ['', [Validators.required]],
      enableForInvoicing: [true, Validators.required]
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.bankAccountId = this.activatedRoute.snapshot.paramMap.get('bankAccountId');

      if (this.bankAccountId) {
        await this.aclService.checkAclAccess(EnumAcl.bankAccountsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.bankAccountsAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadBankAccount();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadBankAccount(): void {
    this.subscriptions.add(
      this.bankAccountService
        .getFromId(this.bankAccountId)
        .subscribe((bankAccount: IBankAccount) => {
          this.bankAccount = bankAccount;

          this.setBankAccount();
        })
    );
  }

  setBankAccount(): void {
    if (this.form && this.bankAccount) {
      this.form.get('name').setValue(this.bankAccount.name);
      this.form.get('accountLabel').setValue(this.bankAccount.accountLabel);
      this.form.get('iban').setValue(this.bankAccount.iban);
      this.form.get('bic').setValue(this.bankAccount.bic);
      this.form.get('defaultCurrency').setValue(this.bankAccount.defaultCurrency);
      this.form.get('enableForInvoicing').setValue(this.bankAccount.enableForInvoicing);

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.bankAccountId) {
        data.id = this.bankAccountId;
        promise = () => this.bankAccountService.update(data);
      } else {
        promise = () => this.bankAccountService.create(data);
      }

      promise()
        .then(async id => {
          if (!this.bankAccountId) {
            this.bankAccountId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/bank-accounts/' + this.bankAccountId]);
  }
}
