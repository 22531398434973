import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';
import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';

export interface ICurrentBreadcrumbItem {
  id: string;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  currentItem: ICurrentBreadcrumbItem | null = null;
  currentItemBehavior = new BehaviorSubject<ICurrentBreadcrumbItem | null>(null);
  currentItemObservable: Observable<ICurrentBreadcrumbItem | null> =
    this.currentItemBehavior.asObservable();

  manualBreadcrumbItems: IBreadcrumbLink[] = [];
  manualBreadcrumbItemsBehavior = new BehaviorSubject<IBreadcrumbLink[]>([]);
  manualBreadcrumbItemsObservable: Observable<IBreadcrumbLink[]> =
    this.manualBreadcrumbItemsBehavior.asObservable();

  constructor() {
    this.currentItemObservable.subscribe(
      (currentItem: ICurrentBreadcrumbItem | null) => (this.currentItem = currentItem)
    );

    this.manualBreadcrumbItemsObservable.subscribe(
      (manualBreadcrumbItems: IBreadcrumbLink[]) =>
        (this.manualBreadcrumbItems = manualBreadcrumbItems)
    );
  }

  setCurrentItem(currentItem: ICurrentBreadcrumbItem | null): void {
    this.currentItemBehavior.next(currentItem);
  }

  setManualBreadcrumbItems(manualBreadcrumbItems: IBreadcrumbLink[]): void {
    this.manualBreadcrumbItemsBehavior.next(manualBreadcrumbItems);
  }
}
