import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { isAfter } from 'date-fns';
import { IInvoice } from 'src/app/interfaces/invoice.interface';
import { formatPrice } from 'src/app/misc.utils';
import { AlgoliaService } from 'src/app/services/algolia.service';
import { RemoteService } from 'src/app/services/remote.service';

@Component({
  selector: 'app-invoice-ref-picker',
  templateUrl: './invoice-ref-picker.component.html',
  styleUrls: ['./invoice-ref-picker.component.scss']
})
export class InvoiceRefPickerComponent implements OnChanges {
  @Input('isInvalid') isInvalid: boolean;
  @Input('inputName') inputName: string;
  @Input('disabled') disabled: boolean = false;
  @Input('clearOnSelect') clearOnSelect: boolean = false;
  @Input('defaultValue') defaultValue: string;
  @Input('hidePaidInvoice') hidePaidInvoice: boolean = false;
  @Output() setValueToFormControl: EventEmitter<{
    fields: Array<{
      name: string;
      value: string;
    }>;
  }> = new EventEmitter();
  value: string;
  selectedInvoice: IInvoice | null = null;
  invoices: IInvoice[] = [];
  optionsSuggested: IInvoice[] = [];
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  // initiated: boolean = false

  formatPrice = formatPrice;

  faXmark = faXmark;
  faSearch = faSearch;

  query: string;

  constructor(private algoliaService: AlgoliaService, private remoteService: RemoteService) {}

  ngOnChanges() {
    // if (!this.initiated && this.defaultId) {
    this.value = this.defaultValue;

    // this.initiated = true
    // }

    this.updateSelectedInvoice();
  }

  async updateSelectedInvoice(): Promise<void> {
    if (this.value) {
      const invoiceDoc = await this.remoteService.getDocument('invoices', this.value);

      if (invoiceDoc) {
        const invoice: IInvoice = invoiceDoc as IInvoice;

        this.invoices.push(invoice);
      }
    }

    for (const invoice of this.invoices) {
      if (invoice.id == this.value) {
        this.selectedInvoice = invoice;

        break;
      }
    }
  }

  updateFormValue(selectedInvoice: IInvoice | null = null): void {
    this.value = selectedInvoice ? selectedInvoice.id : null;

    this.updateSelectedInvoice();

    this.setValueToFormControl.emit({
      fields: [
        {
          name: this.inputName,
          value: this.value
        }
      ]
    });

    this.optionsSuggested = [];
    this.query = '';

    if (this.clearOnSelect) {
      this.value = null;
    }
  }

  async updateAutocomplete(): Promise<void> {
    this.optionsSuggested = [];

    if (this.query && this.query.length > 2) {
      try {
        this.invoices = await this.algoliaService.searchInvoices(
          this.query,
          ['ref'],
          this.hidePaidInvoice
            ? [
                {
                  field: 'isFullyPaid',
                  type: 'boolean',
                  operator: '=',
                  value: false
                }
              ]
            : []
        );

        this.optionsSuggested = this.invoices;

        this.optionsSuggested.sort((a, b) => (isAfter(a.created, b.created) ? -1 : 1));

        if (this.optionsSuggested.length === 1) {
          this.updateFormValue(this.optionsSuggested[0]);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  clearValue(): void {
    this.query = '';

    this.updateAutocomplete();
    this.updateFormValue(null);

    setTimeout(() => {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    });
  }
}
