import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { PipedriveService } from 'src/app/services/pipedrive.service';

import countries from '../../../countries_fr.json';
import { getEnquiryBreadcrumbTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumInvoiceType } from 'src/app/enums/invoice-type.enum';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumInvoiceStatus, getEnumInvoiceStatusLabel } from 'src/app/enums/invoice-status.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import {
  getInvoiceDaysLate,
  getInvoiceTitle,
  IInvoice
} from 'src/app/interfaces/invoice.interface';

import * as PDFObject from 'pdfobject';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import {
  faCheck,
  faEdit,
  faExternalLink,
  faFileInvoice,
  faPlus,
  faReceipt,
  faRefresh,
  faTrash,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { formatPrice } from 'src/app/misc.utils';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild('pdfViewerEmbed', { static: false }) pdfViewerEmbedElement: ElementRef;

  getEnumInvoiceStatusLabel = getEnumInvoiceStatusLabel;
  formatPrice = formatPrice;
  getInvoiceDaysLate = getInvoiceDaysLate;
  getInvoiceTitle = getInvoiceTitle;

  faRefresh = faRefresh;
  faFileInvoice = faFileInvoice;
  faReceipt = faReceipt;
  faTrash = faTrash;
  faEdit = faEdit;
  faExternalLink = faExternalLink;
  faPlus = faPlus;
  faCheck = faCheck;
  faWarning = faWarning;

  EnumInvoiceType = EnumInvoiceType;
  EnumInvoiceStatus = EnumInvoiceStatus;
  EnumAcl = EnumAcl;

  loading: boolean = false;
  invoiceId: string;
  invoice: IInvoice | null = null;
  isLogged: boolean = false;
  enquiry: IEnquiry;
  loadingClient: boolean = false;
  client: IPipedriveOrganization | null = null;
  requestingUser: IUser | null = null;
  loadingRequestingUser: boolean = false;

  generatingPdf: boolean = false;

  usersObj: { [key: string]: IUser } = {};
  userGroup: IUserGroup | null = null;
  countries = countries;

  referalInvoice: IInvoice | null = null;
  loadingReferalInvoice: boolean = false;
  followingInvoice: IInvoice | null = null;
  loadingFollowingInvoice: boolean = false;

  sentToClientList: {
    title: string;
    value: boolean;
  }[] = [
    {
      title: 'Oui',
      value: true
    },
    {
      title: 'Non',
      value: false
    }
  ];
  bankAccount: IBankAccount | null = null;
  encaissements: IEncaissement[] = [];
  loadingEncaissements: boolean = false;

  loadingGeneratedPdf: boolean = false;

  generatedPdfUrl: string | null = null;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private pipedriveService: PipedriveService,
    private enquiryService: EnquiryService,
    private encaissementService: EncaissementService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService,
    private bankAccountService: BankAccountService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userGroupObservable.subscribe(
      (userGroup: IUserGroup | null) => (this.userGroup = userGroup)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');

      this.loadData();

      this.setBreadcrumbsItems();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getInvoiceStatuses(): EnumInvoiceStatus[] {
    return Object.values(EnumInvoiceStatus);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadInvoice();
      this.loadEncaissements();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadInvoice(): Promise<void> {
    if (this.invoiceId) {
      this.loading = true;

      this.subscriptions.add(
        this.invoiceService.getFromId(this.invoiceId).subscribe(async (invoice: IInvoice) => {
          this.invoice = invoice;

          if (this.invoice) {
            this.setBreadcrumbsItems();

            await this.loadClient();

            if (this.invoice.enquiryId) {
              this.loadEnquiry();
            }
            this.loadRequestingUser();
            this.loadReferralInvoice();
            this.loadFollowingInvoice();

            this.loadBankAccount();

            setTimeout(() => {
              this.initPDFViewer();
            }, 1000);
          }

          this.loading = false;
        })
      );
    }
  }

  loadReferralInvoice(): void {
    if (this.invoice?.referalInvoiceId) {
      this.loadingReferalInvoice = true;

      this.subscriptions.add(
        this.invoiceService
          .getFromId(this.invoice.referalInvoiceId)
          .subscribe((referalInvoice: IInvoice | null) => {
            this.referalInvoice = referalInvoice;

            this.loadingReferalInvoice = false;
          })
      );
    }
  }

  loadFollowingInvoice(): void {
    if (this.invoice?.followingInvoiceId) {
      this.loadingFollowingInvoice = true;

      this.subscriptions.add(
        this.invoiceService
          .getFromId(this.invoice.followingInvoiceId)
          .subscribe((followingInvoice: IInvoice | null) => {
            this.followingInvoice = followingInvoice;

            this.loadingFollowingInvoice = false;
          })
      );
    }
  }

  loadEnquiry(): void {
    if (this.invoice.enquiryId) {
      this.subscriptions.add(
        this.enquiryService
          .getFromId(this.invoice.enquiryId)
          .subscribe(async (enquiry: IEnquiry) => {
            this.enquiry = enquiry;

            this.setBreadcrumbsItems();

            await this.loadUsers();
          })
      );
    }
  }

  async loadUsers(): Promise<void> {
    const usersId: string[] = [];

    if (this.enquiry) {
      for (const field of ['receivedBy', 'processedBy']) {
        if (usersId.indexOf(this.enquiry[field]) === -1) {
          usersId.push(this.enquiry[field]);
        }
      }
    }

    if (usersId.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId('users', usersId);

      for (const doc of docs) {
        this.usersObj[doc['id']] = doc as IUser;
      }
    }
  }

  async delete(): Promise<void> {
    if (this.hasAclAccess(EnumAcl.invoicesDelete)) {
      const result = confirm(
        'La suppression de la facture sera permanente. Êtes-vous sûr de vouloir continuer ?'
      );

      if (result) {
        this.loaderService.presentLoader();

        const enquiryId: string | null = this.invoice?.enquiryId || null;

        try {
          await this.invoiceService.delete(this.invoiceId);

          await this.loaderService.hideLoaderOnSuccess();

          if (enquiryId) {
            this.router.navigate(['/admin/invoices/enquiry/' + enquiryId]);
          } else {
            this.router.navigate(['/admin/invoices']);
          }
        } catch (err) {
          await this.loaderService.hideLoaderOnFailure(err);
        }
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  changeStatus(newStatus: string): void {
    if (this.hasAclAccess(EnumAcl.invoicesSwitchStatus)) {
      this.loaderService.presentLoader();

      this.remoteService
        .updateDocumentToCollection('invoices', this.invoiceId, {
          status: newStatus
        })
        .then(async () => {
          await this.loadInvoice();

          await this.loaderService.hideLoaderOnSuccess();
        })
        .catch(async err => {
          await this.loaderService.hideLoaderOnFailure(err);
        });
    }
  }

  changeSentToClient(sentToClient: boolean): void {
    this.loaderService.presentLoader();

    this.remoteService
      .updateDocumentToCollection('invoices', this.invoiceId, {
        sentToClient: sentToClient
      })
      .then(async () => {
        await this.loadInvoice();

        await this.loaderService.hideLoaderOnSuccess();
      })
      .catch(async err => {
        await this.loaderService.hideLoaderOnFailure(err);
      });
  }

  async loadClient(): Promise<void> {
    if (this.invoice.clientId) {
      this.loadingClient = true;

      this.client = await this.pipedriveService.getOrganization(this.invoice.clientId);

      this.loadingClient = false;
    }
  }

  loadRequestingUser(): void {
    if (this.invoice.requestedBy) {
      this.loadingRequestingUser = true;

      this.subscriptions.add(
        this.userService.getFromId(this.invoice.requestedBy).subscribe(async (user: IUser) => {
          this.requestingUser = user;

          this.loadingRequestingUser = false;
        })
      );
    }
  }

  downloadPdf(): void {
    if (this.generatedPdfUrl) {
      window.open(this.generatedPdfUrl, '_blank');
    }
  }

  loadBankAccount(): void {
    if (this.invoice?.bankAccount) {
      this.subscriptions.add(
        this.bankAccountService
          .getFromId(this.invoice.bankAccount)
          .subscribe((bankAccount: IBankAccount | null) => {
            this.bankAccount = bankAccount;
          })
      );
    }
  }

  loadEncaissements(): void {
    if (this.invoiceId) {
      this.loadingEncaissements = true;

      this.subscriptions.add(
        this.encaissementService
          .getAllForInvoice(this.invoiceId)
          .subscribe((encaissements: IEncaissement[]) => {
            this.encaissements = encaissements;

            this.loadingEncaissements = false;
          })
      );
    }
  }

  async initPDFViewer(): Promise<void> {
    if (this.pdfViewerEmbedElement?.nativeElement && this.invoice) {
      this.loadingGeneratedPdf = true;

      this.generatedPdfUrl = await this.invoiceService.getInvoicePdfUrl(this.invoice.id);

      if (!this.generatedPdfUrl) {
        this.generatedPdfUrl = await this.invoiceService.regenerateInvoicePdf(this.invoice.id);
      }

      this.loadingGeneratedPdf = false;

      if (this.generatedPdfUrl && this.pdfViewerEmbedElement) {
        setTimeout(() => {
          PDFObject.embed(this.generatedPdfUrl, this.pdfViewerEmbedElement.nativeElement);
        }, 300);
      }
    }
  }

  async regenerateInvoicePdf(): Promise<void> {
    this.loaderService.presentLoader();

    this.generatedPdfUrl = await this.invoiceService.regenerateInvoicePdf(this.invoice.id);

    if (this.generatedPdfUrl) {
      setTimeout(() => {
        PDFObject.embed(this.generatedPdfUrl, this.pdfViewerEmbedElement.nativeElement);
      }, 300);
    }

    await this.loaderService.hideLoaderOnSuccess();
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    if (this.enquiry) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        text: getEnquiryBreadcrumbTitle(this.enquiry),
        url: '/admin/enquiries/' + this.enquiry.id
      });
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices/enquiry/' + this.enquiry.id
      });
    } else if (!this.enquiry && this.invoice?.enquiryId) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices/enquiry/' + this.invoice.enquiryId
      });
    } else {
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices'
      });
    }

    if (this.invoice) {
      breadcrumbItems.push({
        text: getInvoiceTitle(this.invoice),
        url: '/admin/invoices/' + this.invoice.id
      });
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }
}
