import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import {
  EnumAcl,
  EnumGroupAcl,
  getAclTitle,
  getChildrenAclForGroup,
  getGroupAclTitle
} from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { UserService } from 'src/app/services/user/user.service';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-group-view',
  templateUrl: './user-group-view.component.html',
  styleUrls: ['./user-group-view.component.scss']
})
export class UserGroupViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  loading: boolean = false;
  userGroupId: string;
  userGroup: IUserGroup | null = null;
  isLogged: boolean = false;
  aclList: {
    title: string;
    id: string;
    checked: boolean;
    children: {
      title: string;
      value: string;
      checked: boolean;
    }[];
  }[] = [];

  loadingUsers: boolean = false;
  users: IUser[] = [];

  getUserFullname = getUserFullname;

  private subscription = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private userService: UserService
  ) {
    for (const groupAcl of Object.values(EnumGroupAcl)) {
      const newAclModule: {
        title: string;
        id: string;
        checked: boolean;
        children: Array<{
          title: string;
          value: string;
          checked: boolean;
        }>;
      } = {
        title: getGroupAclTitle(groupAcl),
        id: groupAcl,
        checked: false,
        children: []
      };

      for (const acl of getChildrenAclForGroup(groupAcl)) {
        newAclModule.children.push({
          title: getAclTitle(acl),
          value: acl,
          checked: false
        });
      }

      this.aclList.push(newAclModule);
    }

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.userGroupId = this.activatedRoute.snapshot.paramMap.get('userGroupId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadUserGroup();
      this.loadUsers();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadUserGroup(): Promise<void> {
    this.loading = true;

    const doc = await this.remoteService.getDocument('userGroups', this.userGroupId);

    this.userGroup = doc as IUserGroup;

    for (const rule of this.userGroup.rules) {
      const ruleId: Array<string> = rule.id.split('.');

      if (ruleId.length === 2) {
        for (const aclModule of this.aclList) {
          if (aclModule.id === ruleId[0]) {
            for (const child of aclModule.children) {
              if (child.value === aclModule.id + '.' + ruleId[1]) {
                child.checked = rule.access;
                break;
              }
            }

            this.updateParentModuleChecked(aclModule.id);
            break;
          }
        }
      }
    }

    this.loading = false;
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.userGroupsDelete)) {
      const result = confirm(
        "La suppression d'un groupe d'utilisateurs sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.remoteService
          .deleteDocumentInCollection('userGroups', this.userGroupId)
          .then(() => {
            this.router.navigate(['/admin/user-groups']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  updateParentModuleChecked(moduleId): void {
    for (const aclModule of this.aclList) {
      if (aclModule.id === moduleId) {
        let hasOneChildCheck: boolean = false;
        for (const child of aclModule.children) {
          if (child.checked) {
            hasOneChildCheck = true;
            break;
          }
        }

        aclModule.checked = hasOneChildCheck;
        break;
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadUsers(): void {
    if (this.userGroupId) {
      this.loadingUsers = true;

      this.subscription.add(
        this.userService.getAllFromGroup(this.userGroupId).subscribe((users: IUser[]) => {
          this.users = users;

          this.loadingUsers = false;
        })
      );
    }
  }
}
