import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { FlightBriefsListForClientComponent } from './flight-briefs-list-for-client.component';
import { DataListModule } from '../data-list/data-list.module';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, RouterModule, DataListModule],
  declarations: [FlightBriefsListForClientComponent],
  exports: [FlightBriefsListForClientComponent]
})
export class FlightBriefsListForClientModule {}
