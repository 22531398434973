import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { CrewFirestoreService } from './crews-firestore.service';
import { ICrew } from 'src/app/interfaces/crew.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CrewService {
  constructor(private crewFirestoreService: CrewFirestoreService) {}

  create(Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.create(Crew);
  }

  createWithId(id: string, Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.createWithId(id, Crew);
  }

  update(Crew: ICrew): Promise<string> {
    return this.crewFirestoreService.update(Crew);
  }

  delete(id: string): Promise<void> {
    return this.crewFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ICrew> {
    return this.crewFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICrew[]> {
    return this.crewFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.crewFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<ICrew[]> {
    return this.crewFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getForAirline(airlineId: string): Observable<ICrew[]> {
    return this.crewFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }
}
