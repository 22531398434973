import { Injectable } from '@angular/core';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateFirestoreService extends FirestoreService<IEmailTemplate> {
  protected basePath = 'emailTemplates';
}
