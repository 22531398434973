import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getYear } from 'date-fns';
import {
  EnumCurrency,
  getDefaultCurrency,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumPeriod, getPeriodLabel } from '../financial-reports.component';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';
import {
  getMonthLabelByQuarter,
  getMonthLabel,
  formatPrice,
  roundNumber
} from 'src/app/misc.utils';
import { Subscription } from 'rxjs';
import { IFinancialReportClient } from 'src/app/interfaces/financial-report-client.interface';
import { FinancialReportClientsService } from 'src/app/services/financial-report-clients/financial-report-clients.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

enum EnumFinancialReportClientVirtualField {
  marginHTPercent = 'marginHTPercent',
  nbContractsPercent = 'nbContractsPercent',
  caPercent = 'caPercent'
}

interface IFinancialReportClientVirtualFields {
  marginHTPercent: number;
  nbContractsPercent: number;
  caPercent: number;
}

interface IFinancialReportClientAllAndClients {
  label: string;
  all: IFinancialReportClient | null;
  financialReportClients: IFinancialReportClient[];
  virtualFields: IFinancialReportClientVirtualFieldsAllAndClients;
}

interface IFinancialReportClientVirtualFieldsAllAndClients {
  [clientId: string]: IFinancialReportClientVirtualFields | null;
  all: IFinancialReportClientVirtualFields | null;
}

@Component({
  selector: 'app-financial-report-client',
  templateUrl: './financial-report-client.component.html',
  styleUrls: ['./financial-report-client.component.scss']
})
export class FinancialReportClientComponent implements OnInit, OnDestroy, OnChanges {
  @Input('currentYear') currentYear: number = getYear(new Date());
  @Input('currentCurrency') currentCurrency: EnumCurrency | null = null;
  @Input('currentPeriod') currentPeriod: EnumPeriod = EnumPeriod.months;

  @Output() updateCurrentPeriod: EventEmitter<EnumPeriod> = new EventEmitter();

  getPeriodLabel = getPeriodLabel;
  getMonthLabelByQuarter = getMonthLabelByQuarter;
  getMonthLabel = getMonthLabel;
  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getUserFullname = getUserFullname;
  formatPrice = formatPrice;
  roundNumber = roundNumber;
  getDefaultCurrency = getDefaultCurrency;

  EnumPeriod = EnumPeriod;
  EnumFinancialReportClientVirtualField = EnumFinancialReportClientVirtualField;

  loading: boolean = false;
  loadingYearsOnly: boolean = false;

  financialReportClients: IFinancialReportClient[] = [];
  financialReportClientsOnlyYears: IFinancialReportClient[] = [];
  financialReportClientsByPeriod: {
    [period: string]: {
      [year: string]: IFinancialReportClientAllAndClients[];
    };
  } = {};

  subscriptions = new Subscription();
  subscriptionsForYear = new Subscription();

  constructor(
    private financialReportClientsService: FinancialReportClientsService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentYear || changes.currentCurrency) {
      this.loadAll();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionsForYear.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  getPeriods(): EnumPeriod[] {
    return Object.values(EnumPeriod);
  }

  getData(): IFinancialReportClientAllAndClients[] {
    switch (this.currentPeriod) {
      case EnumPeriod.years:
        return this.financialReportClientsByPeriod[this.currentPeriod].all;
      default:
        return this.financialReportClientsByPeriod[this.currentPeriod][this.currentYear];
    }
  }

  async changeCurrentPeriod(newPeriod: EnumPeriod): Promise<void> {
    this.loaderService.presentLoader();

    setTimeout(() => {
      this.currentPeriod = newPeriod;

      this.updateCurrentPeriod.emit(this.currentPeriod);

      setTimeout(() => {
        this.loaderService.hideLoaderImmediately();
      }, 1000);
    }, 300);
  }

  loadAll(): void {
    this.loading = true;

    this.resetSubscriptionForYear();

    this.resetFinancialReportClientsDisplayedData(this.currentYear);

    this.loadFinancialReports();
    this.loadFinancialReportClientsYearsOnly();
  }

  resetSubscriptionForYear(): void {
    if (this.subscriptionsForYear) {
      this.subscriptionsForYear.unsubscribe();

      this.subscriptionsForYear = new Subscription();
    }
  }

  loadFinancialReportClientsYearsOnly(): void {
    this.loadingYearsOnly = true;

    // By years
    this.subscriptionsForYear.add(
      this.financialReportClientsService
        .getAllForCurrencyOnlyYears(this.currentCurrency)
        .subscribe((financialReportClients: IFinancialReportClient[]) => {
          this.financialReportClientsOnlyYears = financialReportClients;

          this.organiseFinancialReportClientsYearsOnly();

          this.loadingYearsOnly = false;
        })
    );
  }

  organiseFinancialReportClientsYearsOnly(): void {
    this.financialReportClientsByPeriod[EnumPeriod.years] = {
      all: []
    };

    const financialReportClientsByYearObj: {
      [year: string]: IFinancialReportClientAllAndClients;
    } = {};

    for (const financialReportClient of this.financialReportClientsOnlyYears) {
      if (typeof financialReportClientsByYearObj[financialReportClient.year] === 'undefined') {
        const virtualFieldsByEnquiryClients: IFinancialReportClientVirtualFieldsAllAndClients = {
          all: {} as IFinancialReportClientVirtualFields
        };

        virtualFieldsByEnquiryClients[financialReportClient.clientId] =
          {} as IFinancialReportClientVirtualFields;

        for (const field in virtualFieldsByEnquiryClients) {
          for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
            virtualFieldsByEnquiryClients[field][virtualField] = 0;
          }
        }

        financialReportClientsByYearObj[financialReportClient.year] = {
          label: financialReportClient.year.toString(),
          all: null,
          financialReportClients: [],
          virtualFields: {
            all: {} as IFinancialReportClientVirtualFields
          }
        };

        for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
          financialReportClientsByYearObj[financialReportClient.year].virtualFields.all[
            virtualField
          ] = 0;
        }
      }

      if (financialReportClient.clientId) {
        financialReportClientsByYearObj[financialReportClient.year].financialReportClients.push(
          this.cloneVariable(financialReportClient)
        );

        financialReportClientsByYearObj[financialReportClient.year].virtualFields[
          financialReportClient.clientId
        ] = {} as IFinancialReportClientVirtualFields;

        for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
          financialReportClientsByYearObj[financialReportClient.year].virtualFields[
            financialReportClient.clientId
          ][virtualField] = 0;
        }
      } else {
        financialReportClientsByYearObj[financialReportClient.year].all = financialReportClient;
      }
    }

    this.financialReportClientsByPeriod[EnumPeriod.years].all = Object.values(
      financialReportClientsByYearObj
    ).reverse();

    for (let financialReportClientAllAndClients of this.financialReportClientsByPeriod[
      EnumPeriod.years
    ].all) {
      financialReportClientAllAndClients = this.setVirtualFieldsForFinancialReportClient(
        financialReportClientAllAndClients
      );

      financialReportClientAllAndClients.financialReportClients.sort((a, b) =>
        a.clientTitle.toLocaleLowerCase() < b.clientTitle.toLocaleLowerCase() ? -1 : 1
      );
    }

    this.loadingYearsOnly = false;
  }

  getPercentage(value: number, total: number): number {
    if (total !== 0) {
      return roundNumber((value * 100) / total);
    }

    return 0;
  }

  loadFinancialReports(): void {
    this.subscriptionsForYear.add(
      this.financialReportClientsService
        .getAllForCurrencyForYear(this.currentCurrency, this.currentYear)
        .subscribe((financialReportClients: IFinancialReportClient[]) => {
          this.financialReportClients = financialReportClients;

          this.organiseFinancialReportClients(this.currentYear);
        })
    );
  }

  organiseFinancialReportClients(year: number): void {
    for (const financialReportClient of this.financialReportClients) {
      if (financialReportClient.month) {
        if (financialReportClient.clientId) {
          this.financialReportClientsByPeriod[EnumPeriod.months][year][
            financialReportClient.month - 1
          ].financialReportClients.push(this.cloneVariable(financialReportClient));
        } else {
          this.financialReportClientsByPeriod[EnumPeriod.months][year][
            financialReportClient.month - 1
          ].all = this.cloneVariable(financialReportClient);
        }
      } else if (financialReportClient.quarter) {
        if (financialReportClient.clientId) {
          this.financialReportClientsByPeriod[EnumPeriod.quarters][year][
            financialReportClient.quarter - 1
          ].financialReportClients.push(this.cloneVariable(financialReportClient));
        } else {
          this.financialReportClientsByPeriod[EnumPeriod.quarters][year][
            financialReportClient.quarter - 1
          ].all = this.cloneVariable(financialReportClient);
        }
      }
    }

    for (const period of this.getPeriods()) {
      for (const year in this.financialReportClientsByPeriod[period]) {
        for (let financialReportClientAllAndClients of this.financialReportClientsByPeriod[period][
          year
        ]) {
          financialReportClientAllAndClients.financialReportClients.sort((a, b) =>
            a.clientTitle.toLocaleLowerCase() < b.clientTitle.toLocaleLowerCase() ? -1 : 1
          );

          financialReportClientAllAndClients = this.setVirtualFieldsForFinancialReportClient(
            financialReportClientAllAndClients
          );
        }
      }
    }

    this.loading = false;
  }

  setVirtualFieldsForFinancialReportClient(
    financialReportClientAllAndClients: IFinancialReportClientAllAndClients
  ): IFinancialReportClientAllAndClients {
    for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
      switch (virtualField) {
        default:
          const field: string = (virtualField as string).replace('Percent', '');

          for (const financialReportClient of financialReportClientAllAndClients.financialReportClients) {
            if (
              financialReportClient[field] &&
              financialReportClientAllAndClients.all !== null &&
              financialReportClientAllAndClients.all[field]
            ) {
              if (
                typeof financialReportClientAllAndClients.virtualFields[
                  financialReportClient.clientId
                ] === 'undefined'
              ) {
                financialReportClientAllAndClients.virtualFields[financialReportClient.clientId] =
                  {} as IFinancialReportClientVirtualFields;

                for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
                  financialReportClientAllAndClients.virtualFields[financialReportClient.clientId][
                    virtualField
                  ] = 0;
                }
              }

              if (
                typeof financialReportClientAllAndClients.virtualFields[
                  financialReportClient.clientId
                ] === 'undefined'
              ) {
                financialReportClientAllAndClients.virtualFields[financialReportClient.clientId][
                  virtualField as string
                ] = 0;
              }
              financialReportClientAllAndClients.virtualFields[financialReportClient.clientId][
                virtualField as string
              ] = this.getPercentage(
                financialReportClient[field],
                financialReportClientAllAndClients.all[field]
              );
            }
          }

          break;
      }
    }

    for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
      for (const financialReportClient of financialReportClientAllAndClients.financialReportClients) {
        if (
          typeof financialReportClientAllAndClients.virtualFields.all[virtualField as string] ===
          'undefined'
        ) {
          financialReportClientAllAndClients.virtualFields.all[virtualField as string] = 0;
        }

        financialReportClientAllAndClients.virtualFields.all[virtualField as string] +=
          financialReportClientAllAndClients.virtualFields[financialReportClient.clientId][
            virtualField as string
          ];
      }
    }

    return financialReportClientAllAndClients;
  }

  resetFinancialReportClientsDisplayedData(year: number): void {
    for (const period of this.getPeriods()) {
      this.financialReportClientsByPeriod[period] = {};
      this.financialReportClientsByPeriod[period][year] = [];
      const virtualFieldsByEnquiryClients: IFinancialReportClientVirtualFieldsAllAndClients = {
        all: {} as IFinancialReportClientVirtualFields
      };
      for (const field in virtualFieldsByEnquiryClients) {
        for (const virtualField of Object.values(EnumFinancialReportClientVirtualField)) {
          virtualFieldsByEnquiryClients[field][virtualField] = 0;
        }
      }
      let countItems: number = 1;
      switch (period) {
        case EnumPeriod.months:
          countItems = 12;
          break;
        case EnumPeriod.quarters:
          countItems = 4;
          break;
      }
      for (let i = 1; i <= countItems; i++) {
        this.financialReportClientsByPeriod[period][year].push({
          label:
            period === EnumPeriod.quarters
              ? this.getMonthLabelByQuarter(i) + ' ' + this.currentYear
              : getMonthLabel(i) + ' ' + this.currentYear,
          all: null,
          financialReportClients: [],
          virtualFields: this.cloneVariable(virtualFieldsByEnquiryClients)
        });
      }
    }
  }

  cloneVariable(variable: any): any {
    return JSON.parse(JSON.stringify(variable));
  }
}
