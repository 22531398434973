import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { getDisplayedEnquiryRefTitle, IEnquiry } from 'src/app/interfaces/enquiry.interface';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnAlign,
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import {
  getDepartDate,
  getItineraryVolumeList,
  getItineraryWeightList,
  getPassengersTotalLabelList,
  getReturnDate
} from 'src/app/interfaces/enquiry-itinerary.interface';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';
import { getEnumEnquiryCancelledReasonLabel } from 'src/app/enums/enquiry-cancelled-reason.enum';

@Component({
  selector: 'app-enquiries-list-for-client',
  templateUrl: './enquiries-list-for-client.component.html',
  styleUrls: ['./enquiries-list-for-client.component.scss']
})
export class EnquiriesListForClientComponent
  extends DataList<IEnquiry>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() clientId: string | null = null;
  @Input() enquiryStatus: EnumEnquiryStatus | null = null;
  @Input() filterByPeriod: string | null = null;

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'client-enquiries-list';
  columns: IDataListColumn[] = [];

  orderBy: IDataListOrderBy | null = { field: 'refEnquiryTitleWithoutPrefix', direction: 'desc' };

  isLogged: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private enquiryService: EnquiryService,
    private userService: UserService,
    private remoteService: RemoteService
  ) {
    super();

    this.setDataService(this.enquiryService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.updatedParams();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const field of ['clientId', 'enquiryStatus', 'filterByPeriod']) {
      if (changes[field] && !changes[field].isFirstChange()) {
        this.updatedParams();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedParams(): void {
    this.updateColumns();

    if (this.isLogged) {
      this.conditions = [];

      if (this.clientId) {
        this.conditions.push({
          field: 'clientId',
          operator: '==',
          value: this.clientId
        });
      }

      let refField: string = 'refEnquiry';

      if (this.enquiryStatus) {
        this.conditions.push({
          field: 'status',
          operator: '==',
          value: this.enquiryStatus
        });

        if (this.enquiryStatus === EnumEnquiryStatus.confirmed) {
          refField = 'refContract';
        }
      }

      if (this.filterByPeriod) {
        const splittedFilterByPeriod: string[] = this.filterByPeriod.split('-');

        if (splittedFilterByPeriod[0] && splittedFilterByPeriod[0] === 'quarter') {
          this.conditions.push({
            field: refField + 'Year',
            operator: '==',
            value: parseInt(splittedFilterByPeriod[1]) - 2000 // We use the last 2 digits (ex: 21)
          });
          this.conditions.push({
            field: refField + 'Month',
            operator: 'in',
            value: [
              1 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3,
              2 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3,
              3 + (parseInt(splittedFilterByPeriod[2]) - 1) * 3
            ]
          });
        } else if (splittedFilterByPeriod[0] && splittedFilterByPeriod[0] === 'year') {
          this.conditions.push({
            field: refField + 'Year',
            operator: '==',
            value: parseInt(splittedFilterByPeriod[1]) - 2000 // We use the last 2 digits (ex: 21)
          });
        } else {
          if (splittedFilterByPeriod[0]) {
            this.conditions.push({
              field: refField + 'Year',
              operator: '==',
              value: parseInt(splittedFilterByPeriod[0]) - 2000 // We use the last 2 digits (ex: 21)
            });
          }
          if (splittedFilterByPeriod[1]) {
            this.conditions.push({
              field: refField + 'Month',
              operator: '==',
              value: parseInt(splittedFilterByPeriod[1])
            });
          }
        }
      }

      console.log(
        '🚀 ~ file: enquiries-list-for-client.component.ts:320 ~ updatedParams ~ this.conditions:',
        this.conditions
      );
      this.resetAndLoad();

      this.loadUsers();
    } else {
      setTimeout(() => {
        this.updatedParams();
      }, 500);
    }
  }

  rowCls(item: IEnquiry): string {
    return 'enquiry-' + item.type;
  }

  loadUsers(): void {
    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        for (const user of users) {
          this.usersObj[user.id] = user;
        }
      })
    );
  }

  updateColumns(): void {
    this.columns = [];

    this.columns.push({
      title: '<strong>№ contrat</strong> /<br />№ demande',
      field: 'refEnquiryTitle',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        if (item.refContractTitle) {
          html +=
            '<div><strong>' + getDisplayedEnquiryRefTitle(item, 'refContract') + '</strong></div>';
        }

        if (item.refEnquiryTitle) {
          html += '<div>' + getDisplayedEnquiryRefTitle(item, 'refEnquiry') + '</div>';
        }

        return html;
      }
    });

    this.columns.push({
      title: 'Nature',
      field: 'type',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return getEnumEnquiryTypeLabel(item.type);
      }
    });

    if (!this.enquiryStatus) {
      this.columns.push({
        title: 'Statut',
        field: 'status',
        notSortable: true,
        type: EnumDataListColumnType.function,
        resolve: (item: IEnquiry): string => {
          let classArr: string[] = ['badge'];

          switch (item.status) {
            case EnumEnquiryStatus.draft:
              classArr.push('bg-primary');
              break;
            case EnumEnquiryStatus.confirmed:
              classArr.push('bg-success');
              break;
            case EnumEnquiryStatus.cancelled:
              classArr.push('bg-danger');
              break;
          }

          return (
            '<span class="' +
            classArr.join(' ') +
            '"> ' +
            getEnumEnquiryStatusLabel(item.status) +
            '</span>'
          );
        }
      });
    }
    if (this.enquiryStatus === EnumEnquiryStatus.cancelled) {
      this.columns.push({
        title: "Raison d'annulation",
        field: 'reasonCancelled.reason',
        notSortable: true,
        type: EnumDataListColumnType.function,
        resolve: (item: IEnquiry): string => {
          let text: string =
            '<strong>' +
            getEnumEnquiryCancelledReasonLabel(item.reasonCancelled?.reason) +
            '</strong>';

          if (item.reasonCancelled.comment) {
            text += '<p class="mb-0">' + item.reasonCancelled.comment + '</p>';
          }

          return text;
        }
      });
    }

    if (this.enquiryStatus === EnumEnquiryStatus.confirmed) {
      this.columns.push({
        title: 'Date de confirmation',
        field: 'dateConfirmed',
        notSortable: true,
        type: EnumDataListColumnType.date,
        align: EnumDataListColumnAlign.center
      });
    } else if (this.enquiryStatus === EnumEnquiryStatus.cancelled) {
      this.columns.push({
        title: "Date d'annulation",
        field: 'dateCancelled',
        notSortable: true,
        type: EnumDataListColumnType.date,
        align: EnumDataListColumnAlign.center
      });
    }

    this.columns.push({
      title: 'Routing',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += itinerary.title;
          }

          html += itinerary.routing;

          if (getReturnDate(itinerary)) {
            html += '&nbsp;';
          }

          html += '</div>';
        }

        return html;
      }
    });
    this.columns.push({
      title: 'Dates de vol',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += '&nbsp;';
          }

          if (getDepartDate(itinerary)) {
            html += '<div>' + format(getDepartDate(itinerary), 'dd/MM/yy') + '</div>';
          }

          if (getReturnDate(itinerary)) {
            html += '<div>' + format(getReturnDate(itinerary), 'dd/MM/yy') + '</div>';
          }

          html += '</div>';
        }

        return html;
      }
    });
    this.columns.push({
      title: 'PAX ou kg / m<sup>3</sup>',
      type: EnumDataListColumnType.function,
      notSortable: true,
      resolve: (item: IEnquiry): string => {
        let html: string = '';

        for (const itinerary of item.itineraries) {
          html += '<div>';

          if (item.itineraries.length > 1) {
            html += '&nbsp;';
          }

          if (item.type === EnumEnquiryType.cargo) {
            html +=
              getItineraryWeightList(itinerary)?.join('|') +
              ' kg / ' +
              getItineraryVolumeList(itinerary)?.join('|') +
              ' m<sup>3</sup>';
          } else {
            html += getPassengersTotalLabelList(itinerary)?.join('|') + ' PAX';
          }

          if (getReturnDate(itinerary)) {
            html += '&nbsp;';
          }

          html += '</div>';
        }

        return html;
      }
    });

    this.columns.push({
      title: 'Reçu par',
      field: 'receivedBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return item.receivedBy && this.usersObj[item.receivedBy]
          ? this.usersObj[item.receivedBy].initials
          : '';
      }
    });
    this.columns.push({
      title: 'Traité par',
      field: 'processedBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IEnquiry): string => {
        return item.processedBy && this.usersObj[item.processedBy]
          ? this.usersObj[item.processedBy].initials
          : '';
      }
    });
    this.columns.push({
      title: 'Date de création',
      field: 'created',
      notSortable: true,
      type: EnumDataListColumnType.date,
      align: EnumDataListColumnAlign.center
    });
  }
}
