import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PaginationService } from '../../../services/pagination.service';
import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { FlightChecklistTemplateService } from 'src/app/services/flight-checklist-templates/flight-checklist-templates.service';
import {
  EnumFlightChecklistTemplateFieldOptionStatus,
  EnumFlightChecklistTemplateFieldType,
  IFlightChecklistTemplate,
  getFlightChecklistTemplateFieldTypeLabel
} from 'src/app/interfaces/flight-checklist-template.interface';
import { getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-flight-checklist-template-view',
  templateUrl: './flight-checklist-template-view.component.html',
  styleUrls: ['./flight-checklist-template-view.component.scss']
})
export class FlightChecklistTemplateViewComponent implements OnInit, OnDestroy {
  faEdit = faEdit;
  faTrash = faTrash;

  EnumAcl = EnumAcl;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getFlightChecklistTemplateFieldTypeLabel = getFlightChecklistTemplateFieldTypeLabel;

  EnumFlightChecklistTemplateFieldType = EnumFlightChecklistTemplateFieldType;
  EnumFlightChecklistTemplateFieldOptionStatus = EnumFlightChecklistTemplateFieldOptionStatus;

  loading: boolean = false;
  flightChecklistTemplateId: string;
  flightChecklistTemplate: IFlightChecklistTemplate;
  isLogged: boolean = false;

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public paginationService: PaginationService,
    private aclService: AclService,
    private flightChecklistTemplateService: FlightChecklistTemplateService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.flightChecklistTemplateId = this.activatedRoute.snapshot.paramMap.get(
        'flightChecklistTemplateId'
      );

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightChecklistTemplate();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFlightChecklistTemplate(): void {
    this.loading = true;

    this.subscriptions.add(
      this.flightChecklistTemplateService
        .getFromId(this.flightChecklistTemplateId)
        .subscribe((flightChecklistTemplate: IFlightChecklistTemplate) => {
          this.flightChecklistTemplate = flightChecklistTemplate;

          if (this.flightChecklistTemplate) {
            this.breadcrumbsService.setCurrentItem({
              id: this.flightChecklistTemplate.id,
              text: getEnumEnquiryTypeLabel(this.flightChecklistTemplate.enquiryType)
            });
          }

          this.loading = false;
        })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.flightChecklistTemplatesDelete)) {
      const result = confirm(
        "La suppression d'un modèle de checklist de vols sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        this.flightChecklistTemplateService
          .delete(this.flightChecklistTemplateId)
          .then(() => {
            this.router.navigate(['/admin/flight-checklist-templates']);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
