import { Injectable } from '@angular/core';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupFirestoreService extends FirestoreService<IUserGroup> {
  protected basePath = 'userGroups';
}
