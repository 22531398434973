<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="code">Pays</label>
                        <input
                            type="text"
                            disabled
                            class="form-control"
                            [value]="countriesFr[form.value.code] ?? form.value.code"
                            *ngIf="countryId && form.value.code"
                        />
                        <select
                            class="form-select"
                            id="code"
                            formControlName="code"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['code'].touched) &&
                                form.controls['code'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            *ngIf="!(countryId && form.value.code)"
                        >
                            <option
                                *ngFor="let country of countries"
                                [value]="country.code"
                                [innerText]="country.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['code'].touched) &&
                                    form.controls['code'].errors &&
                                    form.controls['code'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="hasDiplomaticDifficulties"
                            formControlName="hasDiplomaticDifficulties"
                        />
                        <label class="form-check-label" for="hasDiplomaticDifficulties"
                            >Est incident diplomatique ?</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center text-center mt-4">
        <div class="col-md-4">
            <button
                type="button"
                [routerLink]="countryId ? '/admin/countries/' + countryId : '/admin/countries'"
                class="btn btn-secondary btn-block mb-4"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col-md-4">
            <button
                type="submit"
                class="btn btn-primary btn-block mb-4"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
