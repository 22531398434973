import { EnumCurrency } from '../enums/currency.enum';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { IStoreData } from './store-data.interface';

export interface IFinancialReportDepartment extends IStoreData {
  currency: EnumCurrency;
  year: number;
  quarter: number;
  month: number | null;
  enquiryType: EnumFinancialReportDepartmentEnquiryType | null;
  nbContracts: number;
  nbCompleted: number;
  completedPercent: number;
  marginHT: number;
  marginPercent: number;
  usersId: string[];
  ca: number;
  enquiriesId: string[];
  enquiriesRefContractTitle: string[];
  invoicesId: string[];
  dateUpdated: Date;
}

export enum EnumFinancialReportDepartmentEnquiryType {
  business = 'business',
  commercial = 'commercial',
  cargo = 'cargo',
  sport = 'sport',
  helico = 'helico',
  businessHelico = 'business-helico',
  acmi = 'acmi'
}

export const getEnquiryTypesWithoutVirtual = (): EnumFinancialReportDepartmentEnquiryType[] => {
  const enquiryTypes: EnumFinancialReportDepartmentEnquiryType[] = [];

  for (let enquiryType of Object.values(EnumFinancialReportDepartmentEnquiryType)) {
    if (![EnumFinancialReportDepartmentEnquiryType.businessHelico].includes(enquiryType)) {
      enquiryTypes.push(enquiryType);
    }
  }

  return enquiryTypes;
};

export const getFinancialReportDepartmentEnquiryTypeLabel = (
  type: EnumFinancialReportDepartmentEnquiryType
): string => {
  switch (type) {
    default:
      return type;
    case EnumFinancialReportDepartmentEnquiryType.business:
      return "Aviation d'affaires";
    case EnumFinancialReportDepartmentEnquiryType.commercial:
      return 'Aviation commerciale';
    case EnumFinancialReportDepartmentEnquiryType.cargo:
      return 'Aviation cargo';
    case EnumFinancialReportDepartmentEnquiryType.sport:
      return 'Aviation sport';
    case EnumFinancialReportDepartmentEnquiryType.helico:
      return 'Hélicoptère';
    case EnumFinancialReportDepartmentEnquiryType.businessHelico:
      return "Aviation d'affaires + Hélicoptère";
    case EnumFinancialReportDepartmentEnquiryType.acmi:
      return 'ACMI';
  }
};
