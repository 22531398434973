import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { CountryFirestoreService } from './countries-firestore.service';
import { ICountry } from 'src/app/interfaces/country.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private countryFirestoreService: CountryFirestoreService) {}

  create(country: ICountry): Promise<string> {
    return this.countryFirestoreService.create(country);
  }

  createWithId(id: string, country: ICountry): Promise<string> {
    return this.countryFirestoreService.createWithId(id, country);
  }

  update(country: ICountry): Promise<string> {
    return this.countryFirestoreService.update(country);
  }

  delete(id: string): Promise<void> {
    return this.countryFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<ICountry> {
    return this.countryFirestoreService.docValue$(id);
  }

  getAll(): Observable<ICountry[]> {
    return this.countryFirestoreService.collectionValues$();
  }

  getAllActive(): Observable<ICountry[]> {
    return this.countryFirestoreService.collectionValues$(ref => ref.where('isActive', '==', true));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.countryFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<ICountry[]> {
    return this.countryFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
