import { Component, OnInit, NgZone, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  ICrew,
  getCrewFullname,
  getCrewGenderLabel,
  getCrewTypeLabel
} from 'src/app/interfaces/crew.interface';
import { Subscription } from 'rxjs';
import { CrewService } from 'src/app/services/crews/crews.service';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-crew-view',
  templateUrl: './crew-view.component.html',
  styleUrls: ['./crew-view.component.scss']
})
export class CrewViewComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getCrewFullname = getCrewFullname;
  getCrewTypeLabel = getCrewTypeLabel;
  getCrewGenderLabel = getCrewGenderLabel;

  loading: boolean = false;
  crewId: string;
  crew: ICrew | null = null;
  isLogged: boolean = false;

  crewsObj: { [id: string]: ICrew | null } = {};
  airlinesObj: { [id: string]: IAirline | null } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private crewService: CrewService,
    private airlineService: AirlineService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(() => {
      this.crewId = this.activatedRoute.snapshot.paramMap.get('crewId');

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadCrew();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadCrew(): void {
    this.subscriptions.add(
      this.crewService.getFromId(this.crewId).subscribe((crew: ICrew) => {
        this.crew = crew;

        this.setBreadcrumbsItems();

        if (this.crew?.airlineId) {
          this.loadAirline(this.crew.airlineId);
        }
      })
    );
  }

  delete(): void {
    if (this.hasAclAccess(EnumAcl.crewsDelete)) {
      const result = confirm(
        "La suppression d'un membre de l'équipage sera permanente. Êtes-vous sûr de vouloir continuer?"
      );

      if (result) {
        const redirectUrl: string = '/admin/airlines/' + this.crew.airlineId + '/crews';

        this.crewService
          .delete(this.crewId)
          .then(() => {
            this.router.navigate([redirectUrl]);

            alert('La suppression a été effectuée avec succès.');
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  loadAirline(airlineId: string): void {
    if (typeof this.airlinesObj[airlineId] === 'undefined') {
      this.airlinesObj[airlineId] = null;

      this.subscriptions.add(
        this.airlineService.getFromId(airlineId).subscribe((airline: IAirline | null) => {
          this.airlinesObj[airlineId] = airline;

          this.setBreadcrumbsItems();
        })
      );
    }
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    breadcrumbItems.push({
      text: 'Compagnies aériennes',
      url: '/admin/airlines'
    });

    if (this.crew?.airlineId && this.airlinesObj[this.crew.airlineId]) {
      breadcrumbItems.push({
        text: this.airlinesObj[this.crew.airlineId].title,
        url: '/admin/airlines/' + this.crew.airlineId
      });
    } else {
      breadcrumbItems.push({
        isPlaceholder: true
      });
    }

    if (this.crew) {
      breadcrumbItems.push({
        text: 'Équipage',
        url: '/admin/airlines/' + this.crew.airlineId + '/crews'
      });

      breadcrumbItems.push({
        text: getCrewFullname(this.crew)
      });
    } else {
      breadcrumbItems.push({
        text: 'Équipage'
      });
      breadcrumbItems.push({
        isPlaceholder: true
      });
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }
}
