import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { PipedriveService } from '../../../services/pipedrive.service';

import { addMinutes, differenceInDays, differenceInMinutes, format } from 'date-fns';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnquiryOfferService } from 'src/app/services/enquiry-offer/enquiry-offer.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { EnumEnquiryCotationStatus } from 'src/app/enums/enquiry-cotation-status.enum';
import { EnumEnquiryType } from 'src/app/enums/enquiry-type.enum';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import {
  addZeroToDigit,
  convertHtmlToPlainText,
  estimatedFlyTimeInMinutes,
  generateRandomId
} from 'src/app/misc.utils';
import {
  IAirport,
  getAirportLocalTimeFromUTC,
  getAirportUTCTime
} from 'src/app/interfaces/airport.interface';
import { IEnquiryTrip } from 'src/app/interfaces/enquiry-trip.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import {
  IPipedriveOrganization,
  IPipedrivePerson,
  getPipedrivePersonPrimaryEmail,
  getPipedrivePersonPrimaryPhone
} from 'src/app/interfaces/pipedrive.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import {
  EnumQuotationStatus,
  EnumQuotationValueType,
  IQuotation,
  IQuotationAircraftInfo,
  IQuotationInfoTranslationField,
  IQuotationItemInfo,
  IQuotationPriceInfo,
  IQuotationSection,
  IQuotationSectionService
} from 'src/app/interfaces/quotation.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import {
  faArrowDown,
  faArrowUp,
  faExternalLink,
  faEye,
  faPlus,
  faSearch,
  faToggleOff,
  faToggleOn,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { IQuotationTrip } from 'src/app/interfaces/quotation-trip.interface';
import { getItineraryRouting } from 'src/app/interfaces/enquiry-itinerary.interface';
import { QuotationService } from 'src/app/services/quotations/quotations.service';
import { AirportService } from 'src/app/services/airports/airports.service';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html',
  styleUrls: ['./quotation-edit.component.scss']
})
export class QuotationEditComponent implements OnInit, OnDestroy {
  @ViewChild('modalImage', { static: false }) modalImageElement: ElementRef;

  EnumEnquiryType = EnumEnquiryType;
  EnumCurrency = EnumCurrency;
  EnumLanguage = EnumLanguage;
  EnumQuotationValueType = EnumQuotationValueType;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getLanguageLabel = getLanguageLabel;
  getItineraryRouting = getItineraryRouting;

  faSearch = faSearch;
  faTrash = faTrash;
  faExternalLink = faExternalLink;
  faEye = faEye;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faPlus = faPlus;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  queryAircraftRegistration: string = '';

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();

  quotation: IQuotation | null = null;
  quotationId: string | null = null;
  enquiryId: string | null = null;
  enquiry: IEnquiry;
  enquiryOffers: IEnquiryOffer[] = [];
  enquiryCotations: IEnquiryCotation[] = [];
  offerId: string | null = null;
  cotationId: string | null = null;
  client: IPipedriveOrganization | null = null;
  contact: IPipedrivePerson | null = null;
  quotations: IQuotation[] = [];
  user: IUser;
  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  airportsList: {
    title: string;
    value: string;
  }[] = [];

  defaultAircraftInfos: IQuotationAircraftInfo[] = [];
  defaultPriceInfos: IQuotationPriceInfo[] = [];
  defaultServicesSections: IQuotationSection[] = [];

  translationObj: object = {};
  selectedItineraryId: string | null = null;

  selectedOffer: IEnquiryOffer;
  selectedCotation: IEnquiryCotation;

  aircraftCompiled: IAircraftCompiled | null = null;

  loadingAircraftModels: boolean = false;
  aircraftModels: IAircraftModel[] = [];
  aircraftModelsObj: { [id: string]: IAircraftModel } = {};
  currentAircraftModelInitiated: boolean = false;

  selectedImageField: 'image1' | 'image2' | 'image3' | null = null;
  selectedImageFieldForAircraftModel: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl' | null = null;

  aircraft: IAircraft | null = null;

  currentTripIndex: number | null = null;

  selectedAircraftModelId: string | null = null;

  subscriptions = new Subscription();
  subscriptionsAircraft = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private pipedriveService: PipedriveService,
    private translateService: TranslateService,
    private enquiryService: EnquiryService,
    private enquiryOfferService: EnquiryOfferService,
    private enquiryCotationService: EnquiryCotationService,
    private userService: UserService,
    private aircraftModelService: AircraftModelService,
    private aircraftService: AircraftService,
    private loaderService: LoaderService,
    private breadcrumbsService: BreadcrumbsService,
    private quotationService: QuotationService,
    private airportService: AirportService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  async ngOnInit(): Promise<void> {
    this.form.disable();

    for (const language of this.getLanguages()) {
      await this.loadLanguageTranslation(language);
    }

    this.activatedRoute.params.subscribe(async () => {
      this.quotationId = this.activatedRoute.snapshot.paramMap.get('quotationId');
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');
      this.offerId = this.activatedRoute.snapshot.paramMap.get('offerId');
      this.cotationId = this.activatedRoute.snapshot.paramMap.get('cotationId');

      if (this.enquiryId) {
        this.form.get('enquiryId').setValue(this.enquiryId);

        this.loadEnquiry();
      }

      this.setBreadcrumbsItems();

      if (this.quotationId) {
        await this.aclService.checkAclAccess(EnumAcl.quotationsEdit);
        await this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.quotationsAdd);
        if (this.enquiryId) {
          await this.loadEnquiryQuotations();
          await this.loadEnquiryCotations();
          this.form.get('versionNumber').setValue(this.quotations.length + 1);
          this.refreshQuotationTitle();
          this.loadAircraftModels();

          await this.setEnquiryFields();

          this.form.enable();
        } else {
          this.router.navigate(['/admin/quotations']);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsAircraft.unsubscribe();

    this.removeModal();
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    if (this.enquiry) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        text: getEnquiryBreadcrumbTitle(this.enquiry),
        url: '/admin/enquiries/' + this.enquiry.id
      });
      breadcrumbItems.push({
        text: 'Devis',
        url: '/admin/quotations/enquiry/' + this.enquiry.id
      });
    } else if (this.enquiryId) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Devis',
        url: '/admin/quotations/enquiry/' + this.enquiryId
      });
    } else {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Devis'
      });
    }

    if (this.quotation) {
      breadcrumbItems.push({
        text: this.quotation.title,
        url: '/admin/quotations/' + this.quotation.id
      });
      breadcrumbItems.push({
        text: 'Édition',
        url: '/admin/quotations/' + this.quotation.id + '/edit'
      });
    } else if (this.quotationId) {
      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Édition',
        url: '/admin/quotations/' + this.quotationId + '/edit'
      });
    } else {
      breadcrumbItems.push({
        text: 'Ajout'
      });
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }

  removeModal(): void {
    window['$'](this.modalImageElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  get clientTitle(): FormControl {
    return this.form.get('clientTitle') as FormControl;
  }

  get contactFirstname(): FormControl {
    return this.form.get('contactFirstname') as FormControl;
  }

  get contactLastname(): FormControl {
    return this.form.get('contactLastname') as FormControl;
  }

  get contactPhone(): FormControl {
    return this.form.get('contactPhone') as FormControl;
  }

  get contactEmail(): FormControl {
    return this.form.get('contactEmail') as FormControl;
  }

  get companyTitle(): FormControl {
    return this.form.get('companyTitle') as FormControl;
  }

  get agentFirstname(): FormControl {
    return this.form.get('agentFirstname') as FormControl;
  }

  get agentLastname(): FormControl {
    return this.form.get('agentLastname') as FormControl;
  }

  get agentPhone(): FormControl {
    return this.form.get('agentPhone') as FormControl;
  }

  get agentEmail(): FormControl {
    return this.form.get('agentEmail') as FormControl;
  }

  get date(): FormControl {
    return this.form.get('date') as FormControl;
  }

  get title(): FormControl {
    return this.form.get('title') as FormControl;
  }

  get versionNumber(): FormControl {
    return this.form.get('versionNumber') as FormControl;
  }

  get language(): FormControl {
    return this.form.get('language') as FormControl;
  }

  get enquiryType(): FormControl {
    return this.form.get('enquiryType') as FormControl;
  }

  get aircraftModelId(): FormControl {
    return this.form.get('aircraftModelId') as FormControl;
  }

  get aircraftCompiledId(): FormControl {
    return this.form.get('aircraftCompiledId') as FormControl;
  }

  get aircraftId(): FormControl {
    return this.form.get('aircraftId') as FormControl;
  }

  get trips(): FormArray {
    return this.form.get('trips') as FormArray;
  }

  getTrip(i: number): FormGroup {
    return this.trips.at(i) as FormGroup;
  }

  getTripField(i: number, field: string): FormControl {
    return this.getTrip(i).get(field) as FormControl;
  }

  get priceInfos(): FormArray {
    return this.form.get('priceInfos') as FormArray;
  }

  getPriceInfo(i: number): FormGroup {
    return this.priceInfos.at(i) as FormGroup;
  }

  getPriceInfoField(i: number, field: string): FormControl {
    return this.getPriceInfo(i).get(field) as FormControl;
  }

  get paymentConditionsSettings(): FormGroup {
    return this.form.get('paymentConditionsSettings') as FormGroup;
  }

  get cancellingConditionsSettings(): FormGroup {
    return this.form.get('cancellingConditionsSettings') as FormGroup;
  }

  get servicesSections(): FormArray {
    return this.form.get('servicesSections') as FormArray;
  }

  getServicesSection(i: number): FormGroup {
    return this.servicesSections.at(i) as FormGroup;
  }

  getServicesSectionField(i: number, field: string): FormControl {
    return this.getServicesSection(i).get(field) as FormControl;
  }

  getServicesInSection(i: number): FormArray {
    return this.getServicesSection(i).get('services') as FormArray;
  }

  getServiceInSection(i: number, j: number): FormGroup {
    return this.getServicesInSection(i).at(j) as FormGroup;
  }

  getServiceFieldInSection(i: number, j: number, field: string): FormControl {
    return this.getServiceInSection(i, j).get(field) as FormControl;
  }

  get aircraftInfos(): FormArray {
    return this.form.get('aircraftInfos') as FormArray;
  }

  getAircraftInfo(i: number): FormGroup {
    return this.aircraftInfos.at(i) as FormGroup;
  }

  getAircraftInfoField(i: number, field: string): FormControl {
    return this.getAircraftInfo(i).get(field) as FormControl;
  }

  addTrip(): void {
    this.trips.push(
      new FormGroup({
        id: new FormControl(generateRandomId(), [Validators.required]),
        date: new FormControl(null, [Validators.required, this.dateMinimum('2000-01-01')]),
        time: new FormControl(null),
        aircraftModelId: new FormControl(null),
        arrivalTime: new FormControl(null),
        airportDepart: new FormControl(null),
        airportDepartTax: new FormControl(0),
        airportDepartTimezoneId: new FormControl(''),
        airportDepartTimezoneRawOffset: new FormControl(''),
        airportDepartTimezoneGmt: new FormControl(''),
        airportDestination: new FormControl(null, [Validators.required]),
        airportDestinationTax: new FormControl(0, [Validators.required]),
        airportDestinationTimezoneId: new FormControl(''),
        airportDestinationTimezoneRawOffset: new FormControl(''),
        airportDestinationTimezoneGmt: new FormControl(''),
        passengersTotal: new FormControl(''),
        passengersBusiness: new FormControl(''),
        weight: new FormControl(''),
        volume: new FormControl(''),
        natureFret: new FormControl(''),
        dimensions: new FormControl(''),
        hasFuelStopOver: new FormControl(false),
        fuelStopOverAirport: new FormControl(''),
        hasCommercialStopOver: new FormControl(false),
        commercialStopOverAirport: new FormControl(''),
        passengersCommercialStop: new FormControl(''),
        distanceInKm: new FormControl(null),
        speedInKts: new FormControl(null),
        flyTimeInHours: new FormControl(null)
      })
    );
  }

  async addTripAndSet(trip: IQuotationTrip): Promise<void> {
    this.addTrip();

    const i: number = this.trips.length - 1;

    for (const field in this.getTrip(i).value) {
      if (typeof trip[field] !== 'undefined') {
        this.getTripField(i, field).setValue(trip[field]);
        this.getTripField(i, field).updateValueAndValidity();
      }
    }

    await this.loadAirport(trip.airportDepart);
    await this.loadAirport(trip.airportDestination);

    if (
      !this.getTripField(i, 'airportDepartTimezoneId').value ||
      !this.getTripField(i, 'airportDestinationTimezoneId').value
    ) {
      this.setTimezoneForAirports(i);
    }

    if (trip.hasFuelStopOver) {
      this.getTripField(i, 'airportDestination').setValue(trip.fuelStopOverAirport);
      this.getTripField(i, 'airportDestinationTimezoneId').setValue(null);
      this.getTripField(i, 'airportDestinationTimezoneRawOffset').setValue(null);
      this.getTripField(i, 'airportDestinationTimezoneGmt').setValue(null);
      this.getTripField(i, 'arrivalTime').setValue(null);

      this.setTimezoneForAirports(this.trips.length - 1);

      this.addTrip();

      const tripFormGroupFuelStopOver: FormGroup = this.trips.at(
        this.trips.length - 1
      ) as FormGroup;

      tripFormGroupFuelStopOver.get('airportDepart').setValue(trip.fuelStopOverAirport);
      tripFormGroupFuelStopOver.get('airportDestination').setValue(trip.airportDestination);
      tripFormGroupFuelStopOver.get('passengersTotal').setValue(trip.passengersTotal);
      tripFormGroupFuelStopOver.get('passengersBusiness').setValue(trip.passengersBusiness);
      tripFormGroupFuelStopOver.get('date').setValue(trip.date);

      await this.loadAirport(trip.fuelStopOverAirport);

      this.setTimezoneForAirports(this.trips.length - 1);
    } else if (trip.hasCommercialStopOver) {
      this.getTripField(i, 'airportDestination').setValue(trip.commercialStopOverAirport);
      this.getTripField(i, 'airportDestinationTimezoneId').setValue(null);
      this.getTripField(i, 'airportDestinationTimezoneRawOffset').setValue(null);
      this.getTripField(i, 'airportDestinationTimezoneGmt').setValue(null);
      this.getTripField(i, 'arrivalTime').setValue(null);

      this.setTimezoneForAirports(this.trips.length - 1);

      this.addTrip();

      const tripFormGroupCommercialStopOver: FormGroup = this.trips.at(
        this.trips.length - 1
      ) as FormGroup;

      tripFormGroupCommercialStopOver.get('airportDepart').setValue(trip.commercialStopOverAirport);
      tripFormGroupCommercialStopOver.get('airportDestination').setValue(trip.airportDestination);

      tripFormGroupCommercialStopOver
        .get('passengersCommercialStop')
        .setValue(trip.passengersCommercialStop);
      tripFormGroupCommercialStopOver.get('passengersTotal').setValue(trip.passengersTotal);
      tripFormGroupCommercialStopOver.get('passengersBusiness').setValue(trip.passengersBusiness);
      tripFormGroupCommercialStopOver.get('date').setValue(trip.date);

      this.setTimezoneForAirports(this.trips.length - 1);
    }

    this.tripTimesUpdated(this.trips.length - 1);

    if (
      !this.getTripField(i, 'airportDepartTimezoneId').value ||
      !this.getTripField(i, 'airportDestinationTimezoneId').value
    ) {
      this.setTimezoneForAirports(i);
    }
  }

  async addTripAndPrefill(): Promise<void> {
    if (this.selectedItineraryId) {
      for (const itinerary of this.enquiry.itineraries) {
        if (itinerary.id === this.selectedItineraryId) {
          for (const trip of itinerary.trips) {
            this.addTripAndSet(trip as IQuotationTrip);
          }

          break;
        }
      }

      this.setEstimatedArrivalTimeIfNotProvided();

      this.refreshVATFieldValue();

      this.generateGoogleMapStaticUrl();

      this.selectedItineraryId = null;
    } else {
      this.addTrip();
    }
  }

  refreshVATFieldValue(): void {
    const isNationalFlight: boolean = this.tripsIsNationalFlight();

    for (const module of ['aircraftInfos', 'priceInfos']) {
      const formArray: FormArray = this.form.get(module) as FormArray;
      for (let i = 0; i < formArray.value.length; i++) {
        switch (formArray.at(i).value.type) {
          case EnumQuotationValueType.vat:
            formArray
              .at(i)
              .get('value')
              .setValue(
                isNationalFlight
                  ? this.getTranslation('QUOTATION.PRICE.TO_FILL')
                  : this.getTranslation('QUOTATION.PRICE.EXEMPT')
              );
            break;
        }
      }
    }
  }

  tripsIsNationalFlight(): boolean {
    let isNationalFlight: boolean = false;

    if (this.trips.value) {
      for (const trip of this.trips.value) {
        if (trip.airportDepart && trip.airportDestination) {
          if (
            this.airportsObj[trip.airportDepart] &&
            this.airportsObj[trip.airportDestination] &&
            this.airportsObj[trip.airportDepart].countryCode ===
              this.airportsObj[trip.airportDestination].countryCode
          ) {
            isNationalFlight = true;
          }
        }
      }
    }

    return isNationalFlight;
  }

  removeTrip(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.trips.removeAt(i);

      this.refreshVATFieldValue();
    }
  }

  refreshQuotationTitle(): void {
    this.title.setValue(
      getDisplayedEnquiryRefTitle(this.enquiry, 'refEnquiry') +
        '.' +
        (this.versionNumber.value || 1)
    );
  }

  async setEnquiryFields(): Promise<void> {
    if (this.enquiry) {
      setTimeout(async () => {
        this.enquiryType.setValue(this.enquiry.type);

        if (!this.quotationId) {
          this.refreshQuotationTitle();
        }
        this.form.get('clientId').setValue(this.enquiry.clientId);
        this.form.get('contactId').setValue(this.enquiry.contactId);
        this.form.get('agentId').setValue(this.enquiry.processedBy);

        await this.loadClient();

        if (this.client) {
          this.form.get('clientTitle').setValue(this.client.name);
        }

        if (this.contact.first_name) {
          this.form.get('contactFirstname').setValue(this.contact.first_name);
        }
        if (this.contact.last_name) {
          this.form.get('contactLastname').setValue(this.contact.last_name);
        }
        if (getPipedrivePersonPrimaryPhone(this.contact)) {
          this.form.get('contactPhone').setValue(getPipedrivePersonPrimaryPhone(this.contact));
        }
        if (getPipedrivePersonPrimaryEmail(this.contact)) {
          this.form.get('contactEmail').setValue(getPipedrivePersonPrimaryEmail(this.contact));
        }

        this.loadUser();

        this.setDefaultFields();

        if (this.enquiry.itineraries.length === 1 && !this.trips.length) {
          // We prefill because there is only one itinerary
          this.selectedItineraryId = this.enquiry.itineraries[0].id;

          this.addTripAndPrefill();
        }

        if (this.selectedCotation && this.selectedCotation?.aircraftCompiled) {
          this.aircraftCompiled = this.selectedCotation?.aircraftCompiled;
        } else if (this.selectedOffer && this.selectedOffer.aircraftCompiled) {
          this.aircraftCompiled = this.selectedOffer.aircraftCompiled;
        }

        if (this.aircraftCompiled) {
          this.aircraftInfos.at(0).get('value').setValue(this.aircraftCompiled.type.toUpperCase());

          if (this.aircraftCompiled.aircraftModelId) {
            this.aircraftModelId.setValue(this.aircraftCompiled.aircraftModelId);
            this.aircraftModelId.updateValueAndValidity();

            this.updatedAircraftModelId();

            this.loadAircraftModel(this.aircraftCompiled.aircraftModelId);
          }
        }

        if (this.selectedCotation) {
          this.form
            .get('totalAmountPayment')
            .setValue(this.selectedCotation?.sellingPriceInCurrency);
          this.form.get('totalAmountPaymentCurrency').setValue(this.selectedCotation?.currency);

          this.applyPaxToPriceInfos(this.selectedCotation.nbPax);

          this.updatedTotalAmountPayment();
        }
      }, 300);
    }

    setTimeout(() => {
      this.changedLanguage();
    }, 1000);

    this.generateGoogleMapStaticUrl();
  }

  updatedTotalAmountPayment(): void {
    for (const module of ['aircraftInfos', 'priceInfos']) {
      const formArray: FormArray = this.form.get(module) as FormArray;
      for (let i = 0; i < formArray.value.length; i++) {
        switch (formArray.at(i).value.type) {
          case EnumQuotationValueType.totalAmountPayment:
            formArray.at(i).get('value').setValue(this.getTotalAmountPaymentFormatted());

            this.refreshPricePerPassenger();
            break;
        }
      }
    }
  }

  getTotalAmountPaymentFormatted(): string {
    return this.formatPrice(
      this.form.value.totalAmountPayment,
      this.form.value.totalAmountPaymentCurrency
    );
  }

  async setCurrentCotation(): Promise<void> {
    if (this.offerId || this.cotationId) {
      for (const offer of this.enquiryOffers) {
        if (!this.trips.length) {
          if (offer.id === this.offerId) {
            this.selectedOffer = offer;
          }

          if (this.cotationId) {
            for (const enquiryCotation of this.enquiryCotations) {
              if (enquiryCotation.id === this.cotationId) {
                this.selectedOffer = offer;
                this.selectedCotation = enquiryCotation;
                this.selectedItineraryId = enquiryCotation.itineraryId;

                this.addTripAndPrefill();

                break;
              }
            }
          }
        }
      }
    } else {
      for (const enquiryCotation of this.enquiryCotations) {
        if (enquiryCotation.status === EnumEnquiryCotationStatus.confirmed) {
          for (const enquiryOffer of this.enquiryOffers) {
            if (enquiryOffer.id === enquiryCotation.offerId) {
              this.selectedOffer = enquiryOffer;
            }
          }

          this.selectedCotation = enquiryCotation;
          this.selectedItineraryId = enquiryCotation.itineraryId;

          this.changedLanguage();
          break;
        }
      }
    }
  }

  setDefaultFields(): void {
    this.form.get('notes').setValue(this.getTranslation('QUOTATION.NOTES'));

    switch (this.enquiryType.value) {
      case EnumEnquiryType.helico:
      case EnumEnquiryType.business:
        this.defaultAircraftInfos = [
          {
            hasTitle: true,
            type: EnumQuotationValueType.aircraftModelTitle,
            translations: {
              title: 'QUOTATION.AIRCRAFT.MODEL',
              placeholder: 'Hawker 800A'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.CABIN_HEIGHT',
              placeholder: '1m82'
            }
          },
          {
            title: 'QUOTATION.AIRCRAFT.MANUFACTURE_YEAR',
            type: EnumQuotationValueType.year,
            translations: {
              title: 'QUOTATION.AIRCRAFT.MANUFACTURE_YEAR',
              placeholder: '1990'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.year,
            translations: {
              title: 'QUOTATION.AIRCRAFT.REFURBISHMENT',
              placeholder: '2020'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.BAGGAGE_ALLOWANCE',
              placeholder: 'QUOTATION.AIRCRAFT.TWO_LUGGAGES_23KG'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.CRUISE_SPEED',
              placeholder: '850km/h'
            }
          }
        ];

        this.defaultPriceInfos = [
          {
            hasTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              title: 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR',
              placeholder: this.formatPrice(30750)
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.pax,
            translations: {
              title: 'QUOTATION.PRICE.PASSENGER',
              placeholder: '0',
              value: '0'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.FLIGHT_ATTENDANT',
              placeholder: 'PRICE.NOT_INCLUDED',
              value: 'QUOTATION.PRICE.NOT_INCLUDED'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.CATERING_VIP',
              placeholder: 'PRICE.SNACKS_BAR',
              value: 'QUOTATION.PRICE.SNACKS_BAR'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.vat,
            translations: {
              title: 'QUOTATION.PRICE.VAT_NATIONAL_FLIGHT',
              placeholder: 'PRICE.EXEMPT',
              value: 'QUOTATION.PRICE.EXEMPT'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.SUPPLEMENTARY_SERVICES',
              placeholder: 'PRICE.SEE_PAGE_2',
              value: 'QUOTATION.PRICE.SEE_PAGE_2'
            }
          },
          {
            hasSubTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              subTitle: 'QUOTATION.PRICE.PRICE_NET_TTC',
              placeholder: this.formatPrice(30750)
            }
          }
        ];
        this.defaultServicesSections = [
          {
            translations: {
              title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.TITLE'
            },
            services: [
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.PREPARATION_AND_FLIGHT_FOLLOW_UP',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.ARTHEAU_AVIATION_AGENT_ON_DEPARTURE',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.DISPLAY_SCREEN_ACCORDING_TO_AIRPORT',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_CHECKIN',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.WAITING_LOUNGE',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              }
            ]
          },
          {
            translations: {
              title: 'QUOTATION.SERVICES.ABOARD.TITLE'
            },
            services: [
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.OPERATIONAL_SUPPORT_24H',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.CATERING_STANDARD',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.CATERING_SPECIFIC',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.WIFI',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              }
            ]
          },
          {
            translations: {
              title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TITLE'
            },
            services: [
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.LUGGAGES_PLUS',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.AIRPORT_TRANSFER',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TERMINAL_VIP',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              }
            ]
          }
        ];
        break;
      case EnumEnquiryType.cargo:
        this.defaultAircraftInfos = [
          {
            hasTitle: true,
            type: EnumQuotationValueType.aircraftModelTitle,
            translations: {
              title: 'QUOTATION.AIRCRAFT.MODEL',
              placeholder: 'Hawker 800A'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.aircraftModelTotalSeat,
            translations: {
              title: 'QUOTATION.AIRCRAFT.CONFIGURATION',
              placeholder: 'Hawker 800A'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.TECHNICAL_LIMITATION'
            }
          }
        ];

        this.defaultPriceInfos = [
          {
            hasTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              title: 'QUOTATION.PRICE.NET_PRICE_PER_ROTATION',
              placeholder: this.formatPrice(300000)
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.vat,
            translations: {
              title: 'QUOTATION.PRICE.VAT',
              placeholder: 'QUOTATION.PRICE.EXEMPT',
              value: 'QUOTATION.PRICE.EXEMPT'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.ADDITIONAL_SERVICES_SEE_PAGE_2',
              placeholder: 'QUOTATION.PRICE.UPON_REQUEST',
              value: 'QUOTATION.PRICE.UPON_REQUEST'
            }
          },
          {
            hasSubTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              subTitle: 'QUOTATION.PRICE.TOTAL_PRICE_ALL_INCLUDED',
              placeholder: this.formatPrice(300000)
            }
          }
        ];
        this.defaultServicesSections = [
          {
            translations: {
              title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.TITLE'
            },
            services: [
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.AIRCRAFT_FULL_CHARTER',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.FUEL',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.AIRCRAFT_INSURANCE',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.LANDING_FEES',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.RAMP_HANDLING_FEES',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.FLIGHT_SERVICES.PARKING_FEES',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              }
            ]
          },
          {
            translations: {
              title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TITLE'
            },
            services: [
              {
                checked: false,
                translations: {
                  title:
                    'QUOTATION.SERVICES.FLIGHT_SERVICES.EXCEPTIONNAL_LOADING_OFFLOADING_EQUIPMENT',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.PALLET_BUILD_AND_BREAK',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.OFFLOADING_OF_TRUCKS',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.WAREHOUSING',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.SCREENING',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.CUSTOMS_EXPORT_IMPORT',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TRUCKING',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title:
                    'QUOTATION.SERVICES.ADDITIONAL_SERVICES.LOCAL_TAXES_ACCORDING_TO_COUNTRIES',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.TERMINAL_HANDLING_CHARGES',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.WAR_RISK_INSURANCE',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.ROYALTIES_NON_OBJECTION_FEES',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ADDITIONAL_SERVICES.DE_ICING',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              }
            ]
          }
        ];
        break;
      case EnumEnquiryType.commercial:
      default:
        this.defaultAircraftInfos = [
          {
            hasTitle: true,
            type: EnumQuotationValueType.aircraftModelTitle,
            translations: {
              title: 'QUOTATION.AIRCRAFT.MODEL',
              placeholder: 'Hawker 800A'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.aircraftModelTotalSeat,
            translations: {
              title: 'QUOTATION.AIRCRAFT.CONFIGURATION'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.TECHNICAL_LIMITATION'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.BAGGAGE_ALLOWANCE',
              placeholder: 'QUOTATION.AIRCRAFT.TWO_LUGGAGES_23KG'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.AIRCRAFT.CRUISE_SPEED',
              placeholder: '850km/h'
            }
          }
        ];

        this.defaultPriceInfos = [
          {
            hasTitle: true,
            hasSubTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              title: 'QUOTATION.PRICE.PRICE_EXCLUDING_TAX_FOR',
              subTitle: 'QUOTATION.PRICE.X_PASSENGERS',
              placeholder: this.formatPrice(30750)
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.PASSENGER_TAXES',
              placeholder: 'QUOTATION.PRICE.INCLUDED_FEMININE',
              value: 'QUOTATION.PRICE.INCLUDED_FEMININE'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.SERVICE_ON_BOARD',
              placeholder: 'QUOTATION.PRICE.INCLUDED',
              value: 'QUOTATION.PRICE.INCLUDED'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.vat,
            translations: {
              title: 'QUOTATION.PRICE.VAT_NATIONAL_FLIGHT',
              placeholder: 'QUOTATION.PRICE.EXEMPT',
              value: 'QUOTATION.PRICE.EXEMPT'
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.string,
            translations: {
              title: 'QUOTATION.PRICE.SUPPLEMENTARY_SERVICES',
              placeholder: 'QUOTATION.PRICE.SEE_PAGE_2',
              value: 'QUOTATION.PRICE.SEE_PAGE_2'
            }
          },
          {
            hasSubTitle: true,
            type: EnumQuotationValueType.totalAmountPayment,
            translations: {
              subTitle: 'QUOTATION.PRICE.TOTAL_PRICE_ALL_INCLUDED',
              placeholder: this.formatPrice(30750)
            }
          },
          {
            hasTitle: true,
            type: EnumQuotationValueType.pricePerPax,
            translations: {
              title: 'QUOTATION.PRICE.PRICE_ALL_INCLUDED_PER_PASSENGER',
              placeholder: this.formatPrice(200)
            }
          }
        ];
        this.defaultServicesSections = [
          {
            translations: {
              title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.TITLE'
            },
            services: [
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.PREPARATION_AND_FLIGHT_FOLLOW_UP',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.ARTHEAU_AVIATION_AGENT_ON_DEPARTURE',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.DISPLAY_SCREEN_ACCORDING_TO_AIRPORT',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.MOBILE_CUSTOM_DESK',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.AIRPORT_TRANSFERTS',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_CHECKIN',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.VIP_TERMINAL',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.LABELS_LUGGAGE_PERSONALIZED',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.BEFORE_FLIGHT.HEADCOVERS',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              }
            ]
          },
          {
            translations: { title: 'QUOTATION.SERVICES.ABOARD.TITLE' },
            services: [
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.ARTHEAU_AVIATION_REPRESENTATIVE_ON_BOARD',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.OPERATIONAL_SUPPORT_24H',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: true,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.CATERING_STANDARD',
                  comment: 'QUOTATION.SERVICES.INCLUDED'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.VIP_CATERING',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.HEADREST',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.CUSTOMIZATION_INSIDE',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.CUSTOMIZATION_OUTSIDE',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.ABOARD.NEWSPAPER',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              }
            ]
          },
          {
            translations: {
              title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TITLE'
            },
            services: [
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.LUGGAGES_PLUS',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.AIRPORT_TRANSFER',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              },
              {
                checked: false,
                translations: {
                  title: 'QUOTATION.SERVICES.AFTER_FLIGHT.TERMINAL_VIP',
                  comment: 'QUOTATION.SERVICES.UPON_REQUEST'
                }
              }
            ]
          }
        ];
        break;
    }

    switch (this.enquiryType.value) {
      case EnumEnquiryType.business:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_3'));
        break;
      case EnumEnquiryType.helico:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
      case EnumEnquiryType.commercial:
        this.form
          .get('garantiesAndEngagements')
          .setValue(this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      case EnumEnquiryType.cargo:
        this.form
          .get('garantiesAndEngagements')
          .setValue(this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      default:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
    }

    if (!this.aircraftInfos.length) {
      for (const defaultAircraftInfo of this.defaultAircraftInfos) {
        const item: IQuotationAircraftInfo = Object.assign({}, defaultAircraftInfo);

        for (const field in item.translations) {
          item[field] = this.getTranslation(item.translations[field]);
        }

        this.addAircraftInfoAndSet(item);
      }
    }

    if (!this.priceInfos.length) {
      for (const defaultPriceInfo of this.defaultPriceInfos) {
        const item: IQuotationPriceInfo = Object.assign({}, defaultPriceInfo);

        for (const field in item.translations) {
          item[field] = this.getTranslation(item.translations[field]);
        }

        this.addPriceInfoAndSet(item);
      }
    }

    this.refreshVATFieldValue();

    if (!this.servicesSections.length) {
      for (const servicesSections of this.defaultServicesSections) {
        const item: IQuotationSection = Object.assign({}, servicesSections);

        for (const field in item.translations) {
          item[field] = this.getTranslation(item.translations[field]);
        }

        if (item.services?.length) {
          for (let i = 0; i < item.services.length; i++) {
            for (const field in item.services[i].translations) {
              item.services[i][field] = this.getTranslation(item.services[i].translations[field]);
            }
          }
        }

        this.addServicesSectionAndSet(item);
      }
    }
  }

  addAircraftInfo(): void {
    this.aircraftInfos.push(
      new FormGroup({
        title: new FormControl(''),
        subTitle: new FormControl(''),
        hasTitle: new FormControl(false),
        hasSubTitle: new FormControl(false),
        type: new FormControl(''),
        placeholder: new FormControl(''),
        value: new FormControl(''),
        translations: new FormControl(null)
      })
    );
  }

  addAircraftInfoAndSet(aircraftInfo: IQuotationAircraftInfo): void {
    this.addAircraftInfo();

    const i: number = this.aircraftInfos.length - 1;

    for (const field in this.getAircraftInfo(i).value) {
      if (typeof aircraftInfo[field] !== 'undefined') {
        switch (field) {
          case 'title':
            const titleArr: string[] = aircraftInfo.title.split('<br>');

            let title: string = '';
            let subTitle: string = '';
            let hasTitle: boolean = false;
            let hasSubTitle: boolean = false;

            for (const str of titleArr) {
              if (str.match('<strong>')) {
                subTitle = convertHtmlToPlainText(str);
                hasSubTitle = true;
              } else {
                title = str;
                hasTitle = true;
              }
            }

            this.getAircraftInfoField(i, 'title').setValue(title);
            this.getAircraftInfoField(i, 'subTitle').setValue(subTitle);
            this.getAircraftInfoField(i, 'hasTitle').setValue(hasTitle);
            this.getAircraftInfoField(i, 'hasSubTitle').setValue(hasSubTitle);

            this.getAircraftInfoField(i, 'title').updateValueAndValidity();
            this.getAircraftInfoField(i, 'subTitle').updateValueAndValidity();
            this.getAircraftInfoField(i, 'hasTitle').updateValueAndValidity();
            this.getAircraftInfoField(i, 'hasSubTitle').updateValueAndValidity();
            break;
          default:
            this.getAircraftInfoField(i, field).setValue(aircraftInfo[field]);
            this.getAircraftInfoField(i, field).updateValueAndValidity();
            break;
        }
      }
    }
  }

  removeAircraftInfo(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.aircraftInfos.removeAt(i);
    }
  }

  addPriceInfo(): void {
    this.priceInfos.push(
      new FormGroup({
        title: new FormControl(''),
        subTitle: new FormControl(''),
        hasTitle: new FormControl(false),
        hasSubTitle: new FormControl(false),
        type: new FormControl(''),
        placeholder: new FormControl(''),
        value: new FormControl(''),
        translations: new FormControl(null)
      })
    );
  }

  addPriceInfoAndSet(priceInfo: IQuotationPriceInfo): void {
    this.addPriceInfo();

    const i: number = this.priceInfos.length - 1;

    for (const field in this.getPriceInfo(i).value) {
      if (typeof priceInfo[field] !== 'undefined') {
        switch (field) {
          case 'title':
            const titleArr: string[] = priceInfo.title.split('<br>');

            let title: string = '';
            let subTitle: string = '';
            let hasTitle: boolean = false;
            let hasSubTitle: boolean = false;

            for (const str of titleArr) {
              if (str.match('<strong>')) {
                subTitle = convertHtmlToPlainText(str);
                hasSubTitle = true;
              } else {
                title = str;
                hasTitle = true;
              }
            }

            this.getPriceInfoField(i, 'title').setValue(title);
            this.getPriceInfoField(i, 'subTitle').setValue(subTitle);
            this.getPriceInfoField(i, 'hasTitle').setValue(hasTitle);
            this.getPriceInfoField(i, 'hasSubTitle').setValue(hasSubTitle);

            this.getPriceInfoField(i, 'title').updateValueAndValidity();
            this.getPriceInfoField(i, 'subTitle').updateValueAndValidity();
            this.getPriceInfoField(i, 'hasTitle').updateValueAndValidity();
            this.getPriceInfoField(i, 'hasSubTitle').updateValueAndValidity();
            break;
          default:
            this.getPriceInfoField(i, field).setValue(priceInfo[field]);
            this.getPriceInfoField(i, field).updateValueAndValidity();
            break;
        }
      }
    }
  }

  removePriceInfo(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
      this.priceInfos.removeAt(i);
    }
  }

  addServicesSection(): void {
    this.servicesSections.push(
      new FormGroup({
        title: new FormControl(null, Validators.required),
        translations: new FormControl(null),
        services: new FormArray([])
      })
    );
  }

  addServicesSectionAndSet(section: IQuotationSection): void {
    this.addServicesSection();

    const i: number = this.servicesSections.length - 1;

    for (const field in this.getServicesSection(i).value) {
      if (typeof section[field] !== 'undefined') {
        switch (field) {
          case 'services':
            for (const service of section.services) {
              this.addServiceToSectionAndSet(i, service);
            }
            break;
          default:
            this.getServicesSectionField(i, field).setValue(section[field]);
            this.getServicesSectionField(i, field).updateValueAndValidity();
            break;
        }
      }
    }
  }

  removeServicesSection(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette section ?')) {
      this.servicesSections.removeAt(i);
    }
  }

  addServiceToSection(i: number): void {
    this.getServicesInSection(i).push(
      new FormGroup({
        title: new FormControl('', Validators.required),
        checked: new FormControl(false),
        comment: new FormControl(''),
        translations: new FormControl(null)
      })
    );
  }

  addServiceToSectionAndSet(i: number, service: IQuotationSectionService): void {
    this.addServiceToSection(i);

    const j: number = this.getServicesInSection(i).length - 1;

    for (const field in this.getServiceInSection(i, j).value) {
      if (typeof service[field] !== 'undefined') {
        this.getServiceFieldInSection(i, j, field).setValue(service[field]);
        this.getServiceFieldInSection(i, j, field).updateValueAndValidity();
      }
    }
  }

  removeServiceFromSection(i: number, j: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer ce service ?')) {
      this.getServicesInSection(i).removeAt(j);
    }
  }

  updateAirport(legIndex: number, airportField: string) {
    this.setTimezoneForAirport(legIndex, airportField);
    this.generateGoogleMapStaticUrl();

    this.refreshVATFieldValue();
  }

  setTimezoneForAirports(legIndex: number): void {
    for (const airportField of ['airportDepart', 'airportDestination']) {
      this.setTimezoneForAirport(legIndex, airportField);
    }
  }

  async setTimezoneForAirport(i: number, airportField: string) {
    if (this.getTripField(i, airportField).value) {
      let legDate: string = this.getTripField(i, 'date').value;

      if (this.getTripField(i, 'time').value) {
        legDate += 'T' + this.getTripField(i, 'time').value;
      } else {
        legDate += 'T12:00';
      }

      if (typeof this.airportsObj[this.getTripField(i, airportField).value] === 'undefined') {
        await this.loadAirport(this.getTripField(i, airportField).value);
      }

      const result: object = await this.airportService.getAirportTimezone(
        this.airportsObj[this.getTripField(i, airportField).value],
        new Date(legDate)
      );

      if (result) {
        const timezoneRawOffset: number = result['rawOffset'] + result['dstOffset'];
        const timezoneGmt: number = timezoneRawOffset / 3600;

        this.getTripField(i, airportField + 'TimezoneId').setValue(result['timeZoneId']);
        this.getTripField(i, airportField + 'TimezoneRawOffset').setValue(timezoneRawOffset);
        this.getTripField(i, airportField + 'TimezoneGmt').setValue(
          timezoneGmt >= 0 ? '+' + timezoneGmt : timezoneGmt?.toString()
        );
      }
    } else {
      this.getTripField(i, airportField + 'TimezoneId').setValue(null);
      this.getTripField(i, airportField + 'TimezoneRawOffset').setValue(null);
      this.getTripField(i, airportField + 'TimezoneGmt').setValue(null);
    }
  }

  async loadClient(): Promise<void> {
    if (this.enquiry.clientId) {
      this.client = await this.pipedriveService.getOrganization(this.enquiry.clientId.toString());
    }

    if (this.enquiry.contactId) {
      this.contact = await this.pipedriveService.getPerson(this.enquiry.contactId);
    }
  }

  loadUser(): void {
    if (this.enquiry.processedBy) {
      this.subscriptions.add(
        this.userService.getFromId(this.enquiry.processedBy).subscribe((user: IUser) => {
          this.user = user;

          this.form.get('agentFirstname').setValue(this.user?.firstname || null);
          this.form.get('agentLastname').setValue(this.user?.lastname || null);
          this.form.get('agentPhone').setValue(this.user?.phone || null);
          this.form.get('agentEmail').setValue(this.user?.email || null);
        })
      );
    }
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (aircraftModelId) {
      this.subscriptions.add(
        this.aircraftModelService
          .getFromId(aircraftModelId)
          .subscribe((aircraftModel: IAircraftModel) => {
            this.aircraftModelsObj[aircraftModel.id] = aircraftModel;

            if (this.aircraftModelsObj[aircraftModel.id] && !this.currentAircraftModelInitiated) {
              this.currentAircraftModelInitiated = true;

              if (this.aircraftModelsObj[aircraftModel.id].imageUrl && !this.form.value.image1Url) {
                this.form
                  .get('image1Url')
                  .setValue(this.aircraftModelsObj[aircraftModel.id].imageUrl);
              }
              if (
                this.aircraftModelsObj[aircraftModel.id].imagePlanUrl &&
                !this.form.value.image2Url
              ) {
                this.form
                  .get('image2Url')
                  .setValue(this.aircraftModelsObj[aircraftModel.id].imagePlanUrl);
              }
              if (
                this.aircraftModelsObj[aircraftModel.id].imageInsideUrl &&
                !this.form.value.image3Url
              ) {
                this.form
                  .get('image3Url')
                  .setValue(this.aircraftModelsObj[aircraftModel.id].imageInsideUrl);
              }
            }
          })
      );
    }
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadQuotation();
      this.loadEnquiry();
      this.loadAircraftModels();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEnquiryQuotations(): Promise<void> {
    this.quotations = await this.remoteService.getEnquiryCotations(this.enquiryId);
  }

  loadEnquiry(): void {
    if (this.enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(this.enquiryId).subscribe((enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          this.setBreadcrumbsItems();

          if (this.enquiry) {
            if (!this.enquiryType.value) {
              this.enquiryType.setValue(this.enquiry.type);
            }
          }
        })
      );
    }
  }

  loadEnquiryOffers(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.enquiryOfferService
          .getAllForEnquiry(this.enquiryId)
          .subscribe((enquiryOffers: IEnquiryOffer[]) => {
            this.enquiryOffers = enquiryOffers;

            this.setCurrentCotation();

            resolve();
          })
      );
    });
  }

  loadEnquiryCotations(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.subscriptions.add(
        this.enquiryCotationService
          .getAllForEnquiry(this.enquiryId)
          .subscribe(async (enquiryCotations: IEnquiryCotation[]) => {
            this.enquiryCotations = enquiryCotations;

            if (this.enquiryCotations.length && !this.enquiryOffers.length) {
              await this.loadEnquiryOffers();
            }

            resolve();
          })
      );
    });
  }

  loadQuotation(): void {
    if (this.quotationId) {
      const sub = this.quotationService
        .getFromId(this.quotationId)
        .subscribe((quotation: IQuotation | null) => {
          sub.unsubscribe();

          this.quotation = quotation;

          this.setQuotation();
        });
    }
  }

  async setQuotation(): Promise<void> {
    if (this.form && this.quotation) {
      this.form = this.resetForm();

      this.setBreadcrumbsItems();

      for (const field in this.form.value) {
        if (typeof this.quotation[field] !== 'undefined') {
          switch (field) {
            case 'trips':
              for (const trip of this.quotation.trips) {
                this.addTripAndSet(trip);
              }
              break;
            case 'aircraftInfos':
              for (const aircraftInfo of this.quotation.aircraftInfos) {
                this.addAircraftInfoAndSet(this.fixMissingTranslationForItemInfo(aircraftInfo));
              }
              break;
            case 'priceInfos':
              for (const priceInfo of this.quotation.priceInfos) {
                this.addPriceInfoAndSet(this.fixMissingTranslationForItemInfo(priceInfo));
              }
              break;
            case 'paymentConditionsSettings':
              for (const module in this.paymentConditionsSettings.value) {
                if (typeof this.quotation.paymentConditionsSettings[module] !== 'undefined') {
                  for (const moduleField in this.paymentConditionsSettings.value[module]) {
                    if (
                      typeof this.quotation.paymentConditionsSettings[module][moduleField] !==
                      'undefined'
                    ) {
                      this.paymentConditionsSettings
                        .get(module)
                        .get(moduleField)
                        .setValue(this.quotation.paymentConditionsSettings[module][moduleField]);
                    }
                  }
                }
              }

              this.setPaymentTexts();
              break;
            case 'cancellingConditionsSettings':
              for (const module in this.cancellingConditionsSettings.value) {
                if (typeof this.quotation.cancellingConditionsSettings[module] !== 'undefined') {
                  for (const moduleField in this.cancellingConditionsSettings.value[module]) {
                    if (
                      typeof this.quotation.cancellingConditionsSettings[module][moduleField] !==
                      'undefined'
                    ) {
                      this.cancellingConditionsSettings
                        .get(module)
                        .get(moduleField)
                        .setValue(this.quotation.cancellingConditionsSettings[module][moduleField]);
                    }
                  }
                }
              }

              this.setCancellingTexts();
              break;
            case 'servicesSections':
              for (const servicesSection of this.quotation.servicesSections) {
                this.addServicesSectionAndSet(
                  this.fixMissingTranslationForServiceSection(servicesSection)
                );
              }
              break;
            default:
              this.form.get(field).setValue(this.quotation[field]);
              break;
          }
        }
      }

      this.enquiryId = this.quotation.enquiryId;

      this.loadEnquiry();

      this.setDefaultFields();

      this.generateGoogleMapStaticUrl();

      this.form.enable();
    }
  }

  setPaymentTexts(force: boolean = false): void {
    if (
      force ||
      !this.paymentConditionsSettings.value.firstDeposit.text ||
      this.paymentConditionsSettings.value.firstDeposit.text === ''
    ) {
      this.form
        .get('paymentConditionsSettings')
        .get('firstDeposit')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.PAYMENT_CONDITIONS_1'));
    }
    if (
      force ||
      !this.paymentConditionsSettings.value.lastDeposit.text ||
      this.paymentConditionsSettings.value.lastDeposit.text === ''
    ) {
      this.form
        .get('paymentConditionsSettings')
        .get('lastDeposit')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.PAYMENT_CONDITIONS_2'));
    }
  }

  setCancellingTexts(force: boolean = false): void {
    if (
      force ||
      !this.cancellingConditionsSettings.value.firstStep.text ||
      this.cancellingConditionsSettings.value.firstStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('firstStep')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_1'));
    }
    if (
      force ||
      !this.cancellingConditionsSettings.value.secondStep.text ||
      this.cancellingConditionsSettings.value.secondStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('secondStep')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_2'));
    }
    if (
      force ||
      !this.cancellingConditionsSettings.value.thirdStep.text ||
      this.cancellingConditionsSettings.value.thirdStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('thirdStep')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_3'));
    }
    if (
      force ||
      !this.cancellingConditionsSettings.value.fourthStep.text ||
      this.cancellingConditionsSettings.value.fourthStep.text === ''
    ) {
      this.form
        .get('cancellingConditionsSettings')
        .get('fourthStep')
        .get('text')
        .setValue(this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_4'));
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }

        for (const subfield in data[field]) {
          if (typeof data[field][subfield] == 'undefined') {
            data[field][subfield] = null;
          }

          for (const subsubfield in data[field][subfield]) {
            if (typeof data[field][subfield][subsubfield] == 'undefined') {
              data[field][subfield][subsubfield] = null;
            }
          }
        }
      }

      data['contactTitle'] = data['contactFirstname'] + ' ' + data['contactLastname'];
      data['agentTitle'] = data['agentFirstname'] + ' ' + data['agentLastname'];

      for (const fieldCotation of ['aircraftInfos', 'priceInfos']) {
        for (const cotationItem of data[fieldCotation]) {
          // Format title field
          let titleArr: string[] = [];

          if (cotationItem.hasTitle) {
            titleArr.push(cotationItem.title);
          }
          if (cotationItem.hasSubTitle) {
            titleArr.push('<strong>' + cotationItem.subTitle + '</strong>');
          }

          cotationItem.title = titleArr.join('<br>');

          delete cotationItem.hasTitle;
          delete cotationItem.hasSubTitle;
          delete cotationItem.subTitle;
          delete cotationItem.placeholder;
        }
      }

      data.paymentConditions = '';

      if (data.paymentConditionsSettings.firstDeposit.percent) {
        let text = data.paymentConditionsSettings.firstDeposit.text;

        text = text.replace('%1', data.paymentConditionsSettings.firstDeposit.percent);
        text = text.replace(
          '%MONTANT_STEP_1',
          this.getValuePercentOfTotalAmount(data.paymentConditionsSettings.firstDeposit.percent)
        );

        data.paymentConditions += text + '\n';
      }
      if (data.paymentConditionsSettings.lastDeposit.percent) {
        let text = data.paymentConditionsSettings.lastDeposit.text;

        text = text.replace('%2', data.paymentConditionsSettings.lastDeposit.percent);
        text = text.replace('%3', data.paymentConditionsSettings.lastDeposit.daysBefore);
        text = text.replace(
          '%MONTANT_STEP_2',
          this.getValuePercentOfTotalAmount(data.paymentConditionsSettings.lastDeposit.percent)
        );

        data.paymentConditions += text + '\n';
      }

      const cancellingConditions: {
        firstStep: string;
        secondStep: string;
        thirdStep: string;
        fourthStep: string;
      } = {
        firstStep: this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_1'),
        secondStep: this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_2'),
        thirdStep: this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_3'),
        fourthStep: this.getTranslation('QUOTATION.CANCELLING_CONDITIONS_4')
      };

      data.cancellingConditions = '';

      if (data.cancellingConditionsSettings.firstStep.percent) {
        let text = data.cancellingConditionsSettings.firstStep.text;

        text = text.replace('%1', data.cancellingConditionsSettings.firstStep.percent);
        text = text.replace('%2', data.cancellingConditionsSettings.firstStep.daysBefore);
        text = text.replace(
          '%MONTANT_STEP_1',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.firstStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.secondStep.percent) {
        let text = data.cancellingConditionsSettings.secondStep.text;

        text = text.replace('%3', data.cancellingConditionsSettings.secondStep.percent);
        text = text.replace('%4', data.cancellingConditionsSettings.secondStep.daysBeforeStart);
        text = text.replace('%5', data.cancellingConditionsSettings.secondStep.daysBeforeEnd);

        text = text.replace(
          '%MONTANT_STEP_2',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.secondStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.thirdStep.percent) {
        let text = data.cancellingConditionsSettings.thirdStep.text;

        text = text.replace('%6', data.cancellingConditionsSettings.thirdStep.percent);
        text = text.replace('%7', data.cancellingConditionsSettings.thirdStep.daysBeforeStart);
        text = text.replace('%8', data.cancellingConditionsSettings.thirdStep.hoursBeforeEnd);

        text = text.replace(
          '%MONTANT_STEP_3',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.thirdStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }
      if (data.cancellingConditionsSettings.fourthStep.percent) {
        let text = data.cancellingConditionsSettings.fourthStep.text;

        text = text.replace('%9', data.cancellingConditionsSettings.fourthStep.percent);
        text = text.replace('%10', data.cancellingConditionsSettings.fourthStep.hoursBefore);

        text = text.replace(
          '%MONTANT_STEP_4',
          this.getValuePercentOfTotalAmount(data.cancellingConditionsSettings.fourthStep.percent)
        );

        data.cancellingConditions += text + '\n';
      }

      this.form.disable();

      if (this.quotationId) {
        this.remoteService
          .updateDocumentToCollection('quotations', this.quotationId, data)
          .then(async () => {
            await this.loaderService.hideLoaderOnSuccess();
            this.redirectAfterSaving();
          })
          .catch(async err => {
            await this.loaderService.hideLoaderOnFailure(err);
            this.form.enable();
          });
      } else {
        data.status = EnumQuotationStatus.draft;

        this.remoteService
          .addDocumentToCollection('quotations', data)
          .then(async (docId: string) => {
            this.quotationId = docId;

            await this.loaderService.hideLoaderOnSuccess();

            this.redirectAfterSaving();
          })
          .catch(async err => {
            await this.loaderService.hideLoaderOnFailure(err);
            this.form.enable();
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/quotations/' + this.quotationId]);
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      if (
        [
          'airportDepart',
          'airportDestination',
          'fuelStopOverAirport',
          'commercialStopOverAirport'
        ].indexOf(nameList[nameList.length - 1]) !== -1
      ) {
        await this.loadAirport(field.value);
      }

      if (['airportDepart', 'airportDestination'].indexOf(nameList[nameList.length - 1]) !== -1) {
        this.updateAirport(parseInt(nameList[1]), nameList[nameList.length - 1]);
      }

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  async loadAirport(airportId: string): Promise<void> {
    if (airportId && !this.airportsObj[airportId]) {
      const doc: object = await this.remoteService.getDocument('airports', airportId);

      const airport = doc as IAirport;

      this.airportsObj[airport.id] = airport;

      for (let i = 0; i < this.trips.value.length; i++) {
        if (
          this.trips.value[i].airportDepart === airportId ||
          this.trips.value[i].airportDestination === airportId
        ) {
          this.tripTimesUpdated(i);
        }
      }
    }
  }

  updateLegDate(i: number): void {
    this.setTimezoneForAirports(i);
  }

  convertTimeToHour(time: string): number {
    const timeSplitted: string[] = time.split(':');

    const hours = parseInt(timeSplitted[0]);
    const minutes = parseInt(timeSplitted[1]);

    return (hours * 60 + minutes) / 60;
  }

  convertHourToTime(value: number): string {
    const totalMinutes = value * 60;
    const hours = totalMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return addZeroToDigit(rhours) + ':' + addZeroToDigit(rminutes);
  }

  async generateGoogleMapStaticUrl(): Promise<void> {
    let url: string = 'https://maps.googleapis.com/maps/api/staticmap?';

    url += 'size=300x220&scale=2';
    url += '&language=' + this.language.value;
    url += '&key=AIzaSyDyPoaFu7WvRZjqmSlQq0QdWA1FS_RLMQw';
    url += '&style=feature:road|visibility:off|';
    url += '&path=color:0x1c4d6bff|weight:2|geodesic:true|';

    const coordinates: string[] = [];

    if (this.trips.length) {
      for (const trip of this.trips.value) {
        for (const airportField of ['airportDepart', 'airportDestination']) {
          if (typeof this.airportsObj[trip[airportField]] === 'undefined') {
            await this.loadAirport(trip[airportField]);
          }

          if (trip[airportField] && this.airportsObj[trip[airportField]]) {
            coordinates.push(
              this.airportsObj[trip[airportField]].latitude +
                ',' +
                this.airportsObj[trip[airportField]].longitude
            );
          }
        }
      }
    }

    if (coordinates.length) {
      this.form.get('googleMapStaticUrl').setValue(url + coordinates.join('|'));
    } else {
      this.form.get('googleMapStaticUrl').setValue(null);
    }
  }

  formatPrice(value: number, currency: EnumCurrency = EnumCurrency.EUR): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0
    });

    return formatter.format(value);
  }

  triggerInputFile(): void {
    if (this.form.enabled) {
      document.getElementById('imageUrlInput').click();
    }
  }

  triggerInputFileForAircraftModel(): void {
    if (this.form.enabled) {
      document.getElementById('aircraftModelImageInput').click();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'quotation');
    }
  }

  changeInputFileForAircraftModel(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0], 'aircraftModel');
    }
  }

  upload(file: File, target: 'quotation' | 'aircraftModel'): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loaderService.presentLoader();

      this.form.disable();

      let folder: string = 'images';
      switch (target) {
        case 'quotation':
          folder = 'quotations/images';
          break;
        case 'aircraftModel':
          folder = 'aircraft-models/images';
          break;
      }

      const result = this.remoteService.upload(folder, generateRandomId(), file, 'file');

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe(async (downloadUrl: string) => {
              switch (target) {
                case 'quotation':
                  this.setCurrentImageFieldValue(downloadUrl);

                  (<HTMLInputElement>document.getElementById('imageUrlInput')).value = '';
                  break;
                case 'aircraftModel':
                  if (this.selectedImageFieldForAircraftModel) {
                    await this.updateImageFieldValueForAircraftModel(
                      this.selectedImageFieldForAircraftModel,
                      downloadUrl
                    );

                    if (!this.form.get(this.selectedImageField + 'Url')?.value) {
                      this.setCurrentImageFieldValue(downloadUrl);
                    }
                  }

                  (<HTMLInputElement>document.getElementById('aircraftModelImageInput')).value = '';
                  break;
              }

              await this.loaderService.hideLoaderOnSuccess();

              this.form.enable();

              this.form.updateValueAndValidity();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  changedLanguage(): void {
    this.generateGoogleMapStaticUrl();

    this.form.get('notes').setValue(this.getTranslation('QUOTATION.NOTES'));

    switch (this.enquiry.type) {
      case EnumEnquiryType.business:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_3'));
        break;
      case EnumEnquiryType.helico:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
      case EnumEnquiryType.commercial:
        this.form
          .get('garantiesAndEngagements')
          .setValue(this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      case EnumEnquiryType.cargo:
        this.form
          .get('garantiesAndEngagements')
          .setValue(this.getTranslation('QUOTATION.OUR_GUARANTIES_AND_ENGAGEMENTS_TEXT'));
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_2'));
        break;
      default:
        this.form
          .get('observationsTrips')
          .setValue(this.getTranslation('QUOTATION.OBSERVATIONS_TRIPS_TEXT_1'));
        break;
    }

    if (this.language.value) {
      let aircraftCompiled: IAircraftCompiled | null = null;

      if (!this.aircraftCompiledId.value) {
        if (this.selectedCotation?.aircraftCompiledId) {
          this.aircraftCompiledId.setValue(this.selectedCotation.aircraftCompiledId);
        } else if (this.selectedOffer?.aircraftCompiledId) {
          this.aircraftCompiledId.setValue(this.selectedOffer.aircraftCompiledId);
        }

        this.aircraftCompiledId.updateValueAndValidity();
      }

      if (this.selectedCotation?.aircraftCompiled) {
        aircraftCompiled = this.selectedCotation.aircraftCompiled;
      } else if (this.selectedOffer?.aircraftCompiled) {
        aircraftCompiled = this.selectedOffer.aircraftCompiled;
      }

      if (aircraftCompiled) {
        this.aircraftModelId.setValue(aircraftCompiled.aircraftModelId);
        this.aircraftModelId.updateValueAndValidity();

        this.updatedAircraftModelId();
      }

      for (let i = 0; i < this.aircraftInfos.length; i++) {
        if (this.aircraftInfos.at(i).value.translations) {
          for (const field in this.aircraftInfos.at(i).value.translations) {
            this.getAircraftInfoField(i, field).setValue(
              this.getTranslation(this.aircraftInfos.at(i).value.translations[field])
            );
          }
        }
      }

      for (let i = 0; i < this.priceInfos.length; i++) {
        if (this.priceInfos.at(i).value.translations) {
          for (const field in this.priceInfos.at(i).value.translations) {
            this.getPriceInfoField(i, field).setValue(
              this.getTranslation(this.priceInfos.at(i).value.translations[field])
            );
          }
        }
      }

      this.refreshVATFieldValue();

      if (this.selectedCotation?.nbPax) {
        this.applyPaxToPriceInfos(this.selectedCotation.nbPax);
      }
      this.updatedTotalAmountPayment();

      for (let i = 0; i < this.servicesSections.length; i++) {
        for (const field in this.servicesSections.at(i).value) {
          switch (field) {
            case 'services':
              for (let j = 0; j < this.getServicesInSection(i).length; j++) {
                for (const serviceField in this.getServiceInSection(i, j).value) {
                  if (
                    this.getServiceInSection(i, j).value.translations &&
                    this.getServiceInSection(i, j).value.translations[serviceField]
                  ) {
                    this.getServiceFieldInSection(i, j, serviceField).setValue(
                      this.getTranslation(
                        this.getServiceInSection(i, j).value.translations[serviceField]
                      )
                    );
                  }
                }
              }
              break;
            default:
              if (
                this.servicesSections.at(i).value.translations &&
                this.servicesSections.at(i).value.translations[field]
              ) {
                this.getServicesSectionField(i, field).setValue(
                  this.getTranslation(this.servicesSections.at(i).value.translations[field])
                );
              }
              break;
          }
        }
      }
    }

    this.setPaymentTexts(true);
    this.setCancellingTexts(true);
  }

  getTranslation(path: string, language: EnumLanguage | null = null): string {
    if (!language) {
      language = this.language.value;
    }

    if (language) {
      return this.getStringTranslation(language, path);
    } else {
      return path;
    }
  }

  loadLanguageTranslation(language: EnumLanguage): Promise<void> {
    return new Promise((resolve, reject) => {
      this.translateService.getTranslation(language).subscribe((translationObj: object) => {
        this.translationObj[language] = translationObj;

        resolve();
      });
    });
  }

  getStringTranslation(language: EnumLanguage, path: string): string {
    if (this.translationObj[language]) {
      let translation: object | string = Object.assign({}, this.translationObj[language]);
      const splittedPath: string[] = path ? path.split('.') : [];

      for (const onePath of splittedPath) {
        if (translation[onePath]) {
          translation = translation[onePath];
        } else {
          translation = path;
          break;
        }
      }

      return path ? translation.toString() : '';
    }
  }

  getValuePercentOfTotalAmount(percent: number): string {
    return this.formatPrice(
      (percent * this.form.value.totalAmountPayment) / 100,
      this.form.value.totalAmountPaymentCurrency
    );
  }

  getListOfAirportsGmt(): string {
    let listAirports: string[] = [];

    const airportsList: string[] = [];

    for (const trip of this.trips.value) {
      for (const airportField of ['airportDepart', 'airportDestination']) {
        if (trip[airportField] && airportsList.indexOf(trip[airportField]) === -1) {
          airportsList.push(trip[airportField]); // to avoid duplicate

          listAirports.push(
            this.airportsObj[trip[airportField]]
              ? this.removeAirportStr(this.airportsObj[trip[airportField]]?.title) +
                  ' : GMT ' +
                  trip[airportField + 'TimezoneGmt']
              : trip[airportField] + ' : GMT ' + trip[airportField + 'TimezoneGmt']
          );
        }
      }
    }

    return listAirports.join(' | ');
  }

  removeAirportStr(name: string): string {
    return name.replace('airport', '').replace('Airport', '').trim();
  }

  dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (differenceInDays(new Date(control.value), new Date(date)) < 0) {
        return {
          dateBelowMinimum: true,
          dateMinimum: new Date(date)
        };
      } else {
        return null;
      }
    };
  }

  tripTimesUpdated(i: number): void {
    const tripFormGroup: FormGroup = this.getTrip(i);

    if (
      tripFormGroup.value.airportDepart &&
      this.airportsObj[tripFormGroup.value.airportDepart] &&
      tripFormGroup.value.airportDestination &&
      this.airportsObj[tripFormGroup.value.airportDestination] &&
      tripFormGroup.value.time &&
      tripFormGroup.value.arrivalTime
    ) {
      const departureTimeUtc: Date = getAirportUTCTime(
        this.airportsObj[tripFormGroup.value.airportDepart],
        tripFormGroup.value.date,
        tripFormGroup.value.time
      );

      const arrivalTimeUtc: Date = getAirportUTCTime(
        this.airportsObj[tripFormGroup.value.airportDestination],
        tripFormGroup.value.date,
        tripFormGroup.value.arrivalTime
      );

      let diffInMinutes: number = differenceInMinutes(arrivalTimeUtc, departureTimeUtc);

      const flyTime: {
        hours: number;
        minutes: number;
      } = {
        hours: 0,
        minutes: 0
      };

      if (diffInMinutes < 0) {
        // Arrive the next day, we take this into account
        diffInMinutes = 24 * 60 - differenceInMinutes(departureTimeUtc, arrivalTimeUtc);
      }

      flyTime.hours = Math.floor(diffInMinutes / 60);
      flyTime.minutes = diffInMinutes - flyTime.hours * 60;

      tripFormGroup
        .get('flyTimeInHours')
        .setValue(addZeroToDigit(flyTime.hours) + ':' + addZeroToDigit(flyTime.minutes));
    }
  }

  setEstimatedArrivalTimeIfNotProvided(): void {
    if (
      this.trips.length &&
      this.aircraftModelId.value &&
      this.aircraftModelsObj[this.aircraftModelId.value]
    ) {
      for (let i = 0; i < this.trips.length; i++) {
        this.setEstimatedArrivalTimeIfNotProvidedForTrip(i);
      }
    }
  }

  setEstimatedArrivalTimeIfNotProvidedForTrip(
    tripIndex: number,
    forceUpdateArrivalTime: boolean = false
  ): void {
    const trip: IEnquiryTrip = this.getTrip(tripIndex).value;
    if (
      (!trip.arrivalTime || trip.arrivalTime === '' || forceUpdateArrivalTime) &&
      trip.airportDepart &&
      trip.airportDestination &&
      this.airportsObj[trip.airportDepart] &&
      this.airportsObj[trip.airportDestination]
    ) {
      this.getTrip(tripIndex)
        .get('arrivalTime')
        .setValue(this.getEstimatedArrivalTimeForTrip(tripIndex));
      this.tripTimesUpdated(tripIndex);
    }
  }

  getEstimatedArrivalTimeForTrip(tripIndex: number): string | null {
    const trip: IEnquiryTrip = this.getTrip(tripIndex).value;

    if (
      trip.date &&
      trip.time &&
      this.airportsObj[trip.airportDepart] &&
      this.airportsObj[trip.airportDestination]
    ) {
      // Set estimated arrival time
      let selectedAircraftModel: IAircraftModel | null = null;

      if (trip.aircraftModelId && this.aircraftModelsObj[trip.aircraftModelId]) {
        selectedAircraftModel = this.aircraftModelsObj[trip.aircraftModelId];
      }

      if (selectedAircraftModel) {
        const flyTimeInMinutes: number = estimatedFlyTimeInMinutes(
          selectedAircraftModel,
          this.airportsObj[trip.airportDepart],
          this.airportsObj[trip.airportDestination]
        );

        if (flyTimeInMinutes) {
          const arrivalTimeUtc: Date = addMinutes(
            getAirportUTCTime(this.airportsObj[trip.airportDepart], trip.date, trip.time),
            flyTimeInMinutes
          );

          return getAirportLocalTimeFromUTC(this.airportsObj[trip.airportDepart], arrivalTimeUtc);
        }
      }
    }

    return null;
  }

  updatedPriceValue(i: number): void {
    const passengerStrSingular: string = this.getTranslation('QUOTATION.PRICE.PASSENGER');
    const passengerStrPlural: string = this.getTranslation('QUOTATION.PRICE.PASSENGERS');

    if ([passengerStrSingular, passengerStrPlural].includes(this.getPriceInfo(i).value.title)) {
      // We are on the field for passenger number

      this.getPriceInfo(i)
        .get('title')
        .setValue(this.getPriceInfo(i).value.value > 1 ? passengerStrPlural : passengerStrSingular);
      this.getPriceInfo(i).get('title').updateValueAndValidity();
    }
  }

  loadAircraftModels(): void {
    this.loadingAircraftModels = true;

    this.subscriptions.add(
      this.aircraftModelService.getAll().subscribe((aircraftModels: IAircraftModel[]) => {
        this.aircraftModels = aircraftModels;

        for (const aircraftModel of this.aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }

        this.aircraftModels.sort((a, b) => (a.title < b.title ? -1 : 1));

        this.loadingAircraftModels = false;
      })
    );
  }

  openImageModal(field: 'image1' | 'image2' | 'image3'): void {
    this.selectedImageField = field;

    window['$'](this.modalImageElement.nativeElement).modal('show');
  }

  async editImageToAircraftModel(
    field: 'imageUrl' | 'imageInsideUrl' | 'imagePlanUrl'
  ): Promise<void> {
    this.selectedImageFieldForAircraftModel = field;

    this.triggerInputFileForAircraftModel();
  }

  async deleteImageFromAircraftModel(field: string): Promise<void> {
    if (
      this.aircraftModelId.value &&
      this.aircraftModelsObj[this.aircraftModelId.value] &&
      confirm(
        'Êtes-vous sûr de vouloir cette image du modèle ' +
          this.aircraftModelsObj[this.aircraftModelId.value].title +
          ' ?'
      )
    ) {
      await this.updateImageFieldValueForAircraftModel(field, null);
    }
  }

  async updateImageFieldValueForAircraftModel(field: string, value: string | null): Promise<void> {
    if (this.aircraftModelId.value && this.aircraftModelsObj[this.aircraftModelId.value]) {
      this.loaderService.presentLoader();

      try {
        for (const imageField of ['image1Url', 'image2Url', 'image3Url']) {
          if (
            this.form.get(imageField).value ===
            this.aircraftModelsObj[this.aircraftModelId.value][field]
          ) {
            this.form.get(imageField).setValue(null);
          }
        }

        const data: IAircraftModel = {
          id: this.aircraftModelId.value
        } as IAircraftModel;

        data[field] = value;

        await this.aircraftModelService.update(data);

        await this.loaderService.hideLoaderOnSuccess();
      } catch (err) {
        await this.loaderService.hideLoaderOnFailure(err.message);
      }
    }
  }

  setCurrentImageFieldValue(value: string | null): void {
    if (this.selectedImageField) {
      this.form.get(this.selectedImageField + 'Url').setValue(value);
    }
  }

  toggleSelectedImageValue(value: string): void {
    if (this.selectedImageField) {
      this.form
        .get(this.selectedImageField + 'Url')
        .setValue(this.form.get(this.selectedImageField + 'Url').value === value ? null : value);
    }
  }

  editSelectedImage(): void {
    this.triggerInputFile();
  }

  deleteSelectedImage(): void {
    this.form.get(this.selectedImageField + 'Url').setValue(null);
  }

  async searchAircraftByRegistration(): Promise<void> {
    if (this.queryAircraftRegistration) {
      this.loaderService.presentLoader();

      this.subscriptionsAircraft.add(
        this.aircraftService
          .getOneByRegistration(this.queryAircraftRegistration.trim())
          .subscribe((aircraft: IAircraft | null) => {
            this.aircraft = aircraft;

            if (this.aircraft) {
              this.aircraftId.setValue(this.aircraft.id);
              this.aircraftModelId.setValue(this.aircraft.aircraftModelId);

              this.updatedAircraftModelId();

              this.aircraftId.updateValueAndValidity();
              this.aircraftModelId.updateValueAndValidity();

              if (this.selectedImageField) {
                for (const field of ['image1', 'image2']) {
                  if (!this.form.get(this.selectedImageField + 'Url').value) {
                    switch (this.selectedImageField) {
                      case 'image1':
                        if (this.aircraft.imageOutsideUrl) {
                          this.toggleSelectedImageValue(this.aircraft.imageOutsideUrl);
                        }
                        break;
                      case 'image2':
                        if (this.aircraft.imageOutsideUrl) {
                          this.toggleSelectedImageValue(this.aircraft.imageInsideUrl);
                        }
                        break;
                    }
                  }
                }
              }

              this.loaderService.hideLoaderOnSuccess();
            } else {
              this.loaderService.hideLoaderOnFailure('Aucun appareil avec cette immatriculation');
            }
          })
      );
    }
  }

  refreshAircraftModelIdInTrips(): void {
    for (let i = 0; i < this.trips.length; i++) {
      this.getTripField(i, 'aircraftModelId').setValue(this.aircraftModelId.value);
      this.getTripField(i, 'aircraftModelId').updateValueAndValidity();

      this.setEstimatedArrivalTimeIfNotProvidedForTrip(i);
    }
  }

  updateVersionNumber(): void {
    this.refreshQuotationTitle();
  }

  moveTrip(currentIndex: number, newIndex: number): void {
    const currentGroup: FormGroup = this.getTrip(currentIndex);
    this.trips.removeAt(currentIndex);
    this.trips.insert(newIndex, currentGroup);
  }

  moveTripUp(i: number): void {
    this.moveTrip(i, i - 1);
  }

  moveTripDown(i: number): void {
    this.moveTrip(i, i + 1);
  }

  getArrivalTimeEstimation(tripIndex: number): string | null {
    if (
      this.aircraftModelId.value &&
      this.aircraftModelsObj[this.aircraftModelId.value] &&
      this.getTripField(tripIndex, 'date').value &&
      this.getTripField(tripIndex, 'time').value &&
      this.getTripField(tripIndex, 'airportDepart').value &&
      this.getTripField(tripIndex, 'airportDestination').value &&
      this.airportsObj[this.getTripField(tripIndex, 'airportDepart').value] &&
      this.airportsObj[this.getTripField(tripIndex, 'airportDestination').value]
    ) {
      const flyTimeInMinutes: number = estimatedFlyTimeInMinutes(
        this.aircraftModelsObj[this.aircraftModelId.value],
        this.airportsObj[this.getTripField(tripIndex, 'airportDepart').value],
        this.airportsObj[this.getTripField(tripIndex, 'airportDestination').value]
      );

      if (flyTimeInMinutes) {
        const arrivalTimeUtc: Date = addMinutes(
          getAirportUTCTime(
            this.airportsObj[this.getTripField(tripIndex, 'airportDepart').value],
            this.getTripField(tripIndex, 'date').value,
            this.getTripField(tripIndex, 'time').value
          ),
          flyTimeInMinutes
        );

        return getAirportLocalTimeFromUTC(
          this.airportsObj[this.getTripField(tripIndex, 'airportDepart').value],
          arrivalTimeUtc
        );
      }
    }

    return null;
  }

  applyEstimatedArrivalTimeToTrip(tripIndex: number): void {
    this.getTripField(tripIndex, 'arrivalTime').setValue(this.getArrivalTimeEstimation(tripIndex));
    this.getTripField(tripIndex, 'arrivalTime').updateValueAndValidity();
  }

  resetForm(): FormGroup {
    return new FormGroup({
      enquiryId: new FormControl('', [Validators.required]),
      enquiryType: new FormControl('', [Validators.required]),
      date: new FormControl(format(new Date(), 'yyyy-MM-dd'), [
        Validators.required,
        this.dateMinimum('2000-01-01')
      ]),
      companyTitle: new FormControl('Artheau Aviation', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      versionNumber: new FormControl(1, [Validators.required]),
      language: new FormControl(EnumLanguage.fr, [Validators.required]),
      clientId: new FormControl('', [Validators.required]),
      clientTitle: new FormControl('', [Validators.required]),
      contactId: new FormControl('', [Validators.required]),
      contactFirstname: new FormControl('', [Validators.required]),
      contactLastname: new FormControl('', [Validators.required]),
      contactPhone: new FormControl(''),
      contactEmail: new FormControl(''),
      agentId: new FormControl('', [Validators.required]),
      agentFirstname: new FormControl('', [Validators.required]),
      agentLastname: new FormControl('', [Validators.required]),
      agentPhone: new FormControl(''),
      agentEmail: new FormControl(''),
      trips: new FormArray([]),
      notes: new FormControl(''),
      aircraftInfos: new FormArray([]),
      priceInfos: new FormArray([]),
      paymentConditionsSettings: new FormGroup({
        firstDeposit: new FormGroup({
          percent: new FormControl(30),
          text: new FormControl('')
        }),
        lastDeposit: new FormGroup({
          percent: new FormControl(70),
          daysBefore: new FormControl(2),
          text: new FormControl('')
        })
      }),
      cancellingConditionsSettings: new FormGroup({
        firstStep: new FormGroup({
          percent: new FormControl(30),
          daysBefore: new FormControl(15),
          text: new FormControl('')
        }),
        secondStep: new FormGroup({
          percent: new FormControl(50),
          daysBeforeStart: new FormControl(14),
          daysBeforeEnd: new FormControl(8),
          text: new FormControl('')
        }),
        thirdStep: new FormGroup({
          percent: new FormControl(75),
          daysBeforeStart: new FormControl(7),
          hoursBeforeEnd: new FormControl(49),
          text: new FormControl('')
        }),
        fourthStep: new FormGroup({
          percent: new FormControl(100),
          hoursBefore: new FormControl(48),
          text: new FormControl('')
        })
      }),
      servicesSections: new FormArray([]),
      googleMapStaticUrl: new FormControl(''),
      image1Url: new FormControl(''),
      image2Url: new FormControl(''),
      image3Url: new FormControl(''),
      image1ZoomLevel: new FormControl(1),
      image2ZoomLevel: new FormControl(1),
      image3ZoomLevel: new FormControl(1),
      garantiesAndEngagements: new FormControl(''),
      observationsTrips: new FormControl(''),
      totalAmountPayment: new FormControl(0),
      totalAmountPaymentCurrency: new FormControl(EnumCurrency.EUR),
      aircraftCompiledId: new FormControl(null),
      aircraftModelId: new FormControl(null),
      aircraftId: new FormControl(null)
    });
  }

  removeAircraftId(): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette appareil ?')) {
      this.aircraftId.setValue(null);
      this.aircraftId.updateValueAndValidity();
    }
  }

  updatedAircraftModelId(): void {
    this.refreshAircraftModelIdInTrips();

    for (let i = 0; i < this.aircraftInfos.value.length; i++) {
      switch (this.aircraftInfos.at(i).value.type) {
        case EnumQuotationValueType.aircraftModelTitle:
          if (this.aircraftModelId.value && this.aircraftModelsObj[this.aircraftModelId.value]) {
            this.aircraftInfos
              .at(i)
              .get('value')
              .setValue(this.aircraftModelsObj[this.aircraftModelId.value].title);
          } else if (this.aircraftCompiled) {
            this.aircraftInfos
              .at(i)
              .get('value')
              .setValue(this.aircraftCompiled.type.toUpperCase());
          }
          break;
        case EnumQuotationValueType.aircraftModelTotalSeat:
          const xSeatsStr: string = this.getTranslation('QUOTATION.AIRCRAFT.X_SEATS');

          if (this.aircraftCompiled) {
            this.aircraftInfos
              .at(i)
              .get('value')
              .setValue(xSeatsStr.replace('%s', (this.aircraftCompiled.seatTotal || 0).toString()));
          }
          break;
      }
    }
  }

  applyPaxToPriceInfos(nbPax: number): void {
    for (let i = 0; i < this.priceInfos.value.length; i++) {
      switch (this.priceInfos.at(i).value.type) {
        case EnumQuotationValueType.pax:
          const passengerStrSingular: string = this.getTranslation('QUOTATION.PRICE.PASSENGER');
          const passengerStrPlural: string = this.getTranslation('QUOTATION.PRICE.PASSENGERS');

          this.priceInfos
            .at(i)
            .get('title')
            .setValue(nbPax > 1 ? passengerStrPlural : passengerStrSingular);
          this.priceInfos.at(i).get('value').setValue(addZeroToDigit(nbPax));

          this.refreshPricePerPassenger();
          break;
        case EnumQuotationValueType.totalAmountPayment:
          if (this.priceInfos.at(i).value.hasTitle && this.priceInfos.at(i).value.hasSubTitle) {
            const passengerStrSingular: string = this.getTranslation('QUOTATION.PRICE.X_PASSENGER');
            const passengerStrPlural: string = this.getTranslation('QUOTATION.PRICE.X_PASSENGERS');

            this.priceInfos
              .at(i)
              .get('subTitle')
              .setValue(
                (nbPax > 1 ? passengerStrPlural : passengerStrSingular).replace(
                  '%s',
                  nbPax.toString()
                )
              );

            this.refreshPricePerPassenger();
          }
          break;
      }
    }
  }

  refreshPricePerPassenger(): void {
    let pax: number = 0;
    for (let i = 0; i < this.priceInfos.value.length; i++) {
      switch (this.priceInfos.at(i).value.type) {
        case EnumQuotationValueType.pax:
          pax = parseInt(this.priceInfos.at(i).value.value);

          break;
        case EnumQuotationValueType.totalAmountPayment:
          if (this.priceInfos.at(i).value.hasTitle && this.priceInfos.at(i).value.hasSubTitle) {
            pax = parseInt(this.priceInfos.at(i).value.subTitle);
          }
      }
    }

    if (isNaN(pax)) {
      pax = 0;
    }

    for (let i = 0; i < this.priceInfos.value.length; i++) {
      switch (this.priceInfos.at(i).value.type) {
        case EnumQuotationValueType.pricePerPax:
          this.priceInfos
            .at(i)
            .get('value')
            .setValue(
              this.formatPrice(
                pax > 1 ? this.form.value.totalAmountPayment / pax : 0,
                this.form.value.totalAmountPaymentCurrency
              )
            );
          break;
      }
    }
  }

  updatedSubTitle(module: string, i: number): void {
    const formArray: FormArray = this.form.get(module) as FormArray;

    if (
      formArray.at(i).value.type === EnumQuotationValueType.totalAmountPayment &&
      formArray.at(i).value.hasTitle &&
      formArray.at(i).value.hasSubTitle
    ) {
      this.refreshPricePerPassenger();
    }
  }

  fixMissingTranslationForItemInfo(item: IQuotationItemInfo): IQuotationItemInfo {
    // Find translations for previously created quotation without translations in cache
    if (typeof item.translations === 'undefined') {
      item.translations = {};

      for (const field of ['title', 'subTitle', 'placeholder', 'value', 'comment']) {
        if (item[field]) {
          for (const language of this.getLanguages()) {
            const translationPath: string | null = this.getTranslationCodeFromTranslated(
              item[field],
              this.translationObj[language]
            );

            if (translationPath) {
              item.translations[field] = translationPath;
            }
          }
        }
      }
    }

    return item;
  }

  fixMissingTranslationForServiceSection(section: IQuotationSection): IQuotationSection {
    // Find translations for previously created quotation without translations in cache
    if (typeof section.translations === 'undefined') {
      section.translations = {};

      for (const field of ['title']) {
        if (section[field]) {
          for (const language of this.getLanguages()) {
            const translationPath: string | null = this.getTranslationCodeFromTranslated(
              section[field],
              this.translationObj[language]
            );

            if (translationPath) {
              section.translations[field] = translationPath;
            }
          }
        }
      }

      if (section.services) {
        for (let i = 0; i < section.services.length; i++) {
          section.services[i] = this.fixMissingTranslationForService(section.services[i]);
        }
      }
    }

    return section;
  }

  fixMissingTranslationForService(service: IQuotationSectionService): IQuotationSectionService {
    // Find translations for previously created quotation without translations in cache
    if (typeof service.translations === 'undefined') {
      service.translations = {};

      for (const field of ['title', 'comment']) {
        if (service[field]) {
          for (const language of this.getLanguages()) {
            const translationPath: string | null = this.getTranslationCodeFromTranslated(
              service[field],
              this.translationObj[language]
            );

            if (translationPath) {
              service.translations[field] = translationPath;
            }
          }
        }
      }
    }

    return service;
  }

  getTranslationCodeFromTranslated(translatedText: string, translations: object): string | null {
    for (const field in translations) {
      if (typeof translations[field] === 'object') {
        const subPath: string | null = this.getTranslationCodeFromTranslated(
          translatedText,
          translations[field]
        );

        if (subPath && subPath.length) {
          return field + '.' + subPath;
        }
      } else {
        if (translations[field] === translatedText) {
          return field;
        }
      }
    }

    return null;
  }

  isItemFieldEdited(
    item:
      | IQuotationSection
      | IQuotationSectionService
      | IQuotationAircraftInfo
      | IQuotationPriceInfo,
    field: string
  ): boolean {
    if (typeof item[field] !== 'undefined' && item.translations[field] !== 'undefined') {
      const totalAmountPaymentFormatted: string = this.getTotalAmountPaymentFormatted();

      if (
        item[field].replaceAll(/\s+/g, '') !== totalAmountPaymentFormatted.replaceAll(/\s+/g, '') &&
        item[field] !== this.getTranslation(item.translations[field])
      ) {
        return true;
      }
    }

    return false;
  }
}
