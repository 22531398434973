<div class="text-end">
    <a
        [routerLink]="
            airlineId
                ? '/admin/airline-supervisors/add/airline/' + airlineId
                : '/admin/airline-supervisors/add'
        "
        class="btn btn-primary mb-4"
        *ngIf="hasAclAccess(EnumAcl.airlineSupervisorsAdd)"
        >+ Nouveau superviseur</a
    >
</div>

<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [rowUrl]="'/admin/airline-supervisors/'"
    [orderBy]="orderBy"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    [currentPage]="currentPage"
></app-data-list>
