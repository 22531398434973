<div class="row justify-content-center">
    <div class="col-xl-6">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && emailTemplate">
                <h3 [innerHtml]="emailTemplate.title" class="text-center"></h3>
                <dl class="row">
                    <dt class="col-md-6">Langue</dt>
                    <dd class="col-md-6">
                        {{ getLanguageLabel(emailTemplate.language) }}
                    </dd>
                    <dt class="col-md-12">Sujet de l'email</dt>
                    <dd class="col-md-12">{{ emailTemplate.subject }}</dd>
                    <dt class="col-md-12">Contenu de l'email</dt>
                    <dd class="col-md-12" [innerHtml]="emailTemplate.messageHtml"></dd>
                </dl>

                <div class="text-center mt-4">
                    <a
                        [routerLink]="'/admin/email-templates/' + emailTemplate.id + '/edit'"
                        class="btn btn-primary mr-4"
                        *ngIf="hasAclAccess(EnumAcl.emailTemplatesEdit)"
                        >Éditer</a
                    >
                    <button
                        type="button"
                        (click)="delete()"
                        class="btn btn-primary ml-4"
                        *ngIf="hasAclAccess(EnumAcl.emailTemplatesDelete)"
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
