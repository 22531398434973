import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import {
  EnumAcl,
  EnumGroupAcl,
  getAclTitle,
  getChildrenAclForGroup,
  getGroupAclTitle
} from 'src/app/enums/acl.enum';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';

@Component({
  selector: 'app-user-groups-edit',
  templateUrl: './user-groups-edit.component.html',
  styleUrls: ['./user-groups-edit.component.scss']
})
export class UserGroupsEditComponent implements OnInit {
  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  userGroup: IUserGroup | null = null;
  userGroupId: string;
  aclList: {
    title: string;
    id: string;
    checked: boolean;
    children: {
      title: string;
      value: string;
      checked: boolean;
    }[];
  }[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService
  ) {
    for (const groupAcl of Object.values(EnumGroupAcl)) {
      const newAclModule: {
        title: string;
        id: string;
        checked: boolean;
        children: {
          title: string;
          value: string;
          checked: boolean;
        }[];
      } = {
        title: getGroupAclTitle(groupAcl),
        id: groupAcl,
        checked: false,
        children: []
      };

      for (const acl of getChildrenAclForGroup(groupAcl)) {
        newAclModule.children.push({
          title: getAclTitle(acl),
          value: acl,
          checked: false
        });
      }

      this.aclList.push(newAclModule);
    }

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.userGroupId = this.activatedRoute.snapshot.paramMap.get('userGroupId');

      if (this.userGroupId) {
        await this.aclService.checkAclAccess(EnumAcl.userGroupsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.userGroupsAdd);
        this.form.enable();
      }
    });
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadUserGroup();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadUserGroup(): Promise<void> {
    const doc = await this.remoteService.getDocument('userGroups', this.userGroupId);

    this.userGroup = doc as IUserGroup;

    this.setUserGroup();
  }

  setUserGroup(): void {
    if (this.form && this.userGroup) {
      this.form.get('title').setValue(this.userGroup.title);

      for (const rule of this.userGroup.rules) {
        const ruleId: Array<string> = rule.id.split('.');

        if (ruleId.length === 2) {
          for (const aclModule of this.aclList) {
            if (aclModule.id === ruleId[0]) {
              for (const child of aclModule.children) {
                if (child.value === aclModule.id + '.' + ruleId[1]) {
                  child.checked = rule.access;
                  break;
                }
              }

              this.updateParentModuleChecked(aclModule.id);

              break;
            }
          }
        }
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      data.rules = [];

      for (const aclModule of this.aclList) {
        for (const child of aclModule.children) {
          data.rules.push({
            id: child.value,
            access: child.checked
          });
        }
      }

      if (this.userGroupId) {
        this.remoteService
          .updateDocumentToCollection('userGroups', this.userGroupId, data)
          .then(() => {
            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      } else {
        this.remoteService
          .addDocumentToCollection('userGroups', data)
          .then((docId: string) => {
            this.userGroupId = docId;

            this.sending = false;
            this.redirectAfterSaving();
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/user-groups/' + this.userGroupId]);
  }

  updateModule(moduleId: string): void {
    for (const aclModule of this.aclList) {
      if (aclModule.id === moduleId) {
        for (const child of aclModule.children) {
          child.checked = aclModule.checked;
        }

        break;
      }
    }
  }

  updateRule(ruleId: Array<string>): void {
    if (ruleId.length === 2) {
      this.updateParentModuleChecked(ruleId[0]);
    }
  }

  updateParentModuleChecked(moduleId): void {
    for (const aclModule of this.aclList) {
      if (aclModule.id === moduleId) {
        let hasOneChildCheck: boolean = false;
        for (const child of aclModule.children) {
          if (child.checked) {
            hasOneChildCheck = true;
            break;
          }
        }

        aclModule.checked = hasOneChildCheck;
        break;
      }
    }
  }
}
