import { EnumCurrency } from '../enums/currency.enum';
import { EnumEnquiryCotationStatus } from '../enums/enquiry-cotation-status.enum';
import { IAircraftCompiled } from './aircraft-compiled.interface';
import { IAirline } from './airline.interface';
import { getCotationAirline } from './enquiry.interface';
import { IFile } from './file.interface';
import { IStoreData } from './store-data.interface';

export interface IEnquiryCotation extends IStoreData {
  enquiryId: string;
  offerId: string;
  itineraryId: string;
  aircraftCompiledId: string;
  aircraftCompiled: IAircraftCompiled | null;
  legsId: string[];
  nbPax: number;
  currency: EnumCurrency;
  buyingPriceInCurrency: number;
  buyingPrice: number;
  pricePPInCurrency: number;
  pricePP: number;
  priceNetTTCInCurrency: number;
  priceNetTTC: number;
  sellingPricePPInCurrency: number;
  sellingPricePP: number;
  taxesByPersonInCurrency: number;
  taxesByPerson: number;
  taxesTotalPaxInCurrency: number;
  taxesTotalPax: number;
  taxesByPersonIncluded: boolean;
  isTaxesByPersonType: 'leg' | 'airport';
  taxesByPersonByLeg: {
    legId: string;
    amount: number;
    amountInCurrency: number;
    nbPax: number;
  }[];
  taxesByPersonByAirport: {
    airport: string;
    amount: number;
    amountInCurrency: number;
    nbPax: number;
  }[];
  cateringByPersonInCurrency: number;
  cateringByPerson: number;
  cateringByPersonIncluded: boolean;
  notes: string;
  sellingPriceInCurrency: number;
  sellingPrice: number;
  marginInCurrency: number;
  margin: number;
  marginPercentInCurrency: number;
  marginPercent: number;
  marginOnConfirmed: number;
  status: EnumEnquiryCotationStatus;
  attachedDocuments: IFile[];
}

export const getAirlineIdFromCotation = (enquiryCotation: IEnquiryCotation): string | null => {
  let airline: IAirline | null = getCotationAirline(enquiryCotation);

  if (airline) {
    return airline.id;
  } else if (enquiryCotation.aircraftCompiledId) {
    const splittedAircraftCompiledId: string[] = enquiryCotation.aircraftCompiledId.split('-');

    return splittedAircraftCompiledId[0];
  }

  return null;
};
