import { Component, OnInit } from '@angular/core';

import { AclService } from '../../../services/acl.service';

import { EnumAcl } from 'src/app/enums/acl.enum';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { DataList } from 'src/app/components/data-list/data-list.class';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';

@Component({
  selector: 'app-list-bank-accounts',
  templateUrl: './list-bank-accounts.component.html',
  styleUrls: ['./list-bank-accounts.component.scss']
})
export class ListBankAccountsComponent extends DataList<IBankAccount> implements OnInit {
  EnumAcl = EnumAcl;

  columns: IDataListColumn[] = [
    {
      title: 'Nom',
      field: 'name'
    },
    {
      title: 'IBAN',
      field: 'iban'
    },
    {
      title: 'Devise principale',
      field: 'defaultCurrency'
    },
    {
      title: 'Activé pour la facturation ?',
      field: 'enableForInvoicing',
      type: EnumDataListColumnType.boolean
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'name', direction: 'asc' };

  constructor(private bankAccountService: BankAccountService, private aclService: AclService) {
    super();

    this.setDataService(this.bankAccountService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.bankAccountsList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
