import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { getLanguageLabel } from 'src/app/enums/language.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEmailTemplate } from 'src/app/interfaces/email-template.interface';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';
import { DataList } from 'src/app/components/data-list/data-list.class';
import { EmailTemplateService } from 'src/app/services/email-templates/email-templates.service';

@Component({
  selector: 'app-list-email-templates',
  templateUrl: './list-email-templates.component.html',
  styleUrls: ['./list-email-templates.component.scss']
})
export class ListEmailTemplatesComponent extends DataList<IEmailTemplate> implements OnInit {
  getLanguageLabel = getLanguageLabel;

  EnumAcl = EnumAcl;

  columns: IDataListColumn[] = [
    {
      title: 'Nom',
      field: 'title'
    },
    {
      title: 'Langue',
      field: 'language',
      type: EnumDataListColumnType.function,
      resolve: (item: IEmailTemplate): string => {
        return getLanguageLabel(item.language);
      }
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'title', direction: 'asc' };

  constructor(private aclService: AclService, private emailTemplateService: EmailTemplateService) {
    super();

    this.setDataService(this.emailTemplateService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.emailTemplatesList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
