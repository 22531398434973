import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';
import { IFlightBrief } from 'src/app/interfaces/flight-brief.interface';
import { FlightBriefService } from 'src/app/services/flight-briefs/flight-briefs.service';
import { getLanguageLabel } from 'src/app/enums/language.enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-flight-briefs-list-for-client',
  templateUrl: './flight-briefs-list-for-client.component.html',
  styleUrls: ['./flight-briefs-list-for-client.component.scss']
})
export class FlightBriefsListForClientComponent
  extends DataList<IFlightBrief>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() clientId: string | null = null;

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'client-flight-briefs-list';
  columns: IDataListColumn[] = [
    {
      title: 'Version',
      field: 'title',
      notSortable: true
    },
    {
      title: 'Langue',
      field: 'language',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IFlightBrief): string => {
        return getLanguageLabel(item.language);
      }
    },
    {
      title: 'Date de création',
      field: 'created',
      notSortable: true,
      type: EnumDataListColumnType.date
    },
    {
      title: 'Créé par qui ?',
      field: 'createdBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IFlightBrief): string => {
        return item.createdBy && this.usersObj[item.createdBy]
          ? this.usersObj[item.createdBy].initials
          : '';
      }
    },
    {
      title: "Date d'envoi",
      field: 'sendDate',
      notSortable: true,
      type: EnumDataListColumnType.date
    },
    {
      title: 'Si envoyé, par qui ?',
      field: 'sentBy',
      notSortable: true,
      type: EnumDataListColumnType.function,
      resolve: (item: IFlightBrief): string => {
        return item.sentBy && this.usersObj[item.sentBy] ? this.usersObj[item.sentBy].initials : '';
      }
    }
  ];

  orderBy: IDataListOrderBy | null = { field: 'title', direction: 'asc' };

  isLogged: boolean = false;

  private subscriptions = new Subscription();

  constructor(
    private flightBriefService: FlightBriefService,
    private remoteService: RemoteService,
    private userService: UserService
  ) {
    super();

    this.setDataService(this.flightBriefService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.updatedClientId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientId'] && !changes['clientId'].isFirstChange()) {
      this.updatedClientId();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedClientId(): void {
    if (this.isLogged) {
      if (this.clientId) {
        this.conditions = [
          {
            field: 'clientId',
            operator: '==',
            value: this.clientId
          }
        ];

        this.resetAndLoad();
      }

      this.loadUsers();
    } else {
      setTimeout(() => {
        this.updatedClientId();
      }, 500);
    }
  }

  loadUsers(): void {
    this.subscriptions.add(
      this.userService.getAll().subscribe((users: IUser[]) => {
        for (const user of users) {
          this.usersObj[user.id] = user;
        }
      })
    );
  }
}
