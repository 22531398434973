import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ViewChild,
  NgZone
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { PipedriveService } from '../../../services/pipedrive.service';

import countries from '../../../countries_fr.json';

import { TranslateService } from '@ngx-translate/core';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryBreadcrumbTitle,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { finalize, Subscription } from 'rxjs';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnumEnquiryCotationStatus } from 'src/app/enums/enquiry-cotation-status.enum';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import {
  EnumInvoiceLanguage,
  EnumLanguage,
  getInvoiceLanguageLabel
} from 'src/app/enums/language.enum';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { EnumInvoiceType, getEnumInvoiceTypeLabel } from 'src/app/enums/invoice-type.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumTvaRate, getEnumTvaRateLabel } from 'src/app/enums/tva-rates.enum';
import {
  EnumInvoiceProductDescription,
  getEnumInvoiceProductDescriptionLabel
} from 'src/app/enums/invoice-product-description.enum';
import { IUser } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user.service';
import { addZeroToDigit, generateRandomId } from 'src/app/misc.utils';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import {
  getInvoiceTitle,
  IInvoice,
  invoiceGetProductsLabel
} from 'src/app/interfaces/invoice.interface';
import { BankAccountService } from 'src/app/services/bank-accounts/bank-accounts.service';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';

import * as PDFObject from 'pdfobject';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { IPipedriveOrganization } from 'src/app/interfaces/pipedrive.interface';
import { IClientBillingInfos } from 'src/app/interfaces/client-billing-infos.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IAirport } from 'src/app/interfaces/airport.interface';
import { IUserGroup } from 'src/app/interfaces/user-group.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { IBreadcrumbLink } from 'src/app/components/header-menu-breadcrumbs/header-menu-breadcrumbs.component';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class InvoiceEditComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile', { static: false }) inputFileElement: ElementRef;
  @ViewChild('pdfViewerEmbed', { static: false }) pdfViewerEmbedElement: ElementRef;

  EnumCurrency = EnumCurrency;
  EnumInvoiceLanguage = EnumInvoiceLanguage;
  EnumInvoiceType = EnumInvoiceType;
  EnumTvaRate = EnumTvaRate;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getInvoiceLanguageLabel = getInvoiceLanguageLabel;
  getEnumTvaRateLabel = getEnumTvaRateLabel;
  getEnumInvoiceProductDescriptionLabel = getEnumInvoiceProductDescriptionLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  invoice: IInvoice;
  invoiceId: string;
  enquiryId: string;
  enquiry: IEnquiry;
  enquiryCotations: IEnquiryCotation[] = [];
  client: IPipedriveOrganization | null = null;
  requestingUser: IUser;
  currentUser: IUser;
  userGroup: IUserGroup | null = null;

  bankAccountsList: IBankAccount[] = [];
  invoiceTypesList: {
    title: string;
    value: EnumInvoiceType;
  }[] = [];

  itinerariesList: {
    title: string;
    value: string;
  }[] = [];
  itinerariesObj: object = {};
  legsList: object = {};
  legsObj: object = {};
  cotationsList: {
    title: string;
    cotationId: string;
  }[] = [];
  airportsObj: { [key: string]: IAirport | null } = {};
  airportsToLoad: string[] = [];
  countriesList: {
    title: string;
    value: string;
  }[] = [];
  clientBillingInfos: IClientBillingInfos | null = null;
  EnumInvoiceProductDescription = EnumInvoiceProductDescription;
  invoiceProductDescriptionsObj: object = {};
  percentagesList: number[] = [];
  aircraftsCompiledObj: { [key: string]: IAircraftCompiled | null } = {};
  airlinesObj: { [key: string]: IAirline | null } = {};
  confirmedCotations: IEnquiryCotation[] = [];
  totalBuyingPriceHT: number = 0;
  isDuplicate = false;
  forcedInvoiceType: EnumInvoiceType;

  translationObj: object = {};

  referalInvoicesList: IInvoice[] = [];

  subscriptions = new Subscription();

  isRequesting: boolean = false;
  generatingDefinitiveInvoiceFromProforma: boolean = false;

  uploading: boolean = false;
  uploadingProgress: number;
  currentFile: File;

  loadedPdfViewer: boolean = false;

  loadingEnquiryInvoices: boolean = false;
  enquiryInvoices: IInvoice[] = [];
  enquiryInvoicesByType: { [type: string]: IInvoice[] } = {};

  encaissementsForReferalInvoice: IEncaissement[] = [];
  loadingEncaissementsForReferalInvoice: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private pipedriveService: PipedriveService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private enquiryService: EnquiryService,
    private enquiryCotationService: EnquiryCotationService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private bankAccountService: BankAccountService,
    private zone: NgZone,
    private breadcrumbsService: BreadcrumbsService,
    private encaissementService: EncaissementService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe((user: IUser) => (this.currentUser = user));
    this.remoteService.userGroupObservable.subscribe((userGroup: IUserGroup | null) => {
      this.userGroup = userGroup;

      this.setInvoiceTypesList();
    });

    for (const countryCode in countries) {
      this.countriesList.push({
        title: countries[countryCode],
        value: countryCode
      });
    }
    for (let i = 0; i <= 100; i++) {
      this.percentagesList.push(i);
    }
    for (const invoiceProductDescription of Object.values(EnumInvoiceProductDescription)) {
      this.invoiceProductDescriptionsObj[invoiceProductDescription] =
        getEnumInvoiceProductDescriptionLabel(invoiceProductDescription);
    }
  }

  get products(): FormArray {
    return this.form.get('products') as FormArray;
  }

  getProduct(i: number): FormGroup {
    return this.products.at(i) as FormGroup;
  }

  getProductField(i: number, field: string): FormControl {
    return this.getProduct(i).get(field) as FormControl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        ref: ['', [Validators.required]],
        refMonth: [''],
        refYear: [''],
        refNumber: [''],
        invoiceType: [EnumInvoiceType.proforma],
        referalInvoiceId: [null],
        language: [EnumInvoiceLanguage.fr, [Validators.required]],
        enquiryId: [''],
        clientId: ['', [Validators.required]],
        dueDate: [format(new Date(), 'yyyy-MM-dd'), [Validators.required]],
        issueDate: [format(new Date(), 'yyyy-MM-dd'), [Validators.required]],
        versionNumber: [1, [Validators.required]],
        buyingPriceSupplierPriceInfo: [''],
        buyingPriceSupplierPaymentsInfo: [''],
        displayCotationAirlineAndAircraft: [true, [Validators.required]],
        sentToClient: [false, [Validators.required]],
        currency: new FormControl(EnumCurrency.EUR, [Validators.required]),
        bankAccount: new FormControl('', [Validators.required]),
        products: new FormArray([], [Validators.required]),
        cotationsCachedInfos: new FormArray([]),
        cotationsId: new FormControl([]),
        clientBillingInfos: new FormGroup({
          clientName: new FormControl(''),
          street: new FormControl(''),
          city: new FormControl(''),
          postalCode: new FormControl(''),
          countryCode: new FormControl('FR', [Validators.required]),
          siret: new FormControl(''),
          tvaNumber: new FormControl('')
        }),
        internalNote: [''],
        amountHtTotal: new FormControl('', [Validators.required]),
        amountTvaTotal: new FormControl(''),
        amountTtcTotal: new FormControl('', [Validators.required]),
        document: new FormControl(null)
      },
      { validator: this.checkTotalAmountAccordingToInvoiceType.bind(this) }
    );

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      if (window.location.href.match('add-final-invoice')) {
        this.form.get('invoiceType').setValue(EnumInvoiceType.definitive);
      } else if (window.location.href.match('add-credit-note-invoice')) {
        this.form.get('invoiceType').setValue(EnumInvoiceType.creditNote);
      } else if (window.location.href.match('add-purchase-invoice')) {
        this.form.get('invoiceType').setValue(EnumInvoiceType.purchase);
      } else if (window.location.href.match('add-purchase-credit-note')) {
        this.form.get('invoiceType').setValue(EnumInvoiceType.purchaseCreditNote);
      } else if (
        window.location.href.match('request-credit-note-invoice') ||
        window.location.href.match('add-credit-note-proforma')
      ) {
        this.form.get('invoiceType').setValue(EnumInvoiceType.proformaCreditNote);
      } else if (window.location.href.match('duplicate')) {
        this.isDuplicate = true;

        if (window.location.href.match('proforma-to-final-invoice')) {
          this.forcedInvoiceType = EnumInvoiceType.definitive;
          this.generatingDefinitiveInvoiceFromProforma = true;
        } else if (window.location.href.match('final-invoice-to-credit-note')) {
          this.forcedInvoiceType = EnumInvoiceType.creditNote;
        }
      }

      this.updateInvoiceType();

      this.isRequesting = !!(
        window.location.href.match('request-credit-note-invoice') ||
        window.location.href.match('request-final-invoice')
      );

      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');
      this.enquiryId = this.activatedRoute.snapshot.paramMap.get('enquiryId');

      this.setBreadcrumbsItems();

      if (this.enquiryId) {
        this.form.get('enquiryId').setValue(this.enquiryId);

        await this.loadEnquiry();

        if (!this.invoiceId) {
          this.setInvoiceRefAutomatically();
        }
      }

      if (this.invoiceId) {
        await this.loadData();
      } else {
        // await this.aclService.checkAclAccess('invoices.add');

        if (this.enquiryId) {
          this.addProductFormGroup();

          this.initCotationCachedInfosFromEnquiry();

          if (this.form.value.cotationsCachedInfos) {
            for (const cotationsCachedInfo of this.form.value.cotationsCachedInfos) {
              this.toggleCotationIdFromCotationsIdFormControl(cotationsCachedInfo.cotationId);
            }
          }

          this.updateInvoiceType();

          this.form.markAllAsTouched();

          this.form.enable();
        } else {
          this.updateInvoiceType();

          this.form.markAllAsTouched();

          this.form.enable();
        }
      }
    });

    this.loadBankAccounts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

    this.initPDFViewer();

    setTimeout(() => {
      this.initPDFViewer();
    }, 5000);
  }

  getLanguages(): EnumInvoiceLanguage[] {
    return Object.values(EnumInvoiceLanguage);
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getInvoiceProductDescriptions(): EnumInvoiceProductDescription[] {
    return Object.values(EnumInvoiceProductDescription);
  }

  getTvaRates(): EnumTvaRate[] {
    return Object.values(EnumTvaRate);
  }

  setBreadcrumbsItems(): void {
    const breadcrumbItems: IBreadcrumbLink[] = [];

    if (this.enquiry) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        text: getEnquiryBreadcrumbTitle(this.enquiry),
        url: '/admin/enquiries/' + this.enquiry.id
      });
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices/enquiry/' + this.enquiry.id
      });
    } else if (!this.enquiry && this.invoice?.enquiryId) {
      breadcrumbItems.push({
        text: 'Dossiers',
        url: '/admin/enquiries'
      });

      breadcrumbItems.push({
        isPlaceholder: true
      });
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices/enquiry/' + this.invoice.enquiryId
      });
    } else {
      breadcrumbItems.push({
        text: 'Factures',
        url: '/admin/invoices'
      });
    }

    if (this.invoice) {
      breadcrumbItems.push({
        text: getInvoiceTitle(this.invoice),
        url: '/admin/invoices/' + this.invoice.id
      });

      if (this.isDuplicate) {
        if (this.forcedInvoiceType) {
          switch (this.forcedInvoiceType) {
            case EnumInvoiceType.definitive:
              breadcrumbItems.push({
                text: 'Génération facture définitive'
              });
              break;
            case EnumInvoiceType.creditNote:
              breadcrumbItems.push({
                text: 'Génération avoir'
              });
              break;
            default:
              breadcrumbItems.push({
                text: 'Duplication facture'
              });

              break;
          }
        } else {
          breadcrumbItems.push({
            text: 'Duplication facture',
            url: '/admin/invoices/' + this.invoice.id + '/duplicate'
          });
        }
      } else {
        breadcrumbItems.push({
          text: "Édition d'une facture"
        });
      }
    } else {
      if (window.location.href.match('request-credit-note-invoice')) {
        breadcrumbItems.push({
          text: 'Demande avoir définitif'
        });
      } else if (window.location.href.match('request-final-invoice')) {
        breadcrumbItems.push({
          text: 'Demande facture définitive'
        });
      } else {
        breadcrumbItems.push({
          text: 'Ajout'
        });
      }
    }

    this.breadcrumbsService.setManualBreadcrumbItems(breadcrumbItems);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      await this.loadInvoice();
      await this.loadEnquiry();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  setInvoiceTypesList(): void {
    this.invoiceTypesList = [];

    if (this.hasAclAccess(EnumAcl.invoicesAddProforma)) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.proforma),
        value: EnumInvoiceType.proforma
      });
    }
    if (
      this.hasAclAccess(EnumAcl.invoicesRequestFinalCreditNoteInvoice) ||
      this.hasAclAccess(EnumAcl.invoicesAddCreditNoteProforma)
    ) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.proformaCreditNote),
        value: EnumInvoiceType.proformaCreditNote
      });
    }
    if (this.hasAclAccess(EnumAcl.invoicesAddCreditNoteFinal)) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.creditNote),
        value: EnumInvoiceType.creditNote
      });
    }
    if (this.hasAclAccess(EnumAcl.invoicesAddFinalInvoice)) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.definitive),
        value: EnumInvoiceType.definitive
      });
    }
    if (this.hasAclAccess(EnumAcl.invoicesAddPurchaseInvoice)) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.purchase),
        value: EnumInvoiceType.purchase
      });
    }
    if (this.hasAclAccess(EnumAcl.invoicesAddPurchaseCreditNote)) {
      this.invoiceTypesList.push({
        title: getEnumInvoiceTypeLabel(EnumInvoiceType.purchaseCreditNote),
        value: EnumInvoiceType.purchaseCreditNote
      });
    }
  }

  async loadEnquiry(): Promise<void> {
    let enquiryId: string;

    if (this.invoice && this.invoice.enquiryId) {
      enquiryId = this.invoice.enquiryId;
    } else if (this.form.value.enquiryId) {
      enquiryId = this.form.value.enquiryId;
    } else if (this.enquiryId) {
      enquiryId = this.enquiryId;
    }

    if (enquiryId) {
      this.subscriptions.add(
        this.enquiryService.getFromId(enquiryId).subscribe(async (enquiry: IEnquiry) => {
          this.enquiry = enquiry;

          if (this.enquiry) {
            this.setBreadcrumbsItems();

            this.loadEnquiryInvoices();

            try {
              if (!this.form.value.clientId) {
                this.form.get('clientId').setValue(this.enquiry.clientId);
              }

              if (!this.invoiceId) {
                await this.setClientBillingInfos(this.form.value.clientId);
              }

              await this.loadAirportsOfEnquiry();

              this.reorganizeCotationsInfo();

              this.loadEnquiryCotations();
            } catch (err) {
              console.log(err);
            }
          }
        })
      );
    }
  }

  loadEnquiryCotations(): void {
    this.subscriptions.add(
      this.enquiryCotationService
        .getAllForEnquiry(this.enquiryId)
        .subscribe(async (enquiryCotations: IEnquiryCotation[]) => {
          this.enquiryCotations = enquiryCotations;

          this.confirmedCotations = [];
          for (const cotation of this.enquiryCotations) {
            if (cotation.status === EnumEnquiryCotationStatus.confirmed) {
              this.confirmedCotations.push(cotation);
            }
          }

          await this.fetchAircraftsAndFlightCompanies();

          this.initCotationCachedInfosFromEnquiry();
        })
    );
  }

  async loadAirportsOfEnquiry(): Promise<void> {
    if (this.enquiry?.itineraries) {
      for (const itinerary of this.enquiry.itineraries) {
        for (const trip of itinerary.trips) {
          for (const field of [
            'airportDepart',
            'airportDestination',
            'commercialStopOverAirport',
            'fuelStopOverAirport'
          ]) {
            if (trip[field]) {
              this.addAirportToLoad(trip[field]);
            }
          }
        }
      }

      await this.loadAirports();
    }
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  loadEnquiryInvoices(): void {
    if (this.enquiryId) {
      this.loadingEnquiryInvoices = true;

      this.subscriptions.add(
        this.invoiceService.getAllForEnquiry(this.enquiryId).subscribe((invoices: IInvoice[]) => {
          this.enquiryInvoices = invoices;

          this.enquiryInvoicesByType = {};
          for (const enquiryInvoice of this.enquiryInvoices) {
            if (typeof this.enquiryInvoicesByType[enquiryInvoice.invoiceType] === 'undefined') {
              this.enquiryInvoicesByType[enquiryInvoice.invoiceType] = [];
            }

            this.enquiryInvoicesByType[enquiryInvoice.invoiceType].push(enquiryInvoice);
          }

          this.loadingEnquiryInvoices = false;
        })
      );
    }
  }

  loadInvoice(): void {
    const sub = this.invoiceService
      .getFromId(this.invoiceId)
      .subscribe(async (invoice: IInvoice) => {
        sub.unsubscribe();

        this.invoice = invoice;

        if (this.invoice) {
          this.breadcrumbsService.setCurrentItem({
            id: this.invoice.id,
            text: getInvoiceTitle(this.invoice)
          });
        }

        this.setBreadcrumbsItems();

        if (this.invoice?.id) {
          let editAcl: EnumAcl | null = null;

          switch (this.invoice.invoiceType) {
            case EnumInvoiceType.creditNote:
              editAcl = EnumAcl.invoicesEditCreditNote;
              break;
            case EnumInvoiceType.definitive:
              editAcl = EnumAcl.invoicesEditDefinitive;
              break;
            case EnumInvoiceType.proforma:
              editAcl = EnumAcl.invoicesEditProforma;
              break;
            case EnumInvoiceType.proformaCreditNote:
              editAcl = EnumAcl.invoicesEditProformaCreditNote;
              break;
            case EnumInvoiceType.purchaseCreditNote:
              editAcl = EnumAcl.invoicesEditPurchaseCreditNote;
              break;
            case EnumInvoiceType.purchase:
              editAcl = EnumAcl.invoicesEditPurchase;
              break;
          }

          if (editAcl) {
            await this.aclService.checkAclAccess(editAcl);
          }
        }

        if (!this.enquiry) {
          this.loadEnquiry();
        }

        this.setInvoice();
      });
  }

  async setInvoice(): Promise<void> {
    if (this.form && this.invoice) {
      this.enquiryId = this.invoice.enquiryId;

      this.initCotationCachedInfosFromEnquiry();

      if (this.invoice.cotationsId) {
        for (const cotationId of this.invoice.cotationsId) {
          this.toggleCotationIdFromCotationsIdFormControl(cotationId);
        }
      }

      for (const field of [
        'ref',
        'refMonth',
        'refYear',
        'refNumber',
        'enquiryId',
        'language',
        'clientId',
        'currency',
        'bankAccount',
        'buyingPriceSupplierPriceInfo',
        'buyingPriceSupplierPaymentsInfo',
        'displayCotationAirlineAndAircraft',
        'sentToClient',
        'versionNumber',
        'internalNote',
        'invoiceType',
        'amountHtTotal',
        'amountTvaTotal',
        'amountTtcTotal',
        'referalInvoiceId',
        'document'
      ]) {
        if (typeof this.invoice[field] !== 'undefined') {
          this.form.get(field).setValue(this.invoice[field]);
        }
      }

      if (!this.isDuplicate) {
        if (this.invoice['dueDate']) {
          this.form.get('dueDate').setValue(format(this.invoice['dueDate'], 'yyyy-MM-dd'));
        } else {
          this.form.get('dueDate').setValue(format(this.invoice['created'], 'yyyy-MM-dd'));
        }

        if (this.invoice['issueDate']) {
          this.form.get('issueDate').setValue(format(this.invoice['issueDate'], 'yyyy-MM-dd'));
        } else {
          this.form.get('issueDate').setValue(format(this.invoice['created'], 'yyyy-MM-dd'));
        }
      }

      await this.setClientBillingInfos(this.form.value.clientId);

      if (!this.form.value.currency) {
        this.form.get('currency').setValue(EnumCurrency.EUR);
      }

      for (const field of [
        'clientName',
        'street',
        'city',
        'postalCode',
        'countryCode',
        'siret',
        'tvaNumber'
      ]) {
        if (
          this.invoice.clientBillingInfos &&
          typeof this.invoice.clientBillingInfos[field] !== 'undefined'
        ) {
          this.form
            .get('clientBillingInfos')
            .get(field)
            .setValue(this.invoice.clientBillingInfos[field]);
        }
      }

      if (!this.form.get('clientBillingInfos').get('clientName').value) {
        await this.loadClient(this.form.value.clientId);

        this.form.get('clientBillingInfos').get('clientName').setValue(this.client.name);
      }

      if (this.forcedInvoiceType) {
        this.form.get('invoiceType').setValue(this.forcedInvoiceType);
      }

      if (!this.products.length) {
        for (let i: number = 0; i < this.invoice.products.length; i++) {
          this.addProductFormGroup();
          for (const field of [
            'id',
            'descriptionType',
            'descriptionPercent',
            'descriptionTitle',
            'amountHt',
            'tvaRate',
            'amountTva',
            'tvaRate',
            'tvaRateSelected',
            'amountTtc',
            'currency'
          ]) {
            if (typeof this.invoice.products[i][field] !== 'undefined') {
              this.getProductField(i, field).setValue(this.invoice.products[i][field]);
            }
          }
          this.updatedProductsAmounts();
          this.updateProductDescriptionTitle(i, false);
          //   for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
          //     this.realAmountFieldsUpdated(parseInt(i));
          //   }
          //   for (const tvaRate of this.tvaRatesList) {
          //     if (this.invoice.products[i].tvaRate === tvaRate.value) {
          //       productsFormArray
          //         .at(parseInt(i))
          //         .get('tvaRateSelected')
          //         .setValue(this.invoice.products[i].tvaRate);
          //       break;
          //     }
          //   }
          //   if (productsFormArray.value[i].tvaRateSelected !== productsFormArray.value[i].tvaRate) {
          //     productsFormArray.at(parseInt(i)).get('tvaRateSelected').setValue(EnumTvaRate.other);
          //   }
          // }
          // this.updatedCurrency();
          // if (this.isDuplicate) {
          //   for (const field of ['ref', 'refMonth', 'refYear', 'refNumber', 'versionNumber']) {
          //     if (typeof this.invoice[field] !== 'undefined') {
          //       this.form.get(field).setValue(null);
          //     }
          //   }
          setTimeout(() => {
            if (this.forcedInvoiceType) {
              this.form.get('invoiceType').setValue(this.forcedInvoiceType);
              this.updateInvoiceType();
              if (
                [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
                  this.forcedInvoiceType
                )
              ) {
                for (let i: number = 0; i < this.invoice.products.length; i++) {
                  for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
                    if (typeof this.invoice.products[i][field] !== 'undefined') {
                      this.getProductField(i, field).setValue(-1 * this.invoice.products[i][field]);
                    }
                  }
                }
                this.updatedProductsAmounts();
              }
            }
          }, 1000);
        }
      }

      if (this.isDuplicate) {
        this.form.get('referalInvoiceId').setValue(this.invoice.id);
        this.form.get('referalInvoiceId').updateValueAndValidity();

        this.loadEncaissementsForReferalInvoice();
      }

      if (!this.loadedPdfViewer) {
        this.initPDFViewer();
      }

      this.updateInvoiceType();

      this.form.markAllAsTouched();
      this.form.enable();

      await this.loadRequestingUser();
    }
  }

  initCotationCachedInfosFromEnquiry(): void {
    if (this.enquiry) {
      const cotationsCachedInfoFormArray = this.form.get('cotationsCachedInfos') as FormArray;

      if (!cotationsCachedInfoFormArray.value?.length) {
        for (const cotation of this.confirmedCotations) {
          this.addOneCotationsCachedInfo();

          const i: number = cotationsCachedInfoFormArray.length - 1;
          const currentCotationsCachedInfoFormGroup = cotationsCachedInfoFormArray.at(i);

          if (
            this.invoice &&
            this.invoice.cotationsCachedInfos?.length &&
            this.invoice.cotationsCachedInfos[i].cotationId
          ) {
            currentCotationsCachedInfoFormGroup
              .get('cotationId')
              .setValue(this.invoice.cotationsCachedInfos[i].cotationId);
            currentCotationsCachedInfoFormGroup
              .get('dates')
              .setValue(this.invoice.cotationsCachedInfos[i].dates);
            currentCotationsCachedInfoFormGroup
              .get('routing')
              .setValue(this.invoice.cotationsCachedInfos[i].routing);
            currentCotationsCachedInfoFormGroup
              .get('aircraftCompiledId')
              .setValue(this.invoice.cotationsCachedInfos[i].aircraftCompiledId);
            if (this.aircraftsCompiledObj[cotation.aircraftCompiledId]) {
              currentCotationsCachedInfoFormGroup
                .get('airlineId')
                .setValue(this.invoice.cotationsCachedInfos[i].airlineId);
              currentCotationsCachedInfoFormGroup
                .get('airlineTitle')
                .setValue(this.invoice.cotationsCachedInfos[i].airlineTitle);
              currentCotationsCachedInfoFormGroup
                .get('aircraftModel')
                .setValue(this.invoice.cotationsCachedInfos[i].aircraftModel);
            }
          } else {
            const dates: string[] = [];
            const routing: string[] = [];

            for (const legId of cotation.legsId) {
              if (this.legsObj[legId]) {
                const dateStr: string = this.capitalizeFirstLetter(
                  format(parseISO(this.legsObj[legId].date), 'EEEE dd MMMM yyyy', {
                    locale: fr
                  })
                );
                if (dates.indexOf(dateStr) === -1) {
                  dates.push(dateStr);
                }
                for (const field of ['airportDepart', 'airportDestination']) {
                  if (routing.indexOf(this.legsObj[legId][field + 'Title']) === -1) {
                    routing.push(this.legsObj[legId][field + 'Title']);
                  }
                }
              }
            }

            currentCotationsCachedInfoFormGroup.get('cotationId').setValue(cotation.id);
            currentCotationsCachedInfoFormGroup.get('dates').setValue(dates.join(' - '));
            currentCotationsCachedInfoFormGroup.get('routing').setValue(routing.join(' - '));

            if (this.aircraftsCompiledObj[cotation.aircraftCompiledId]) {
              currentCotationsCachedInfoFormGroup
                .get('airlineId')
                .setValue(this.aircraftsCompiledObj[cotation.aircraftCompiledId].airlineId);
              currentCotationsCachedInfoFormGroup
                .get('airlineTitle')
                .setValue(this.aircraftsCompiledObj[cotation.aircraftCompiledId].airlineTitle);
              currentCotationsCachedInfoFormGroup
                .get('aircraftModel')
                .setValue(
                  this.aircraftsCompiledObj[cotation.aircraftCompiledId].modelTitle.toUpperCase()
                );
              currentCotationsCachedInfoFormGroup
                .get('aircraftCompiledId')
                .setValue(this.aircraftsCompiledObj[cotation.aircraftCompiledId].id);
            }
          }

          this.form.markAllAsTouched();

          this.form.enable();
        }
      }
    }
  }

  async loadClient(clientId: string): Promise<void> {
    this.client = await this.pipedriveService.getOrganization(clientId);
  }

  async setClientBillingInfos(clientId: string): Promise<void> {
    if (clientId) {
      try {
        const clientBillingInfosDoc: object = await this.remoteService.getDocument(
          'clientBillingInfos',
          clientId.toString()
        );

        this.clientBillingInfos = clientBillingInfosDoc as IClientBillingInfos;

        if (!this.clientBillingInfos.clientName) {
          await this.loadClient(clientId);

          this.form.get('clientBillingInfos').get('clientName').setValue(this.client['name']);
        }

        for (const field of [
          'clientName',
          'street',
          'city',
          'postalCode',
          'countryCode',
          'siret',
          'tvaNumber'
        ]) {
          if (typeof this.clientBillingInfos[field] !== 'undefined') {
            this.form.get('clientBillingInfos').get(field).setValue(this.clientBillingInfos[field]);
          }
        }

        this.form.updateValueAndValidity();
      } catch (err) {
        console.log(err);

        this.form.disable();

        // No client billing found
        await this.loadClient(clientId);

        this.form.get('clientBillingInfos').get('clientName').setValue(this.client['name']);

        const street: string[] = [];
        if (this.client['address_street_number']) {
          street.push(this.client['address_street_number']);
        }

        if (this.client['address_route']) {
          street.push(this.client['address_route']);
        }

        this.form.get('clientBillingInfos').get('street').setValue(street.join(' '));
        this.form
          .get('clientBillingInfos')
          .get('postalCode')
          .setValue(this.client['address_postal_code']);
        this.form.get('clientBillingInfos').get('city').setValue(this.client['address_locality']);
        this.form
          .get('clientBillingInfos')
          .get('countryCode')
          .setValue(this.client['address_country'] === 'France' ? 'FR' : null);

        // TVA Number
        if (this.client['23d8b7314ee850fcb82063b1ec6c22b7eea4eb17']) {
          this.form
            .get('clientBillingInfos')
            .get('tvaNumber')
            .setValue(this.client['23d8b7314ee850fcb82063b1ec6c22b7eea4eb17']);
        }

        // Siret
        if (this.client['33d99dd8f79d371b60024816d576956da94bc1b1']) {
          this.form
            .get('clientBillingInfos')
            .get('siret')
            .setValue(this.client['33d99dd8f79d371b60024816d576956da94bc1b1']);
        }

        this.form.updateValueAndValidity();

        this.form.enable();
      }
    }
  }

  toggleCotationIdFromCotationsIdFormControl(cotationId: string): void {
    const cotationsId: string[] = [...this.form.value.cotationsId];

    const index: number = cotationsId.indexOf(cotationId);
    if (index === -1) {
      cotationsId.push(cotationId);
    } else {
      cotationsId.splice(index, 1);
    }

    this.form.get('cotationsId').setValue(cotationsId);

    for (const productIndex in this.form.value.products) {
      this.updateAmountField(parseInt(productIndex));
    }

    this.updateBuyingPriceField();
  }

  updateBuyingPriceField(): void {
    const buyingPrice: string[] = [];

    if (this.enquiry) {
      for (const cotation of this.confirmedCotations) {
        if (this.form.value.cotationsId?.includes(cotation.id)) {
          buyingPrice.push(this.formatPrice(cotation.buyingPriceInCurrency, cotation.currency));
        }
      }
    }

    if (!this.form.value.buyingPriceSupplierPriceInfo?.length) {
      this.form.get('buyingPriceSupplierPriceInfo').setValue(buyingPrice.join(' + '));
    }
  }

  updateAmountField(productIndex: number): void {
    let amountHt: number = 0;

    if (this.invoice) {
      if (this.invoice.products[productIndex]) {
        amountHt = this.invoice.products[productIndex].amountHt;
      }
    } else if (this.enquiry) {
      for (const cotation of this.confirmedCotations) {
        if (
          this.form.value.cotationsId &&
          this.form.value.cotationsId.indexOf(cotation.id) !== -1
        ) {
          amountHt += cotation.sellingPriceInCurrency;
        }
      }
    }

    const amountHtFormControl: FormControl = this.getProductField(productIndex, 'amountHt');

    if (this.form.value.products[productIndex].descriptionPercent === '') {
      // amountHtFormControl.setValue('');
    } else {
      const value: number = this.isDuplicate
        ? amountHt
        : Math.round(
            this.form.value.products[productIndex].descriptionPercent * 0.01 * amountHt * 100
          ) / 100;

      amountHtFormControl.setValue(
        [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        )
          ? -1 * value
          : value
      );
    }

    amountHtFormControl.updateValueAndValidity();

    this.realAmountFieldsUpdated(productIndex);

    this.updatedProductsAmounts();
  }

  // updateTotalBuyingPriceInForm(productIndex: number): void {
  //   if (
  //     this.form.value.products.length &&
  //     this.form.value.products[productIndex] &&
  //     ['balance', 'down-payment'].indexOf(
  //       this.form.value.products[productIndex].descriptionType
  //     ) !== -1
  //   ) {
  //     (this.form.get('products') as FormArray)
  //       .at(0)
  //       .get('amountHt')
  //       .setValue(
  //         Math.round(
  //           this.form.value.products[productIndex].descriptionPercent *
  //             0.01 *
  //             this.totalBuyingPriceHT *
  //             100
  //         ) / 100
  //       );

  //     this.updatedProductsAmounts();
  //   }
  // }

  async fetchAircraftsAndFlightCompanies(): Promise<void> {
    if (this.enquiry) {
      const aircraftsCompiledId: string[] = [];

      for (const cotation of this.confirmedCotations) {
        aircraftsCompiledId.push(cotation.aircraftCompiledId);
      }

      if (aircraftsCompiledId.length) {
        const aircraftsCompiledList: object[] = await this.remoteService.getDocumentsFromDocId(
          'aircraftsCompiled',
          aircraftsCompiledId
        );

        for (const aircraftCompiledObj of aircraftsCompiledList) {
          this.aircraftsCompiledObj[aircraftCompiledObj['id']] =
            aircraftCompiledObj as IAircraftCompiled;
        }
      }

      const airlinesId: string[] = [];
      for (const aircraftCompiledId in this.aircraftsCompiledObj) {
        airlinesId.push(this.aircraftsCompiledObj[aircraftCompiledId].airlineId);
      }

      if (airlinesId.length) {
        const airlinesList: object[] = await this.remoteService.getDocumentsFromDocId(
          'airlines',
          airlinesId
        );

        for (const airlineObj of airlinesList) {
          this.airlinesObj[airlineObj['id']] = airlineObj as IAirline;
        }
      }
    }
  }

  addProductFormGroup(): void {
    this.products.push(
      new FormGroup({
        id: new FormControl(generateRandomId(), [Validators.required]),
        descriptionType: new FormControl(EnumInvoiceProductDescription.balance, [
          Validators.required
        ]),
        descriptionPercent: new FormControl('100'),
        descriptionTitle: new FormControl('', [Validators.required]),
        amountHt: new FormControl('', [Validators.required]),
        amountHtPositive: new FormControl('', [Validators.required]),
        tvaRateSelected: new FormControl(20, [Validators.required]),
        tvaRate: new FormControl(20, [Validators.required]),
        amountTva: new FormControl('', [Validators.required]),
        amountTvaPositive: new FormControl('', [Validators.required]),
        amountTtc: new FormControl('', [Validators.required]),
        amountTtcPositive: new FormControl('', [Validators.required]),
        currency: new FormControl(this.form.value.currency, [Validators.required])
      })
    );

    this.form.updateValueAndValidity();

    this.updateProductDescriptionTitle(this.products.length - 1);
  }

  removeProduct(i: number, force: boolean = false): void {
    let continueRemove = force;

    if (!continueRemove) {
      continueRemove = confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?');
    }

    if (continueRemove) {
      this.products.removeAt(i);
    }
  }

  updateTotalAmount(): void {
    for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
      let totalAmount: number = 0;
      for (const product of this.form.value.products) {
        totalAmount += product[field];
      }

      this.form.get(field + 'Total').setValue(totalAmount);
      this.form.get(field + 'Total').updateValueAndValidity();
    }
  }

  updateTvaRateSelected(i: number): void {
    if (this.form.value.products[i].tvaRateSelected !== EnumTvaRate.other) {
      this.getProductField(i, 'tvaRate').setValue(this.form.value.products[i].tvaRateSelected);
    } else {
      this.getProductField(i, 'tvaRate').setValue('');
    }

    this.updatedProductsAmounts();
  }

  updatedProductsAmounts(): void {
    for (let i: number = 0; i < this.products.length; i++) {
      const amountTvaPositive =
        parseFloat(this.form.value.products[i].tvaRate) === 0
          ? 0
          : Math.round(
              (this.form.value.products[i].amountHtPositive / 100) *
                parseFloat(this.form.value.products[i].tvaRate) *
                100
            ) / 100;
      this.getProductField(i, 'amountTvaPositive').setValue(amountTvaPositive);
      this.getProductField(i, 'amountTtcPositive').setValue(
        Math.round((this.form.value.products[i].amountHtPositive + amountTvaPositive) * 100) / 100
      );
    }
    this.positiveAmountFieldsUpdated();
    this.updateTotalAmount();
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      if (data.bankAccount) {
        for (const bankAccount of this.bankAccountsList) {
          if (data.bankAccount === bankAccount.id) {
            data.bankAccountData = bankAccount;
          }
        }
      }

      this.sending = true;

      this.form.disable();

      if (
        [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(data.invoiceType)
      ) {
        data['status'] = 'requested';
        data['requestedDate'] = new Date();
        data['requestedBy'] = this.currentUser.id;
        data['definitiveInvoice'] = false;
      } else {
        data['status'] = 'generated';
        data['generatedDate'] = new Date();
        data['generatedBy'] = this.currentUser.id;
        data['definitiveInvoice'] = true;

        if (
          ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(data.invoiceType)
        ) {
          const refSplit: string[] = data.ref.split('-');

          if (refSplit.length === 3) {
            data.refYear = parseInt(refSplit[1].substring(0, 4));
            data.refMonth = parseInt(refSplit[1].substring(5, 6));
            data.refNumber = parseInt(refSplit[2]);
          }
        }
      }

      if (data['dueDate']) {
        data['dueDate'] = new Date(data['dueDate']);
      }
      if (data['issueDate']) {
        data['issueDate'] = new Date(data['issueDate']);
      }

      data['invoiceObject'] = invoiceGetProductsLabel(data).join(' / ');

      if (
        ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(data.invoiceType)
      ) {
        await this.saveClientBillingInfos(data);
      }

      let promise: any;

      if (!this.isDuplicate && this.invoiceId) {
        promise = this.remoteService.updateDocumentToCollection('invoices', this.invoiceId, data);
      } else {
        promise = this.remoteService.addDocumentToCollection('invoices', data);
      }

      promise
        .then(async (docId: string) => {
          if (!this.invoiceId || this.generatingDefinitiveInvoiceFromProforma) {
            this.invoiceId = docId;
          }

          if (this.generatingDefinitiveInvoiceFromProforma) {
            await this.assignProformaEncaissemnetToDefinitiveInvoice();
          }

          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          this.sending = false;
          this.form.enable();

          alert(err.message);
        });
    }
  }

  async saveClientBillingInfos(data: any): Promise<void> {
    if (data.clientId) {
      data['clientBillingInfos']['clientId'] = data.clientId;

      await this.remoteService.addDocumentToCollectionWithId(
        'clientBillingInfos',
        data.clientId.toString(),
        data['clientBillingInfos']
      );
    }
  }

  redirectAfterSaving(): void {
    if (
      window.location.href.match('request-credit-note-invoice') ||
      window.location.href.match('request-final-invoice')
    ) {
      this.router.navigate(['/admin/send-emails/invoice-requested/' + this.invoiceId]);
    } else {
      this.router.navigate(['/admin/invoices/' + this.invoiceId]);
    }
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  formatPrice(value: number, currency: string = null): string {
    if (!currency && this.form.value.currency) {
      currency = this.form.value.currency;
    }

    if (currency) {
      const formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
      });

      return formatter.format(value);
    }

    return '0';
  }

  async loadRequestingUser(): Promise<void> {
    if (this.invoice.requestedBy) {
      this.subscriptions.add(
        this.userService.getFromId(this.invoice.requestedBy).subscribe((user: IUser) => {
          this.requestingUser = user;
        })
      );
    }
  }

  updatedCurrency(): void {
    if (this.form.value.currency) {
      for (let i: number = 0; i < this.products.length; i++) {
        this.getProductField(i, 'currency').setValue(this.form.value.currency);
      }
    }
  }

  reorganizeCotationsInfo(): void {
    this.legsList = {};
    this.itinerariesObj = {};
    this.itinerariesList = [];

    if (this.enquiry?.itineraries) {
      for (const itineraryIndex in this.enquiry.itineraries) {
        this.itinerariesList.push({
          title: this.enquiry.itineraries[itineraryIndex].title,
          value: this.enquiry.itineraries[itineraryIndex].id
        });
        this.itinerariesObj[this.enquiry.itineraries[itineraryIndex].id] =
          this.enquiry.itineraries[itineraryIndex].title;

        for (const trip of this.enquiry.itineraries[itineraryIndex].trips) {
          const title: string =
            this.airportsObj[trip.airportDepart].title +
            ' / ' +
            this.airportsObj[trip.airportDestination].title;

          if (!this.legsList[this.enquiry.itineraries[itineraryIndex].id]) {
            this.legsList[this.enquiry.itineraries[itineraryIndex].id] = [];
          }

          this.legsList[this.enquiry.itineraries[itineraryIndex].id].push({
            title: title,
            value: trip.id
          });

          this.legsObj[trip.id] = {
            airportDepart: this.airportsObj[trip.airportDepart]?.iataCode,
            airportDestination: this.airportsObj[trip.airportDestination]?.iataCode,
            airportDepartTitle: this.airportsObj[trip.airportDepart]?.title,
            airportDestinationTitle: this.airportsObj[trip.airportDestination]?.title,
            title: title,
            value: trip.id,
            nbPax: trip.passengersTotal,
            date: trip.date
          };
        }
      }
    }
  }

  capitalizeFirstLetter(val: string): string {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  updateInvoiceType(): void {
    if (this.form.value.invoiceType) {
      let mandatoryFields: string[] = [];
      let notMandatoryFields: string[] = [];

      if (
        [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        )
      ) {
        mandatoryFields = ['versionNumber'];
        notMandatoryFields = ['refMonth', 'refYear', 'refNumber'];
      } else if (
        [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
          this.form.value.invoiceType
        )
      ) {
        notMandatoryFields = [
          'versionNumber',
          'refMonth',
          'refYear',
          'refNumber',
          'bankAccount',
          'clientId'
        ];
      } else {
        mandatoryFields = ['refMonth', 'refYear', 'refNumber', 'bankAccount', 'dueDate'];
        notMandatoryFields = ['versionNumber'];
      }

      for (const field of notMandatoryFields) {
        this.form.get(field).setValue(null);
        this.form.get(field).clearValidators();
        this.form.get(field).updateValueAndValidity();
      }

      for (const field of mandatoryFields) {
        this.form.get(field).setValidators([Validators.required]);
        this.form.get(field).updateValueAndValidity();
      }

      this.setInvoiceRefAutomatically();
    }
  }

  async setInvoiceRefAutomatically(): Promise<void> {
    if (!this.invoice?.ref || this.isDuplicate) {
      this.form.disable();

      if (
        [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        )
      ) {
        if (this.form.value.enquiryId) {
          const lastProformaInvoice: IInvoice = await this.remoteService.getLatestProformaOfEnquiry(
            this.form.value.enquiryId
          );

          if (lastProformaInvoice) {
            if (!this.invoice || lastProformaInvoice.id !== this.invoice.id) {
              this.form
                .get('versionNumber')
                .setValue(
                  lastProformaInvoice.versionNumber ? lastProformaInvoice.versionNumber + 1 : 1
                );
            } else {
              this.form.get('versionNumber').setValue(lastProformaInvoice.versionNumber);
            }
          } else {
            this.form.get('versionNumber').setValue(1);
          }
        } else {
          this.form.get('ref').setValue(null);
          this.form.get('versionNumber').setValue(1);
        }
      } else if (
        [EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
          this.form.value.invoiceType
        )
      ) {
        for (const field of ['ref', 'versionNumber', 'refYear', 'refMonth']) {
          this.form.get(field).setValue(null);
        }
      } else {
        const today: Date = new Date();

        this.form.get('refYear').setValue(today.getFullYear());
        this.form.get('refMonth').setValue(today.getMonth() + 1);

        const lastDefinitiveInvoice: IInvoice = await this.remoteService.getLatestInvoiceOfYear(
          this.form.value.refYear
        );

        if (lastDefinitiveInvoice) {
          this.form.get('refNumber').setValue(lastDefinitiveInvoice.refNumber + 1);
        } else {
          this.form.get('refNumber').setValue(1);
        }
      }

      this.setRefTitle();

      this.form.markAllAsTouched();
      this.form.enable();
    }
  }

  async setRefTitle(): Promise<void> {
    if (
      [EnumInvoiceType.proforma, EnumInvoiceType.proformaCreditNote].includes(
        this.form.value.invoiceType
      )
    ) {
      if (this.form.value.enquiryId) {
        if (!(this.enquiry && this.enquiry.id === this.form.value.enquiryId)) {
          await this.loadEnquiry();
        }

        if (this.enquiry) {
          const prefix: string =
            this.form.value.invoiceType === EnumInvoiceType.proformaCreditNote ? 'A-PRO' : 'PRO';

          this.form
            .get('ref')
            .setValue(
              prefix +
                '-' +
                getDisplayedEnquiryRefTitle(this.enquiry, 'refContract') +
                '.' +
                this.form.value.versionNumber
            );
        }
      } else {
        this.form.get('ref').setValue(null);
      }
    } else if (
      ![EnumInvoiceType.purchase, EnumInvoiceType.purchaseCreditNote].includes(
        this.form.value.invoiceType
      )
    ) {
      if (this.form.value.refNumber) {
        const prefixLetter: string =
          this.form.value.invoiceType === EnumInvoiceType.creditNote ? 'A' : 'F';

        this.form
          .get('ref')
          .setValue(
            prefixLetter +
              '-' +
              this.form.value.refYear +
              addZeroToDigit(this.form.value.refMonth) +
              '-' +
              addZeroToDigit(this.form.value.refNumber, 3)
          );
      } else {
        this.form.get('ref').setValue('');
      }
    }
  }

  updateProductDescription(i: number): void {
    if (this.form.value.products[i].descriptionType === EnumInvoiceProductDescription.other) {
      for (const field of ['descriptionType', 'descriptionPercent']) {
        this.getProductField(i, field).clearValidators();
        this.getProductField(i, field).updateValueAndValidity();
      }
    } else {
      for (const field of ['descriptionType', 'descriptionPercent']) {
        this.getProductField(i, field).setValidators([Validators.required]);
        this.getProductField(i, field).updateValueAndValidity();
      }
    }

    if (this.form.value.products[i].descriptionType !== EnumInvoiceProductDescription.other) {
      this.getProductField(i, 'descriptionTitle').setValue(
        this.invoiceProductDescriptionsObj[this.form.value.products[i].descriptionType] +
          ' ' +
          this.form.value.products[i].descriptionPercent +
          '%'
      );
    }
  }

  updateProductDescriptionTitle(i: number, forceUpdateAmount: boolean = true): void {
    this.updateProductDescription(i);

    if (forceUpdateAmount) {
      this.updateAmountField(i);
      this.updateBuyingPriceField();
    }
  }

  async setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
    }[];
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();

      if (field.name === 'clientId') {
        this.setClientBillingInfos(field.value);
      } else if (field.name === 'enquiryId') {
        this.enquiryId = field.value;
        this.form.get('enquiryId').setValue(field.value);

        if (this.enquiryId) {
          await this.loadEnquiry();

          this.setBreadcrumbsItems();

          this.addProductFormGroup();

          for (const cotationsCachedInfo of this.form.value.cotationsCachedInfos) {
            this.toggleCotationIdFromCotationsIdFormControl(cotationsCachedInfo.cotationId);
          }

          this.updateInvoiceType();
        } else {
          for (let i = this.form.value.cotationsCachedInfos.length - 1; i >= 0; i--) {
            this.removeOneCotationsCachedInfo(i);
          }

          for (let i = this.form.value.products.length - 1; i >= 0; i--) {
            this.removeProduct(i, true);
          }
        }

        this.setInvoiceRefAutomatically();
      }
    }
  }

  addOneCotationsCachedInfo(): void {
    const cotationsCachedInfosFormArray = this.form.get('cotationsCachedInfos') as FormArray;

    cotationsCachedInfosFormArray.push(
      new FormGroup({
        cotationId: new FormControl(''),
        dates: new FormControl(''),
        routing: new FormControl(''),
        airlineId: new FormControl(''),
        airlineTitle: new FormControl(''),
        aircraftModel: new FormControl(''),
        aircraftCompiledId: new FormControl('')
      })
    );

    this.form.get('cotationsCachedInfos').updateValueAndValidity();
  }

  removeOneCotationsCachedInfo(i: number): void {
    const cotationsCachedInfosFormArray = this.form.get('cotationsCachedInfos') as FormArray;

    cotationsCachedInfosFormArray.removeAt(i);
  }

  removeValueFromCotationInfo(
    cotationCachedIndex: number,
    field: string,
    valueIndex: number,
    separator: string
  ): void {
    const valueArr: string[] =
      this.form.value.cotationsCachedInfos[cotationCachedIndex][field].split(separator);

    valueArr.splice(valueIndex, 1);

    (this.form.get('cotationsCachedInfos') as FormArray)
      .at(cotationCachedIndex)
      .get(field)
      .setValue(valueArr.join(separator));

    (this.form.get('cotationsCachedInfos') as FormArray)
      .at(cotationCachedIndex)
      .get(field)
      .updateValueAndValidity();
  }

  addValueToCotationInfo(cotationCachedIndex: number, field: string, separator: string): void {
    const newValue: string = window.prompt('Ajouter une nouvelle valeur', '');

    if (newValue !== '') {
      const valueArr: string[] =
        this.form.value.cotationsCachedInfos[cotationCachedIndex][field].split(separator);

      if (valueArr[0] === '') {
        valueArr.splice(0, 1);
      }

      valueArr.push(newValue);

      (this.form.get('cotationsCachedInfos') as FormArray)
        .at(cotationCachedIndex)
        .get(field)
        .setValue(valueArr.join(separator));

      (this.form.get('cotationsCachedInfos') as FormArray)
        .at(cotationCachedIndex)
        .get(field)
        .updateValueAndValidity();
    }
  }

  checkTotalAmountAccordingToInvoiceType(formGroup: FormGroup): void {
    if (this.form) {
      if (this.form.value.amountTtcTotal == 0) {
        formGroup.get('amountTtcTotal').setErrors({
          errorTotalAmountCantBeZero: true
        });
      } else if (
        ![EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        ) &&
        this.form.value.amountTtcTotal < 0
      ) {
        formGroup.get('amountTtcTotal').setErrors({
          errorTotalAmountShouldNotBeNegative: true
        });
      } else if (
        [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        ) &&
        this.form.value.amountTtcTotal > 0
      ) {
        formGroup.get('amountTtcTotal').setErrors({
          errorTotalAmountShouldNotBePositive: true
        });
      }
    }
  }

  getInvoiceTypeTitle(): string {
    return this.invoiceTypesList.find(
      invoiceType => invoiceType.value === this.form.value.invoiceType
    )?.title;
  }

  positiveAmountFieldsUpdated(): void {
    for (let i = 0; i < this.form.value.products.length; i++) {
      for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
        this.getProductField(i, field).setValue(
          [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
            this.form.value.invoiceType
          )
            ? -1 * this.getProductField(i, field + 'Positive').value
            : this.getProductField(i, field + 'Positive').value
        );
        this.getProductField(i, field).updateValueAndValidity();
      }
    }
  }

  realAmountFieldsUpdated(productIndex: number): void {
    for (const field of ['amountHt', 'amountTva', 'amountTtc']) {
      this.getProductField(productIndex, field + 'Positive').setValue(
        [EnumInvoiceType.creditNote, EnumInvoiceType.proformaCreditNote].includes(
          this.form.value.invoiceType
        )
          ? -1 * this.getProductField(productIndex, field).value
          : this.getProductField(productIndex, field).value
      );
      this.getProductField(productIndex, field + 'Positive').updateValueAndValidity();
    }
  }

  async getTranslation(path: string, language: EnumLanguage = null): Promise<string> {
    if (!language) {
      language = this.form.value.language;
    }

    if (language) {
      if (this.translationObj[language]) {
        return await this.getStringTranslation(language, path);
      } else {
        await this.loadLanguageTranslation(language);

        return await this.getStringTranslation(language, path);
      }
    } else {
      return path;
    }
  }

  loadLanguageTranslation(language: EnumLanguage): Promise<void> {
    return new Promise((resolve, reject) => {
      this.translateService.getTranslation(language).subscribe((translationObj: object) => {
        this.translationObj[language] = translationObj;

        resolve();
      });
    });
  }

  getDefaultStringTranslation(path: string): string {
    return this.getStringTranslation(this.form.value.language, path);
  }

  getStringTranslation(language: EnumLanguage, path: string): string {
    if (this.translationObj[language]) {
      let translation: object | string = Object.assign({}, this.translationObj[language]);
      const splittedPath: string[] = path.split('.');

      for (const onePath of splittedPath) {
        if (translation[onePath]) {
          translation = translation[onePath];
        } else {
          translation = path;
          break;
        }
      }

      return translation.toString();
    }
  }

  loadBankAccounts(): void {
    this.subscriptions.add(
      this.bankAccountService.getAllForInvoicing().subscribe((bankAccounts: IBankAccount[]) => {
        this.bankAccountsList = bankAccounts;
      })
    );
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.currentFile = fileInput.target.files[0];

      if (this.currentFile) {
        this.upload();
      }
    }
  }

  upload(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.disable();
      this.uploading = true;
      this.uploadingProgress = 0;

      let result = this.remoteService.upload('invoices/document', null, this.currentFile, 'file');

      result['task'].percentageChanges().subscribe((progress: number) => {
        this.zone.run(() => {
          this.uploadingProgress = Math.round(progress);
        });
      });

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.form.enable();

              const documentFormControl = this.form.get('document') as FormControl;

              documentFormControl.setValue({
                name: this.currentFile['name'],
                type: this.currentFile['type'],
                size: this.currentFile['size'],
                url: downloadUrl
              });

              this.form.updateValueAndValidity();

              this.inputFileElement.nativeElement.value = '';

              setTimeout(() => {
                this.initPDFViewer();

                setTimeout(() => {
                  this.initPDFViewer();
                }, 5000);
              }, 1000);

              this.uploading = false;
            });
          })
        )
        .subscribe();
    });
  }

  initPDFViewer(): void {
    if (this.form.value.document?.url && this.pdfViewerEmbedElement && !this.loadedPdfViewer) {
      PDFObject.embed(this.form.value.document.url, this.pdfViewerEmbedElement.nativeElement);
      this.loadedPdfViewer = true;
    }
  }

  removeDocument(): void {
    const result = confirm(
      'La suppression du document sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      this.form.get('document').setValue(null);
      this.form.get('document').updateValueAndValidity();
    }
  }

  loadEncaissementsForReferalInvoice(): void {
    if (this.generatingDefinitiveInvoiceFromProforma && this.form.get('referalInvoiceId').value) {
      this.loadingEncaissementsForReferalInvoice = true;

      this.subscriptions.add(
        this.encaissementService
          .getAllForInvoice(this.form.get('referalInvoiceId').value)
          .subscribe((encaissements: IEncaissement[]) => {
            this.encaissementsForReferalInvoice = encaissements;
            console.log(
              '🚀 ~ file: invoice-edit.component.ts:2025 ~ InvoiceEditComponent ~ .subscribe ~ this.encaissementsForReferalInvoice:',
              this.encaissementsForReferalInvoice
            );

            this.loadingEncaissementsForReferalInvoice = false;
          })
      );
    }
  }

  async assignProformaEncaissemnetToDefinitiveInvoice(): Promise<void> {
    if (this.encaissementsForReferalInvoice.length && this.form.get('referalInvoiceId').value) {
      for (const encaissement of this.encaissementsForReferalInvoice) {
        const invoiceIds: string[] = [...encaissement.invoiceIds];

        const indexProformaInvoiceId: number = invoiceIds.indexOf(
          this.form.get('referalInvoiceId').value
        );

        if (indexProformaInvoiceId !== -1) {
          invoiceIds.splice(indexProformaInvoiceId, 1);
        }

        if (!invoiceIds.includes(this.invoiceId)) {
          invoiceIds.push(this.invoiceId);
        }

        console.log(
          '🚀 ~ file: invoice-edit.component.ts:2055 ~ InvoiceEditComponent ~ assignProformaEncaissemnetToDefinitiveInvoice ~ invoiceIds:',
          invoiceIds
        );

        await this.encaissementService.update({
          id: encaissement.id,
          invoiceIds
        } as IEncaissement);
      }
    }
  }
}
