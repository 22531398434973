<div class="mt-4" *ngIf="!isLogged"></div>

<div class="table-responsive main-table" *ngIf="loading">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th><div class="placeholder w-100"></div></th>
                <th scope="col" class="sortable" *ngFor="let field of fields">
                    <div class="placeholder w-100"></div>
                </th>
            </tr>
        </thead>
        <tbody *ngFor="let number of [].constructor(7)">
            <tr>
                <td><div class="placeholder w-100"></div></td>
                <td scope="col" class="sortable" *ngFor="let field of fields">
                    <div class="placeholder w-100"></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row mb-4" *ngIf="!loading && enquiry">
    <div class="col-lg-3 order-last">
        <div class="card text-center" *ngIf="enquiry">
            <div class="card-body">
                <h4 *ngIf="enquiry.refContractTitle">
                    <strong
                        [innerHtml]="
                            'N° contrat <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refContract')
                        "
                    ></strong>
                </h4>
                <h4
                    [innerHtml]="
                        'N° demande <br>' + getDisplayedEnquiryRefTitle(enquiry, 'refEnquiry')
                    "
                    *ngIf="enquiry.refEnquiryTitle"
                ></h4>
            </div>
        </div>

        <div
            class="alert alert-warning"
            *ngIf="enquiry.status === EnumEnquiryStatus.cancelled && enquiry.reasonCancelled"
        >
            <p>Le dossier est <strong>annulé</strong>.</p>
            <p class="mb-0">
                <strong>Raison :</strong><br />
                {{ getEnumEnquiryCancelledReasonLabel(enquiry.reasonCancelled.reason) }}
            </p>
            <div *ngIf="enquiry.reasonCancelled.comment">
                <strong>Commentaire :</strong>
                <div [innerHtml]="enquiry.reasonCancelled.comment" class="cancelled-comment"></div>
            </div>
        </div>
    </div>
    <div class="col-lg">
        <div class="btn-toolbar justify-content-center mb-4" id="btns" *ngIf="!isLogged">
            <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#enquiry-detail"
                >
                    Détails de la demande
                </button>
                <a routerLink="/login" class="btn btn-primary">Se connecter pour intéragir</a>
            </div>
        </div>

        <div class="d-flex justify-content-center gap-2 flex-wrap mb-4" *ngIf="isLogged && enquiry">
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#enquiry-detail"
            >
                Détails de la demande
            </button>
            <a
                [routerLink]="'/admin/enquiries/' + enquiryId + '/edit'"
                class="btn btn-primary"
                *ngIf="
                    (enquiry.status !== EnumEnquiryStatus.confirmed &&
                        hasAclAccess(EnumAcl.enquiriesEdit)) ||
                    (enquiry.status === EnumEnquiryStatus.confirmed &&
                        hasAclAccess(EnumAcl.enquiriesEditForConfirmedEnquiry))
                "
                >Éditer</a
            >
            <a
                [routerLink]="'/admin/enquiries/' + enquiryId + '/select-airlines'"
                class="btn btn-primary"
                [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                *ngIf="hasAclAccess(EnumAcl.enquiriesSelectAirlines)"
                >Sélection des compagnies</a
            >
            <a
                [routerLink]="'/admin/enquiries/' + enquiryId + '/send-message-to-airlines'"
                class="btn btn-primary"
                [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                *ngIf="hasAclAccess(EnumAcl.enquirieSendMessageToAirlines)"
                >Contactez les compagnies</a
            >
            <a
                [routerLink]="'/admin/enquiries/' + enquiryId + '/relance-airline'"
                class="btn btn-primary"
                [class.disabled]="enquiry.status === EnumEnquiryStatus.confirmed"
                *ngIf="hasAclAccess(EnumAcl.enquiriesRelanceAirline)"
                >Relancez les compagnies</a
            >
            <a
                [routerLink]="'/admin/enquiries/' + enquiryId + '/cancel-email-airline'"
                class="btn btn-primary"
                [class.disabled]="
                    ![EnumEnquiryStatus.confirmed, EnumEnquiryStatus.cancelled].includes(
                        enquiry.status
                    )
                "
                *ngIf="hasAclAccess(EnumAcl.enquiriesCancelledEmailToAirline)"
                >Email d'annulation pour les compagnies</a
            >
            <div class="dropdown">
                <button
                    class="btn btn-primary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton-status"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    [class.disabled]="
                        !hasAclAccess(EnumAcl.enquiriesSwitchStatus) ||
                        (enquiry.status === EnumEnquiryStatus.confirmed &&
                            !hasAclAccess(EnumAcl.enquiriesSwitchStatusFromConfirmed))
                    "
                >
                    Statut : {{ getEnumEnquiryStatusLabel(enquiry.status) }}
                </button>
                <div
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton-status"
                >
                    <button
                        type="button"
                        class="dropdown-item"
                        *ngFor="let enquiryStatus of getEnquiryStatus()"
                        [innerHtml]="
                            'Changer le statut en \'' +
                            getEnumEnquiryStatusLabel(enquiryStatus) +
                            '\''
                        "
                        (click)="changeStatus(enquiryStatus)"
                        [class.disabled]="enquiry.status === enquiryStatus"
                    ></button>
                </div>
            </div>
            <a
                [routerLink]="'/admin/quotations/enquiry/' + enquiryId"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.quotationsList)"
                >Devis</a
            >
            <a
                [routerLink]="'/admin/invoices/enquiry/' + enquiryId"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.invoicesList)"
                >Factures</a
            >
            <a
                [routerLink]="'/admin/flight-briefs/enquiry/' + enquiryId"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.flightBriefsList)"
                >Flight briefs</a
            >
            <div class="btn-group ml-2">
                <a [routerLink]="'/public/enquiry/' + enquiryId" class="btn btn-primary"
                    >Lien public</a
                >
                <button
                    type="button"
                    (click)="copyPublicUrl()"
                    class="btn btn-primary"
                    rel="tooltip"
                    data-bs-title="Copier le lien public"
                >
                    <fa-icon [icon]="faCopy"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!loading && enquiry">
    <div class="alert alert-success text-center" *ngIf="enquiry.isCompleted">
        Ce dossier a été clotûré le {{ enquiry.dateCompleted | date : "dd/MM/yyyy à HH:mm" }}.

        <div class="mt-2" *ngIf="hasAclAccess(EnumAcl.enquiriesSetCommission)">
            <button type="button" class="btn btn-primary btn-sm" (click)="reopenEnquiry()">
                Réouvrir
            </button>
        </div>
    </div>

    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
        <li
            class="nav-item"
            role="presentation"
            *ngIf="enquiry.status === EnumEnquiryStatus.confirmed"
        >
            <button
                class="nav-link active"
                [attr.aria-selected]="
                    enquiry.status === EnumEnquiryStatus.confirmed ? 'true' : 'false'
                "
                id="flights-tab"
                data-bs-toggle="tab"
                data-bs-target="#flights-tab-pane"
                type="button"
                role="tab"
                aria-controls="flights-tab-pane"
            >
                Vols
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                [class.active]="enquiry.status !== EnumEnquiryStatus.confirmed"
                [attr.aria-selected]="
                    enquiry.status !== EnumEnquiryStatus.confirmed ? 'true' : 'false'
                "
                id="cotations-tab"
                data-bs-toggle="tab"
                data-bs-target="#cotations-tab-pane"
                type="button"
                role="tab"
                aria-controls="cotations-tab-pane"
            >
                Cotations
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="documents-overview-tab"
                data-bs-toggle="tab"
                data-bs-target="#documents-overview-tab-pane"
                type="button"
                role="tab"
                aria-controls="documents-overview-tab-pane"
                aria-selected="false"
            >
                Vue d'ensemble des documents
            </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="quotations-tab"
                data-bs-toggle="tab"
                data-bs-target="#quotations-tab-pane"
                type="button"
                role="tab"
                aria-controls="quotations-tab-pane"
                aria-selected="false"
            >
                Devis
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="proformas-tab"
                data-bs-toggle="tab"
                data-bs-target="#proformas-tab-pane"
                type="button"
                role="tab"
                aria-controls="proformas-tab-pane"
                aria-selected="false"
            >
                Proformas
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="invoices-tab"
                data-bs-toggle="tab"
                data-bs-target="#invoices-tab-pane"
                type="button"
                role="tab"
                aria-controls="invoices-tab-pane"
                aria-selected="false"
            >
                Factures
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="credit-notes-tab"
                data-bs-toggle="tab"
                data-bs-target="#credit-notes-tab-pane"
                type="button"
                role="tab"
                aria-controls="credit-notes-tab-pane"
                aria-selected="false"
            >
                Avoirs
            </button>
        </li> -->
    </ul>
    <div class="tab-content" id="myTabContent">
        <div
            class="tab-pane fade show active"
            id="flights-tab-pane"
            role="tabpanel"
            aria-labelledby="flights-tab"
            tabindex="0"
            *ngIf="enquiry.status === EnumEnquiryStatus.confirmed"
        >
            <app-enquiry-flights-cards
                [loading]="loadingEnquiryFlights"
                [enquiryFlights]="enquiryFlights"
                [hideEnquiryColumns]="true"
                [enquiry]="enquiry"
            ></app-enquiry-flights-cards>
        </div>
        <div
            class="tab-pane fade"
            id="cotations-tab-pane"
            role="tabpanel"
            aria-labelledby="cotations-tab"
            tabindex="0"
            [class.show]="enquiry.status !== EnumEnquiryStatus.confirmed"
            [class.active]="enquiry.status !== EnumEnquiryStatus.confirmed"
        >
            <app-enquiry-cotations [enquiry]="enquiry"></app-enquiry-cotations>
        </div>
        <div
            class="tab-pane fade"
            id="documents-overview-tab-pane"
            role="tabpanel"
            aria-labelledby="documents-overview-tab"
            tabindex="0"
        >
            <div
                class="alert alert-warning text-center"
                *ngIf="
                    !quotations.length &&
                    !proformas.length &&
                    !invoices.length &&
                    !creditNotes.length &&
                    !flightBriefs.length
                "
            >
                Aucun document à afficher
            </div>
            <div class="card mt-4" *ngIf="quotations.length">
                <div class="card-header">Devis</div>
                <div class="table-responsive no-border">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Status</th>
                                <th scope="col">Langue</th>
                                <th scope="col">Date de création</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let quotation of quotations"
                                [routerLink]="'/admin/quotations/' + quotation.id"
                                class="clickable"
                            >
                                <td [innerHtml]="quotation.title"></td>
                                <td [innerHtml]="quotation.statusLabel"></td>
                                <td [innerHtml]="getLanguageLabel(quotation.language)"></td>
                                <td [innerHtml]="quotation.created | date : 'dd/MM/yyyy'"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="proformas.length">
                <div class="card-header">Proformas</div>
                <div class="table-responsive no-border">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of proformas"
                                [routerLink]="'/admin/invoices/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPaid
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="invoices.length">
                <div class="card-header">Factures</div>
                <div class="table-responsive no-border">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Type</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of invoices"
                                [routerLink]="'/admin/invoices/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPaid
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="getEnumInvoiceTypeLabel(invoice.invoiceType)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="creditNotes.length">
                <div class="card-header">Avoirs</div>
                <div class="table-responsive no-border">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Facture</th>
                                <th scope="col">Date de facture</th>
                                <th scope="col">Date d'échéance</th>
                                <th scope="col">Retard</th>
                                <th scope="col">Libellé</th>
                                <th scope="col">Envoyé ?</th>
                                <th scope="col">Montant HT</th>
                                <th scope="col">Montant TVA</th>
                                <th scope="col">Montant TTC</th>
                                <th scope="col">Date du réglement</th>
                                <th scope="col">Compte bancaire</th>
                                <th scope="col">Montant reglé</th>
                                <th scope="col">Montant restant</th>
                                <th scope="col">Notes internes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let invoice of creditNotes"
                                [routerLink]="'/admin/invoices/' + invoice.id"
                                class="clickable"
                                [class.table-success]="
                                    invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                                "
                                [class.table-warning]="
                                    invoiceDiffDueDate(invoice) >= -5 &&
                                    invoiceDiffDueDate(invoice) < -1 &&
                                    !invoice.isFullyPaid
                                "
                                [class.table-danger]="
                                    invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                                "
                            >
                                <td [innerText]="getInvoiceTitle(invoice)"></td>
                                <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                                <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                                <td>
                                    <span
                                        class="badge bg-danger text-light"
                                        [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                        *ngIf="
                                            invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid
                                        "
                                    ></span>
                                </td>
                                <td
                                    [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                                ></td>
                                <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountHtTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTvaTotal, invoice.currency)
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(invoice.amountTtcTotal, invoice.currency)
                                    "
                                ></td>
                                <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                                <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldePaid(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="
                                        formatPrice(
                                            invoiceGetAmountSoldeLeft(invoice),
                                            invoice.currency
                                        )
                                    "
                                ></td>
                                <td
                                    [innerText]="invoice.internalNote ? invoice.internalNote : ''"
                                ></td>
                                <!-- 
            <td>
                {{
                    invoice.requestDate
                        ? invoice.requestDate
                        : (invoice.created | date: "dd/MM/yyyy HH:mm")
                }}
            </td>
            <td>
                <span
                    [innerHtml]="
                        usersObj[invoice.requestedBy].lastname +
                        ' ' +
                        usersObj[invoice.requestedBy].firstname
                    "
                    *ngIf="usersObj[invoice.requestedBy]"
                ></span>
            </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card mt-4" *ngIf="flightBriefs.length">
                <div class="card-header">Flight briefs</div>
                <div class="table-responsive no-border">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Version</th>
                                <th scope="col">Langue</th>
                                <th scope="col">Date de création</th>
                                <th scope="col">Créé par ?</th>
                                <th scope="col">Date d'envoi</th>
                                <th scope="col">Si envoyé, par qui ?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let flightBrief of flightBriefs"
                                [routerLink]="'/admin/flight-briefs/' + flightBrief.id"
                                class="clickable"
                            >
                                <td [innerHtml]="flightBrief.title"></td>
                                <td [innerHtml]="getLanguageLabel(flightBrief.language)"></td>
                                <td [innerHtml]="flightBrief.created | date : 'dd/MM/yyyy'"></td>
                                <td [innerHtml]="flightBrief.createdByFullname"></td>
                                <ng-container *ngIf="!flightBrief.isSent">
                                    <td>À envoyer</td>
                                    <td>-</td>
                                </ng-container>
                                <ng-container *ngIf="flightBrief.isSent">
                                    <td
                                        [innerHtml]="flightBrief.sentDate | date : 'dd/MM/yyyy'"
                                    ></td>
                                    <td [innerHtml]="flightBrief.sentByFullname"></td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="quotations-tab-pane"
            role="tabpanel"
            aria-labelledby="quotations-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!quotations.length">
                Aucun devis à afficher
            </div>
            <div class="table-responsive no-border" *ngIf="quotations.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Status</th>
                            <th scope="col">Langue</th>
                            <th scope="col">Date de création</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let quotation of quotations"
                            [routerLink]="'/admin/quotations/' + quotation.id"
                            class="clickable"
                        >
                            <td [innerHtml]="quotation.title"></td>
                            <td [innerHtml]="quotation.statusLabel"></td>
                            <td [innerHtml]="getLanguageLabel(quotation.language)"></td>
                            <td [innerHtml]="quotation.created | date : 'dd/MM/yyyy'"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="proformas-tab-pane"
            role="tabpanel"
            aria-labelledby="proformas-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!proformas.length">
                Aucune proforma à afficher
            </div>
            <div class="table-responsive no-border" *ngIf="proformas.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of proformas"
                            [routerLink]="'/admin/invoices/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPaid
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="invoices-tab-pane"
            role="tabpanel"
            aria-labelledby="invoices-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!invoices.length">
                Aucune facture à afficher
            </div>
            <div class="table-responsive" *ngIf="invoices.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of invoices"
                            [routerLink]="'/admin/invoices/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPaid
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
                <td>
                    {{
                        invoice.requestDate
                            ? invoice.requestDate
                            : (invoice.created | date: "dd/MM/yyyy HH:mm")
                    }}
                </td>
                <td>
                    <span
                        [innerHtml]="
                            usersObj[invoice.requestedBy].lastname +
                            ' ' +
                            usersObj[invoice.requestedBy].firstname
                        "
                        *ngIf="usersObj[invoice.requestedBy]"
                    ></span>
                </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            class="tab-pane fade"
            id="credit-notes-tab-pane"
            role="tabpanel"
            aria-labelledby="credit-notes-tab"
            tabindex="0"
        >
            <div class="alert alert-warning text-center" *ngIf="!creditNotes.length">
                Aucun avoir à afficher
            </div>
            <div class="table-responsive no-border" *ngIf="creditNotes.length">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Facture</th>
                            <th scope="col">Date de facture</th>
                            <th scope="col">Date d'échéance</th>
                            <th scope="col">Retard</th>
                            <th scope="col">Libellé</th>
                            <th scope="col">Envoyé ?</th>
                            <th scope="col">Montant HT</th>
                            <th scope="col">Montant TVA</th>
                            <th scope="col">Montant TTC</th>
                            <th scope="col">Date du réglement</th>
                            <th scope="col">Compte bancaire</th>
                            <th scope="col">Montant reglé</th>
                            <th scope="col">Montant restant</th>
                            <th scope="col">Notes internes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invoice of creditNotes"
                            [routerLink]="'/admin/invoices/' + invoice.id"
                            class="clickable"
                            [class.table-success]="
                                invoiceDiffDueDate(invoice) <= -15 && !invoice.isFullyPaid
                            "
                            [class.table-warning]="
                                invoiceDiffDueDate(invoice) >= -5 &&
                                invoiceDiffDueDate(invoice) < -1 &&
                                !invoice.isFullyPaid
                            "
                            [class.table-danger]="
                                invoiceDiffDueDate(invoice) >= -1 && !invoice.isFullyPaid
                            "
                        >
                            <td [innerText]="getInvoiceTitle(invoice)"></td>
                            <td [innerText]="invoice.issueDate | date : 'dd/MM/yyyy'"></td>
                            <td [innerText]="invoice.dueDate | date : 'dd/MM/yyyy'"></td>
                            <td>
                                <span
                                    class="badge bg-danger text-light"
                                    [innerText]="invoiceDiffDueDate(invoice) + ' jours'"
                                    *ngIf="invoiceDiffDueDate(invoice) > 0 && !invoice.isFullyPaid"
                                ></span>
                            </td>
                            <td
                                [innerText]="invoice.invoiceObject ? invoice.invoiceObject : ''"
                            ></td>
                            <td [innerText]="invoice.sentToClient ? 'Oui' : 'Non'"></td>
                            <td
                                [innerText]="formatPrice(invoice.amountHtTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTvaTotal, invoice.currency)"
                            ></td>
                            <td
                                [innerText]="formatPrice(invoice.amountTtcTotal, invoice.currency)"
                            ></td>
                            <td [innerText]="invoice.soldedDate ? invoice.soldedDate : ''"></td>
                            <td [innerText]="bankAccountsObj[invoice.bankAccount]?.name"></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldePaid(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td
                                [innerText]="
                                    formatPrice(
                                        invoiceGetAmountSoldeLeft(invoice),
                                        invoice.currency
                                    )
                                "
                            ></td>
                            <td [innerText]="invoice.internalNote ? invoice.internalNote : ''"></td>
                            <!-- 
            <td>
                {{
                    invoice.requestDate
                        ? invoice.requestDate
                        : (invoice.created | date: "dd/MM/yyyy HH:mm")
                }}
            </td>
            <td>
                <span
                    [innerHtml]="
                        usersObj[invoice.requestedBy].lastname +
                        ' ' +
                        usersObj[invoice.requestedBy].firstname
                    "
                    *ngIf="usersObj[invoice.requestedBy]"
                ></span>
            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" #modalCancelled>
        <form
            (submit)="saveStatusCancelled()"
            [formGroup]="formStatusCancelled"
            (keydown.enter)="saveStatusCancelled()"
            class="main-form"
        >
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Dossier annulé</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="reason">Raison d'annulation</label>
                            <select
                                class="form-select"
                                id="reason"
                                formControlName="reason"
                                required
                                [class.is-invalid]="
                                    (formStatusCancelled.touched ||
                                        formStatusCancelled.controls['reason'].touched) &&
                                    formStatusCancelled.controls['reason'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            >
                                <option value="">Sélectionner une raison</option>
                                <option
                                    [value]="reason"
                                    *ngFor="let reason of getCancelledReasons()"
                                    [innerHtml]="getEnumEnquiryCancelledReasonLabel(reason)"
                                ></option>
                            </select>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formStatusCancelled.touched ||
                                            formStatusCancelled.controls['reason'].touched) &&
                                        formStatusCancelled.controls['reason'].errors &&
                                        formStatusCancelled.controls['reason'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="comment">Commentaire</label>
                            <textarea
                                formControlName="comment"
                                class="form-control"
                                id="comment"
                                [class.is-invalid]="
                                    (formStatusCancelled.touched ||
                                        formStatusCancelled.controls['comment'].touched) &&
                                    formStatusCancelled.controls['comment'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            ></textarea>

                            <div class="invalid-tooltip">
                                <div
                                    *ngIf="
                                        (formStatusCancelled.touched ||
                                            formStatusCancelled.controls['comment'].touched) &&
                                        formStatusCancelled.controls['comment'].errors &&
                                        formStatusCancelled.controls['comment'].errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Annuler
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="saveStatusCancelled()"
                            [disabled]="formStatusCancelled.disabled || !formStatusCancelled.valid"
                        >
                            Sauvegarder
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="modal" tabindex="-1" role="dialog" #modalConfirmed>
        <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Dossier confirmé</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <p class="alert alert-information text-center mb-2">
                        Sélectionnez la cotation confirmée ci-dessous.
                    </p>

                    <div class="table-responsive no-border" *ngIf="lines.length">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="sortable text-center"
                                        *ngFor="let field of fields"
                                        [class.is-sorted]="orderBy.field == field.code"
                                        [class.asc]="
                                            orderBy.field == field.code &&
                                            orderBy.direction == 'asc'
                                        "
                                        [class.desc]="
                                            orderBy.field == field.code &&
                                            orderBy.direction == 'desc'
                                        "
                                        (click)="sortField(field.code)"
                                        [innerText]="field.title"
                                    ></th>
                                    <th class="text-center">Confirmé ?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let line of lines"
                                    [class.table-success]="
                                        line.status === EnumEnquiryCotationStatus.confirmed
                                    "
                                >
                                    <td>
                                        <span
                                            class="badge"
                                            [class.bg-primary]="
                                                line.status ===
                                                EnumEnquiryCotationStatus.waitingForAnswer
                                            "
                                            [class.bg-success]="
                                                ['answered', 'confirmed'].indexOf(line.status) !==
                                                -1
                                            "
                                            [class.bg-danger]="
                                                line.status === EnumEnquiryCotationStatus.rejected
                                            "
                                            [class.bg-warning]="
                                                line.status === EnumEnquiryCotationStatus.toContact
                                            "
                                            [innerHtml]="
                                                getEnumEnquiryCotationStatusLabel(line.status)
                                            "
                                        ></span>
                                    </td>
                                    <td [innerHtml]="line.airlineTitle"></td>
                                    <td [innerHtml]="line.airlineCountryTitle"></td>
                                    <td>
                                        <span [innerHtml]="line.legsTitle"></span>

                                        <div
                                            *ngIf="
                                                line.legsId &&
                                                legsList[line.itineraryId] &&
                                                line.legsId.length &&
                                                line.legsId.length <
                                                    legsList[line.itineraryId].length
                                            "
                                        >
                                            <fa-icon
                                                [icon]="faWarning"
                                                rel="tooltip"
                                                [title]="
                                                    'Tous les legs ne sont pas présent sur cette cotation (' +
                                                    line.legsId.length +
                                                    '/' +
                                                    legsList[line.itineraryId].length +
                                                    ')'
                                                "
                                            ></fa-icon>
                                        </div>
                                    </td>
                                    <td>
                                        {{ line.aircraftTitle.toUpperCase() }}

                                        <div *ngIf="line.aircraftErrorMessages.length">
                                            <fa-icon
                                                [icon]="faWarning"
                                                rel="tooltip"
                                                [title]="line.aircraftErrorMessages.join('<hr>')"
                                            ></fa-icon>
                                        </div>
                                    </td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="
                                            line.ageAverage ? line.ageAverage + ' ans' : 'Inconnu'
                                        "
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.capacityAircraft"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.weightAircraft + ' kg'"
                                        *ngIf="enquiry.type === EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.volumeAircraft + ' m<sup>3</sup>'"
                                        *ngIf="enquiry.type === EnumEnquiryType.cargo"
                                    ></td>
                                    <td
                                        class="text-center"
                                        [innerHtml]="line.nbPax"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    ></td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.buyingPriceInCurrency !== null
                                                    ? formatPrice(
                                                          line.buyingPriceInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.buyingPrice !== null
                                                    ? "= " +
                                                      formatPrice(line.buyingPrice, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>

                                        <div *ngIf="line.isNationalFlight && line.buyingPrice">
                                            <fa-icon
                                                [icon]="faWarning"
                                                rel="tooltip"
                                                data-bs-title="Attention: les prix sont avec TVA car c'est un vol national"
                                            ></fa-icon>
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.taxesTotalPaxInCurrency !== null
                                                    ? formatPrice(
                                                          line.taxesTotalPaxInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.taxesTotalPax !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.taxesTotalPax,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.cateringInCurrency !== null
                                                    ? formatPrice(
                                                          line.cateringInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.catering !== null
                                                    ? "= " +
                                                      formatPrice(line.catering, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.priceNetTTCInCurrency !== null
                                                    ? formatPrice(
                                                          line.priceNetTTCInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.priceNetTTC !== null
                                                    ? "= " +
                                                      formatPrice(line.priceNetTTC, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.pricePPInCurrency !== null
                                                    ? formatPrice(
                                                          line.pricePPInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.pricePP !== null
                                                    ? "= " +
                                                      formatPrice(line.pricePP, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.sellingPriceInCurrency !== null
                                                    ? formatPrice(
                                                          line.sellingPriceInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.sellingPrice !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.sellingPrice,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.marginInCurrency !== null
                                                    ? formatPrice(
                                                          line.marginInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.margin !== null
                                                    ? "= " +
                                                      formatPrice(line.margin, defaultCurrency)
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                            {{
                                                line.marginPercentInCurrency !== null
                                                    ? line.marginPercentInCurrency
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.marginPercent !== null
                                                    ? "= " + line.marginPercent + "%"
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        class="text-end"
                                        *ngIf="enquiry.type !== EnumEnquiryType.cargo"
                                    >
                                        <div>
                                            {{
                                                line.sellingPricePPInCurrency !== null
                                                    ? formatPrice(
                                                          line.sellingPricePPInCurrency,
                                                          line.currency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                        <div *ngIf="line.currency !== defaultCurrency">
                                            {{
                                                line.sellingPricePP !== null
                                                    ? "= " +
                                                      formatPrice(
                                                          line.sellingPricePP,
                                                          defaultCurrency
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </td>
                                    <td [innerHtml]="line.notes"></td>
                                    <td>
                                        <div class="d-flex justify-content-center" *ngIf="isLogged">
                                            <span
                                                *ngIf="!line.cotationId"
                                                rel="tooltip"
                                                data-bs-title="Cette cotation n'a pas été remplie"
                                                data-bs-placement="left"
                                            >
                                                <fa-icon [icon]="faCheck"></fa-icon>
                                            </span>

                                            <span
                                                *ngIf="line.cotationId && !line.sellingPrice"
                                                rel="tooltip"
                                                data-bs-title="Cette cotation n'a pas de prix de vente"
                                                data-bs-placement="left"
                                            >
                                                <fa-icon [icon]="faCheck"></fa-icon>
                                            </span>

                                            <div
                                                class="form-check"
                                                *ngIf="line.cotationId && line.sellingPrice"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    [checked]="
                                                        line.status ===
                                                            EnumEnquiryCotationStatus.confirmed &&
                                                        selectedConfirmedCotationsId.includes(
                                                            line.cotationId
                                                        )
                                                    "
                                                    (click)="
                                                        toggleConfirmedCotation(line.cotationId)
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th [attr.colspan]="fields.length"></th>
                                    <th class="text-center">
                                        <button
                                            type="button"
                                            class="btn btn-success"
                                            (click)="saveConfirmedCotations()"
                                            [disabled]="!selectedConfirmedCotationsId.length"
                                        >
                                            <fa-icon [icon]="faCheck"></fa-icon> Sauvegarder
                                        </button>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="enquiry-detail"
        tabindex="-1"
        aria-labelledby="enquiryDetailLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="enquiryDetailLabel">Détails de la demande</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body" *ngIf="enquiry">
                    <app-enquiry-card [enquiry]="enquiry"></app-enquiry-card>
                </div>
            </div>
        </div>
    </div>
</div>
