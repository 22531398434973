<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div class="row">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="userGroupId">Groupe d'utilisateurs</label>
                        <select
                            class="form-select"
                            id="userGroupId"
                            formControlName="userGroupId"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['userGroupId'].touched) &&
                                form.controls['userGroupId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="userGroup.id"
                                *ngFor="let userGroup of userGroups"
                                [innerHtml]="userGroup.title"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['userGroupId'].touched) &&
                                    form.controls['userGroupId'].errors &&
                                    form.controls['userGroupId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="lastname">Nom</label>
                        <input
                            class="form-control"
                            id="lastname"
                            formControlName="lastname"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['lastname'].touched) &&
                                form.controls['lastname'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="updateInitials()"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['lastname'].touched) &&
                                    form.controls['lastname'].errors &&
                                    form.controls['lastname'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="firstname">Prénom</label>
                        <input
                            class="form-control"
                            id="firstname"
                            formControlName="firstname"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['firstname'].touched) &&
                                form.controls['firstname'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            (keyup)="updateInitials()"
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['firstname'].touched) &&
                                    form.controls['firstname'].errors &&
                                    form.controls['firstname'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="initials">Initiales</label>
                        <input
                            class="form-control"
                            id="initials"
                            formControlName="initials"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['initials'].touched) &&
                                form.controls['initials'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['initials'].touched) &&
                                    form.controls['initials'].errors &&
                                    form.controls['initials'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="!userId">
                        <label for="email">Email</label>
                        <input
                            class="form-control"
                            id="email"
                            formControlName="email"
                            type="email"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['email'].touched) &&
                                form.controls['email'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].errors &&
                                    form.controls['email'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].errors &&
                                    form.controls['email'].errors['email']
                                "
                            >
                                Ce champ doit être un email valide.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="phone">Téléphone</label>
                        <input
                            class="form-control"
                            id="phone"
                            formControlName="phone"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || form.controls['phone'].touched) &&
                                form.controls['phone'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['phone'].touched) &&
                                    form.controls['phone'].errors &&
                                    form.controls['phone'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="emailSignatureHtml">Signature d'email</label>
                        <quill-editor formControlName="emailSignatureHtml"></quill-editor>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['emailSignatureHtml'].touched) &&
                                    form.controls['emailSignatureHtml'].errors &&
                                    form.controls['emailSignatureHtml'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="email-in-cc"
                            formControlName="emailInCc"
                        />
                        <label class="form-check-label" for="email-in-cc"
                            >Mettre l'email de l'utilisateur en CC dans les envois d'email ?</label
                        >
                    </div>

                    <div class="form-group">
                        <label for="primaryColor">Couleur assigné</label>
                        <input
                            class="form-control"
                            id="primaryColor"
                            formControlName="primaryColor"
                            type="color"
                        />
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="displayInStatistics"
                            formControlName="displayInStatistics"
                        />
                        <label class="form-check-label" for="displayInStatistics"
                            >Affiché dans les statistiques ?</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="card-header">Pipedrive</div>
                <div class="card-body">
                    <div class="placeholder w-100" *ngIf="loadingPipedriveUsers"></div>
                    <div class="form-group mb-0" *ngIf="!loadingPipedriveUsers">
                        <label for="pipedriveId">Utilisateur Pipedrive</label>
                        <select
                            class="form-select"
                            id="pipedriveId"
                            formControlName="pipedriveId"
                            [class.is-invalid]="
                                (form.touched || form.controls['pipedriveId'].touched) &&
                                form.controls['pipedriveId'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="null">-- Lié à aucun utilisateur --</option>
                            <option
                                [value]="pipedriveUser.id"
                                *ngFor="let pipedriveUser of pipedriveUsers"
                                [innerText]="pipedriveUser.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['pipedriveId'].touched) &&
                                    form.controls['pipedriveId'].errors &&
                                    form.controls['pipedriveId'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 mb-4 text-center">
        <div class="col">
            <button
                type="button"
                [routerLink]="userId ? '/admin/users/' + userId : '/admin/users'"
                class="btn btn-secondary btn-block"
                [disabled]="form.disabled || sending"
            >
                Annuler
            </button>
        </div>
        <div class="col">
            <button
                type="submit"
                class="btn btn-primary btn-block"
                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                [disabled]="form.disabled || !form.valid || sending"
            ></button>
        </div>
    </div>
</form>
