import { EnumEnquiryOfferStatus } from '../enums/enquiry-offer-status.enum';
import { EnumLanguage } from '../enums/language.enum';
import { IAircraftCompiled } from './aircraft-compiled.interface';
import { IAirline } from './airline.interface';
import { IEnquiryCotation } from './enquiry-cotation.interface';
import { getOfferAirline } from './enquiry.interface';
import { IStoreData } from './store-data.interface';

export interface IEnquiryOffer extends IStoreData {
  aircraftCompiledId: string;
  selectedDatetime: Date;
  contactDatetime: Date;
  contactedBy: string;
  email: string;
  message: string;
  language: EnumLanguage;
  status: EnumEnquiryOfferStatus;
  followedUp: boolean;
  aircraftCompiled: IAircraftCompiled;
  enquiryId: string;
  cotations?: IEnquiryCotation[];
}

export const getAirlineIdFromOffer = (enquiryOffer: IEnquiryOffer): string | null => {
  let airline: IAirline | null = getOfferAirline(enquiryOffer);

  if (airline) {
    return airline.id;
  } else if (enquiryOffer.aircraftCompiledId) {
    const splittedAircraftCompiledId: string[] = enquiryOffer.aircraftCompiledId.split('-');

    return splittedAircraftCompiledId[0];
  }

  return null;
};
