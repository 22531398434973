import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IStoreData } from 'src/app/interfaces/store-data.interface';
import firebase from 'firebase/compat/app';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatPrice } from 'src/app/misc.utils';

export enum EnumDataListColumnType {
  text = 'text',
  email = 'email',
  datetime = 'datetime',
  date = 'date',
  boolean = 'boolean',
  function = 'function',
  image = 'image',
  price = 'price'
}

export enum EnumDataListColumnAlign {
  start = 'start',
  center = 'center',
  end = 'end'
}

export interface IDataListColumn {
  title: string;
  field?: string;
  notSortable?: boolean;
  type?: EnumDataListColumnType;
  align?: EnumDataListColumnAlign;
  resolve?: any;
}

export interface IDataListOrderBy {
  field: string;
  direction: firebase.firestore.OrderByDirection;
}

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss']
})
export class DataListComponent<T extends IStoreData> {
  @Input() loading: boolean = false;
  @Input() data: T[] = [];
  @Input() columns: IDataListColumn[] = [];
  @Input() orderBy: IDataListOrderBy | null = null;
  @Input() itemsPerPage: number = 20;
  @Input() rowCls: any | null = null;
  @Input() rowUrl: string | null = null;
  @Input() currentPage: number = 0;
  @Input() openItemEvent: boolean = false;

  @Output() updatedOrderBy: EventEmitter<IDataListOrderBy | null> = new EventEmitter();
  @Output() prevPage: EventEmitter<void> = new EventEmitter();
  @Output() nextPage: EventEmitter<void> = new EventEmitter();
  @Output() openItem: EventEmitter<T> = new EventEmitter();

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  EnumDataListColumnType = EnumDataListColumnType;
  EnumDataListColumnAlign = EnumDataListColumnAlign;

  formatPrice = formatPrice;

  sortField(column: IDataListColumn): void {
    if (!column.notSortable) {
      if (!this.orderBy) {
        this.orderBy = {
          direction: 'asc',
          field: null
        };
      }

      if (this.orderBy.field !== column.field) {
        this.orderBy.direction = 'asc';
        this.orderBy.field = column.field;
      } else {
        if (this.orderBy.direction === 'asc') {
          this.orderBy.direction = 'desc';
        } else {
          this.orderBy.direction = 'asc';
        }
      }

      this.updatedOrderBy.emit(this.orderBy);
    }
  }
}
