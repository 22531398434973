import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryPaymentSummaryDetailFirestoreService } from './enquiry-payment-summary-details-firestore.service';
import { IEnquiryPaymentSummaryDetail } from 'src/app/interfaces/enquiry-payment-summary-detail.interface';
import { addZeroToDigit } from 'src/app/misc.utils';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryPaymentSummaryDetailService {
  constructor(
    private enquiryPaymentSummaryDetailFirestoreService: EnquiryPaymentSummaryDetailFirestoreService
  ) {}

  create(enquiryPaymentSummaryDetail: IEnquiryPaymentSummaryDetail): Promise<string> {
    return this.enquiryPaymentSummaryDetailFirestoreService.create(enquiryPaymentSummaryDetail);
  }

  update(enquiryPaymentSummaryDetail: IEnquiryPaymentSummaryDetail): Promise<string> {
    return this.enquiryPaymentSummaryDetailFirestoreService.update(enquiryPaymentSummaryDetail);
  }

  delete(id: string): Promise<void> {
    return this.enquiryPaymentSummaryDetailFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiryPaymentSummaryDetail> {
    return this.enquiryPaymentSummaryDetailFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService.collectionValues$();
  }

  getAllForEnquiryPaymentSummary(
    enquiryPaymentSummaryId: string
  ): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService
      .collectionValues$(ref => ref.where('enquiryPaymentSummaryId', '==', enquiryPaymentSummaryId))
      .pipe(
        map(results => {
          for (const result of results) {
            if (result.temporaryRows) {
              for (let temporaryRow of result.temporaryRows) {
                temporaryRow =
                  this.enquiryPaymentSummaryDetailFirestoreService.timestampsToDate(temporaryRow);
              }
            }
          }

          return results;
        })
      );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryPaymentSummaryDetailFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }

  getAllOfMonth(year: number, month: number): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService
      .collectionValues$(ref => ref.where('periodMonth', '==', year + '-' + addZeroToDigit(month)))
      .pipe(
        map(results => {
          for (const result of results) {
            if (result.temporaryRows) {
              for (let temporaryRow of result.temporaryRows) {
                temporaryRow =
                  this.enquiryPaymentSummaryDetailFirestoreService.timestampsToDate(temporaryRow);
              }
            }
          }

          return results;
        })
      );
  }

  getAllOfQuarter(year: number, quarter: number): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService
      .collectionValues$(ref => ref.where('periodQuarter', '==', year + '-q' + quarter))
      .pipe(
        map(results => {
          for (const result of results) {
            if (result.temporaryRows) {
              for (let temporaryRow of result.temporaryRows) {
                temporaryRow =
                  this.enquiryPaymentSummaryDetailFirestoreService.timestampsToDate(temporaryRow);
              }
            }
          }

          return results;
        })
      );
  }

  getAllOfYear(year: number): Observable<IEnquiryPaymentSummaryDetail[]> {
    return this.enquiryPaymentSummaryDetailFirestoreService
      .collectionValues$(ref => ref.where('periodYear', '==', year))
      .pipe(
        map(results => {
          for (const result of results) {
            if (result.temporaryRows) {
              for (let temporaryRow of result.temporaryRows) {
                temporaryRow =
                  this.enquiryPaymentSummaryDetailFirestoreService.timestampsToDate(temporaryRow);
              }
            }
          }

          return results;
        })
      );
  }
}
