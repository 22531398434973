<div class="row justify-content-between mt-4">
    <div class="col-sm-4 col-md-3">
        <div class="form-group">
            <select
                class="form-select"
                id="filterByPeriod"
                [(ngModel)]="filterByPeriod"
                (change)="changePeriod()"
            >
                <option
                    [value]="filter.value"
                    *ngFor="let filter of filtersByPeriod"
                    [innerHtml]="filter.title"
                ></option>
            </select>
        </div>
    </div>

    <div class="col-sm-4 text-center text-sm-end">
        <div class="btn-group">
            <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                [attr.data-bs-target]="userId ? '#columns-my-data-modal' : '#columns-modal'"
            >
                <fa-icon [icon]="faTableColumns"></fa-icon>
            </button>
            <a
                class="btn btn-primary"
                *ngIf="!userId && hasAclAccess(EnumAcl.enquiryPaymentsSummaryImport)"
                routerLink="/admin/finance/import-enquiry-payments-summaries"
            >
                Import
            </a>
            <button
                type="button"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryExport)"
                data-bs-toggle="modal"
                [attr.data-bs-target]="userId ? '#export-my-data-modal' : '#export-modal'"
                [disabled]="loading || loadingTotal"
            >
                Export
            </button>
        </div>
    </div>
</div>

<div class="table-reponsive mb-4">
    <table class="table table-striped table-hover" *ngIf="loading">
        <thead>
            <tr>
                <th
                    *ngFor="
                        let cell of [].constructor(
                            columnsSettings.allColumns
                                ? columns.length
                                : columnsSettings.columnSelected.length
                        )
                    "
                >
                    <div class="placeholder w-100"></div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let number of [].constructor(10)">
                <td
                    *ngFor="
                        let cell of [].constructor(
                            columnsSettings.allColumns
                                ? columns.length
                                : columnsSettings.columnSelected.length
                        )
                    "
                >
                    <div class="placeholder w-100"></div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-striped table-hover" *ngIf="!loading">
        <thead>
            <tr>
                <ng-container *ngFor="let column of columns">
                    <th
                        [class.column-date]="column.searchType === EnumColumnSearch.date"
                        [class.column-number]="column.searchType === EnumColumnSearch.number"
                        [class.d-none]="column.hide"
                        [class.col-airline]="column.field === 'enquiryPaymentSummary.airlineTitles'"
                        *ngIf="
                            !column.visibleOnlyExport &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(column.field))
                        "
                    >
                        {{ column.title }}

                        <div class="form-group mb-0" *ngIf="column.searchType">
                            <input
                                type="search"
                                class="form-control"
                                placeholder="Rechercher"
                                [(ngModel)]="column.query"
                                (keyup)="updateColumnSearch()"
                                (search)="updateColumnSearch()"
                                [disabled]="loading"
                                *ngIf="column.searchType === EnumColumnSearch.text"
                            />

                            <div
                                class="input-group"
                                *ngIf="
                                    [EnumColumnSearch.date, EnumColumnSearch.number].includes(
                                        column.searchType
                                    )
                                "
                            >
                                <button
                                    class="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    [innerText]="getColumnSearchOperatorLabel(column.operator)"
                                    [disabled]="loading"
                                ></button>
                                <ul class="dropdown-menu">
                                    <button
                                        type="button"
                                        class="dropdown-item"
                                        *ngFor="let searchOperator of getSearchOperators()"
                                        [innerText]="getColumnSearchOperatorLabel(searchOperator)"
                                        (click)="changeSearchOperator(column, searchOperator)"
                                        [disabled]="searchOperator === column.operator"
                                    ></button>
                                </ul>
                                <input
                                    *ngIf="column.searchType === EnumColumnSearch.number"
                                    type="number"
                                    class="form-control"
                                    [(ngModel)]="column.query"
                                    (keyup)="updateColumnSearch()"
                                    (change)="updateColumnSearch()"
                                    [disabled]="loading"
                                    debounce="500"
                                />
                                <input
                                    *ngIf="column.searchType === EnumColumnSearch.date"
                                    type="date"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    [(ngModel)]="column.query"
                                    (change)="updateColumnSearch()"
                                    [disabled]="loading"
                                />
                            </div>

                            <select
                                class="form-select"
                                *ngIf="column.searchType === EnumColumnSearch.select"
                                [(ngModel)]="column.query"
                                (change)="updateColumnSearch()"
                            >
                                <option [ngValue]="null">Tous</option>
                                <option
                                    *ngFor="let option of column.options"
                                    [value]="option.value"
                                    [innerText]="option.label"
                                ></option>
                            </select>
                            <select
                                class="form-select"
                                *ngIf="column.searchType === EnumColumnSearch.users"
                                [(ngModel)]="column.query"
                                (change)="updateColumnSearch()"
                                [disabled]="loadingAllUsers"
                            >
                                <option [ngValue]="null">Tous</option>
                                <option
                                    *ngFor="let user of users"
                                    [value]="user.initials"
                                    [innerText]="user.initials + ' (' + getUserFullname(user) + ')'"
                                ></option>
                            </select>
                            <select
                                class="form-select"
                                *ngIf="column.searchType === EnumColumnSearch.enquiryTypes"
                                [(ngModel)]="column.query"
                                (change)="updateColumnSearch()"
                            >
                                <option [ngValue]="null">Tous</option>
                                <option
                                    *ngFor="let enquiryType of getEnquiryTypes()"
                                    [value]="enquiryType"
                                    [innerText]="getEnumEnquiryTypeLabel(enquiryType)"
                                ></option>
                            </select>
                            <select
                                class="form-select"
                                *ngIf="column.searchType === EnumColumnSearch.boolean"
                                [(ngModel)]="column.query"
                                (change)="updateColumnSearch()"
                            >
                                <option [ngValue]="null">Tous</option>
                                <option [ngValue]="true">Oui</option>
                                <option [ngValue]="false">Non</option>
                            </select>
                        </div>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr class="table-warning" *ngIf="!dataDisplayed.length">
                <td
                    [attr.colspan]="
                        columnsSettings.allColumns
                            ? columns.length
                            : columnsSettings.columnSelected.length
                    "
                    class="text-center"
                >
                    Aucun dossier pour cette période
                </td>
            </tr>
            <tr
                class="table-warning"
                *ngIf="!columnsSettings.allColumns && !columnsSettings.columnSelected.length"
            >
                <td class="text-center">
                    Aucune colonne affichée<br />

                    <button
                        type="button"
                        class="btn btn-primary mt-2"
                        (click)="resetColumnSettings()"
                    >
                        Réinitialiser
                    </button>
                </td>
            </tr>
            <ng-container *ngFor="let item of dataDisplayed; let i = index">
                <tr
                    [class.enquiry-business]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.business
                    "
                    [class.enquiry-commercial]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.commercial
                    "
                    [class.enquiry-cargo]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.cargo
                    "
                    [class.enquiry-sport]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.sport
                    "
                    [class.enquiry-helico]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.helico
                    "
                    [class.enquiry-acmi]="
                        item.enquiryPaymentSummary.enquiryType === EnumEnquiryType.acmi
                    "
                >
                    <td
                        class="last-tr-for-enquiry"
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryRefContractTitle'
                                ))
                        "
                    >
                        <a
                            [href]="'/admin/enquiries/' + item.enquiryPaymentSummary.enquiryId"
                            [innerText]="item.enquiryPaymentSummary.enquiryRefContractTitle"
                            target="_blank"
                        ></a>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummaryDetail.comment'
                                ))
                        "
                    >
                        <div
                            [innerText]="item.enquiryPaymentSummaryDetail?.comment"
                            *ngIf="item.enquiryPaymentSummaryDetail?.comment?.length"
                        ></div>
                        <div *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummarySetComments)">
                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="setComment(item)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                        </div>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryIsCompleted'
                                ))
                        "
                    >
                        <span
                            class="badge"
                            [class.text-bg-secondary]="
                                !item.enquiryPaymentSummary.enquiryIsCompleted
                            "
                            [class.text-bg-success]="item.enquiryPaymentSummary.enquiryIsCompleted"
                            [innerText]="
                                item.enquiryPaymentSummary.enquiryIsCompleted ? 'Oui' : 'Non'
                            "
                        ></span>
                        <div
                            class="mt-2"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiriesSetCommission) &&
                                (item.enquiryPaymentSummary.enquiryIsCompleted ||
                                    item.mainStatus === EnumEnquiryPaymentStatus.payed)
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                [innerText]="
                                    !item.enquiryPaymentSummary.enquiryIsCompleted
                                        ? 'Clôturer'
                                        : 'Réouvrir'
                                "
                                (click)="toggleEnquiryIsCompleted(item)"
                            ></button>
                        </div>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryCommissionFee'
                                ))
                        "
                        [class.text-danger]="
                            item.enquiryPaymentSummary.enquiryCommissionFeeAmount < 0
                        "
                    >
                        <div
                            class="mb-2"
                            *ngIf="
                                item.enquiryPaymentSummary.enquiryCommissionFee ||
                                item.enquiryPaymentSummary.enquiryCommissionFee === 0 ||
                                item.enquiryPaymentSummary.enquiryCommissionFeeAmount
                            "
                        >
                            <div
                                *ngIf="
                                    item.enquiryPaymentSummary.enquiryIsCommissionFeePercentage &&
                                    item.enquiryPaymentSummary.enquiryCommissionFee
                                "
                            >
                                {{ item.enquiryPaymentSummary.enquiryCommissionFee + "%" }}
                                <span
                                    *ngIf="item.enquiryPaymentSummary.enquiryCommissionFeeAmount"
                                    [innerText]="
                                        '(= ' +
                                        formatPrice(
                                            item.enquiryPaymentSummary.enquiryCommissionFeeAmount,
                                            getDefaultCurrency()
                                        ) +
                                        ')'
                                    "
                                ></span>
                            </div>
                            <div
                                *ngIf="
                                    !item.enquiryPaymentSummary.enquiryIsCommissionFeePercentage &&
                                    item.enquiryPaymentSummary.enquiryCommissionFeeAmount
                                "
                                [innerText]="
                                    item.enquiryPaymentSummary.enquiryCommissionFeeAmount +
                                    getEnumCurrencySymbol(getDefaultCurrency())
                                "
                            ></div>
                            <span
                                class="badge bg-warning"
                                *ngIf="
                                    item.enquiryPaymentSummary.enquiryCommissionStatus &&
                                    ![EnumEnquiryCommissionStatus.success].includes(
                                        item.enquiryPaymentSummary.enquiryCommissionStatus
                                    )
                                "
                                [innerText]="
                                    getEnumEnquiryCommissionStatusLabel(
                                        item.enquiryPaymentSummary.enquiryCommissionStatus
                                    )
                                "
                            ></span>
                            <p
                                class="text-muted mb-0"
                                [innerText]="item.enquiryPaymentSummary.enquiryCommissionComment"
                                *ngIf="item.enquiryPaymentSummary.enquiryCommissionComment"
                            ></p>
                        </div>
                        <button
                            type="button"
                            class="btn btn-link btn-sm"
                            *ngIf="hasAclAccess(EnumAcl.enquiriesSetCommission)"
                            (click)="openModalCommission(item.enquiryPaymentSummary)"
                        >
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.legDates'
                                ))
                        "
                    >
                        <div
                            *ngFor="let legDate of item.enquiryPaymentSummary.legDates"
                            [innerText]="legDate"
                        ></div>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            !userId &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.userInitials'
                                ))
                        "
                    >
                        <a
                            [href]="'/admin/users/' + item.enquiryPaymentSummary.enquiryProcessedBy"
                            [innerText]="item.enquiryPaymentSummary.userInitials"
                            target="_blank"
                        ></a>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            !userId &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryType'
                                ))
                        "
                        [innerText]="
                            getEnumEnquiryTypeLabel(item.enquiryPaymentSummary.enquiryType)
                        "
                    ></td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.clientName'
                                ))
                        "
                    >
                        <a
                            [href]="'/admin/clients/' + item.enquiryPaymentSummary.clientId"
                            [innerText]="item.enquiryPaymentSummary.clientName"
                            target="_blank"
                        ></a>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.airlineTitles'
                                ))
                        "
                    >
                        <div
                            *ngFor="
                                let airlineId of item.enquiryPaymentSummary.airlineIds;
                                let i = index
                            "
                        >
                            <a
                                [href]="'/admin/airlines/' + airlineId"
                                [innerText]="item.enquiryPaymentSummary.airlineTitles[i]"
                                target="_blank"
                            ></a>
                        </div>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceRef'
                            )
                        "
                    >
                        <a
                            [href]="'/admin/invoices/' + item.enquiryPaymentSummary.invoiceId"
                            [innerText]="item.enquiryPaymentSummary.invoiceRef"
                            *ngIf="item.enquiryPaymentSummary.invoiceId"
                            target="_blank"
                        ></a>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceClientName'
                            )
                        "
                    >
                        <a
                            [href]="'/admin/invoices/' + item.enquiryPaymentSummary.invoiceClientId"
                            [innerText]="item.enquiryPaymentSummary.invoiceClientName"
                            *ngIf="item.enquiryPaymentSummary.invoiceClientId"
                            target="_blank"
                        ></a>

                        <span
                            [innerText]="item.enquiryPaymentSummary.invoiceClientName"
                            *ngIf="!item.enquiryPaymentSummary.invoiceClientId"
                        ></span>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceIssueDate'
                            )
                        "
                    >
                        {{ item.enquiryPaymentSummary.invoiceIssueDate | date : "dd/MM/yyyy" }}

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="text-end"
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceAmountHtTotal'
                            )
                        "
                        [class.text-danger]="item.enquiryPaymentSummary.invoiceAmountHtTotal < 0"
                    >
                        <ng-container *ngIf="item.enquiryPaymentSummary.invoiceId">
                            <div
                                *ngIf="
                                    getDefaultCurrency() ===
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                                [innerText]="
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountHtTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                "
                            ></div>
                            <div
                                *ngIf="
                                    getDefaultCurrency() !==
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                            >
                                {{
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountHtTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                }}
                                <div>
                                    <em
                                        >=
                                        {{
                                            formatPrice(
                                                item.enquiryPaymentSummary
                                                    .invoiceAmountHtTotalInDefaultCurrency,
                                                getDefaultCurrency()
                                            )
                                        }}</em
                                    >
                                </div>
                            </div>
                        </ng-container>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="text-end"
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceAmountTvaTotal'
                            )
                        "
                        [class.text-danger]="item.enquiryPaymentSummary.invoiceAmountTvaTotal < 0"
                    >
                        <ng-container *ngIf="item.enquiryPaymentSummary.invoiceId">
                            <div
                                *ngIf="
                                    getDefaultCurrency() ===
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                                [innerText]="
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountTvaTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                "
                            ></div>
                            <div
                                *ngIf="
                                    getDefaultCurrency() !==
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                            >
                                {{
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountTvaTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                }}
                                <div>
                                    <em
                                        >=
                                        {{
                                            formatPrice(
                                                item.enquiryPaymentSummary
                                                    .invoiceAmountTvaTotalInDefaultCurrency,
                                                getDefaultCurrency()
                                            )
                                        }}</em
                                    >
                                </div>
                            </div>
                        </ng-container>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="text-end"
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceAmountTtcTotal'
                            )
                        "
                        [class.text-danger]="item.enquiryPaymentSummary.invoiceAmountTtcTotal < 0"
                    >
                        <ng-container *ngIf="item.enquiryPaymentSummary.invoiceId">
                            <div
                                *ngIf="
                                    getDefaultCurrency() ===
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                                [innerText]="
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountTtcTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                "
                            ></div>
                            <div
                                *ngIf="
                                    getDefaultCurrency() !==
                                    item.enquiryPaymentSummary.invoiceCurrency
                                "
                            >
                                {{
                                    formatPrice(
                                        item.enquiryPaymentSummary.invoiceAmountTtcTotal,
                                        item.enquiryPaymentSummary.invoiceCurrency
                                    )
                                }}
                                <div>
                                    <em
                                        >=
                                        {{
                                            formatPrice(
                                                item.enquiryPaymentSummary
                                                    .invoiceAmountTtcTotalInDefaultCurrency,
                                                getDefaultCurrency()
                                            )
                                        }}</em
                                    >
                                </div>
                            </div>
                        </ng-container>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        class="text-end last-tr-for-enquiry"
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryMargin'
                                ))
                        "
                        [class.text-danger]="item.enquiryPaymentSummary.enquiryMargin < 0"
                    >
                        <div
                            *ngIf="
                                item.enquiryPaymentSummary.enquiryCurrencies.length === 1 &&
                                item.enquiryPaymentSummary.enquiryCurrencies.includes(
                                    getDefaultCurrency()
                                )
                            "
                        >
                            <strong
                                [innerText]="
                                    formatPrice(
                                        item.enquiryPaymentSummary.enquiryMargin,
                                        getDefaultCurrency()
                                    )
                                "
                            ></strong>
                        </div>
                        <div
                            *ngIf="
                                !(
                                    item.enquiryPaymentSummary.enquiryCurrencies.length === 1 &&
                                    item.enquiryPaymentSummary.enquiryCurrencies.includes(
                                        getDefaultCurrency()
                                    )
                                )
                            "
                        >
                            <div>
                                <strong
                                    [innerText]="
                                        formatPrice(
                                            item.enquiryPaymentSummary.enquiryMargin,
                                            getDefaultCurrency()
                                        )
                                    "
                                ></strong>
                            </div>
                            <em
                                [innerHtml]="
                                    '= ' +
                                    getValueByCurrency(
                                        item.enquiryPaymentSummary.enquiryMarginInCurrency
                                    )
                                "
                            >
                            </em>
                        </div>
                    </td>
                    <td
                        class="text-end last-tr-for-enquiry"
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes('invoiceAmountHtTotal'))
                        "
                        [class.text-danger]="item.invoiceAmountHtTotal.defaultCurrency < 0"
                    >
                        <ng-container *ngIf="item.invoiceAmountHtTotal.values.length">
                            <div
                                *ngIf="
                                    item.invoiceAmountHtTotal.values.length === 1 &&
                                    item.invoiceAmountHtTotal.values[0].currency ===
                                        getDefaultCurrency()
                                "
                            >
                                <strong
                                    [innerText]="
                                        formatPrice(
                                            item.invoiceAmountHtTotal.values[0].value,
                                            item.invoiceAmountHtTotal.values[0].currency
                                        )
                                    "
                                ></strong>
                            </div>
                            <div
                                *ngIf="
                                    !(
                                        item.invoiceAmountHtTotal.values.length === 1 &&
                                        item.invoiceAmountHtTotal.values[0].currency ===
                                            getDefaultCurrency()
                                    )
                                "
                            >
                                <div>
                                    <strong
                                        [innerText]="
                                            formatPrice(
                                                item.invoiceAmountHtTotal.defaultCurrency,
                                                getDefaultCurrency()
                                            )
                                        "
                                    ></strong>
                                </div>
                                <em
                                    [innerHtml]="
                                        '= ' +
                                        getSumInvoiceAmountWithDifferentCurrencies(
                                            item.invoiceAmountHtTotal.values
                                        )
                                    "
                                >
                                </em>
                            </div>
                        </ng-container>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceProductsLabel'
                            )
                        "
                    >
                        <ng-container *ngIf="item.enquiryPaymentSummary.invoiceId">
                            <div
                                class="mb-1"
                                *ngFor="
                                    let label of item.enquiryPaymentSummary.invoiceProductsLabel
                                "
                                [innerText]="label"
                            ></div>
                        </ng-container>

                        <button
                            type="button"
                            class="btn btn-add-tr"
                            *ngIf="
                                hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                (!dataDisplayed[i + 1] ||
                                    item.enquiryPaymentSummary.enquiryId !==
                                        dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                                !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                            "
                            rel="tooltip"
                            [attr.title]="
                                'Ajouter ligne temporaire pour ' +
                                item.enquiryPaymentSummary.enquiryRefContractTitle
                            "
                            (click)="addTemporaryLine(item)"
                        >
                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        class="last-tr-for-enquiry"
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.enquiryRouting'
                                ))
                        "
                    >
                        <div
                            *ngFor="let routing of item.enquiryPaymentSummary.enquiryRouting"
                            [innerText]="routing"
                        ></div>
                    </td>
                    <td
                        class="text-center"
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.invoiceDueDate'
                            )
                        "
                        [innerText]="item.enquiryPaymentSummary.invoiceDueDate | date : 'dd/MM/yy'"
                        [class.text-decoration-line-through]="
                            item.enquiryPaymentSummary.followingInvoiceId
                        "
                    ></td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.encaissementsAmount'
                            )
                        "
                    >
                        <div
                            *ngFor="
                                let encaissementAmount of item.enquiryPaymentSummary
                                    .encaissementsAmount;
                                let i = index
                            "
                            [class.text-danger]="encaissementAmount < 0"
                        >
                            <div
                                *ngIf="
                                    getDefaultCurrency() ===
                                    item.enquiryPaymentSummary.encaissementsCurrency[i]
                                "
                                [innerText]="
                                    formatPrice(
                                        encaissementAmount,
                                        item.enquiryPaymentSummary.encaissementsCurrency[i]
                                    )
                                "
                            ></div>
                            <div
                                *ngIf="
                                    getDefaultCurrency() !==
                                    item.enquiryPaymentSummary.encaissementsCurrency[i]
                                "
                            >
                                {{
                                    formatPrice(
                                        encaissementAmount,
                                        item.enquiryPaymentSummary.encaissementsCurrency[i]
                                    )
                                }}
                                <div>
                                    <em
                                        >=
                                        {{
                                            formatPrice(
                                                item.enquiryPaymentSummary
                                                    .encaissementsAmountInDefaultCurrency[i],
                                                getDefaultCurrency()
                                            )
                                        }}</em
                                    >
                                </div>
                            </div>
                        </div>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.encaissementsTypeName'
                            )
                        "
                    >
                        <a
                            [href]="
                                '/admin/invoices/' + item.enquiryPaymentSummary.referalInvoiceId
                            "
                            [innerHtml]="
                                'Annule la facture <br>' +
                                (invoicesRefById[item.enquiryPaymentSummary.referalInvoiceId] ||
                                    item.enquiryPaymentSummary.referalInvoiceId)
                            "
                            *ngIf="
                                !item.enquiryPaymentSummary.encaissementsTypeName.length &&
                                item.enquiryPaymentSummary.invoiceType ===
                                    EnumInvoiceType.creditNote &&
                                item.enquiryPaymentSummary.referalInvoiceId
                            "
                            target="_blank"
                        ></a>
                        <a
                            [href]="
                                '/admin/invoices/' + item.enquiryPaymentSummary.followingInvoiceId
                            "
                            [innerHtml]="
                                'Annulé par l\'avoir <br>' +
                                (invoicesRefById[item.enquiryPaymentSummary.followingInvoiceId] ||
                                    item.enquiryPaymentSummary.followingInvoiceId)
                            "
                            *ngIf="
                                !item.enquiryPaymentSummary.encaissementsTypeName.length &&
                                item.enquiryPaymentSummary.invoiceType ===
                                    EnumInvoiceType.definitive &&
                                item.enquiryPaymentSummary.followingInvoiceId
                            "
                            target="_blank"
                        ></a>

                        <ng-container
                            *ngIf="item.enquiryPaymentSummary.encaissementsTypeName.length"
                        >
                            <div
                                *ngFor="
                                    let encaissementTypeName of item.enquiryPaymentSummary
                                        .encaissementsTypeName
                                "
                                [innerText]="encaissementTypeName"
                            ></div>
                        </ng-container>
                    </td>
                    <td
                        [class.last-tr-for-enquiry]="
                            (!dataDisplayed[i + 1] ||
                                item.enquiryPaymentSummary.enquiryId !==
                                    dataDisplayed[i + 1].enquiryPaymentSummary.enquiryId) &&
                            !item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            columnsSettings.allColumns ||
                            columnsSettings.columnSelected.includes(
                                'enquiryPaymentSummary.encaissementsDate'
                            )
                        "
                    >
                        <div
                            *ngFor="
                                let encaissementDate of item.enquiryPaymentSummary.encaissementsDate
                            "
                            [innerText]="encaissementDate"
                        ></div>
                    </td>
                    <td
                        [attr.rowspan]="
                            item.rowspan + item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                        "
                        *ngIf="
                            !item.previousIsSameEnquiry &&
                            (columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes('statuses.status'))
                        "
                        class="text-center last-tr-for-enquiry"
                    >
                        <span
                            class="badge"
                            [class.text-bg-success]="
                                status.status === EnumEnquiryPaymentStatus.payed
                            "
                            [class.text-bg-danger]="
                                [
                                    EnumEnquiryPaymentStatus.paymentsMissing,
                                    EnumEnquiryPaymentStatus.overPayments
                                ].includes(status.status)
                            "
                            [class.text-bg-warning]="
                                [EnumEnquiryPaymentStatus.noInvoice].includes(status.status)
                            "
                            *ngFor="let status of item.statuses"
                        >
                            {{
                                [
                                    EnumEnquiryPaymentStatus.noInvoice,
                                    EnumEnquiryPaymentStatus.payed
                                ].includes(status.status)
                                    ? getEnumEnquiryPaymentStatusLabel(status.status)
                                    : getEnumEnquiryPaymentStatusLabel(status.status) +
                                      " - " +
                                      formatPrice(
                                          status.amount,
                                          status.currency === "defaultCurrency"
                                              ? getDefaultCurrency()
                                              : status.currency
                                      )
                            }}
                        </span>
                    </td>
                </tr>

                <ng-container
                    *ngIf="
                        item.lastRowForEnquiry &&
                        item.enquiryPaymentSummaryDetail?.temporaryRows?.length
                    "
                >
                    <tr
                        class="temporary-row"
                        *ngFor="
                            let temporaryRow of item.enquiryPaymentSummaryDetail.temporaryRows;
                            let j = index
                        "
                    >
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceRef'
                                )
                            "
                        >
                            {{ temporaryRow.invoiceRef }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceClientName'
                                )
                            "
                        >
                            {{ temporaryRow.invoiceClientName }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceIssueDate'
                                )
                            "
                        >
                            {{ temporaryRow.invoiceIssueDate }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            class="text-end"
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceAmountHtTotal'
                                )
                            "
                            [class.text-danger]="temporaryRow.invoiceAmountHtTotal < 0"
                        >
                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            {{
                                temporaryRow.invoiceAmountHtTotal !== null
                                    ? formatPrice(
                                          temporaryRow.invoiceAmountHtTotal,
                                          temporaryRow.invoiceCurrency
                                      )
                                    : ""
                            }}

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            class="text-end"
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceAmountTvaTotal'
                                )
                            "
                            [class.text-danger]="temporaryRow.invoiceAmountTvaTotal < 0"
                        >
                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            {{
                                temporaryRow.invoiceAmountTvaTotal !== null
                                    ? formatPrice(
                                          temporaryRow.invoiceAmountTvaTotal,
                                          temporaryRow.invoiceCurrency
                                      )
                                    : ""
                            }}

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            class="text-end"
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceAmountTtcTotal'
                                )
                            "
                            [class.text-danger]="temporaryRow.invoiceAmountTtcTotal < 0"
                        >
                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            {{
                                temporaryRow.invoiceAmountTtcTotal !== null
                                    ? formatPrice(
                                          temporaryRow.invoiceAmountTtcTotal,
                                          temporaryRow.invoiceCurrency
                                      )
                                    : ""
                            }}

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceProductsLabel'
                                )
                            "
                        >
                            {{ temporaryRow.invoiceProductsLabel }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.invoiceDueDate'
                                )
                            "
                        >
                            {{ temporaryRow.invoiceDueDate }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.encaissementsAmount'
                                )
                            "
                            [class.text-danger]="temporaryRow.encaissementsAmount < 0"
                        >
                            {{
                                temporaryRow.encaissementsAmount
                                    ? formatPrice(
                                          temporaryRow.encaissementsAmount,
                                          temporaryRow.invoiceCurrency
                                      )
                                    : ""
                            }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.encaissementsTypeName'
                                )
                            "
                        >
                            {{ encaissementTypesObj[temporaryRow.encaissementsTypeId]?.name }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                        <td
                            [class.last-tr-for-enquiry]="
                                j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                            "
                            *ngIf="
                                columnsSettings.allColumns ||
                                columnsSettings.columnSelected.includes(
                                    'enquiryPaymentSummary.encaissementsDate'
                                )
                            "
                        >
                            {{
                                temporaryRow.encaissementsDate
                                    ? (temporaryRow.encaissementsDate | date : "yyyy-MM-dd")
                                    : ""
                            }}

                            <button
                                type="button"
                                class="btn btn-link btn-sm"
                                (click)="editTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Éditer la ligne temporaire"
                            >
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-link text-danger btn-sm"
                                (click)="deleteTemporaryRow(item, j)"
                                [disabled]="loadingEnquiryPaymentSummaryDetails"
                                *ngIf="hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows)"
                                rel="tooltip"
                                title="Supprimer la ligne temporaire"
                            >
                                <fa-icon [icon]="faTrash"></fa-icon>
                            </button>

                            <button
                                type="button"
                                class="btn btn-add-tr"
                                *ngIf="
                                    hasAclAccess(EnumAcl.enquiryPaymentsSummaryAddTemporaryRows) &&
                                    !item.enquiryPaymentSummary.enquiryIsCompleted &&
                                    j === item.enquiryPaymentSummaryDetail.temporaryRows.length - 1
                                "
                                rel="tooltip"
                                [attr.title]="
                                    'Ajouter ligne temporaire pour ' +
                                    item.enquiryPaymentSummary.enquiryRefContractTitle
                                "
                                (click)="addTemporaryLine(item)"
                            >
                                <fa-icon [icon]="faPlusCircle"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
        <tfoot>
            <tr>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.enquiryRefContractTitle'
                        )
                    "
                    [innerText]="getTotalValue('nbContracts')"
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('enquiryPaymentSummary.comment')
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.enquiryIsCompleted'
                        )
                    "
                    [innerText]="getTotalValue('nbCompleted')"
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.enquiryCommissionFee'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('enquiryPaymentSummary.legDates')
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('enquiryPaymentSummary.enquiryType')
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.userInitials'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('enquiryPaymentSummary.clientName')
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.airlineTitles'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('enquiryPaymentSummary.invoiceRef')
                    "
                    [innerText]="getTotalValue('nbInvoices')"
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceClientName'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceIssueDate'
                        )
                    "
                ></td>
                <td
                    class="text-end"
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceAmountHtTotal'
                        )
                    "
                    [innerHtml]="getTotalValue('invoiceAmountHtTotal')"
                ></td>
                <td
                    class="text-end"
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceAmountTvaTotal'
                        )
                    "
                    [innerHtml]="getTotalValue('invoiceAmountTvaTotal')"
                ></td>
                <td
                    class="text-end"
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceAmountTtcTotal'
                        )
                    "
                    [innerHtml]="getTotalValue('invoiceAmountTtcTotal')"
                ></td>
                <td
                    class="text-end"
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.enquiryMargin'
                        )
                    "
                    [innerHtml]="getTotalValue('enquiryMargin')"
                ></td>
                <td
                    class="text-end"
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('invoiceAmountHtTotal')
                    "
                    [innerHtml]="getTotalValue('realMarginHt')"
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceProductsLabel'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.enquiryRouting'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.invoiceDueDate'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.encaissementsAmount'
                        )
                    "
                    class="text-end"
                    [innerHtml]="getTotalValue('totalPayed')"
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.encaissementsTypeName'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes(
                            'enquiryPaymentSummary.encaissementsDate'
                        )
                    "
                ></td>
                <td
                    *ngIf="
                        columnsSettings.allColumns ||
                        columnsSettings.columnSelected.includes('statuses.status')
                    "
                >
                    <div
                        [innerText]="
                            getEnumEnquiryPaymentStatusLabel(
                                EnumEnquiryPaymentStatus.paymentsMissing
                            )
                        "
                        *ngIf="getLeftToPay().length"
                    ></div>
                    <div *ngFor="let item of getLeftToPay()">
                        <span
                            class="badge"
                            [class.text-bg-success]="item.amount <= 0"
                            [class.text-bg-danger]="item.amount > 0"
                            [innerText]="
                                formatPrice(
                                    item.amount,
                                    item.currency === 'defaultCurrency'
                                        ? getDefaultCurrency()
                                        : item.currency
                                )
                            "
                        >
                        </span>
                    </div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<div
    class="modal fade"
    id="modal-commission"
    tabindex="-1"
    aria-labelledby="modal-commission-label"
    aria-hidden="true"
    #modalCommission
>
    <div class="modal-dialog modal-dialog-centered">
        <form
            (submit)="submitFormCommission()"
            [formGroup]="formCommission"
            (keydown.enter)="submitFormCommission()"
            class="main-form"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="modal-commission-label">
                        Commission pour dossier
                        {{ selectedEnquiryPaymentSummary?.enquiryRefContractTitle }}
                    </h1>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        [disabled]="sendingCommission"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        Courtier : {{ selectedEnquiryPaymentSummary?.userInitials }}
                    </div>

                    <!-- <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="isCommissionFeePercentage"
                            formControlName="isCommissionFeePercentage"
                            (change)="updatedIsCommissionFeePercentage()"
                        />
                        <label class="form-check-label" for="isCommissionFeePercentage"
                            >Commission est un pourcentage ?</label
                        >
                    </div> -->

                    <div class="form-group" *ngIf="formCommission.value.isCommissionFeePercentage">
                        <label for="commissionFee">Commission</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="commissionFee"
                                formControlName="commissionFee"
                                type="number"
                                [class.is-invalid]="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionFee'].touched) &&
                                    formCommission.controls['commissionFee'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span class="input-group-text">%</span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionFee'].touched) &&
                                    formCommission.controls['commissionFee'].errors &&
                                    formCommission.controls['commissionFee'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="!formCommission.value.isCommissionFeePercentage">
                        <label for="commissionFeeAmount">Montant commission</label>
                        <div class="input-group">
                            <input
                                class="form-control"
                                id="commissionFeeAmount"
                                formControlName="commissionFeeAmount"
                                type="number"
                                [class.is-invalid]="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionFeeAmount'].touched) &&
                                    formCommission.controls['commissionFeeAmount'].status ==
                                        'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />
                            <span
                                class="input-group-text"
                                [innerText]="getEnumCurrencySymbol(getDefaultCurrency())"
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionFeeAmount'].touched) &&
                                    formCommission.controls['commissionFeeAmount'].errors &&
                                    formCommission.controls['commissionFeeAmount'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="commissionStatus">Status</label>
                        <select
                            class="form-select"
                            id="commissionStatus"
                            formControlName="commissionStatus"
                            [class.is-invalid]="
                                (formCommission.touched ||
                                    formCommission.controls['commissionStatus'].touched) &&
                                formCommission.controls['commissionStatus'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="null">Choisissez le status</option>
                            <option
                                [value]="status"
                                *ngFor="let status of getEnquiryCommissionStatuses()"
                                [innerHtml]="getEnumEnquiryCommissionStatusLabel(status)"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionStatus'].touched) &&
                                    formCommission.controls['commissionStatus'].errors &&
                                    formCommission.controls['commissionStatus'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="commissionComment">Commentaire</label>
                        <input
                            class="form-control"
                            id="commissionComment"
                            formControlName="commissionComment"
                            type="text"
                            [class.is-invalid]="
                                (formCommission.touched ||
                                    formCommission.controls['commissionComment'].touched) &&
                                formCommission.controls['commissionComment'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formCommission.touched ||
                                        formCommission.controls['commissionComment'].touched) &&
                                    formCommission.controls['commissionComment'].errors &&
                                    formCommission.controls['commissionComment'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        [disabled]="sendingCommission"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary"
                        [innerHtml]="sendingCommission ? 'Sauvegarde ...' : 'Sauvegarder'"
                        [disabled]="
                            formCommission.disabled || !formCommission.valid || sendingCommission
                        "
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>

<div
    class="modal fade"
    [id]="userId ? 'export-my-data-modal' : 'export-modal'"
    tabindex="-1"
    [attr.aria-labelledby]="userId ? 'export-my-data-modal-label' : 'export-modal-label'"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1
                    class="modal-title fs-5"
                    [id]="userId ? 'export-my-data-modal-label' : 'export-modal-label'"
                >
                    Export paiement des dossiers
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group" [class.mb-0]="columnsSettings.allColumns">
                    <label for="allColumns">Colonnes à exporter</label>
                    <select
                        class="form-select"
                        id="allColumns"
                        [(ngModel)]="columnsSettings.allColumns"
                        (change)="updatedExportAllColumns()"
                    >
                        <option [ngValue]="true">Toutes les colonnes</option>
                        <option [ngValue]="false">Seulement certaines colonnes</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="!columnsSettings.allColumns">
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm float-end mb-2"
                        [innerText]="
                            columnsSettings.columnSelected.length === 0
                                ? 'Sélectionner tous'
                                : 'Désélectionner tous'
                        "
                        (click)="toggleAllColumnsToExport()"
                    ></button>
                    <label for="columnSelected">Colonnes à exporter</label>
                    <div class="clearfix"></div>
                    <div class="list-group">
                        <ng-container *ngFor="let column of columns">
                            <button
                                type="button"
                                class="list-group-item list-group-item-action"
                                [class.active]="
                                    columnsSettings.columnSelected.includes(column.field)
                                "
                                (click)="toggleColumnToExport(column.field)"
                                *ngIf="!column.hide"
                            >
                                {{ column.title }}

                                <span
                                    class="badge text-bg-primary float-end"
                                    *ngIf="columnsSettings.columnSelected.includes(column.field)"
                                    >Inclus</span
                                >
                                <span
                                    class="badge text-bg-secondary float-end"
                                    *ngIf="!columnsSettings.columnSelected.includes(column.field)"
                                    >Non-inclus</span
                                >
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="
                        !columnsSettings.allColumns && !columnsSettings.columnSelected.length
                    "
                    (click)="export()"
                >
                    Exporter
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    [id]="userId ? 'columns-my-data-modal' : 'columns-modal'"
    tabindex="-1"
    [attr.aria-labelledby]="userId ? 'columns-my-data-modal-label' : 'columns-modal-label'"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1
                    class="modal-title fs-5"
                    [id]="userId ? 'columns-my-data-modal-label' : 'columns-modal-label'"
                >
                    Colonnes affichées
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <div class="form-group" [class.mb-0]="columnsSettings.allColumns">
                    <label for="allColumns">Colonnes à afficher</label>
                    <select
                        class="form-select"
                        id="allColumns"
                        [(ngModel)]="columnsSettings.allColumns"
                        (change)="updatedExportAllColumns()"
                    >
                        <option [ngValue]="true">Toutes les colonnes</option>
                        <option [ngValue]="false">Seulement certaines colonnes</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="!columnsSettings.allColumns">
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm float-end mb-2"
                        [innerText]="
                            columnsSettings.columnSelected.length === 0
                                ? 'Sélectionner tous'
                                : 'Désélectionner tous'
                        "
                        (click)="toggleAllColumnsToExport()"
                    ></button>
                    <label for="columnSelected">Colonnes à afficher</label>
                    <div class="clearfix"></div>
                    <div class="list-group">
                        <ng-container *ngFor="let column of columns">
                            <button
                                type="button"
                                class="list-group-item list-group-item-action"
                                [class.active]="
                                    columnsSettings.columnSelected.includes(column.field)
                                "
                                (click)="toggleColumnToExport(column.field)"
                                *ngIf="!column.hide && !column.visibleOnlyExport"
                            >
                                {{ column.title }}

                                <span
                                    class="badge text-bg-primary float-end"
                                    *ngIf="columnsSettings.columnSelected.includes(column.field)"
                                    >Inclus</span
                                >
                                <span
                                    class="badge text-bg-secondary float-end"
                                    *ngIf="!columnsSettings.columnSelected.includes(column.field)"
                                    >Non-inclus</span
                                >
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    [id]="userId ? 'set-comment-modal-for-user' : 'set-comment-modal'"
    tabindex="-1"
    [attr.aria-labelledby]="userId ? 'set-comment-for-user-modal-label' : 'set-comment-modal-label'"
    aria-hidden="true"
    #modalDetail
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1
                    class="modal-title fs-5"
                    [id]="userId ? 'set-comment-for-user-modal-label' : 'set-comment-modal-label'"
                >
                    Commentaire
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form (submit)="submitFormComment()" [formGroup]="formDetail" class="main-form">
                    <div class="form-group mb-0">
                        <textarea
                            formControlName="comment"
                            class="form-control"
                            rows="2"
                        ></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="formDetail.disabled || !formDetail.valid"
                    (click)="submitFormComment()"
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    [id]="userId ? 'set-temporary-row-modal-for-user' : 'set-temporary-row-modal'"
    tabindex="-1"
    [attr.aria-labelledby]="
        userId ? 'set-temporary-row-for-user-modal-label' : 'set-temporary-row-modal-label'
    "
    aria-hidden="true"
    #modalTemporaryRow
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1
                    class="modal-title fs-5"
                    [id]="
                        userId
                            ? 'set-temporary-row-for-user-modal-label'
                            : 'set-temporary-row-modal-label'
                    "
                >
                    Édition de la ligne temporaire
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form
                    (submit)="submitFormTemporaryRow()"
                    (keydown.enter)="submitFormTemporaryRow()"
                    [formGroup]="formTemporaryRow"
                    class="main-form"
                >
                    <div class="form-group">
                        <label>N° facture</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="invoiceRef"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceRef'].touched) &&
                                formTemporaryRow.controls['invoiceRef'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceRef'].touched) &&
                                    formTemporaryRow.controls['invoiceRef'].errors &&
                                    formTemporaryRow.controls['invoiceRef'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Entreprise</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="invoiceClientName"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceClientName'].touched) &&
                                formTemporaryRow.controls['invoiceClientName'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceClientName'].touched) &&
                                    formTemporaryRow.controls['invoiceClientName'].errors &&
                                    formTemporaryRow.controls['invoiceClientName'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Dénomination</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="invoiceProductsLabel"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceProductsLabel'].touched) &&
                                formTemporaryRow.controls['invoiceProductsLabel'].status ==
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceProductsLabel']
                                            .touched) &&
                                    formTemporaryRow.controls['invoiceProductsLabel'].errors &&
                                    formTemporaryRow.controls['invoiceProductsLabel'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Date Emission Facture</label>
                        <input
                            type="date"
                            class="form-control"
                            formControlName="invoiceIssueDate"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceIssueDate'].touched) &&
                                formTemporaryRow.controls['invoiceIssueDate'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceIssueDate'].touched) &&
                                    formTemporaryRow.controls['invoiceIssueDate'].errors &&
                                    formTemporaryRow.controls['invoiceIssueDate'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Date d'échéance</label>
                        <input
                            type="date"
                            class="form-control"
                            formControlName="invoiceDueDate"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceDueDate'].touched) &&
                                formTemporaryRow.controls['invoiceDueDate'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceDueDate'].touched) &&
                                    formTemporaryRow.controls['invoiceDueDate'].errors &&
                                    formTemporaryRow.controls['invoiceDueDate'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="invoiceCurrency">Devise</label>
                        <select
                            class="form-select"
                            id="invoiceCurrency"
                            formControlName="invoiceCurrency"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceCurrency'].touched) &&
                                formTemporaryRow.controls['invoiceCurrency'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option
                                [value]="currency"
                                *ngFor="let currency of getCurrencies()"
                                [innerHtml]="
                                    getEnumCurrencySymbol(currency) +
                                    ' (' +
                                    getEnumCurrencyLabel(currency) +
                                    ')'
                                "
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceCurrency'].touched) &&
                                    formTemporaryRow.controls['invoiceCurrency'].errors &&
                                    formTemporaryRow.controls['invoiceCurrency'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Montants HT</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="invoiceAmountHtTotal"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceAmountHtTotal'].touched) &&
                                formTemporaryRow.controls['invoiceAmountHtTotal'].status ==
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceAmountHtTotal']
                                            .touched) &&
                                    formTemporaryRow.controls['invoiceAmountHtTotal'].errors &&
                                    formTemporaryRow.controls['invoiceAmountHtTotal'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>TVA</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="invoiceAmountTvaTotal"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceAmountTvaTotal'].touched) &&
                                formTemporaryRow.controls['invoiceAmountTvaTotal'].status ==
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceAmountTvaTotal']
                                            .touched) &&
                                    formTemporaryRow.controls['invoiceAmountTvaTotal'].errors &&
                                    formTemporaryRow.controls['invoiceAmountTvaTotal'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Montant TTC</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="invoiceAmountTtcTotal"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['invoiceAmountTtcTotal'].touched) &&
                                formTemporaryRow.controls['invoiceAmountTtcTotal'].status ==
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['invoiceAmountTtcTotal']
                                            .touched) &&
                                    formTemporaryRow.controls['invoiceAmountTtcTotal'].errors &&
                                    formTemporaryRow.controls['invoiceAmountTtcTotal'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Paiement</label>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="encaissementsAmount"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['encaissementsAmount'].touched) &&
                                formTemporaryRow.controls['encaissementsAmount'].status ===
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['encaissementsAmount'].touched) &&
                                    formTemporaryRow.controls['encaissementsAmount'].errors &&
                                    formTemporaryRow.controls['encaissementsAmount'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="encaissementsTypeId">MOD</label>
                        <select
                            class="form-select"
                            id="encaissementTypeId"
                            formControlName="encaissementsTypeId"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['encaissementsTypeId'].touched) &&
                                formTemporaryRow.controls['encaissementsTypeId'].status ===
                                    'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="encaissementType.id"
                                *ngFor="let encaissementType of encaissementTypes"
                                [innerHtml]="encaissementType.name"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['encaissementsTypeId'].touched) &&
                                    formTemporaryRow.controls['encaissementsTypeId'].errors &&
                                    formTemporaryRow.controls['encaissementsTypeId'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Date de paiement</label>
                        <input
                            type="date"
                            class="form-control"
                            formControlName="encaissementsDate"
                            [class.is-invalid]="
                                (formTemporaryRow.touched ||
                                    formTemporaryRow.controls['encaissementsDate'].touched) &&
                                formTemporaryRow.controls['encaissementsDate'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formTemporaryRow.touched ||
                                        formTemporaryRow.controls['encaissementsDate'].touched) &&
                                    formTemporaryRow.controls['encaissementsDate'].errors &&
                                    formTemporaryRow.controls['encaissementsDate'].errors[
                                        'required'
                                    ]
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="formTemporaryRow.disabled || !formTemporaryRow.valid"
                    (click)="submitFormTemporaryRow()"
                >
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>
