import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryFlightFirestoreService } from './enquiry-flights-firestore.service';
import { IEnquiryFlight } from 'src/app/interfaces/enquiry-flight.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryFlightService {
  constructor(private enquiryFlightFirestoreService: EnquiryFlightFirestoreService) {}

  create(enquiryFlight: IEnquiryFlight): Promise<string> {
    return this.enquiryFlightFirestoreService.create(enquiryFlight);
  }

  createWithId(id: string, enquiryFlight: IEnquiryFlight): Promise<string> {
    return this.enquiryFlightFirestoreService.createWithId(id, enquiryFlight);
  }

  update(enquiryFlight: IEnquiryFlight): Promise<string> {
    return this.enquiryFlightFirestoreService.update(enquiryFlight);
  }

  delete(id: string): Promise<void> {
    return this.enquiryFlightFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiryFlight> {
    return this.enquiryFlightFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiryFlight[]> {
    return this.enquiryFlightFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IEnquiryFlight[]> {
    return this.enquiryFlightFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId)
    );
  }

  getAllForDates(dates: string[]): Observable<IEnquiryFlight[]> {
    return this.enquiryFlightFirestoreService.collectionValues$(ref =>
      ref.where('departDateLocal', 'in', dates)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryFlightFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEnquiryFlight[]> {
    return this.enquiryFlightFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
