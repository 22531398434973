import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { AlgoliaService, IAlgoliaFilter } from 'src/app/services/algolia.service';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { format, isBefore } from 'date-fns';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumSuggestionInvoiceReason,
  ISuggestedInvoice,
  getSuggestionInvoiceReasonLabel
} from 'src/app/enums/suggested-invoice.enum';
import { IInvoice, getInvoiceTitle } from 'src/app/interfaces/invoice.interface';
import { IEncaissementType } from 'src/app/interfaces/encaissement-type.interface';
import { IBankAccount } from 'src/app/interfaces/bank-account.interface';
import { IEncaissement } from 'src/app/interfaces/encaissement.interface';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs/breadcrumbs.service';
import { EncaissementService } from 'src/app/services/encaissements/encaissements.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Subscription } from 'rxjs';
import { InvoiceService } from 'src/app/services/invoices/invoices.service';
import { faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-encaissement-edit',
  templateUrl: './encaissement-edit.component.html',
  styleUrls: ['./encaissement-edit.component.scss']
})
export class EncaissementEditComponent implements OnInit, OnDestroy {
  EnumCurrency = EnumCurrency;

  getEnumCurrencySymbol = getEnumCurrencySymbol;
  getEnumCurrencyLabel = getEnumCurrencyLabel;
  getSuggestionInvoiceReasonLabel = getSuggestionInvoiceReasonLabel;
  getInvoiceTitle = getInvoiceTitle;

  faTrash = faTrash;
  faExternalLink = faExternalLink;

  isLogged: boolean = false;
  form: FormGroup = this.resetForm();
  encaissement: IEncaissement | null = null;
  encaissementId: string;
  invoiceId: string;
  invoicesObj: { [id: string]: IInvoice | null } = {};

  bankAccountsList: IBankAccount[] = [];
  encaissementTypesList: IEncaissementType[] = [];

  suggestionInvoices: ISuggestedInvoice[] = [];

  private subscriptions = new Subscription();

  constructor(
    private loaderService: LoaderService,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private algoliaService: AlgoliaService,
    private breadcrumbsService: BreadcrumbsService,
    private encaissementService: EncaissementService,
    private invoiceService: InvoiceService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.encaissementId = this.activatedRoute.snapshot.paramMap.get('encaissementId');
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId');

      if (this.encaissementId) {
        await this.aclService.checkAclAccess(EnumAcl.encaissementsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.encaissementsAdd);

        if (this.invoiceId) {
          this.form.get('invoiceIds').setValue([this.invoiceId]);
          this.loadInvoice(this.invoiceId);
        }

        this.form.enable();
      }
    });

    this.loadBankAccounts();
    this.loadEncaissementTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadEncaissement();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async loadEncaissement(): Promise<void> {
    const doc = await this.remoteService.getDocument('encaissements', this.encaissementId);

    this.encaissement = doc as IEncaissement;

    this.setEncaissement();
  }

  resetForm(): FormGroup {
    return new FormGroup({
      libelle: new FormControl('', [Validators.required]),
      encaissementTypeId: new FormControl('', [Validators.required]),
      isCredit: new FormControl(false),
      isDebit: new FormControl(false),
      amount: new FormControl(0, [Validators.required]),
      currency: new FormControl(EnumCurrency.EUR, [Validators.required]),
      bankAccountId: new FormControl('', [Validators.required]),
      invoiceIds: new FormControl([]),
      date: new FormControl('', [Validators.required]),
      isReconciled: new FormControl(false, [Validators.required]),
      comment: new FormControl('')
    });
  }

  setEncaissement(): void {
    this.form = this.resetForm();

    if (this.form && this.encaissement) {
      for (const field in this.form.value) {
        if (typeof this.encaissement[field] !== 'undefined') {
          switch (field) {
            default:
              this.form.get(field).setValue(this.encaissement[field]);
              break;
          }
        }
      }

      if (this.form.value.invoiceIds.length) {
        for (const invoiceId of this.form.value.invoiceIds) {
          this.loadInvoice(invoiceId);
        }
      }

      this.breadcrumbsService.setCurrentItem({
        text: this.encaissement.libelle,
        id: this.encaissement.id
      });

      this.getSuggestionInvoices();

      this.form.enable();
    }
  }

  setEncaissementAccordingToInvoice(): void {
    if (this.form && this.invoiceId && this.invoicesObj[this.invoiceId]) {
      this.form.get('amount').setValue(this.invoicesObj[this.invoiceId].amountSoldeLeft);
      this.form.get('currency').setValue(this.invoicesObj[this.invoiceId].currency);
      this.form.get('invoiceIds').setValue([this.invoicesObj[this.invoiceId].id]);
      this.form.get('date').setValue(format(new Date(), 'yyyy-MM-dd'));

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      data.isDebit = data.amount >= 0;
      data.isCredit = data.amount < 0;

      this.form.disable();

      let promise;
      if (this.encaissementId) {
        data.id = this.encaissementId;
        promise = () => this.encaissementService.update(data);
      } else {
        promise = () => this.encaissementService.create(data);
      }

      promise()
        .then(async id => {
          await this.loaderService.hideLoaderOnSuccess();

          if (!this.encaissementId) {
            this.encaissementId = id;
          }

          this.redirectAfterSaving(data);
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(data: any): void {
    this.router.navigate(['/admin/encaissements/' + this.encaissementId]);
  }

  async loadBankAccounts(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('bankAccounts', {
      field: 'name',
      direction: 'asc'
    });

    this.bankAccountsList = [];
    for (const doc of docs) {
      this.bankAccountsList.push(doc as IBankAccount);
    }
  }

  async loadEncaissementTypes(): Promise<void> {
    const docs = await this.remoteService.getAllDocuments('encaissementTypes', {
      field: 'name',
      direction: 'asc'
    });

    this.encaissementTypesList = [];

    for (const doc of docs) {
      const encaissementType: IEncaissementType = doc as IEncaissementType;
      this.encaissementTypesList.push(encaissementType);

      if (
        !this.form.value.encaissementTypeId &&
        encaissementType.name.toLocaleLowerCase().match('virement')
      ) {
        this.form.get('encaissementTypeId').setValue(encaissementType.id);
      }
    }
  }

  async setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): Promise<void> {
    for (let field of $event.fields) {
      const nameList = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }

      if (field.name === 'invoiceIds') {
        if (field.value) {
          this.addInvoiceId(field.value);
        }
      } else {
        formControl.setValue(field.value);

        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }
    }
  }

  async loadInvoice(invoiceId: string): Promise<void> {
    if (typeof this.invoicesObj[invoiceId] === 'undefined') {
      this.invoicesObj[invoiceId] = null;

      this.subscriptions.add(
        this.invoiceService.getFromId(invoiceId).subscribe((invoice: IInvoice) => {
          this.invoicesObj[invoiceId] = invoice;

          this.setEncaissementAccordingToInvoice();
        })
      );
    }
  }

  selectSuggestedInvoice(suggestionInvoice: ISuggestedInvoice): void {
    const invoiceIds: string[] = [...this.form.value.invoiceIds];

    if (!invoiceIds.includes(suggestionInvoice.invoice.id)) {
      invoiceIds.push(suggestionInvoice.invoice.id);
    }

    this.form.get('invoiceIds').setValue(invoiceIds);
    this.form.get('invoiceIds').updateValueAndValidity();

    this.suggestionInvoices = [];
  }

  async getSuggestionInvoices(): Promise<void> {
    this.suggestionInvoices = [];

    if (!this.form.value.invoiceIds.length) {
      const invoiceRefs: string[] = this.extractInvoiceRefFromText(this.form.value.libelle);
      if (invoiceRefs) {
        for (const invoiceRef of invoiceRefs) {
          const invoicesByRef: IInvoice[] = await this.searchInvoiceInAlgoliaByRef(invoiceRef);

          this.appendSuggestedInvoicesToList(
            invoicesByRef,
            EnumSuggestionInvoiceReason.matchingInvoiceRef
          );
        }
      }

      const invoicesByAmount: IInvoice[] = await this.searchInvoiceSameAmount(
        this.form.value.amount,
        this.form.value.currency
      );

      this.appendSuggestedInvoicesToList(
        invoicesByAmount,
        EnumSuggestionInvoiceReason.sameAmountTtcTotal
      );

      const enquiryRefs: string[] = this.extractEnquiryRefFromText(this.form.value.libelle);

      if (enquiryRefs) {
        for (const enquiryRef of enquiryRefs) {
          const enquiries: IEnquiry[] = await this.searchEnquiryByRef(enquiryRef);

          if (enquiries.length) {
            for (const enquiry of enquiries) {
              const invoicesByEnquiry: IInvoice[] = await this.searchInvoiceByEnquiryId(enquiry.id);

              this.appendSuggestedInvoicesToList(
                invoicesByEnquiry,
                EnumSuggestionInvoiceReason.enquiryMatch
              );
            }
          }
        }
      }
    }

    this.sortSuggestionInvoicesByTotalReasons();
  }

  appendSuggestedInvoicesToList(invoices: IInvoice[], reason: EnumSuggestionInvoiceReason): void {
    for (const invoice of invoices) {
      this.invoicesObj[invoice.id] = invoice;

      if (
        this.form.get('date').value &&
        invoice.issueDate &&
        !isBefore(new Date(this.form.get('date').value), invoice.issueDate)
      ) {
        let existingIndex = -1;
        for (let i = 0; i < this.suggestionInvoices.length; i++) {
          if (this.suggestionInvoices[i].invoice.id === invoice.id) {
            existingIndex = i;
          }
        }

        if (existingIndex === -1) {
          this.suggestionInvoices.push({
            reasons: [reason],
            invoice: invoice
          });
        } else {
          if (this.suggestionInvoices[existingIndex].reasons.indexOf(reason) === -1) {
            this.suggestionInvoices[existingIndex].reasons.push(reason);
          }
        }
      }
    }
  }

  sortSuggestionInvoicesByTotalReasons(): void {
    this.suggestionInvoices = this.suggestionInvoices.sort((a, b) => {
      if (
        a.reasons.length > b.reasons.length ||
        a.reasons.indexOf(EnumSuggestionInvoiceReason.matchingInvoiceRef) !== -1
      ) {
        return -1;
      } else if (a.reasons.length < b.reasons.length) {
        return 1;
      }
    });
  }

  extractInvoiceRefFromText(text: string): string[] {
    return text.match(new RegExp(/([FA]-\d{6}-\d+)/g));
  }

  extractEnquiryRefFromText(text: string): string[] {
    return text.match(new RegExp(/([A-Z]{3}\d{2}\.\d+)/g));
  }

  async searchInvoiceInAlgoliaByRef(invoiceRef: string): Promise<IInvoice[]> {
    if (invoiceRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          invoiceRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchEnquiryByRef(enquiryRef: string): Promise<IEnquiry[]> {
    if (enquiryRef.length) {
      try {
        const enquiries: IEnquiry[] = await this.remoteService.searchEnquiryByRef(enquiryRef);

        return enquiries;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceInAlgoliaByEnquiryRef(enquiryRef: string): Promise<IInvoice[]> {
    if (enquiryRef.length) {
      try {
        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          enquiryRef,
          ['ref'],
          []
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceByEnquiryId(enquiryId: string): Promise<IInvoice[]> {
    if (enquiryId !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'enquiryId',
          type: 'string',
          operator: '=',
          value: enquiryId
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'enquiryId'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  async searchInvoiceSameAmount(amount: number, currency: string): Promise<IInvoice[]> {
    if (amount !== null && currency !== null) {
      try {
        const filters: IAlgoliaFilter[] = [];

        filters.push({
          field: 'amountTtcTotal',
          type: 'number',
          operator: '=',
          value: amount
        });
        filters.push({
          field: 'currency',
          type: 'string',
          operator: '=',
          value: currency
        });

        const invoices: IInvoice[] = await this.algoliaService.searchInvoices(
          '',
          ['ref', 'amountTtcTotal', 'currency'],
          filters
        );

        return invoices;
      } catch (err) {
        console.log(err);

        return [];
      }
    } else {
      return [];
    }
  }

  formatPrice(value: number, currency: string = 'EUR'): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  removeInvoiceId(invoiceId: string): void {
    const invoiceIds: string[] = [...this.form.value.invoiceIds];

    const index: number = invoiceIds.indexOf(invoiceId);
    if (index !== -1) {
      invoiceIds.splice(index, 1);
    }

    this.form.get('invoiceIds').setValue(invoiceIds);
    this.form.get('invoiceIds').updateValueAndValidity();

    this.getSuggestionInvoices();
  }

  addInvoiceId(invoiceId: string): void {
    const invoiceIds: string[] = [...this.form.value.invoiceIds];

    if (!invoiceIds.includes(invoiceId)) {
      invoiceIds.push(invoiceId);

      this.loadInvoice(invoiceId);
    }

    this.form.get('invoiceIds').setValue(invoiceIds);
    this.form.get('invoiceIds').updateValueAndValidity();

    this.getSuggestionInvoices();
  }
}
