import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import {
  EnumCurrency,
  getEnumCurrencyLabel,
  getEnumCurrencySymbol
} from 'src/app/enums/currency.enum';
import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { Subscription } from 'rxjs';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { EnumLanguage, getLanguageLabel } from 'src/app/enums/language.enum';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';

@Component({
  selector: 'app-flight-brief-catering-edit',
  templateUrl: './flight-brief-catering-edit.component.html',
  styleUrls: ['./flight-brief-catering-edit.component.scss']
})
export class FlightBriefCateringEditComponent implements OnInit, OnDestroy {
  getLanguageLabel = getLanguageLabel;

  isLogged: boolean = false;
  form: FormGroup = this.formBuilder.group({
    language: [EnumLanguage.fr, [Validators.required]],
    text: ['', [Validators.required]],
    position: [1, [Validators.required]]
  });
  sending: boolean = false;
  flightBriefCatering: IFlightBriefCatering;
  flightBriefCateringId: string;

  private subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private flightBriefCateringService: FlightBriefCateringService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.flightBriefCateringId =
        this.activatedRoute.snapshot.paramMap.get('flightBriefCateringId');

      if (this.flightBriefCateringId) {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsAdd);
        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getCurrencies(): EnumCurrency[] {
    return Object.values(EnumCurrency);
  }

  getLanguages(): EnumLanguage[] {
    return Object.values(EnumLanguage);
  }

  loadData(): void {
    if (this.isLogged) {
      this.loadFlightBriefCatering();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadFlightBriefCatering(): void {
    this.subscriptions.add(
      this.flightBriefCateringService
        .getFromId(this.flightBriefCateringId)
        .subscribe((flightBriefCatering: IFlightBriefCatering) => {
          this.flightBriefCatering = flightBriefCatering;

          this.setValueToForm();
        })
    );
  }

  setValueToForm(): void {
    if (this.form && this.flightBriefCatering) {
      for (const field in this.form.value) {
        if (typeof this.flightBriefCatering[field] !== 'undefined') {
          this.form.get(field).setValue(this.flightBriefCatering[field]);
        }
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = false;
      this.form.enable();

      data = this.fixUndefinedValues(data);

      let promise;
      if (this.flightBriefCateringId) {
        data.id = this.flightBriefCateringId;
        promise = () => this.flightBriefCateringService.update(data);
      } else {
        promise = () => this.flightBriefCateringService.create(data);
      }
      promise()
        .then(async id => {
          if (!this.flightBriefCateringId) {
            this.flightBriefCateringId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  fixUndefinedValues(data: IFlightBriefCatering): IFlightBriefCatering {
    for (const field in data) {
      if (Array.isArray(data[field]) || typeof data[field] === 'object') {
        data[field] = this.fixUndefinedValues(data[field]);
      } else if (typeof data[field] === 'undefined') {
        data[field] = null;
      }
    }

    return data;
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/flight-brief-caterings/' + this.flightBriefCateringId]);
  }
}
