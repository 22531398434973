import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FinancialReportStaffsFirestoreService } from './financial-report-staffs-firestore.service';
import { EnumCurrency } from 'src/app/enums/currency.enum';
import { IFinancialReportStaff } from 'src/app/interfaces/financial-report-staff.interface';
import { map } from 'rxjs/operators';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportStaffsService {
  constructor(
    private financialReportStaffsFirestoreService: FinancialReportStaffsFirestoreService
  ) {}

  create(financialReportStaff: IFinancialReportStaff): Promise<string> {
    return this.financialReportStaffsFirestoreService.create(financialReportStaff);
  }

  createWithId(id: string, financialReportStaff: IFinancialReportStaff): Promise<string> {
    return this.financialReportStaffsFirestoreService.createWithId(id, financialReportStaff);
  }

  update(financialReportStaff: IFinancialReportStaff): Promise<string> {
    return this.financialReportStaffsFirestoreService.update(financialReportStaff);
  }

  delete(id: string): Promise<void> {
    return this.financialReportStaffsFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IFinancialReportStaff> {
    return this.financialReportStaffsFirestoreService.docValue$(id);
  }

  getAll(): Observable<IFinancialReportStaff[]> {
    return this.financialReportStaffsFirestoreService.collectionValues$();
  }

  getOneForYear(year: number): Observable<IFinancialReportStaff | null> {
    return this.financialReportStaffsFirestoreService
      .collectionValues$(ref => ref.where('year', '==', year))
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  resetPagination(paginationName: string = 'list'): void {
    this.financialReportStaffsFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IFinancialReportStaff[]> {
    return this.financialReportStaffsFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
