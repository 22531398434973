import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { QuotationFirestoreService } from './quotations-firestore.service';
import { IQuotation } from 'src/app/interfaces/quotation.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  constructor(private quotationFirestoreService: QuotationFirestoreService) {}

  create(quotation: IQuotation): Promise<string> {
    return this.quotationFirestoreService.create(quotation);
  }

  update(quotation: IQuotation): Promise<string> {
    return this.quotationFirestoreService.update(quotation);
  }

  delete(id: string): Promise<void> {
    return this.quotationFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IQuotation> {
    return this.quotationFirestoreService.docValue$(id);
  }

  getAll(): Observable<IQuotation[]> {
    return this.quotationFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IQuotation[]> {
    return this.quotationFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId).orderBy('created', 'desc')
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.quotationFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IQuotation[]> {
    return this.quotationFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
