import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryCotationFirestoreService } from './enquiry-cotation-firestore.service';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnumEnquiryCotationStatus } from 'src/app/enums/enquiry-cotation-status.enum';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryCotationService {
  constructor(private enquiryCotationFirestoreService: EnquiryCotationFirestoreService) {}

  create(enquiryCotation: IEnquiryCotation): Promise<string> {
    return this.enquiryCotationFirestoreService.create(enquiryCotation);
  }

  createWithId(id: string, enquiryCotation: IEnquiryCotation): Promise<string> {
    return this.enquiryCotationFirestoreService.createWithId(id, enquiryCotation);
  }

  update(enquiryCotation: IEnquiryCotation): Promise<string> {
    return this.enquiryCotationFirestoreService.update(enquiryCotation);
  }

  delete(id: string): Promise<void> {
    return this.enquiryCotationFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiryCotation> {
    return this.enquiryCotationFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$();
  }

  getAllConfirmed(): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$(ref =>
      ref.where('status', '==', EnumEnquiryCotationStatus.confirmed)
    );
  }

  getAllConfirmedForYearAndMonthly(): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$(ref =>
      ref.where('status', '==', EnumEnquiryCotationStatus.confirmed)
    );
  }

  getAllForEnquiry(enquiryId: string): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId)
    );
  }

  getAllConfirmedForEnquiries(enquiryIds: string[]): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$(ref =>
      ref
        .where('enquiryId', 'in', enquiryIds)
        .where('status', '==', EnumEnquiryCotationStatus.confirmed)
    );
  }

  getAllConfirmedForEnquiry(enquiryId: string): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.collectionValues$(ref =>
      ref
        .where('enquiryId', '==', enquiryId)
        .where('status', '==', EnumEnquiryCotationStatus.confirmed)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryCotationFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEnquiryCotation[]> {
    return this.enquiryCotationFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
