<div class="card">
    <div class="card-body">
        <ul *ngIf="loading" class="list-unstyled">
            <li *ngFor="let number of [].constructor(4)"><div class="placeholder w-100"></div></li>
        </ul>
        <div *ngIf="!loading">
            <ul class="list-unstyled">
                <li><strong>Nom prénom</strong> : {{ user.firstname + " " + user.lastname }}</li>
                <li>
                    <strong>Email</strong> :
                    <a
                        [href]="'mailto:' + user.email"
                        target="_blank"
                        [innerHtml]="user.email"
                        *ngIf="user.email"
                    ></a>
                </li>
                <li>
                    <strong>Signature d'email</strong> : <br />
                    <div [innerHtml]="user.emailSignatureHtml | safeHtml"></div>
                </li>
                <li>
                    <strong>Email en CC dans les envois d'email</strong> :
                    {{ user.emailInCc ? "Oui" : "Non" }}
                </li>
                <li><strong>Créé le :</strong> {{ user.created | date : "dd/MM/yyyy" }}</li>
            </ul>
            <div class="mb-4 text-center">
                <a routerLink="/admin/my-account/edit" class="btn btn-primary">Modifier</a>
                <a routerLink="/admin/my-account/edit-password" class="btn btn-primary"
                    >Modifier mot de passe</a
                >
            </div>
            <div class="text-center">
                <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="signingOut"
                    (click)="signOut()"
                >
                    Se déconnecter
                </button>
            </div>
        </div>
    </div>
</div>
