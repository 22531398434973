import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import countries from '../../../countries_fr.json';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { Subscription } from 'rxjs';
import { AircraftService } from 'src/app/services/aircrafts/aircrafts.service';
import {
  EnumAircraftHasHotCatering,
  EnumAircraftHasStewardess,
  EnumAircraftWifi,
  getAircraftHasHotCateringLabel,
  getAircraftHasStewardessLabel,
  getAircraftWifiLabel,
  IAircraft,
  IAircraftHistory
} from 'src/app/interfaces/aircraft.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { IAirline } from 'src/app/interfaces/airline.interface';
import { AirlineService } from 'src/app/services/airlines/airlines.service';
import { getCountryLabel, getContinentLabel } from 'src/app/enums/continent-code.enum';
import { finalize } from 'rxjs/operators';
import { EnumAircraftStatus, getAircraftStatusLabel } from 'src/app/enums/aircraft-status.enum';
import {
  faClockRotateLeft,
  faCogs,
  faImage,
  faListCheck,
  faPlus,
  faPlusCircle,
  faTrash,
  faUsersLine
} from '@fortawesome/free-solid-svg-icons';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { generateRandomId } from 'src/app/misc.utils';

@Component({
  selector: 'app-aircraft-edit',
  templateUrl: './aircraft-edit.component.html',
  styleUrls: ['./aircraft-edit.component.scss']
})
export class AircraftEditComponent implements OnInit {
  getAircraftHasStewardessLabel = getAircraftHasStewardessLabel;
  getAircraftHasHotCateringLabel = getAircraftHasHotCateringLabel;
  getAircraftWifiLabel = getAircraftWifiLabel;
  getAircraftStatusLabel = getAircraftStatusLabel;

  faTrash = faTrash;
  faCogs = faCogs;
  faClockRotateLeft = faClockRotateLeft;
  faImage = faImage;
  faPlusCircle = faPlusCircle;
  faUsersLine = faUsersLine;
  faListCheck = faListCheck;

  isLogged: boolean = false;
  form: FormGroup;
  aircraft: IAircraft | null = null;
  aircraftId: string;
  airlineId: string;
  airline: IAirline | null = null;
  countriesList: {
    title: string;
    value: string;
  }[] = [];

  aircraftModels: IAircraftModel[] = [];
  @ViewChild('modalAircraftModelAdd', { static: false }) modalAircraftModelAddElement: ElementRef;
  triggerSaveAicraftModal: boolean = false;

  aircraftModel: IAircraftModel | null = null;

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private aircraftService: AircraftService,
    private airlineService: AirlineService,
    private aircraftModelService: AircraftModelService,
    private loaderService: LoaderService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe(
      (aircraftModels: IAircraftModel[]) => (this.aircraftModels = aircraftModels)
    );

    for (const code in countries) {
      this.countriesList.push({
        title: countries[code],
        value: code
      });
    }
  }

  getAircraftHasStewardessOptions(): EnumAircraftHasStewardess[] {
    return Object.values(EnumAircraftHasStewardess);
  }

  getAircraftHasHotCateringOptions(): EnumAircraftHasHotCatering[] {
    return Object.values(EnumAircraftHasHotCatering);
  }

  getAircraftWifiOptions(): EnumAircraftWifi[] {
    return Object.values(EnumAircraftWifi);
  }

  get imageOutsideUrl(): FormControl {
    return this.form.get('imageOutsideUrl') as FormControl;
  }

  get imageInsideUrl(): FormControl {
    return this.form.get('imageInsideUrl') as FormControl;
  }

  get imageUrls(): FormArray {
    return this.form.get('imageUrls') as FormArray;
  }

  getImageUrl(i: number): FormControl {
    return this.imageUrls.at(i) as FormControl;
  }

  get history(): FormArray {
    return this.form.get('history') as FormArray;
  }

  getOneHistory(i: number): FormGroup {
    return this.history.at(i) as FormGroup;
  }

  getOneHistoryField(i: number, field: string): FormControl {
    return this.getOneHistory(i).get(field) as FormControl;
  }

  getAircraftStatuses(): EnumAircraftStatus[] {
    return Object.values(EnumAircraftStatus);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      serialNumber: [''],
      status: ['', [Validators.required]],
      model: [''],
      aircraftModelId: ['', [Validators.required]],
      type: [''],
      registration: [''],
      airlineContinentCode: [''],
      airlineCountryCode: [''],
      airlineContinentTitle: [''],
      airlineCountryTitle: [''],
      airlineId: [''],
      airlineTitle: [''],
      firstFlightDate: [null],
      testRegistration: [''],
      seatTotal: [''],
      seatFirst: [''],
      seatBusiness: [''],
      seatPremiumEconomy: [''],
      seatEconomy: [''],
      engines: [''],
      url: [''],
      weight: [''],
      volume: [''],
      isCargo: [false],
      history: new FormArray([]),
      homebase: [''],
      imageOutsideUrl: [''],
      imageInsideUrl: [''],
      dateOfManufacture: [null],
      dateOfRefurbishment: [null],
      numberOfCrewMembers: [null],
      hasStewardess: [null],
      isSmokingAllowed: [null],
      sleepingConfiguration: [null],
      hasToilet: [null],
      hasHotCatering: [null],
      arePetsAllowed: [null],
      wifi: [null],
      hasSatellitePhone: [null],
      imageUrls: new FormArray([])
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.aircraftId = this.activatedRoute.snapshot.paramMap.get('aircraftId');
      this.airlineId = this.activatedRoute.snapshot.paramMap.get('airlineId');

      if (!this.aircraftId) {
        await this.aclService.checkAclAccess(EnumAcl.aircraftsAdd);
        this.form.enable();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.aircraftsEdit);
      }

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.removeModal();
  }

  removeModal(): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      await this.remoteService.loadAircraftModels();

      if (this.aircraftId) {
        this.loadAircraft();
      } else if (this.airlineId) {
        this.loadAirline(this.airlineId);
      }
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadAirline(airlineId: string): void {
    this.subscriptions.add(
      this.airlineService.getFromId(airlineId).subscribe((airline: IAirline) => {
        this.airline = airline;

        this.form.get('airlineId').setValue(this.airline?.id ?? null);
        this.form.get('airlineTitle').setValue(this.airline?.title ?? null);
        this.form.get('airlineCountryCode').setValue(this.airline?.countryCode ?? null);
        this.form.get('airlineContinentCode').setValue(this.airline?.continentCode ?? null);
        this.form
          .get('airlineCountryTitle')
          .setValue(getContinentLabel(this.airline?.continentCode));
        this.form.get('airlineContinentTitle').setValue(getCountryLabel(this.airline?.countryCode));
      })
    );
  }

  loadAircraft(): void {
    this.subscriptions.add(
      this.aircraftService.getFromId(this.aircraftId).subscribe((aircraft: IAircraft) => {
        this.aircraft = aircraft;

        this.setValues();

        if (this.aircraft.aircraftModelId) {
          this.loadAircraftModel(this.aircraft.aircraftModelId);
        }
      })
    );
  }

  loadAircraftModel(aircraftModelId: string): void {
    this.subscriptions.add(
      this.aircraftModelService
        .getFromId(aircraftModelId)
        .subscribe((aircraftModel: IAircraftModel) => {
          this.aircraftModel = aircraftModel;
        })
    );
  }

  setValues(): void {
    if (this.form && this.aircraft) {
      for (const field in this.form.value) {
        if (typeof this.aircraft[field] !== 'undefined') {
          switch (field) {
            case 'history':
              this.history.clear();

              for (const history of this.aircraft.history) {
                this.addHistoryAndSet(history);
              }
              break;
            case 'imageUrls':
              this.imageUrls.clear();

              for (const imageUrl of this.aircraft.imageUrls) {
                this.addImageUrlAndSet(imageUrl);
              }
              break;
            default:
              this.form.get(field).setValue(this.aircraft[field]);
              break;
          }
        }
      }

      this.form.enable();
    }
  }

  updateModel(): void {
    for (const aircraftModel of this.aircraftModels) {
      if (aircraftModel.id === this.form.value.aircraftModelId) {
        this.form.get('model').setValue(aircraftModel.family);
        this.form.get('type').setValue(aircraftModel.slug);
        break;
      }
    }
  }

  addHistory(): void {
    this.history.push(
      new FormGroup({
        registration: new FormControl('', [Validators.required]),
        deliveryDate: new FormControl('', [Validators.required]),
        airlineId: new FormControl(''),
        airlineTitle: new FormControl(''),
        airlineContinentCode: new FormControl(''),
        airlineCountryCode: new FormControl(''),
        airlineContinentTitle: new FormControl(''),
        airlineCountryTitle: new FormControl(''),
        remark: new FormControl('')
      })
    );

    this.form.updateValueAndValidity();
  }

  addHistoryAndSet(history: IAircraftHistory): void {
    this.addHistory();

    const i: number = this.history.length - 1;

    for (const field in this.getOneHistory(i).value) {
      if (typeof history[field] !== 'undefined') {
        this.getOneHistoryField(i, field).setValue(history[field]);
        this.getOneHistoryField(i, field).updateValueAndValidity();
      }
    }
  }

  removeHistory(i: number): void {
    this.history.removeAt(i);
  }

  async submitForm(): Promise<void> {
    this.form.markAsTouched();

    if (this.form.valid) {
      this.loaderService.presentLoader();

      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      data['classes'] = [];

      data['seatTotal'] = 0;
      for (const field of ['seatFirst', 'seatBusiness', 'seatPremiumEconomy', 'seatEconomy']) {
        if (data[field]) {
          data['seatTotal'] += data[field];

          switch (field) {
            case 'seatFirst':
              data['classes'].push('F');
              break;
            case 'seatBusiness':
              data['classes'].push('C');
              break;
            case 'seatPremiumEconomy':
              data['classes'].push('W');
              break;
            case 'seatEconomy':
              data['classes'].push('Y');
              break;
          }
        }
      }

      this.form.disable();

      let promise;
      if (this.aircraftId) {
        data.id = this.aircraftId;
        promise = () => this.aircraftService.update(data);
      } else {
        promise = () => this.aircraftService.create(data);
      }

      promise()
        .then(async id => {
          await this.loaderService.hideLoaderOnSuccess();

          if (!this.aircraftId) {
            this.aircraftId = id;
          }

          this.form.reset();
          this.form.enable();

          this.redirectAfterSaving();
        })
        .catch(async (err: any) => {
          await this.loaderService.hideLoaderOnFailure(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      const nameList: Array<string> = field.name.split('.');

      let formControl: AbstractControl = this.form;
      for (let name of nameList) {
        formControl = formControl.get(name);
      }
      formControl.setValue(field.value);

      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    }
  }

  addContactCategory(): void {
    const companyContactsFormArray = this.form.get('companyContacts') as FormArray;

    companyContactsFormArray.push(
      new FormGroup({
        title: new FormControl('', [Validators.required]),
        contacts: new FormArray([])
      })
    );

    this.form.updateValueAndValidity();
  }

  deleteOneHistory(i: number): void {
    const result = confirm(
      'La suppression de cet historique sera permanente. Êtes-vous sûr de vouloir continuer?'
    );

    if (result) {
      const historysFormArray = this.form.get('history') as FormArray;

      historysFormArray.removeAt(i);

      this.form.updateValueAndValidity();
    }
  }

  updateWeightAndVolume(): void {
    if (!this.form.value.isCargo) {
      // Cargo removed, we reset its values
      for (const fieldCargo of ['weight', 'volume']) {
        this.form.get(fieldCargo).setValue(null);
      }
    }
  }

  addAircraftModel(): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('show');
  }

  saveAircraftModel(): void {
    this.triggerSaveAicraftModal = !this.triggerSaveAicraftModal;
  }

  getCreatedAircraftModel(aircraftModelId: string): void {
    window['$'](this.modalAircraftModelAddElement.nativeElement).modal('hide');

    if (aircraftModelId) {
      this.form.get('aircraftModelId').setValue(aircraftModelId);
    }
  }

  triggerInputFile(): void {
    if (this.form.enabled) {
      document.getElementById('imageUrlInput').click();
    }
  }

  changeInputFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.upload(fileInput.target.files[0]);
    }
  }

  upload(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loaderService.presentLoader();

      this.form.disable();

      const result = this.remoteService.upload(
        'aircrafts/images',
        generateRandomId(),
        file,
        'file'
      );

      result['task']
        .snapshotChanges()
        .pipe(
          finalize(() => {
            result['ref'].getDownloadURL().subscribe((downloadUrl: string) => {
              this.addImageUrlAndSet(downloadUrl);

              (<HTMLInputElement>document.getElementById('imageUrlInput')).value = '';

              this.form.enable();

              this.form.updateValueAndValidity();

              this.loaderService.hideLoaderOnSuccess();
            });
          })
        )
        .subscribe();
    });
  }

  deleteImage(fileFieldName: string): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      this.form.get(fileFieldName).setValue(null);
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/aircrafts/' + this.aircraftId]);
  }

  addImageUrl(): void {
    this.imageUrls.push(new FormControl(null, Validators.required));
  }

  addImageUrlAndSet(imageUrl: string): void {
    this.addImageUrl();

    const i: number = this.imageUrls.length - 1;

    this.getImageUrl(i).setValue(imageUrl);
    this.getImageUrl(i).updateValueAndValidity();
  }

  removeImageUrl(i: number): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      for (const field of ['imageInsideUrl', 'imageOutsideUrl']) {
        if (this.form.get(field).value === this.getImageUrl(i).value) {
          this.form.get(field).setValue(null);
          this.form.get(field).updateValueAndValidity();
        }
      }

      this.imageUrls.removeAt(i);
    }
  }

  setImageDefaultUrl(i: number, field: 'imageInsideUrl' | 'imageOutsideUrl'): void {
    this.form.get(field).setValue(this.getImageUrl(i).value);
  }
}
