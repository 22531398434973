import { capitalize } from '../misc.utils';
import { IStoreData } from './store-data.interface';

export interface IPipedriveOrganization extends IStoreData {
  name: string;
  address: string;

  add_time: string;
  update_time: string;
}

export interface IPipedrivePerson extends IStoreData {
  first_name: string;
  last_name: string;
  name: string;
  email: any[];
  phone: any[];

  add_time: string;
  update_time: string;
}

export const getPipedrivePersonPrimaryEmail = (pipedrivePerson: IPipedrivePerson): string => {
  for (const item of pipedrivePerson.email) {
    if (item['primary']) {
      return item['value'];
    }
  }

  return null;
};

export const getPipedrivePersonPrimaryPhone = (
  pipedrivePerson: IPipedrivePerson
): string | null => {
  for (const item of pipedrivePerson.phone) {
    if (item['primary']) {
      return item['value'];
    }
  }

  return null;
};

export const getPipedrivePersonTitle = (pipedrivePerson: IPipedrivePerson): string => {
  const titleArray: string[] = [pipedrivePerson.name];

  for (const field of ['email', 'phone']) {
    for (const item of pipedrivePerson[field]) {
      if (item['primary']) {
        pipedrivePerson['primary' + capitalize(field)] = item['value'];

        if (!titleArray.length) {
          titleArray.push(item['value']);
        }
      }
    }
  }

  if (titleArray.length) {
    return titleArray.join(' - ');
  } else {
    return 'Contact sans nom';
  }
};
