import { Component, OnDestroy, OnInit } from '@angular/core';
import { addDays, differenceInDays } from 'date-fns';
import { Subscription } from 'rxjs';
import { EnumCrawlerUrlStatus } from 'src/app/enums/crawler-url-status.enum';
import { ICrawlerUrl } from 'src/app/interfaces/crawler-url.interface';
import { chunk } from 'src/app/misc.utils';
import { CrawlerUrlService } from 'src/app/services/crawler-url/crawler-url.service';
import { IBatchOperationsParams } from 'src/app/services/firestore/firestore.service';

@Component({
  selector: 'app-crawler-alert',
  templateUrl: './crawler-alert.component.html',
  styleUrls: ['./crawler-alert.component.scss']
})
export class CrawlerAlertComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  errorMessageLastCrawl: string | null = null;
  errorMessageInErrors: string | null = null;

  crawlerUrlsInError: ICrawlerUrl[] = [];

  updatingCrawlers: {
    index: number;
    total: number;
  } = {
    index: 0,
    total: 1
  };

  updating: boolean = false;

  constructor(private crawlerUrlService: CrawlerUrlService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.crawlerUrlService
        .getPaginatedResult(
          'list',
          [],
          {
            field: 'modified',
            direction: 'desc'
          },
          1
        )
        .subscribe((crawlerUrls: ICrawlerUrl[]) => {
          this.errorMessageLastCrawl = null;

          if (crawlerUrls.length) {
            const daysSinceLastCrawl: number = differenceInDays(
              crawlerUrls[0].modified,
              new Date()
            );

            if (daysSinceLastCrawl > 0) {
              this.errorMessageLastCrawl =
                "La dernière URL parcourue par le script d'extracteur de données était il y a " +
                daysSinceLastCrawl +
                ' jour(s).';
            }
          } else {
            this.errorMessageLastCrawl = "Aucune url n'a été parcourue.";
          }
        })
    );

    this.subscriptions.add(
      this.crawlerUrlService
        .getAllByStatus(EnumCrawlerUrlStatus.error)
        .subscribe((crawlerUrls: ICrawlerUrl[]) => {
          this.errorMessageInErrors = null;
          this.crawlerUrlsInError = crawlerUrls;

          if (this.crawlerUrlsInError.length) {
            this.errorMessageInErrors =
              'Il y a ' +
              this.crawlerUrlsInError.length +
              " urls en erreur dans le script d'extracteur de données";
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async restartCrawlerUrlsInError(): Promise<void> {
    this.updatingCrawlers = {
      index: 0,
      total: this.crawlerUrlsInError.length
    };

    this.updating = true;

    const batchOperationsParams: IBatchOperationsParams = {
      update: {}
    };

    for (const crawlerUrl of this.crawlerUrlsInError) {
      batchOperationsParams.update[crawlerUrl.id] = {
        status: EnumCrawlerUrlStatus.notStarted,
        nextDateToUpdate: new Date('2022') // Force to refresh first
      };
    }

    const sizeChunkBatch: number = 500;

    const chunkedData: string[][] = chunk(
      Object.keys(batchOperationsParams.update),
      sizeChunkBatch
    );

    for (let i = 0; i < chunkedData.length; i++) {
      this.updatingCrawlers.index = sizeChunkBatch * i;

      const dataToBatch: { [key: string]: ICrawlerUrl } = {};
      for (const docId of chunkedData[i]) {
        dataToBatch[docId] = batchOperationsParams.update[docId];
      }

      await this.crawlerUrlService.batchOperations({
        update: dataToBatch
      });
    }

    this.updating = false;
  }
}
