import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AircraftModelFirestoreService } from './aircraft-models-firestore.service';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftModelService {
  constructor(private aircraftModelFirestoreService: AircraftModelFirestoreService) {}

  create(aircraftModel: IAircraftModel): Promise<string> {
    return this.aircraftModelFirestoreService.create(aircraftModel);
  }

  update(aircraftModel: IAircraftModel): Promise<string> {
    return this.aircraftModelFirestoreService.update(aircraftModel);
  }

  delete(id: string): Promise<void> {
    return this.aircraftModelFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAircraftModel> {
    return this.aircraftModelFirestoreService.docValue$(id);
  }

  getOneByTitle(aircraftModelTitle: string): Observable<IAircraftModel | null> {
    return this.aircraftModelFirestoreService
      .collectionValues$(ref => ref.where('title', '==', aircraftModelTitle))
      .pipe(map(results => (results && results.length ? results[0] : null)));
  }

  getAll(): Observable<IAircraftModel[]> {
    return this.aircraftModelFirestoreService.collectionValues$();
  }

  resetPagination(paginationName: string = 'list'): void {
    this.aircraftModelFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IAircraftModel[]> {
    return this.aircraftModelFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
