<div class="card-with-side-tabs">
    <div class="card-sidebar">
        <ul class="nav nav-pills flex-column" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link active"
                    id="general-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#general-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="general-tab-pane"
                    aria-selected="true"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faCogs"></fa-icon>
                    </span>
                    Informations générales
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="seat-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#seat-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="seat-tab-pane"
                    aria-selected="false"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faUsersLine"></fa-icon>
                    </span>
                    Configuration sièges
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="characteristic-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#characteristic-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="characteristic-tab-pane"
                    aria-selected="false"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faListCheck"></fa-icon>
                    </span>
                    Caractéristiques appareil
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="history-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#history-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="history-tab-pane"
                    aria-selected="false"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faClockRotateLeft"></fa-icon>
                    </span>
                    Opérateurs de l'appareil
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    id="images-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#images-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="images-tab-pane"
                    aria-selected="false"
                    [disabled]="loading"
                >
                    <span class="nav-indicator">
                        <fa-icon [icon]="faImage"></fa-icon>
                    </span>
                    Images
                </button>
            </li>
        </ul>
        <div class="btn-toolbar">
            <button
                type="button"
                (click)="delete()"
                class="btn btn-danger"
                *ngIf="hasAclAccess(EnumAcl.aircraftsDelete)"
            >
                <fa-icon [icon]="faTrash"></fa-icon>
                Supprimer
            </button>

            <a
                [routerLink]="'/admin/aircrafts/' + aircraftId + '/edit'"
                class="btn btn-primary"
                *ngIf="hasAclAccess(EnumAcl.aircraftsEdit)"
            >
                <fa-icon [icon]="faEdit"></fa-icon> Modifier</a
            >
        </div>
    </div>
    <div class="tab-content-container">
        <div class="tab-content">
            <div
                class="tab-pane fade show active"
                id="general-tab-pane"
                role="tabpanel"
                aria-labelledby="general-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Informations générales</div>
                    <ul class="list-group list-group-flush" *ngIf="loading">
                        <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                            <div class="placeholder w-100 mb-0"></div>
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && !aircraft">
                        <li class="list-group-item list-group-item-warning text-center">
                            Aucune donnée à afficher
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && aircraft">
                        <li class="list-group-item" [class.text-muted]="!aircraft.serialNumber">
                            <strong>Manufacturer's Serial Number</strong> :
                            {{ aircraft.serialNumber || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.status">
                            <strong>Status</strong> :
                            <span
                                class="badge"
                                [class.bg-success]="
                                    [
                                        EnumAircraftStatus.active,
                                        EnumAircraftStatus.activeParked
                                    ].includes(aircraft.status)
                                "
                                [class.bg-danger]="
                                    ![
                                        EnumAircraftStatus.active,
                                        EnumAircraftStatus.activeParked
                                    ].includes(aircraft.status)
                                "
                                [innerText]="getAircraftStatusLabel(aircraft.status)"
                            ></span>
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.registration">
                            <strong>Dernière Immatriculation</strong> :
                            {{ aircraft.registration || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.airlineId">
                            <strong>Dernier opérateur</strong> :
                            <div class="placeholder w-100" *ngIf="loadingAirline"></div>
                            <a
                                [routerLink]="'/admin/airlines/' + airline.id"
                                [innerText]="airline.title"
                                *ngIf="!loadingAirline && airline"
                            ></a>
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.aircraftModelId">
                            <strong>Modèle</strong> :
                            <div *ngIf="loadingAircraftModel" class="placeholder w-100"></div>
                            <a
                                [routerLink]="'/admin/aircraft-models/' + aircraft.aircraftModelId"
                                [innerText]="aircraftModel?.title.toUpperCase()"
                                *ngIf="!loadingAircraftModel"
                            ></a>
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.type">
                            <strong>Type</strong> : {{ aircraft.type || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.firstFlightDate">
                            <strong>Date premier vol</strong> :
                            {{ aircraft.firstFlightDate || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.testRegistration">
                            <strong>Immatriculation d'essai</strong> :
                            {{ aircraft.testRegistration || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!getAircraftAge(aircraft)">
                            <strong>Âge de l'avion</strong> :
                            {{ getAircraftAge(aircraft) || "Inconnu" }} ans
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.classes?.length">
                            <strong>Classes</strong> :
                            {{ aircraft.classes?.join("/") || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.engines">
                            <strong>Moteurs</strong> : {{ aircraft.engines || "Inconnu" }}
                        </li>
                        <li class="list-group-item">
                            <strong>Peut être utilisé pour le cargo</strong> :
                            {{ aircraft.isCargo ? "Oui" : "Non" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.weight">
                            <strong>Poids</strong> : {{ aircraft.weight || "Inconnu" }} kg
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.volume">
                            <strong>Volume</strong> : {{ aircraft.volume || "Inconnu" }} m<sup
                                >3</sup
                            >
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.url">
                            <strong>Airfleets</strong> :
                            <a [href]="aircraft.url" target="_blank" *ngIf="aircraft.url">Lien</a>
                            <span *ngIf="!aircraft.url">Aucun</span>
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.homebase">
                            <strong>Aéroport d'attache</strong> :
                            {{ aircraft.homebase || "Inconnu" }}
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="seat-tab-pane"
                role="tabpanel"
                aria-labelledby="seat-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Configuration sièges</div>
                    <ul class="list-group list-group-flush" *ngIf="loading">
                        <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                            <div class="placeholder w-100 mb-0"></div>
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && !aircraft">
                        <li class="list-group-item list-group-item-warning text-center">
                            Aucune donnée à afficher
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && aircraft">
                        <li class="list-group-item" [class.text-muted]="!aircraft.seatFirst">
                            <strong>Classe première</strong> : {{ aircraft.seatFirst || 0 }}F
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.seatBusiness">
                            <strong>Classe affaire</strong> : {{ aircraft.seatBusiness || 0 }}C
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.seatPremiumEconomy"
                        >
                            <strong>Classe économique premium</strong> :
                            {{ aircraft.seatPremiumEconomy || 0 }}W
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.seatEconomy">
                            <strong>Classe économique</strong> : {{ aircraft.seatEconomy || 0 }}Y
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.seatTotal">
                            <strong>Total</strong> : {{ aircraft.seatTotal || 0 }} places
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="characteristic-tab-pane"
                role="tabpanel"
                aria-labelledby="characteristic-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Caractéristiques appareil</div>
                    <ul class="list-group list-group-flush" *ngIf="loading">
                        <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                            <div class="placeholder w-100 mb-0"></div>
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && !aircraft">
                        <li class="list-group-item list-group-item-warning text-center">
                            Aucune donnée à afficher
                        </li>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="!loading && aircraft">
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.dateOfManufacture"
                        >
                            <strong>Date de fabrication</strong> :
                            {{ aircraft.dateOfManufacture || "Inconnu" }}
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.dateOfRefurbishment"
                        >
                            <strong>Date de remise à neuf</strong> :
                            {{ (aircraft.dateOfRefurbishment | date : "dd/MM/yyyy") || "Inconnu" }}
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.dateOfRefurbishment"
                        >
                            <strong>Nombre de membres d'équipage</strong> :
                            {{ aircraft.numberOfCrewMembers || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.hasStewardess">
                            <strong>Hotesse</strong> :
                            {{ getAircraftHasStewardessLabel(aircraft.hasStewardess) || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.isSmokingAllowed">
                            <strong>Fumeur</strong> :
                            {{ aircraft.isSmokingAllowed ? "Oui" : "Non" }}
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.sleepingConfiguration"
                        >
                            <strong>Sleeping Configuration</strong> :
                            {{ aircraft.sleepingConfiguration || "Inconnu" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.hasToilet">
                            <strong>Toilette</strong> :
                            {{ aircraft.hasToilet ? "Oui" : "Non" }}
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.hasHotCatering"
                            *ngIf="aircraft.hasHotCatering"
                        >
                            <strong>Catering Chaud</strong> :
                            {{
                                getAircraftHasHotCateringLabel(aircraft.hasHotCatering) || "Inconnu"
                            }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.arePetsAllowed">
                            <strong>Pets</strong> :
                            {{ aircraft.arePetsAllowed ? "Oui" : "Non" }}
                        </li>
                        <li class="list-group-item" [class.text-muted]="!aircraft.wifi">
                            <strong>Wifi</strong> :
                            {{ getAircraftWifiLabel(aircraft.wifi) || "Inconnu" }}
                        </li>
                        <li
                            class="list-group-item"
                            [class.text-muted]="!aircraft.hasSatellitePhone"
                            *ngIf="aircraft.hasSatellitePhone"
                        >
                            <strong>Satellite Phone</strong> :
                            {{ aircraft.hasSatellitePhone || "Inconnu" }}
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="history-tab-pane"
                role="tabpanel"
                aria-labelledby="history-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Opérateurs de l'appareil</div>
                    <ul class="list-group list-group-flush" *ngIf="loading">
                        <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                            <div class="placeholder w-100 mb-0"></div>
                        </li>
                    </ul>
                    <ul
                        class="list-group list-group-flush"
                        *ngIf="!loading && !aircraft?.history?.length"
                    >
                        <li class="list-group-item list-group-item-warning text-center">
                            Aucune donnée à afficher
                        </li>
                    </ul>
                    <div class="table-responsive" *ngIf="!loading && aircraft?.history?.length">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Immatriculation</th>
                                    <th>Date de livraison</th>
                                    <th>Opérateur</th>
                                    <th>Remarque</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let tr of aircraft.history">
                                    <td [innerText]="tr.registration"></td>
                                    <td [innerText]="tr.deliveryDate"></td>
                                    <td>
                                        <a
                                            [routerLink]="'/admin/airlines/' + tr.airlineId"
                                            [innerText]="tr.airlineTitle"
                                            *ngIf="tr.airlineId"
                                        ></a>
                                        <span
                                            *ngIf="!tr.airlineId"
                                            [innerText]="tr.airlineTitle"
                                        ></span>
                                    </td>
                                    <td [innerText]="tr.remark"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="images-tab-pane"
                role="tabpanel"
                aria-labelledby="images-tab"
                tabindex="0"
            >
                <div class="card mb-4">
                    <div class="card-header">Images</div>
                    <ul class="list-group list-group-flush" *ngIf="loading">
                        <li class="list-group-item" *ngFor="let number of [].constructor(10)">
                            <div class="placeholder w-100 mb-0"></div>
                        </li>
                    </ul>
                    <ul
                        class="list-group list-group-flush"
                        *ngIf="!loading && !aircraft?.imageUrls?.length"
                    >
                        <li class="list-group-item list-group-item-warning text-center">
                            Aucune donnée à afficher
                        </li>
                    </ul>
                    <ul
                        class="list-group list-group-flush"
                        *ngIf="!loading && aircraft?.imageUrls?.length"
                    >
                        <li class="list-group-item" *ngFor="let imageUrl of aircraft.imageUrls">
                            <div class="row align-items-center">
                                <div class="col-6 col-md-4 col-xl-3">
                                    <img [src]="imageUrl" />
                                </div>
                                <div class="col-6 col-md-8 col-xl-9">
                                    <div
                                        class="badge me-2"
                                        [class.text-bg-secondary]="
                                            !aircraft.imageOutsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraft.imageOutsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraft.imageOutsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image extérieure par défaut
                                    </div>
                                    <div
                                        class="badge"
                                        [class.text-bg-secondary]="
                                            !aircraft.imageInsideUrl?.includes(imageUrl)
                                        "
                                        [class.text-bg-success]="
                                            aircraft.imageInsideUrl?.includes(imageUrl)
                                        "
                                    >
                                        <fa-icon
                                            [icon]="
                                                aircraft.imageInsideUrl?.includes(imageUrl)
                                                    ? faToggleOn
                                                    : faToggleOff
                                            "
                                        ></fa-icon>

                                        Image intérieure par défaut
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
