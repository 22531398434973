<div class="row">
    <div class="col-md-8 text-center">
        <div class="card card-statistics mb-4">
            <div class="card-header">
                Objectif {{ currentYear }}
                <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="showYearlyGoalModal()"
                >
                    <fa-icon [icon]="faEdit"></fa-icon> Éditer
                </button>
            </div>
            <div class="card-body big-number">
                <div class="row">
                    <div class="col-sm-4">
                        <h5>Annuel</h5>
                        <div *ngIf="loading" class="placeholder w-100"></div>
                        <div
                            *ngIf="!loading"
                            [innerText]="
                                formatPrice(
                                    companyFinancialGoal?.yearlyGoal || 0,
                                    currentCurrency ?? getDefaultCurrency(),
                                    0
                                )
                            "
                        ></div>
                    </div>
                    <div class="col-sm-4">
                        <h5>Trimestriel</h5>
                        <div *ngIf="loading" class="placeholder w-100"></div>
                        <div
                            *ngIf="!loading"
                            [innerText]="
                                formatPrice(
                                    companyFinancialGoal?.quarterlyGoal || 0,
                                    currentCurrency ?? getDefaultCurrency(),
                                    0
                                )
                            "
                        ></div>
                    </div>
                    <div class="col-sm-4">
                        <h5>Mensuel</h5>
                        <div *ngIf="loading" class="placeholder w-100"></div>
                        <div
                            *ngIf="!loading"
                            [innerText]="
                                formatPrice(
                                    companyFinancialGoal?.monthlyGoal || 0,
                                    currentCurrency ?? getDefaultCurrency(),
                                    0
                                )
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 text-center">
        <div class="card card-statistics mb-4">
            <div class="card-header">Résultat annuel {{ currentYear }}</div>
            <div class="card-body big-number">
                <div class="row">
                    <div
                        class="col-12"
                        [class.text-danger]="
                            financialReportsByPeriod[EnumPeriod.months][currentYear].all?.marginHT -
                                companyFinancialGoal?.yearlyGoal <
                            0
                        "
                        [class.text-success]="
                            financialReportsByPeriod[EnumPeriod.months][currentYear].all?.marginHT -
                                companyFinancialGoal?.yearlyGoal >
                            0
                        "
                    >
                        <div *ngIf="loading" class="placeholder w-100"></div>
                        <div *ngIf="!loading">
                            {{
                                financialReportsByPeriod[EnumPeriod.months][currentYear].all
                                    ?.marginHT -
                                    companyFinancialGoal?.yearlyGoal >
                                0
                                    ? "+"
                                    : ""
                            }}
                            {{
                                formatPrice(
                                    financialReportsByPeriod[EnumPeriod.months][currentYear].all
                                        ?.marginHT - companyFinancialGoal?.yearlyGoal || 0,
                                    currentCurrency ?? getDefaultCurrency(),
                                    0
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ul class="nav nav-pills mb-3 justify-content-center" role="tablist">
    <li class="nav-item" role="presentation" *ngFor="let period of getPeriods()">
        <button
            class="nav-link"
            [class.active]="currentPeriod === period"
            [id]="'total-period-' + period"
            type="button"
            [attr.aria-selected]="currentPeriod === period"
            [innerText]="getPeriodLabel(period)"
            (click)="changeCurrentPeriod(period)"
        ></button>
    </li>
</ul>

<div class="tab-content">
    <div
        class="tab-pane fade"
        *ngFor="let period of getPeriods()"
        [class.show]="currentPeriod === period"
        [class.active]="currentPeriod === period"
        [id]="'total-period-' + period"
        role="tabpanel"
        [attr.aria-labelledby]="'total-period-' + period"
        tabindex="0"
    >
        <div class="card mb-4">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Période</th>
                                <th class="text-center">Nb contrats</th>
                                <th class="text-center">Nb clotûrés</th>
                                <th class="text-end">Marge HT</th>
                                <th class="text-center">Marge %</th>
                                <th class="text-end">CA</th>
                                <th class="text-center">Objectif 100%</th>
                                <th class="text-center">Objectif 110%</th>
                                <th class="text-end">
                                    Delta Marge
                                    {{
                                        period !== EnumPeriod.years ? currentYear - 1 : "Année - 1"
                                    }}
                                </th>
                                <th class="text-end">
                                    Delta CA
                                    {{
                                        period !== EnumPeriod.years ? currentYear - 1 : "Année - 1"
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr *ngFor="let row of [].constructor(12)">
                                <td *ngFor="let number of [].constructor(10)">
                                    <div class="placeholder w-100"></div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!loading">
                            <tr
                                *ngFor="
                                    let financialReportAllAndTypes of financialReportsByPeriod[
                                        period
                                    ][currentYear].financialReports;
                                    let i = index
                                "
                                [class.text-muted]="!financialReportAllAndTypes.financialReport"
                            >
                                <td [innerText]="financialReportAllAndTypes.label"></td>
                                <td
                                    class="text-center"
                                    [innerText]="
                                        financialReportAllAndTypes.financialReport?.nbContracts || 0
                                    "
                                ></td>
                                <td class="text-center">
                                    {{
                                        financialReportAllAndTypes.financialReport?.nbCompleted || 0
                                    }}
                                    ({{
                                        financialReportAllAndTypes.financialReport
                                            ?.completedPercent || 0
                                    }}%)
                                </td>
                                <td
                                    class="text-end"
                                    [innerText]="
                                        formatPrice(
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            currentCurrency ?? getDefaultCurrency()
                                        )
                                    "
                                ></td>
                                <td
                                    class="text-center"
                                    [innerText]="
                                        (financialReportAllAndTypes.financialReport
                                            ?.marginPercent || 0) + '%'
                                    "
                                ></td>
                                <td
                                    class="text-end"
                                    [innerText]="
                                        formatPrice(
                                            financialReportAllAndTypes.financialReport?.ca || 0,
                                            currentCurrency ?? getDefaultCurrency()
                                        )
                                    "
                                ></td>
                                <td
                                    class="text-center"
                                    [class.text-danger]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0
                                        ) < 80
                                    "
                                    [class.text-warning]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0
                                        ) >= 80 &&
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0
                                        ) < 100
                                    "
                                    [class.text-success]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0
                                        ) >= 100
                                    "
                                >
                                    {{
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0
                                        ) + "%"
                                    }}<br />
                                    {{
                                        formatPrice(
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                getCompanyFinancialGoalAccordingToPeriod(period),
                                            currentCurrency ?? getDefaultCurrency(),
                                            2,
                                            true
                                        )
                                    }}
                                </td>
                                <td
                                    class="text-center"
                                    [class.text-danger]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            110
                                        ) < 80
                                    "
                                    [class.text-warning]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            110
                                        ) >= 80 &&
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            110
                                        ) < 100
                                    "
                                    [class.text-success]="
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            110
                                        ) >= 100
                                    "
                                >
                                    {{
                                        getPercentageToGoal(
                                            period,
                                            financialReportAllAndTypes.financialReport?.marginHT ||
                                                0,
                                            110
                                        ) + "%"
                                    }}
                                    <br />
                                    {{
                                        formatPrice(
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                getCompanyFinancialGoalAccordingToPeriod(period),
                                            currentCurrency ?? getDefaultCurrency(),
                                            2,
                                            true
                                        )
                                    }}
                                </td>
                                <ng-container *ngIf="period !== EnumPeriod.years">
                                    <td
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .financialReports[i].financialReport?.marginHT <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .financialReports[i].financialReport?.marginHT >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear - 1]
                                                .financialReports[i]
                                                ? formatPrice(
                                                      financialReportAllAndTypes.financialReport
                                                          ?.marginHT -
                                                          financialReportsByPeriod[period][
                                                              currentYear - 1
                                                          ].financialReports[i].financialReport
                                                              ?.marginHT || 0,
                                                      currentCurrency ?? getDefaultCurrency(),
                                                      2,
                                                      true
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></td>
                                    <td
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear - 1]
                                                .financialReports[i] &&
                                            financialReportAllAndTypes.financialReport?.ca -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .financialReports[i].financialReport?.ca <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear - 1]
                                                .financialReports[i] &&
                                            financialReportAllAndTypes.financialReport?.ca -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .financialReports[i].financialReport?.ca >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear - 1]
                                                .financialReports[i]
                                                ? formatPrice(
                                                      financialReportAllAndTypes.financialReport
                                                          ?.ca -
                                                          financialReportsByPeriod[period][
                                                              currentYear - 1
                                                          ].financialReports[i].financialReport
                                                              ?.ca || 0,
                                                      currentCurrency ?? getDefaultCurrency(),
                                                      2,
                                                      true
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></td>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        period === EnumPeriod.years &&
                                        financialReportsByPeriod[period][currentYear]
                                            .financialReports[i + 1]
                                    "
                                >
                                    <td
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1] &&
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                financialReportsByPeriod[period][currentYear]
                                                    .financialReports[i + 1].financialReport
                                                    ?.marginHT <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1] &&
                                            financialReportAllAndTypes.financialReport?.marginHT -
                                                financialReportsByPeriod[period][currentYear]
                                                    .financialReports[i + 1].financialReport
                                                    ?.marginHT >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1]
                                                ? formatPrice(
                                                      financialReportAllAndTypes.financialReport
                                                          ?.marginHT -
                                                          financialReportsByPeriod[period][
                                                              currentYear
                                                          ].financialReports[i + 1].financialReport
                                                              ?.marginHT || 0,
                                                      currentCurrency ?? getDefaultCurrency(),
                                                      2,
                                                      true
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></td>
                                    <td
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1] &&
                                            financialReportAllAndTypes.financialReport?.ca -
                                                financialReportsByPeriod[period][currentYear]
                                                    .financialReports[i + 1].financialReport?.ca <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1] &&
                                            financialReportAllAndTypes.financialReport?.ca -
                                                financialReportsByPeriod[period][currentYear]
                                                    .financialReports[i + 1].financialReport?.ca >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear]
                                                .financialReports[i + 1]
                                                ? formatPrice(
                                                      financialReportAllAndTypes.financialReport
                                                          ?.ca -
                                                          financialReportsByPeriod[period][
                                                              currentYear
                                                          ].financialReports[i + 1].financialReport
                                                              ?.ca || 0,
                                                      currentCurrency ?? getDefaultCurrency(),
                                                      2,
                                                      true
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></td>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        period === EnumPeriod.years &&
                                        !financialReportsByPeriod[period][currentYear]
                                            .financialReports[i + 1]
                                    "
                                >
                                    <td class="text-end" colspan="2"></td>
                                </ng-container>
                            </tr>
                        </tbody>

                        <tfoot *ngIf="loading">
                            <tr>
                                <th *ngFor="let number of [].constructor(10)">
                                    <div class="placeholder w-100"></div>
                                </th>
                            </tr>
                        </tfoot>

                        <tfoot *ngIf="!loading">
                            <tr>
                                <th>Total</th>
                                <th
                                    class="text-center"
                                    [innerText]="
                                        financialReportsByPeriod[period][currentYear].all
                                            ?.nbContracts || 0
                                    "
                                ></th>
                                <th class="text-center">
                                    {{
                                        financialReportsByPeriod[period][currentYear].all
                                            ?.nbCompleted || 0
                                    }}
                                    ({{
                                        financialReportsByPeriod[period][currentYear].all
                                            ?.completedPercent || 0
                                    }}%)
                                </th>
                                <th
                                    class="text-end"
                                    [innerText]="
                                        formatPrice(
                                            financialReportsByPeriod[period][currentYear].all
                                                ?.marginHT || 0,
                                            currentCurrency ?? getDefaultCurrency()
                                        )
                                    "
                                ></th>
                                <th
                                    class="text-center"
                                    [innerText]="
                                        (financialReportsByPeriod[period][currentYear].all
                                            ?.marginPercent || 0) + '%'
                                    "
                                ></th>
                                <th
                                    class="text-end"
                                    [innerText]="
                                        formatPrice(
                                            financialReportsByPeriod[period][currentYear].all?.ca ||
                                                0,
                                            currentCurrency ?? getDefaultCurrency()
                                        )
                                    "
                                ></th>
                                <ng-container *ngIf="period !== EnumPeriod.years">
                                    <th
                                        class="text-center"
                                        [class.text-danger]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0
                                            ) < 80
                                        "
                                        [class.text-warning]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0
                                            ) >= 80 &&
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0
                                            ) < 100
                                        "
                                        [class.text-success]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0
                                            ) >= 100
                                        "
                                        [innerText]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0
                                            ) + '%'
                                        "
                                    ></th>
                                    <th
                                        class="text-center"
                                        [class.text-danger]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0,
                                                110
                                            ) < 80
                                        "
                                        [class.text-warning]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0,
                                                110
                                            ) >= 80 &&
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0,
                                                110
                                            ) < 100
                                        "
                                        [class.text-success]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0,
                                                110
                                            ) >= 100
                                        "
                                        [innerText]="
                                            getPercentageToGoal(
                                                period,
                                                financialReportsByPeriod[period][currentYear].all
                                                    ?.marginHT || 0,
                                                110
                                            ) + '%'
                                        "
                                    ></th>

                                    <th
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear].all
                                                ?.marginHT -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .all?.marginHT <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear].all
                                                ?.marginHT -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .all?.marginHT >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear - 1]?.all
                                                ? formatPrice(
                                                      financialReportsByPeriod[period][currentYear]
                                                          .all?.marginHT -
                                                          financialReportsByPeriod[period][
                                                              currentYear - 1
                                                          ].all?.marginHT || 0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></th>
                                    <th
                                        class="text-end"
                                        [class.text-danger]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear].all?.ca -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .all?.ca <
                                                0
                                        "
                                        [class.text-success]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear].all?.ca -
                                                financialReportsByPeriod[period][currentYear - 1]
                                                    .all?.ca >
                                                0
                                        "
                                        [innerText]="
                                            financialReportsByPeriod[period][currentYear - 1] &&
                                            financialReportsByPeriod[period][currentYear - 1].all
                                                ? formatPrice(
                                                      financialReportsByPeriod[period][currentYear]
                                                          .all?.ca -
                                                          financialReportsByPeriod[period][
                                                              currentYear - 1
                                                          ].all?.ca || 0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                                : formatPrice(
                                                      0,
                                                      currentCurrency ?? getDefaultCurrency()
                                                  )
                                        "
                                    ></th>
                                </ng-container>
                                <ng-container *ngIf="period === EnumPeriod.years">
                                    <th class="text-end" colspan="4"></th>
                                </ng-container>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade"
    id="modal-yearly-goal"
    tabindex="-1"
    aria-labelledby="modal-yearly-goal-label"
    aria-hidden="true"
    #modalYearlyGoal
>
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="modal-yearly-goal-label">
                    Objectif {{ currentYear }}
                    <span
                        *ngIf="formGoal.value.userId"
                        [innerText]="'pour ' + getUserFullname(usersObj[formGoal.value.userId])"
                    ></span>
                </h1>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form
                    (submit)="submitGoalForm()"
                    (keydown.enter)="submitGoalForm()"
                    [formGroup]="formGoal"
                    class="main-form"
                >
                    <div class="form-group">
                        <label for="yearlyGoal">Objectif annuel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="yearlyGoal"
                                formControlName="yearlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched || formGoal.controls['yearlyGoal'].touched) &&
                                    formGoal.controls['yearlyGoal'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched || formGoal.controls['yearlyGoal'].touched) &&
                                    formGoal.controls['yearlyGoal'].errors &&
                                    formGoal.controls['yearlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="quarterlyGoal">Objectif trimestriel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="quarterlyGoal"
                                formControlName="quarterlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched ||
                                        formGoal.controls['quarterlyGoal'].touched) &&
                                    formGoal.controls['quarterlyGoal'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched ||
                                        formGoal.controls['quarterlyGoal'].touched) &&
                                    formGoal.controls['quarterlyGoal'].errors &&
                                    formGoal.controls['quarterlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="monthlyGoal">Objectif mensuel {{ currentYear }}</label>

                        <div class="input-group">
                            <input
                                class="form-control"
                                id="monthlyGoal"
                                formControlName="monthlyGoal"
                                type="number"
                                [class.is-invalid]="
                                    (formGoal.touched ||
                                        formGoal.controls['monthlyGoal'].touched) &&
                                    formGoal.controls['monthlyGoal'].status === 'INVALID'
                                        ? 'danger'
                                        : ''
                                "
                            />

                            <span
                                class="input-group-text"
                                [innerText]="
                                    getEnumCurrencySymbol(currentCurrency ?? getDefaultCurrency())
                                "
                            ></span>
                        </div>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (formGoal.touched ||
                                        formGoal.controls['monthlyGoal'].touched) &&
                                    formGoal.controls['monthlyGoal'].errors &&
                                    formGoal.controls['monthlyGoal'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    [innerText]="savingGoal ? 'Sauvegarde ...' : 'Sauvegarder'"
                    (click)="submitGoalForm()"
                    [disabled]="formGoal.disabled || !formGoal.valid"
                ></button>
            </div>
        </div>
    </div>
</div>
