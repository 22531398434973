import { Component, OnChanges, Input, OnDestroy } from '@angular/core';

import { RemoteService } from '../../services/remote.service';
import { PipedriveService } from '../../services/pipedrive.service';
import { PaginationService } from '../../services/pagination.service';
import {
  getDisplayedEnquiryRefTitle,
  getEnquiryLegsOfCotation,
  IEnquiry
} from 'src/app/interfaces/enquiry.interface';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { Subscription } from 'rxjs';
import { EnquiryCotationService } from 'src/app/services/enquiry-cotation/enquiry-cotation.service';
import { IEnquiryCotation } from 'src/app/interfaces/enquiry-cotation.interface';
import { EnumEnquiryCotationStatus } from 'src/app/enums/enquiry-cotation-status.enum';
import {
  getDepartDate,
  getItineraryVolumeList,
  getItineraryWeightList,
  getPassengersTotalLabelList,
  getReturnDate
} from 'src/app/interfaces/enquiry-itinerary.interface';
import { getEnumEnquiryCancelledReasonLabel } from 'src/app/enums/enquiry-cancelled-reason.enum';
import { differenceInMinutes } from 'date-fns';
import { IUser } from 'src/app/interfaces/user.interface';
import { IPipedriveOrganization, IPipedrivePerson } from 'src/app/interfaces/pipedrive.interface';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { getCountryLabel } from 'src/app/enums/continent-code.enum';
import { IAirport } from 'src/app/interfaces/airport.interface';

@Component({
  selector: 'enquiries-list',
  templateUrl: './enquiries-list.component.html',
  styleUrls: ['./enquiries-list.component.scss']
})
export class EnquiriesListComponent implements OnChanges, OnDestroy {
  @Input('loading') loading: boolean = false;
  @Input('enquiries') enquiries: IEnquiry[] = [];
  @Input('usersObj') usersObj: { [key: string]: IUser } = {};
  @Input('hideClientColumns') hideClientColumns: boolean = false;
  @Input('status') status: EnumEnquiryStatus | null = null;
  @Input('enquiryType') enquiryType: EnumEnquiryType | null = null;

  getEnumEnquiryStatusLabel = getEnumEnquiryStatusLabel;
  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnquiryLegsOfCotation = getEnquiryLegsOfCotation;
  getItineraryWeightList = getItineraryWeightList;
  getItineraryVolumeList = getItineraryVolumeList;
  getEnumEnquiryCancelledReasonLabel = getEnumEnquiryCancelledReasonLabel;
  getDepartDate = getDepartDate;
  getReturnDate = getReturnDate;
  getPassengersTotalLabelList = getPassengersTotalLabelList;
  getDisplayedEnquiryRefTitle = getDisplayedEnquiryRefTitle;
  getCountryLabel = getCountryLabel;

  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumEnquiryType = EnumEnquiryType;

  organizationsObj: { [key: string]: IPipedriveOrganization | null } = {};
  personsObj: { [key: string]: IPipedrivePerson | null } = {};
  currentPagination: string = 'enquiries-list';
  airportsObj: { [key: string]: IAirport | null } = {};
  aircraftsCompiledObj: { [key: string]: IAircraftCompiled | null } = {};
  airportsToLoad: string[] = [];

  enquiryCotations: { [key: string]: IEnquiryCotation[] } = {};
  enquiriesWithCotations: {
    [key: string]: {
      enquiry: IEnquiry;
      cotations: IEnquiryCotation[];
      confirmedCotations: IEnquiryCotation[];
    };
  } = {};

  subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private pipedriveService: PipedriveService,
    public paginationService: PaginationService,
    private enquiryCotationService: EnquiryCotationService
  ) {}

  async ngOnChanges(): Promise<void> {
    if (this.enquiries) {
      for (const enquiry of this.enquiries) {
        this.loadPipedriveOrgaAndPerson(enquiry);

        this.loadAirportsOfEnquiry(enquiry);

        this.loadEnquiryCotations(enquiry.id);
      }

      this.loadAirports();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadEnquiryCotations(enquiryId: string): void {
    this.subscriptions.add(
      this.enquiryCotationService
        .getAllForEnquiry(enquiryId)
        .subscribe(async (enquiryCotations: IEnquiryCotation[]) => {
          this.enquiryCotations[enquiryId] = enquiryCotations;

          this.refreshEnquiryCotationsByOffers();
        })
    );
  }

  refreshEnquiryCotationsByOffers(): void {
    const aircraftCompiledIds: string[] = [];

    for (const enquiry of this.enquiries) {
      this.enquiriesWithCotations[enquiry.id] = {
        enquiry: enquiry,
        cotations: [],
        confirmedCotations: []
      };

      if (this.enquiryCotations[enquiry.id]?.length) {
        for (const enquiryCotation of this.enquiryCotations[enquiry.id]) {
          if (enquiryCotation.aircraftCompiled) {
            this.aircraftsCompiledObj[enquiryCotation.aircraftCompiledId] =
              enquiryCotation.aircraftCompiled;
          }

          if (this.enquiriesWithCotations[enquiryCotation.enquiryId]) {
            this.enquiriesWithCotations[enquiryCotation.enquiryId].cotations.push(enquiryCotation);
          }

          if (enquiryCotation.status === EnumEnquiryCotationStatus.confirmed) {
            this.enquiriesWithCotations[enquiryCotation.enquiryId].confirmedCotations.push(
              enquiryCotation
            );
          }

          if (
            enquiryCotation.aircraftCompiledId &&
            !aircraftCompiledIds.includes(enquiryCotation.aircraftCompiledId) &&
            !this.aircraftsCompiledObj[enquiryCotation.aircraftCompiledId]
          ) {
            aircraftCompiledIds.push(enquiryCotation.aircraftCompiledId);
          }
        }
      }
    }

    this.loadAircraftCompiled(aircraftCompiledIds);
  }

  async loadAircraftCompiled(aircraftCompiledIds: string[]): Promise<void> {
    const docs: object[] = await this.remoteService.getDocumentsFromDocId(
      'aircraftsCompiled',
      aircraftCompiledIds
    );

    for (const doc of docs) {
      const aircraftsCompiled = doc as IAircraftCompiled;

      this.aircraftsCompiledObj[aircraftsCompiled.id] = aircraftsCompiled;
    }
  }

  async loadPipedriveOrgaAndPerson(enquiry: IEnquiry): Promise<void> {
    if (enquiry.clientId && !this.organizationsObj[enquiry.clientId]) {
      this.organizationsObj[enquiry.clientId] = await this.pipedriveService.getOrganization(
        typeof enquiry.clientId === 'number' ? enquiry.clientId.toString() : enquiry.clientId
      );
    }

    if (enquiry.contactId && !this.personsObj[enquiry.contactId]) {
      this.personsObj[enquiry.contactId] = await this.pipedriveService.getPerson(enquiry.contactId);
    }
  }

  formatPrice(value: number): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });

    return formatter.format(value);
  }

  loadAirportsOfEnquiry(enquiry: IEnquiry): void {
    for (const itinerary of enquiry.itineraries) {
      for (const trip of itinerary.trips) {
        if (trip.airportDepart) {
          this.addAirportToLoad(trip.airportDepart);
        }
        if (trip.airportDestination) {
          this.addAirportToLoad(trip.airportDestination);
        }
      }
    }

    this.loadAirports();
  }

  addAirportToLoad(airportId: string) {
    if (
      this.airportsToLoad.indexOf(airportId) === -1 &&
      typeof this.airportsObj[airportId] === 'undefined'
    ) {
      this.airportsToLoad.push(airportId);
    }
  }

  async loadAirports(): Promise<void> {
    if (this.airportsToLoad.length) {
      const docs: object[] = await this.remoteService.getDocumentsFromDocId(
        'airports',
        this.airportsToLoad
      );

      for (const doc of docs) {
        const airport = doc as IAirport;

        this.airportsObj[airport.id] = airport;
      }

      this.airportsToLoad = [];
    }
  }

  diffDate(date1: Date, date2: Date): number {
    return differenceInMinutes(date2, date1);
  }
}
