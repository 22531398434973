import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EnquiryOfferFirestoreService } from './enquiry-offer-firestore.service';
import { IEnquiryOffer } from 'src/app/interfaces/enquiry-offer.interface';
import { EnumEnquiryOfferStatus } from 'src/app/enums/enquiry-offer-status.enum';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryOfferService {
  constructor(private enquiryOfferFirestoreService: EnquiryOfferFirestoreService) {}

  create(enquiryOffer: IEnquiryOffer): Promise<string> {
    return this.enquiryOfferFirestoreService.create(enquiryOffer);
  }

  createWithId(id: string, enquiryOffer: IEnquiryOffer): Promise<string> {
    return this.enquiryOfferFirestoreService.createWithId(id, enquiryOffer);
  }

  update(enquiryOffer: IEnquiryOffer): Promise<string> {
    return this.enquiryOfferFirestoreService.update(enquiryOffer);
  }

  delete(id: string): Promise<void> {
    return this.enquiryOfferFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEnquiryOffer> {
    return this.enquiryOfferFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEnquiryOffer[]> {
    return this.enquiryOfferFirestoreService.collectionValues$();
  }

  getAllForEnquiry(enquiryId: string): Observable<IEnquiryOffer[]> {
    return this.enquiryOfferFirestoreService.collectionValues$(ref =>
      ref.where('enquiryId', '==', enquiryId)
    );
  }

  getAllConfirmedForEnquiry(enquiryId: string): Observable<IEnquiryOffer[]> {
    return this.enquiryOfferFirestoreService.collectionValues$(ref =>
      ref
        .where('enquiryId', '==', enquiryId)
        .where('status', '==', EnumEnquiryOfferStatus.confirmed)
    );
  }

  getAllConfirmedForEnquiries(enquiryIds: string[]): Observable<IEnquiryOffer[][]> {
    enquiryIds = enquiryIds.filter((value, index, self) => self.indexOf(value) === index);

    const chunkedDocsId: string[][] = this.enquiryOfferFirestoreService.chunk(enquiryIds, 10);

    const observables: Observable<IEnquiryOffer[]>[] = [];
    let results: IEnquiryOffer[] = [];
    for (const docsIdChunked of chunkedDocsId) {
      observables.push(
        this.enquiryOfferFirestoreService.collectionValues$(ref =>
          ref
            .where('enquiryId', 'in', docsIdChunked)
            .where('status', '==', EnumEnquiryOfferStatus.confirmed)
        )
      );
    }

    return combineLatest(observables);
  }

  resetPagination(paginationName: string = 'list'): void {
    this.enquiryOfferFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEnquiryOffer[]> {
    return this.enquiryOfferFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
