<div class="row justify-content-center">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <form (submit)="submitForm()" [formGroup]="form" class="main-form">
                    <div class="form-group">
                        <label for="lastname">Nom</label>
                        <input
                            class="form-control"
                            id="lastname"
                            formControlName="lastname"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['lastname'].touched) &&
                                form.controls['lastname'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['lastname'].touched) &&
                                    form.controls['lastname'].errors &&
                                    form.controls['lastname'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="firstname">Prénom</label>
                        <input
                            class="form-control"
                            id="firstname"
                            formControlName="firstname"
                            type="text"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['firstname'].touched) &&
                                form.controls['firstname'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['firstname'].touched) &&
                                    form.controls['firstname'].errors &&
                                    form.controls['firstname'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="email">Email</label>
                        <input
                            class="form-control"
                            id="email"
                            formControlName="email"
                            type="email"
                            required
                            [class.is-invalid]="
                                (form.touched || form.controls['email'].touched) &&
                                form.controls['email'].status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].errors &&
                                    form.controls['email'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                            <div
                                *ngIf="
                                    (form.touched || form.controls['email'].touched) &&
                                    form.controls['email'].errors &&
                                    form.controls['email'].errors['email']
                                "
                            >
                                Ce champ doit être un email valide.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="emailSignatureHtml">Signature d'email</label>
                        <quill-editor formControlName="emailSignatureHtml"></quill-editor>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || form.controls['emailSignatureHtml'].touched) &&
                                    form.controls['emailSignatureHtml'].errors &&
                                    form.controls['emailSignatureHtml'].errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="email-in-cc"
                            formControlName="emailInCc"
                        />
                        <label class="form-check-label" for="email-in-cc"
                            >Mettre mon email en CC dans les envois d'email ?</label
                        >
                    </div>

                    <div class="form-group">
                        <label for="password">Confirmer avec votre mot de passe </label>
                        <input
                            formControlName="password"
                            id="password"
                            class="form-control"
                            type="password"
                            required
                            [class.is-invalid]="
                                form.controls['password'].invalid &&
                                (form.controls['password'].dirty ||
                                    form.controls['password'].touched)
                            "
                        />
                        <div
                            *ngIf="
                                form.controls['password'].invalid &&
                                (form.controls['password'].dirty ||
                                    form.controls['password'].touched) &&
                                form.controls['password'].errors.required
                            "
                            class="invalid-tooltip"
                        >
                            Ce champ est obligatoire.
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <button
                                type="button"
                                routerLink="/admin/my-account"
                                class="btn btn-secondary btn-block"
                                [disabled]="form.disabled || sending"
                            >
                                Annuler
                            </button>
                        </div>
                        <div class="col">
                            <button
                                type="submit"
                                class="btn btn-primary btn-block"
                                [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                                [disabled]="form.disabled || !form.valid || sending"
                            ></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
