<div class="row">
    <div class="col-md-3 mb-4">
        <a
            routerLink="/admin/user-groups/add"
            class="btn btn-primary"
            *ngIf="hasAclAccess(EnumAcl.userGroupsAdd)"
            >+ Nouveau groupe</a
        >
    </div>
</div>

<app-data-list
    [data]="data"
    [loading]="loadingData"
    [columns]="columns"
    [rowCls]="rowCls"
    [rowUrl]="'/admin/user-groups/'"
    [orderBy]="orderBy"
    (updatedOrderBy)="updatedOrderBy($event)"
    (prevPage)="prevPage()"
    (nextPage)="nextPage()"
    [currentPage]="currentPage"
></app-data-list>
