import { Subscription } from 'rxjs';
import { IDataListColumn, IDataListOrderBy } from './data-list.component';
import { IStoreData } from 'src/app/interfaces/store-data.interface';
import { IFindCondition } from 'src/app/services/firestore/firestore.service';

export class DataList<T extends IStoreData> {
  currentPage: number = 0;
  loadingData: boolean = false;

  currentPagination: string = 'list';
  columns: IDataListColumn[] = [];
  orderBy: IDataListOrderBy | null = { field: 'created', direction: 'asc' };
  conditions: IFindCondition[] = [];
  itemsPerPage: number = 20;

  dataService: any | null = null;
  data: T[] = [];

  protected subscriptionsPagination = new Subscription();

  setDataService(service: any): void {
    this.dataService = service;
  }

  destroyPagination(): void {
    this.subscriptionsPagination.unsubscribe();
    this.subscriptionsPagination = new Subscription();
  }

  resetAndLoad(): void {
    this.resetData();
    this.loadData();
  }

  resetData(): void {
    this.currentPage = 0;

    if (this.dataService) {
      this.dataService.resetPagination(this.currentPagination);
    }
  }

  loadData(direction: 'prev' | 'next' = 'next'): void {
    if (this.dataService) {
      this.loadingData = true;

      this.destroyPagination();

      this.subscriptionsPagination.add(
        this.dataService
          .getPaginatedResult(
            this.currentPagination,
            this.conditions,
            this.orderBy,
            this.itemsPerPage,
            direction
          )
          .subscribe((data: T[]) => {
            this.data = data;

            this.afterLoadData();

            this.loadingData = false;
          })
      );
    }
  }

  afterLoadData(): void {}

  updatedOrderBy(orderBy: IDataListOrderBy): void {
    this.orderBy = Object.assign({}, orderBy);

    this.resetAndLoad();
  }

  prevPage(): void {
    this.currentPage--;
    this.loadData('prev');
  }

  nextPage(): void {
    this.currentPage++;
    this.loadData('next');
  }

  rowCls(item: T): string {
    return '';
  }
}
