import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { getDefaultCurrency, getEnumCurrencySymbol } from 'src/app/enums/currency.enum';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IEmployeeBonusRule } from 'src/app/interfaces/employee-bonus-rule.interface';
import { Subscription } from 'rxjs';
import { EmployeeBonusRulesService } from 'src/app/services/employee-bonus-rules/employee-bonus-rules.service';
import { addMonths, addYears, endOfYear, format, getYear, isAfter, startOfMonth } from 'date-fns';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { fr } from 'date-fns/locale';

@Component({
  selector: 'app-employee-bonus-rule-edit',
  templateUrl: './employee-bonus-rule-edit.component.html',
  styleUrls: ['./employee-bonus-rule-edit.component.scss']
})
export class EmployeeBonusRuleEditComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getDefaultCurrency = getDefaultCurrency;
  getEnumCurrencySymbol = getEnumCurrencySymbol;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  employeeBonusRule: IEmployeeBonusRule;
  employeeBonusRuleId: string;

  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private employeeBonusRulesService: EmployeeBonusRulesService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.generateFilterPeriodOptions();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      enquiryTypes: [[], [Validators.required]],
      minimumNbEnquiries: [1, [Validators.required]],
      minimumMarginHT: [0, [Validators.required]],
      amountBonus: [0, [Validators.required]],
      dateStart: [format(new Date(), 'yyyy-MM') + '-01', [Validators.required]],
      dateEnd: [null],
      hasDateEnd: [false, [Validators.required]]
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.employeeBonusRuleId = this.activatedRoute.snapshot.paramMap.get('employeeBonusRuleId');

      if (this.employeeBonusRuleId) {
        await this.aclService.checkAclAccess(EnumAcl.employeeBonusRulesEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.employeeBonusRulesAdd);

        this.toggleAllEnquiryType();

        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEnquiryTypes(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = getYear(new Date());

    const maxDec: number = currentYear - 2021;
    const beginningOfMonth: Date = startOfMonth(new Date());
    const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

    for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
      let title: string = format(m, 'MMMM yy', {
        locale: fr
      });
      title = title.charAt(0).toUpperCase() + title.slice(1);
      this.filtersByPeriod.push({
        title: title,
        value: format(m, 'yyyy-MM-01')
      });
    }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadEmployeeBonusRule();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadEmployeeBonusRule(): void {
    this.subscriptions.add(
      this.employeeBonusRulesService
        .getFromId(this.employeeBonusRuleId)
        .subscribe((employeeBonusRule: IEmployeeBonusRule) => {
          this.employeeBonusRule = employeeBonusRule;

          this.setValue();
        })
    );
  }

  setValue(): void {
    if (this.form && this.employeeBonusRule) {
      for (const field in this.form.value) {
        if (typeof this.employeeBonusRule[field] !== 'undefined') {
          switch (field) {
            case 'dateStart':
            case 'dateEnd':
              if (this.employeeBonusRule[field]) {
                this.form
                  .get(field)
                  .setValue(format(this.employeeBonusRule[field], 'yyyy-MM') + '-01');
              }
              break;
            default:
              this.form.get(field).setValue(this.employeeBonusRule[field]);

              break;
          }
        }
      }

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      for (const field of ['dateStart', 'dateEnd']) {
        if (data[field]) {
          data[field] = new Date(data[field] + ' 00:00:00');
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.employeeBonusRuleId) {
        data.id = this.employeeBonusRuleId;
        promise = () => this.employeeBonusRulesService.update(data);
      } else {
        promise = () => this.employeeBonusRulesService.create(data);
      }

      promise()
        .then(async id => {
          if (!this.employeeBonusRuleId) {
            this.employeeBonusRuleId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/employee-bonus-rules/' + this.employeeBonusRuleId]);
  }

  updatedHasDateEnd(): void {
    if (!this.form.value.hasDateEnd) {
      this.form.get('dateEnd').setValue(null);
    } else if (!this.form.value.dateEnd) {
      this.form.get('dateEnd').setValue(format(new Date(), 'yyyy-MM') + '-01');
    }

    this.updateDateEndValidators();
  }

  toggleEnquiryType(enquiryType: EnumEnquiryType): void {
    const values: EnumEnquiryType[] = [...this.form.value.enquiryTypes];
    const index: number = values.indexOf(enquiryType);

    if (index === -1) {
      values.push(enquiryType);
    } else {
      values.splice(index, 1);
    }

    this.form.get('enquiryTypes').setValue(values);
    this.form.get('enquiryTypes').updateValueAndValidity();
  }

  toggleAllEnquiryType(): void {
    const values: EnumEnquiryType[] = [];

    if (this.form.value.enquiryTypes.length !== this.getEnquiryTypes().length) {
      for (const enquiryType of this.getEnquiryTypes()) {
        values.push(enquiryType);
      }
    }

    this.form.get('enquiryTypes').setValue(values);
    this.form.get('enquiryTypes').updateValueAndValidity();
  }

  updateDateEndValidators(): void {
    if (this.form.value.hasDateEnd) {
      this.form.get('dateEnd').setValidators(Validators.required);
    } else {
      this.form.get('dateEnd').clearValidators();
    }

    this.form.get('dateEnd').updateValueAndValidity();
  }
}
