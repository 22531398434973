import { Component, OnInit } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';

import { RemoteService } from '../../../services/remote.service';
import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IAircraft } from 'src/app/interfaces/aircraft.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';

@Component({
  selector: 'app-list-aircrafts',
  templateUrl: './list-aircrafts.component.html',
  styleUrls: ['./list-aircrafts.component.scss']
})
export class ListAircraftsComponent implements OnInit {
  isLogged: boolean = false;
  currentPagination: string = 'aircrafts-list';
  aircraftModelsObj: { [id: string]: IAircraftModel } = {};
  aircraftModels: IAircraftModel[] = [];
  filters: {
    aircraftModelId: string;
    airlineId: string;
  } = {
    aircraftModelId: '',
    airlineId: ''
  };
  isSearchListing: boolean = false;
  searchedAircrafts: IAircraft[] = [];
  keyUp = new Subject<string>();
  query: string = '';

  EnumAcl = EnumAcl;

  private subscription: Subscription;

  constructor(
    public paginationService: PaginationService,
    public remoteService: RemoteService,
    private aclService: AclService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.remoteService.aircraftModelsObservable.subscribe((aircraftModels: IAircraftModel[]) => {
      if (aircraftModels) {
        this.aircraftModels = aircraftModels;

        for (const aircraftModel of aircraftModels) {
          this.aircraftModelsObj[aircraftModel.id] = aircraftModel;
        }
      }
    });

    this.subscription = this.keyUp
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        mergeMap(search => of(search).pipe(delay(500)))
      )
      .subscribe((value: string) => {
        this.query = value;

        if (this.query == '') {
          this.fetchData();
        } else {
          this.search();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.aircraftsList);

    this.loadData();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      // this.fetchData()

      await this.remoteService.loadAircraftModels();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  async fetchData(): Promise<void> {
    this.paginationService.pagination[this.currentPagination].conditions = [];

    if (this.filters.aircraftModelId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'aircraftModelId',
        operator: '==',
        value: this.filters.aircraftModelId
      });
    }

    if (this.filters.airlineId) {
      this.paginationService.pagination[this.currentPagination].conditions.push({
        field: 'airlineId',
        operator: '==',
        value: this.filters.airlineId
      });
    }

    if (this.paginationService.pagination[this.currentPagination].conditions.length) {
      await this.paginationService.fetchData(this.currentPagination);
    }

    this.search();
  }

  setValueToFormControl($event: {
    fields: Array<{
      name: string;
      value: string;
    }>;
  }): void {
    for (let field of $event.fields) {
      if (field.name === 'airlineId') {
        this.filters[field.name] = field.value;
      }
    }

    this.fetchData();
  }

  async search(): Promise<void> {
    if (this.query.length) {
      this.isSearchListing = true;

      this.paginationService.pagination[this.currentPagination].loading = false;

      this.searchedAircrafts = await this.remoteService.getAllAircraftsOfHomebase(this.query);
    } else {
      this.isSearchListing = false;
      this.searchedAircrafts = [];
    }
  }
}
