import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { DataList } from '../data-list/data-list.class';
import {
  EnumDataListColumnAlign,
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from '../data-list/data-list.component';
import { Subscription } from 'rxjs';
import { IUser } from 'src/app/interfaces/user.interface';
import { RemoteService } from 'src/app/services/remote.service';
import { AircraftCompiledService } from 'src/app/services/aircraft-compiled/aircraft-compiled.service';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IAircraftModel } from 'src/app/interfaces/aircraft-model.interface';
import { AircraftModelService } from 'src/app/services/aircraft-models/aircraft-models.service';
import { EnumEnquiryTarget, getEnquiryTargetLabel } from 'src/app/enums/enquiry-target.enum';

@Component({
  selector: 'app-aircrafts-compiled-list-for-airline',
  templateUrl: './aircrafts-compiled-list-for-airline.component.html',
  styleUrls: ['./aircrafts-compiled-list-for-airline.component.scss']
})
export class AircraftsCompiledListForAirlineComponent
  extends DataList<IAircraftCompiled>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() airlineId: string | null = null;
  @Input() isCargoOnly: boolean | undefined = undefined;

  @Output() openItem: EventEmitter<IAircraftCompiled> = new EventEmitter();

  usersObj: { [id: string]: IUser } = {};

  currentPagination: string = 'airline-aircrafts-compiled-list';
  columns: IDataListColumn[] = [];

  orderBy: IDataListOrderBy | null = { field: 'model', direction: 'asc' };

  isLogged: boolean = false;

  aircraftModelsObj: { [id: string]: IAircraftModel | null } = {};

  private subscriptions = new Subscription();

  constructor(
    private aircraftCompiledService: AircraftCompiledService,
    private remoteService: RemoteService,
    private aircraftModelService: AircraftModelService
  ) {
    super();

    this.setDataService(this.aircraftCompiledService);
  }

  ngOnInit(): void {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.refreshColumns();
    this.updatedAirlineId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['airlineId'] && !changes['airlineId'].isFirstChange()) {
      this.updatedAirlineId();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updatedAirlineId(): void {
    if (this.isLogged) {
      this.conditions = [];

      if (this.isCargoOnly !== undefined) {
        this.conditions.push({ field: 'isCargo', operator: '==', value: this.isCargoOnly });
      }

      if (this.airlineId) {
        this.conditions.push({
          field: 'airlineId',
          operator: '==',
          value: this.airlineId
        });
      }

      this.resetAndLoad();

      this.refreshColumns();
    } else {
      setTimeout(() => {
        this.updatedAirlineId();
      }, 500);
    }
  }

  afterLoadData(): void {
    for (const item of this.data) {
      if (item.aircraftModelId) {
        this.loadAircraftModel(item.aircraftModelId);
      }
    }
  }

  loadAircraftModel(aircraftModelId: string): void {
    if (typeof this.aircraftModelsObj[aircraftModelId] === 'undefined') {
      this.aircraftModelsObj[aircraftModelId] = null;
    }

    this.subscriptions.add(
      this.aircraftModelService
        .getFromId(aircraftModelId)
        .subscribe((aircraftModel: IAircraftModel | null) => {
          this.aircraftModelsObj[aircraftModelId] = aircraftModel;
        })
    );
  }

  refreshColumns(): void {
    this.columns = [];

    if (this.isCargoOnly === undefined) {
      this.columns.push({
        title: 'Compagnie aérienne',
        field: 'airlineTitle'
      });
      this.columns.push({
        title: 'Pays',
        field: 'airlineCountryTitle'
      });
      this.columns.push({
        title: 'Appareil',
        field: 'type'
      });
    } else {
      this.columns.push({
        title: 'Modèle',
        field: 'aircraftModelId',
        type: EnumDataListColumnType.function,
        resolve: (item: IAircraftCompiled): string => {
          let html: string = '';

          if (item.aircraftModelId && this.aircraftModelsObj[item.aircraftModelId]) {
            html += this.aircraftModelsObj[item.aircraftModelId].title;
          } else if (item.modelTitle) {
            html += item.modelTitle;
          } else {
            html += item.aircraftModelId;
          }

          return html;
        }
      });
    }

    this.columns.push({
      title: 'N°',
      field: 'count',
      align: EnumDataListColumnAlign.center
    });
    if (this.isCargoOnly) {
      this.columns.push({
        title: 'Poids (kg)',
        field: 'weight',
        align: EnumDataListColumnAlign.center
      });
      this.columns.push({
        title: 'Volume (m<sup>3</sup>)',
        field: 'volume',
        align: EnumDataListColumnAlign.center
      });
    } else {
      this.columns.push({
        title: 'Capacité',
        field: 'seatTotal',
        align: EnumDataListColumnAlign.center
      });
      this.columns.push({
        title: 'Classes',
        field: 'classes',
        align: EnumDataListColumnAlign.center,
        type: EnumDataListColumnType.function,
        resolve: (item: IAircraftCompiled): string => {
          return item.classes?.join('/');
        }
      });
    }
    this.columns.push({
      title: 'Cible',
      field: 'target',
      align: EnumDataListColumnAlign.center,
      type: EnumDataListColumnType.function,
      resolve: (item: IAircraftCompiled): string => {
        return getEnquiryTargetLabel(item.target as EnumEnquiryTarget);
      }
    });
    this.columns.push({
      title: 'Âge moyen (an)',
      field: 'ageAverage',
      align: EnumDataListColumnAlign.center
    });
  }
}
