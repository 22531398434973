import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AircraftCompiledFirestoreService } from './aircraft-compiled-firestore.service';
import { IAircraftCompiled } from 'src/app/interfaces/aircraft-compiled.interface';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class AircraftCompiledService {
  constructor(private aircraftCompiledFirestoreService: AircraftCompiledFirestoreService) {}

  create(aircraftCompiled: IAircraftCompiled): Promise<string> {
    return this.aircraftCompiledFirestoreService.create(aircraftCompiled);
  }

  update(aircraftCompiled: IAircraftCompiled): Promise<string> {
    return this.aircraftCompiledFirestoreService.update(aircraftCompiled);
  }

  delete(id: string): Promise<void> {
    return this.aircraftCompiledFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IAircraftCompiled> {
    return this.aircraftCompiledFirestoreService.docValue$(id);
  }

  getAll(): Observable<IAircraftCompiled[]> {
    return this.aircraftCompiledFirestoreService.collectionValues$();
  }

  getAllForAirline(airlineId): Observable<IAircraftCompiled[]> {
    return this.aircraftCompiledFirestoreService.collectionValues$(ref =>
      ref.where('airlineId', '==', airlineId)
    );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.aircraftCompiledFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IAircraftCompiled[]> {
    return this.aircraftCompiledFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
