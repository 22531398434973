<div class="btn-toolbar justify-content-center mb-4">
    <div class="btn-group">
        <a
            [routerLink]="'/admin/fbos/airport/' + airportId"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.fbosList)"
            >FBOs</a
        >
        <a
            [routerLink]="'/admin/handlings/airport/' + airportId"
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(EnumAcl.handlingsList)"
            >Handlings</a
        >
        <a
            [routerLink]="
                isLieuDit
                    ? '/admin/lieux-dits/' + airportId + '/edit'
                    : '/admin/airports/' + airportId + '/edit'
            "
            class="btn btn-primary mr-4"
            *ngIf="hasAclAccess(isLieuDit ? EnumAcl.lieuxDitsEdit : EnumAcl.airportsEdit)"
            >Éditer</a
        >
        <button
            type="button"
            (click)="delete()"
            class="btn btn-danger ml-4"
            *ngIf="hasAclAccess(isLieuDit ? EnumAcl.lieuxDitsDelete : EnumAcl.airportsDelete)"
        >
            Supprimer
        </button>
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-lg-8">
        <div class="card mb-4">
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && airport">
                <h3 [innerHtml]="airport.title" class="text-center"></h3>
                <dl class="row mb-0">
                    <dt class="col-md-6">Code IATA</dt>
                    <dd class="col-md-6">{{ airport.iataCode }}</dd>
                    <dt class="col-md-6">Continent</dt>
                    <dd class="col-md-6">{{ getContinentLabel(airport.continentCode) }}</dd>
                    <dt class="col-md-6">Pays</dt>
                    <dd class="col-md-6">{{ getCountryLabel(airport.countryCode) }}</dd>
                    <dt class="col-md-6">Latitude</dt>
                    <dd class="col-md-6">{{ airport.latitude }}</dd>
                    <dt class="col-md-6">Longitude</dt>
                    <dd class="col-md-6">{{ airport.longitude }}</dd>
                    <dt class="col-md-6">Ville</dt>
                    <dd class="col-md-6">{{ airport.city }}</dd>
                    <dt class="col-md-6">Distance de la ville</dt>
                    <dd class="col-md-6">{{ airport.distancefromCity }}</dd>
                    <dt class="col-md-6">Catégorie incendie</dt>
                    <dd class="col-md-6">{{ airport.fireCategory }}</dd>
                    <dt class="col-md-6">Longueur de piste maximale</dt>
                    <dd class="col-md-6">{{ airport.longestHardSurfaceRunwayInFt }}</dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-lg-4">
        <div class="card mb-4">
            <div class="card-header text-center">Simulateur de prix</div>
            <div class="card-body" *ngIf="loading">
                <h3><div class="placeholder w-100"></div></h3>
            </div>
            <div class="card-body" *ngIf="!loading && airport">
                <dl class="row mb-0">
                    <dt class="col-md-8">Visible dans le simulateur de prix</dt>
                    <dd class="col-md-4 text-end">
                        {{ airport.isInSimulateurPrix ? "Oui" : "Non" }}
                    </dd>
                    <dt class="col-md-8" *ngIf="airport.isInSimulateurPrix">
                        Visible sur la carte
                    </dt>
                    <dd class="col-md-4 text-end" *ngIf="airport.isInSimulateurPrix">
                        {{ airport.visibleInSimulateurPrixMap ? "Oui" : "Non" }}
                    </dd>
                    <dt class="col-md-8" *ngIf="airport.isInSimulateurPrix">Est un aéroport JET</dt>
                    <dd class="col-md-4 text-end" *ngIf="airport.isInSimulateurPrix">
                        {{ airport.isJetAirport ? "Oui" : "Non" }}
                    </dd>
                    <dt class="col-md-8" *ngIf="airport.isInSimulateurPrix">Est très sollicité</dt>
                    <dd class="col-md-4 text-end" *ngIf="airport.isInSimulateurPrix">
                        {{ airport.isHighDemand ? "Oui" : "Non" }}
                    </dd>

                    <dt class="col-12">Information dans le simulateur</dt>
                    <dd class="col-12">
                        <fieldset *ngIf="airport.simulateurInformation">
                            <quill-view
                                [content]="airport.simulateurInformation"
                                format="html"
                                theme="snow"
                            ></quill-view>
                        </fieldset>
                        <span *ngIf="!airport.simulateurInformation">Aucune information</span>
                    </dd>
                </dl>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header">Transferts d'hélicoptère</div>
            <ul class="list-group list-group-flush">
                <li
                    class="list-group-item text-bg-warning text-center"
                    *ngIf="!airport?.helicopterTransfers?.length"
                >
                    Aucun transfert
                </li>
                <li
                    class="list-group-item"
                    *ngFor="let helicopterTransfer of airport?.helicopterTransfers"
                >
                    <dl>
                        <dt>Aéroport</dt>
                        <dd class="placeholder-glow">
                            <a
                                *ngIf="airportsObj[helicopterTransfer.airportDestinationId]"
                                [routerLink]="
                                    '/admin/airports/' + helicopterTransfer.airportDestinationId
                                "
                                [innerText]="
                                    airportsObj[helicopterTransfer.airportDestinationId].title
                                "
                                target="_blank"
                            ></a>
                            <div
                                class="placeholder w-50"
                                *ngIf="!airportsObj[helicopterTransfer.airportDestinationId]"
                            ></div>
                        </dd>
                        <dt>Temps</dt>
                        <dd [innerText]="helicopterTransfer.timeInMin + ' mins'"></dd>
                        <dt>Prix transfert</dt>
                        <dd [innerText]="helicopterTransfer.price + '€'"></dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</div>
