import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import firebase from 'firebase/compat/app';
import { EmployeeBonusRulesFirestoreService } from './employee-bonus-rules-firestore.service';
import { IEmployeeBonusRule } from 'src/app/interfaces/employee-bonus-rule.interface';
import { isAfter, isSameDay } from 'date-fns';
import { IFindCondition } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeBonusRulesService {
  constructor(private employeeBonusRulesFirestoreService: EmployeeBonusRulesFirestoreService) {}

  create(stats: IEmployeeBonusRule): Promise<string> {
    return this.employeeBonusRulesFirestoreService.create(stats);
  }

  update(stats: IEmployeeBonusRule): Promise<string> {
    return this.employeeBonusRulesFirestoreService.update(stats);
  }

  delete(id: string): Promise<void> {
    return this.employeeBonusRulesFirestoreService.delete(id);
  }

  getFromId(id: string): Observable<IEmployeeBonusRule> {
    return this.employeeBonusRulesFirestoreService.docValue$(id);
  }

  getAll(): Observable<IEmployeeBonusRule[]> {
    return this.employeeBonusRulesFirestoreService.collectionValues$();
  }

  getAllInMonth(date: Date): Observable<IEmployeeBonusRule[]> {
    return this.employeeBonusRulesFirestoreService
      .collectionValues$(ref => ref.where('dateStart', '<=', date))
      .pipe(
        map(results => {
          const resultsInMonth: IEmployeeBonusRule[] = [];

          for (const item of results) {
            if (!item.hasDateEnd || isAfter(item.dateEnd, date) || isSameDay(item.dateEnd, date)) {
              resultsInMonth.push(item);
            }
          }

          return resultsInMonth;
        })
      );
  }

  resetPagination(paginationName: string = 'list'): void {
    this.employeeBonusRulesFirestoreService.resetPaginate(paginationName);
  }

  getPaginatedResult(
    paginationName: string = 'list',
    conditions: IFindCondition[] = [],
    orderBy: {
      field: string;
      direction: firebase.firestore.OrderByDirection;
    } = {
      field: 'created',
      direction: 'desc'
    },
    itemsPerPage: number = 20,
    direction: 'prev' | 'next' = 'next'
  ): Observable<IEmployeeBonusRule[]> {
    return this.employeeBonusRulesFirestoreService.paginate(
      conditions,
      orderBy,
      itemsPerPage,
      paginationName,
      direction
    );
  }
}
