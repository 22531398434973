<form (submit)="submitForm()" [formGroup]="form" (keydown.enter)="submitForm()" class="main-form">
    <div class="card-with-side-tabs">
        <div class="card-sidebar">
            <ul class="nav nav-pills flex-column" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active"
                        id="general-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#general-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="general-tab-pane"
                        aria-selected="true"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="faCogs"></fa-icon>
                        </span>
                        Informations générales
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="seat-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#seat-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="seat-tab-pane"
                        aria-selected="false"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="faUsersLine"></fa-icon>
                        </span>
                        Configuration sièges
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="characteristic-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#characteristic-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="characteristic-tab-pane"
                        aria-selected="false"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="faListCheck"></fa-icon>
                        </span>
                        Caractéristiques appareil
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="history-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#history-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="history-tab-pane"
                        aria-selected="false"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="faClockRotateLeft"></fa-icon>
                        </span>
                        Opérateurs de l'appareil
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="images-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#images-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="images-tab-pane"
                        aria-selected="false"
                    >
                        <span class="nav-indicator">
                            <fa-icon [icon]="faImage"></fa-icon>
                        </span>
                        Images
                    </button>
                </li>
            </ul>
        </div>
        <div class="tab-content-container">
            <div class="tab-content">
                <div
                    class="tab-pane fade show active"
                    id="general-tab-pane"
                    role="tabpanel"
                    aria-labelledby="general-tab"
                    tabindex="0"
                >
                    <div class="card mb-4">
                        <div class="card-header">Informations générales</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="serialNumber"
                                            >Manufacturer's Serial Number</label
                                        >
                                        <input
                                            class="form-control"
                                            id="serialNumber"
                                            formControlName="serialNumber"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('serialNumber').touched) &&
                                                form.get('serialNumber').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('serialNumber').touched) &&
                                                    form.get('serialNumber').errors &&
                                                    form.get('serialNumber').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="status">Statut</label>
                                        <select
                                            class="form-select"
                                            id="status"
                                            formControlName="status"
                                            required
                                            [class.is-invalid]="
                                                (form.touched || form.get('status').touched) &&
                                                form.get('status').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option
                                                [value]="status"
                                                *ngFor="let status of getAircraftStatuses()"
                                                [innerHtml]="getAircraftStatusLabel(status)"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || form.get('status').touched) &&
                                                    form.get('status').errors &&
                                                    form.get('status').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <button
                                            type="button"
                                            class="btn btn-icon float-end"
                                            (click)="addAircraftModel()"
                                            [disabled]="form.disabled"
                                        >
                                            <fa-icon [icon]="faPlusCircle"></fa-icon>
                                        </button>
                                        <label for="aircraftModelId">Modèle</label>
                                        <select
                                            class="form-select"
                                            id="aircraftModelId"
                                            formControlName="aircraftModelId"
                                            required
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('aircraftModelId').touched) &&
                                                form.get('aircraftModelId').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                            (change)="updateModel()"
                                        >
                                            <option
                                                [value]="aircraftModel.id"
                                                *ngFor="let aircraftModel of aircraftModels"
                                                [innerHtml]="aircraftModel.title"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('aircraftModelId').touched) &&
                                                    form.get('aircraftModelId').errors &&
                                                    form.get('aircraftModelId').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="registration">Dernière Immatriculation</label>
                                        <input
                                            class="form-control"
                                            id="registration"
                                            formControlName="registration"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('registration').touched) &&
                                                form.get('registration').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('registration').touched) &&
                                                    form.get('registration').errors &&
                                                    form.get('registration').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <airline-input
                                        [disabled]="form.disabled"
                                        (setValueToFormControl)="setValueToFormControl($event)"
                                        label="Sélection Opérateur"
                                        [airlineId]="form.value.airlineId"
                                        [airlineTitle]="form.value.airlineTitle"
                                        [airlineCountryCode]="form.value.airlineCountryCode"
                                        [airlineContinentCode]="form.value.airlineContinentCode"
                                        inputNameAirlineId="airlineId"
                                        inputNameAirlineTitle="airlineTitle"
                                        inputNameAirlineCountryCode="airlineCountryCode"
                                        inputNameAirlineContinentCode="airlineContinentCode"
                                        inputNameAirlineCountryTitle="airlineCountryTitle"
                                        inputNameAirlineContinentTitle="airlineContinentTitle"
                                    ></airline-input>

                                    <div
                                        class="alert alert-danger"
                                        *ngIf="
                                            (form.touched || form.get('airlineId').touched) &&
                                            form.get('airlineId').errors &&
                                            form.get('airlineId').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="firstFlightDate">Date premier vol</label>
                                        <input
                                            class="form-control"
                                            id="firstFlightDate"
                                            formControlName="firstFlightDate"
                                            type="date"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('firstFlightDate').touched) &&
                                                form.get('firstFlightDate').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('firstFlightDate').touched) &&
                                                    form.get('firstFlightDate').errors &&
                                                    form.get('firstFlightDate').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="testRegistration"
                                            >Immatriculation d'essai</label
                                        >
                                        <input
                                            class="form-control"
                                            id="testRegistration"
                                            formControlName="testRegistration"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('testRegistration').touched) &&
                                                form.get('testRegistration').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('testRegistration').touched) &&
                                                    form.get('testRegistration').errors &&
                                                    form.get('testRegistration').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="engines">Moteurs</label>
                                        <input
                                            class="form-control"
                                            id="engines"
                                            formControlName="engines"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched || form.get('engines').touched) &&
                                                form.get('engines').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || form.get('engines').touched) &&
                                                    form.get('engines').errors &&
                                                    form.get('engines').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="url">Airfleets URL</label>
                                        <input
                                            class="form-control"
                                            id="url"
                                            formControlName="url"
                                            type="url"
                                            [class.is-invalid]="
                                                (form.touched || form.get('url').touched) &&
                                                form.get('url').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || form.get('url').touched) &&
                                                    form.get('url').errors &&
                                                    form.get('url').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="homebase">Aéroport d'attache</label>
                                        <input
                                            class="form-control"
                                            id="homebase"
                                            formControlName="homebase"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched || form.get('homebase').touched) &&
                                                form.get('homebase').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('homebase').touched) &&
                                                    form.get('homebase').errors &&
                                                    form.get('homebase').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-check mb-2">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-cargo"
                                            formControlName="isCargo"
                                            (change)="updateWeightAndVolume()"
                                        />
                                        <label class="form-check-label" for="is-cargo"
                                            >Peut être utilisé pour le cargo</label
                                        >
                                    </div>
                                </div>
                            </div>

                            <!-- <div *ngIf="form.value.isCargo">
            <div class="form-group">
              <label for="weight">Poids (kg)</label>
              <input class="form-control" id="weight" formControlName="weight" type="number" min="0" [class.is-invalid]="(form.touched || form.get('weight').touched) && form.get('weight').status === 'INVALID' ? 'danger' : ''">

              <div class="invalid-tooltip">
                <div *ngIf="(form.touched || form.get('weight').touched) && form.get('weight').errors && form.get('weight').errors['required']">Ce champ est obligatoire.</div>
              </div>
            </div>
            <div class="form-group">
              <label for="volume">Volume (m<sup>3</sup>)</label>
              <input class="form-control" id="volume" formControlName="volume" type="number" min="0" [class.is-invalid]="(form.touched || form.get('volume').touched) && form.get('volume').status === 'INVALID' ? 'danger' : ''">

              <div class="invalid-tooltip">
                <div *ngIf="(form.touched || form.get('volume').touched) && form.get('volume').errors && form.get('volume').errors['required']">Ce champ est obligatoire.</div>
              </div>
            </div>
          </div> -->
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="seat-tab-pane"
                    role="tabpanel"
                    aria-labelledby="seat-tab"
                    tabindex="0"
                >
                    <div class="card mb-4">
                        <div class="card-header">Configuration sièges</div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="seatFirst">Classe première</label>
                                <input
                                    class="form-control"
                                    id="seatFirst"
                                    formControlName="seatFirst"
                                    type="number"
                                    min="0"
                                    [class.is-invalid]="
                                        (form.touched || form.get('seatFirst').touched) &&
                                        form.get('seatFirst').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.get('seatFirst').touched) &&
                                            form.get('seatFirst').errors &&
                                            form.get('seatFirst').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="seatBusiness">Classe affaire</label>
                                <input
                                    class="form-control"
                                    id="seatBusiness"
                                    formControlName="seatBusiness"
                                    type="number"
                                    min="0"
                                    [class.is-invalid]="
                                        (form.touched || form.get('seatBusiness').touched) &&
                                        form.get('seatBusiness').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.get('seatBusiness').touched) &&
                                            form.get('seatBusiness').errors &&
                                            form.get('seatBusiness').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="seatPremiumEconomy">Classe économique premium</label>
                                <input
                                    class="form-control"
                                    id="seatPremiumEconomy"
                                    formControlName="seatPremiumEconomy"
                                    type="number"
                                    min="0"
                                    [class.is-invalid]="
                                        (form.touched || form.get('seatPremiumEconomy').touched) &&
                                        form.get('seatPremiumEconomy').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched ||
                                                form.get('seatPremiumEconomy').touched) &&
                                            form.get('seatPremiumEconomy').errors &&
                                            form.get('seatPremiumEconomy').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="seatEconomy">Classe économique</label>
                                <input
                                    class="form-control"
                                    id="seatEconomy"
                                    formControlName="seatEconomy"
                                    type="number"
                                    min="0"
                                    [class.is-invalid]="
                                        (form.touched || form.get('seatEconomy').touched) &&
                                        form.get('seatEconomy').status === 'INVALID'
                                            ? 'danger'
                                            : ''
                                    "
                                />

                                <div class="invalid-tooltip">
                                    <div
                                        *ngIf="
                                            (form.touched || form.get('seatEconomy').touched) &&
                                            form.get('seatEconomy').errors &&
                                            form.get('seatEconomy').errors['required']
                                        "
                                    >
                                        Ce champ est obligatoire.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="characteristic-tab-pane"
                    role="tabpanel"
                    aria-labelledby="characteristic-tab"
                    tabindex="0"
                >
                    <div class="card mb-4">
                        <div class="card-header">Caractéristiques appareil</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="dateOfManufacture">Date de fabrication</label>
                                        <input
                                            class="form-control"
                                            id="dateOfManufacture"
                                            formControlName="dateOfManufacture"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('dateOfManufacture').touched) &&
                                                form.get('dateOfManufacture').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('dateOfManufacture').touched) &&
                                                    form.get('dateOfManufacture').errors &&
                                                    form.get('dateOfManufacture').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="dateOfRefurbishment"
                                            >Date de remise à neuf</label
                                        >
                                        <input
                                            class="form-control"
                                            id="dateOfRefurbishment"
                                            formControlName="dateOfRefurbishment"
                                            type="date"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('dateOfRefurbishment').touched) &&
                                                form.get('dateOfRefurbishment').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('dateOfRefurbishment').touched) &&
                                                    form.get('dateOfRefurbishment').errors &&
                                                    form.get('dateOfRefurbishment').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="numberOfCrewMembers"
                                            >Nombre de membres d'équipage</label
                                        >
                                        <input
                                            class="form-control"
                                            id="numberOfCrewMembers"
                                            formControlName="numberOfCrewMembers"
                                            type="number"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('numberOfCrewMembers').touched) &&
                                                form.get('numberOfCrewMembers').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('numberOfCrewMembers').touched) &&
                                                    form.get('numberOfCrewMembers').errors &&
                                                    form.get('numberOfCrewMembers').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="hasStewardess">Hotesse</label>
                                        <select
                                            class="form-select"
                                            id="hasStewardess"
                                            formControlName="hasStewardess"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('hasStewardess').touched) &&
                                                form.get('hasStewardess').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option
                                                *ngFor="
                                                    let option of getAircraftHasStewardessOptions()
                                                "
                                                [value]="option"
                                                [innerText]="getAircraftHasStewardessLabel(option)"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('hasStewardess').touched) &&
                                                    form.get('hasStewardess').errors &&
                                                    form.get('hasStewardess').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="isSmokingAllowed">Fumeur</label>
                                        <select
                                            class="form-select"
                                            id="isSmokingAllowed"
                                            formControlName="isSmokingAllowed"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('isSmokingAllowed').touched) &&
                                                form.get('isSmokingAllowed').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option [ngValue]="true">Oui</option>
                                            <option [ngValue]="false">Non</option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('isSmokingAllowed').touched) &&
                                                    form.get('isSmokingAllowed').errors &&
                                                    form.get('isSmokingAllowed').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="sleepingConfiguration"
                                            >Sleeping Configuration</label
                                        >
                                        <input
                                            class="form-control"
                                            id="sleepingConfiguration"
                                            formControlName="sleepingConfiguration"
                                            type="text"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('sleepingConfiguration').touched) &&
                                                form.get('sleepingConfiguration').status ==
                                                    'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        />

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('sleepingConfiguration')
                                                            .touched) &&
                                                    form.get('sleepingConfiguration').errors &&
                                                    form.get('sleepingConfiguration').errors[
                                                        'required'
                                                    ]
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="hasToilet">Toilette</label>
                                        <select
                                            class="form-select"
                                            id="hasToilet"
                                            formControlName="hasToilet"
                                            [class.is-invalid]="
                                                (form.touched || form.get('hasToilet').touched) &&
                                                form.get('hasToilet').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option [ngValue]="true">Oui</option>
                                            <option [ngValue]="false">Non</option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('hasToilet').touched) &&
                                                    form.get('hasToilet').errors &&
                                                    form.get('hasToilet').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="hasHotCatering">Catering Chaud</label>
                                        <select
                                            class="form-select"
                                            id="hasHotCatering"
                                            formControlName="hasHotCatering"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('hasHotCatering').touched) &&
                                                form.get('hasHotCatering').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option
                                                *ngFor="
                                                    let option of getAircraftHasHotCateringOptions()
                                                "
                                                [value]="option"
                                                [innerText]="getAircraftHasHotCateringLabel(option)"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('hasHotCatering').touched) &&
                                                    form.get('hasHotCatering').errors &&
                                                    form.get('hasHotCatering').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="arePetsAllowed">Pets</label>
                                        <select
                                            class="form-select"
                                            id="arePetsAllowed"
                                            formControlName="arePetsAllowed"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('arePetsAllowed').touched) &&
                                                form.get('hasToilet').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option [ngValue]="true">Oui</option>
                                            <option [ngValue]="false">Non</option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('arePetsAllowed').touched) &&
                                                    form.get('arePetsAllowed').errors &&
                                                    form.get('arePetsAllowed').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="wifi">Wifi</label>
                                        <select
                                            class="form-select"
                                            id="wifi"
                                            formControlName="wifi"
                                            [class.is-invalid]="
                                                (form.touched || form.get('wifi').touched) &&
                                                form.get('wifi').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option
                                                *ngFor="let option of getAircraftWifiOptions()"
                                                [value]="option"
                                                [innerText]="getAircraftWifiLabel(option)"
                                            ></option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched || form.get('wifi').touched) &&
                                                    form.get('wifi').errors &&
                                                    form.get('wifi').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="hasSatellitePhone">Satellite Phone</label>
                                        <select
                                            class="form-select"
                                            id="hasSatellitePhone"
                                            formControlName="hasSatellitePhone"
                                            [class.is-invalid]="
                                                (form.touched ||
                                                    form.get('hasSatellitePhone').touched) &&
                                                form.get('hasSatellitePhone').status === 'INVALID'
                                                    ? 'danger'
                                                    : ''
                                            "
                                        >
                                            <option [ngValue]="null">-- Choisissez --</option>
                                            <option [ngValue]="true">Oui</option>
                                            <option [ngValue]="false">Non</option>
                                        </select>

                                        <div class="invalid-tooltip">
                                            <div
                                                *ngIf="
                                                    (form.touched ||
                                                        form.get('hasSatellitePhone').touched) &&
                                                    form.get('hasSatellitePhone').errors &&
                                                    form.get('hasSatellitePhone').errors['required']
                                                "
                                            >
                                                Ce champ est obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="history-tab-pane"
                    role="tabpanel"
                    aria-labelledby="history-tab"
                    tabindex="0"
                >
                    <div class="card mb-4">
                        <div class="card-header">Opérateurs de l'appareil</div>
                        <ul class="list-group list-group-flush" formArrayName="history">
                            <li
                                class="list-group-item"
                                [formGroupName]="i"
                                *ngFor="let oneHistory of history.controls; let i = index"
                            >
                                <strong>Historique {{ i + 1 }}</strong>

                                <button
                                    type="button"
                                    (click)="deleteOneHistory(i)"
                                    class="btn btn-danger btn-sm float-end"
                                    [disabled]="form.disabled"
                                >
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </button>

                                <div class="clearfix"></div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="registration">Immatriculation</label>
                                            <input
                                                class="form-control"
                                                id="registration"
                                                formControlName="registration"
                                                type="text"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('registration').touched) &&
                                                    getOneHistory(i).get('registration').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form
                                                                .get('history')
                                                                .get(i.toString())
                                                                .get('registration').touched) &&
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('registration').errors &&
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('registration').errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="deliveryDate">Date de livraison</label>
                                            <input
                                                class="form-control"
                                                id="deliveryDate"
                                                formControlName="deliveryDate"
                                                type="date"
                                                [class.is-invalid]="
                                                    (form.touched ||
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('deliveryDate').touched) &&
                                                    getOneHistory(i).get('deliveryDate').status ==
                                                        'INVALID'
                                                        ? 'danger'
                                                        : ''
                                                "
                                            />

                                            <div class="invalid-tooltip">
                                                <div
                                                    *ngIf="
                                                        (form.touched ||
                                                            form
                                                                .get('history')
                                                                .get(i.toString())
                                                                .get('deliveryDate').touched) &&
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('deliveryDate').errors &&
                                                        form
                                                            .get('history')
                                                            .get(i.toString())
                                                            .get('deliveryDate').errors['required']
                                                    "
                                                >
                                                    Ce champ est obligatoire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <airline-input
                                    [disabled]="form.disabled"
                                    (setValueToFormControl)="setValueToFormControl($event)"
                                    label="Sélection Opérateur"
                                    [airlineId]="getOneHistory(i).value.airlineId"
                                    [airlineTitle]="getOneHistory(i).value.airlineTitle"
                                    [airlineCountryCode]="getOneHistory(i).value.airlineCountryCode"
                                    [airlineContinentCode]="
                                        getOneHistory(i).value.airlineContinentCode
                                    "
                                    [inputNameAirlineId]="'history.' + i + '.airlineId'"
                                    [inputNameAirlineTitle]="'history.' + i + '.airlineTitle'"
                                    [inputNameAirlineCountryCode]="
                                        'history.' + i + '.airlineCountryCode'
                                    "
                                    [inputNameAirlineContinentCode]="
                                        'history.' + i + '.airlineContinentCode'
                                    "
                                    [inputNameAirlineCountryTitle]="
                                        'history.' + i + '.airlineCountryTitle'
                                    "
                                    [inputNameAirlineContinentTitle]="
                                        'history.' + i + '.airlineContinentTitle'
                                    "
                                ></airline-input>

                                <div
                                    class="alert alert-danger"
                                    *ngIf="
                                        (form.touched ||
                                            getOneHistory(i).get('airlineId').touched) &&
                                        getOneHistory(i).get('airlineId').errors &&
                                        getOneHistory(i).get('airlineId').errors['required']
                                    "
                                >
                                    Ce champ est obligatoire.
                                </div>

                                <div class="form-group">
                                    <label for="remark">Remarque</label>

                                    <input
                                        class="form-control"
                                        id="remark"
                                        formControlName="remark"
                                        type="text"
                                        [class.is-invalid]="
                                            (form.touched ||
                                                getOneHistory(i).get('remark').touched) &&
                                            getOneHistory(i).get('remark').status === 'INVALID'
                                                ? 'danger'
                                                : ''
                                        "
                                    />

                                    <div class="invalid-tooltip">
                                        <div
                                            *ngIf="
                                                (form.touched ||
                                                    getOneHistory(i).get('remark').touched) &&
                                                getOneHistory(i).get('remark').errors &&
                                                getOneHistory(i).get('remark').errors['required']
                                            "
                                        >
                                            Ce champ est obligatoire.
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item text-center">
                                <button
                                    type="button"
                                    (click)="addHistory()"
                                    class="btn btn-secondary"
                                    [disabled]="form.disabled"
                                >
                                    + Nouvel historique
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    class="tab-pane fade"
                    id="images-tab-pane"
                    role="tabpanel"
                    aria-labelledby="images-tab"
                    tabindex="0"
                >
                    <div class="card mb-4">
                        <div class="card-header">
                            Images
                            <button
                                type="button"
                                class="btn btn-primary btn-sm float-end"
                                [disabled]="form.disabled"
                                (click)="triggerInputFile()"
                            >
                                + Ajout image
                            </button>
                        </div>
                        <ul class="list-group list-group-flush" formArrayName="imageUrls">
                            <li
                                class="list-group-item"
                                *ngFor="let imageUrl of imageUrls.controls; let i = index"
                            >
                                <div class="row align-items-center">
                                    <div class="col-6 col-md-4 col-xl-3">
                                        <img [src]="imageUrl.value" />
                                    </div>
                                    <div class="col-6 col-md-8 col-xl-9">
                                        <div class="row">
                                            <div class="col-xl-8">
                                                <div class="form-check form-switch">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        [id]="'switch-image-' + i + '-outside'"
                                                        (click)="
                                                            setImageDefaultUrl(i, 'imageOutsideUrl')
                                                        "
                                                        [checked]="
                                                            imageOutsideUrl.value?.includes(
                                                                imageUrl.value
                                                            )
                                                        "
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        [attr.for]="
                                                            'switch-image-' + i + '-outside'
                                                        "
                                                        >Image extérieure par défaut</label
                                                    >
                                                </div>
                                                <div class="form-check form-switch">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        [id]="'switch-image-' + i + '-inside'"
                                                        (click)="
                                                            setImageDefaultUrl(i, 'imageInsideUrl')
                                                        "
                                                        [checked]="
                                                            imageInsideUrl.value?.includes(
                                                                imageUrl.value
                                                            )
                                                        "
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        [attr.for]="'switch-image-' + i + '-inside'"
                                                        >Image intérieure par défaut</label
                                                    >
                                                </div>
                                            </div>
                                            <div class="col-xl-4 text-center">
                                                <button
                                                    type="button"
                                                    class="btn btn-danger btn-sm mt-2"
                                                    (click)="removeImageUrl(i)"
                                                    [disabled]="form.disabled"
                                                >
                                                    <fa-icon [icon]="faTrash"></fa-icon> Supprimer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col">
                    <button
                        type="button"
                        [routerLink]="
                            airlineId
                                ? '/admin/airlines/' + airlineId
                                : aircraftId
                                ? '/admin/aircrafts/' + aircraftId
                                : '/admin/aircrafts'
                        "
                        class="btn btn-secondary"
                        [disabled]="form.disabled"
                    >
                        Annuler
                    </button>
                </div>
                <div class="col text-end">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        [disabled]="form.disabled || !form.valid"
                    >
                        Sauvegarder
                    </button>
                </div>
            </div>
        </div>
    </div>

    <input
        type="file"
        id="imageUrlInput"
        class="form-control"
        (change)="changeInputFile($event)"
        accept="image/*"
    />
</form>

<div class="modal" tabindex="-1" role="dialog" #modalAircraftModelAdd>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ajout d'un nouveau modèle d'avion</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <app-aircraft-model-edit
                    inModal="true"
                    (getCreatedAircraftModel)="getCreatedAircraftModel($event)"
                    [triggerSaveAicraftModal]="triggerSaveAicraftModal"
                ></app-aircraft-model-edit>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Annuler
                </button>
                <button type="button" class="btn btn-primary" (click)="saveAircraftModel()">
                    Sauvegarder
                </button>
            </div>
        </div>
    </div>
</div>
