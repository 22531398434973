import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { IStoreData } from './store-data.interface';

export interface IFlightChecklistTemplate extends IStoreData {
  enquiryType: EnumEnquiryType;
  modules: IFlightChecklistTemplateModule[];
}

export interface IFlightChecklistTemplateModule {
  title: string;
  isSharedAmongAllLegs: boolean;
  fields: IFlightChecklistTemplateField[];
}

export interface IFlightChecklistTemplateField {
  id: string;
  text: string;
  type: EnumFlightChecklistTemplateFieldType;
  options?: IFlightChecklistTemplateFieldOption[];
  defaultOptionId?: string | null;
  finalOptionId?: string | null;
}

export enum EnumFlightChecklistTemplateFieldType {
  select = 'select',
  text = 'text'
}

export const getFlightChecklistTemplateFieldTypeLabel = (
  type: EnumFlightChecklistTemplateFieldType
): string => {
  switch (type) {
    default:
      return type;
    case EnumFlightChecklistTemplateFieldType.select:
      return 'Liste déroulante';
    case EnumFlightChecklistTemplateFieldType.text:
      return 'Champ libre';
  }
};

export interface IFlightChecklistTemplateFieldOption {
  id: string;
  text: string;
  status: EnumFlightChecklistTemplateFieldOptionStatus;
}

export enum EnumFlightChecklistTemplateFieldOptionStatus {
  none = 'none',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info'
}

export const getFlightChecklistTemplateFieldOptionStatusLabel = (
  status: EnumFlightChecklistTemplateFieldOptionStatus
): string => {
  switch (status) {
    default:
      return status;
    case EnumFlightChecklistTemplateFieldOptionStatus.none:
      return 'Pas de couleur';
    case EnumFlightChecklistTemplateFieldOptionStatus.primary:
      return 'Couleur principale';
    case EnumFlightChecklistTemplateFieldOptionStatus.secondary:
      return 'Couleur secondaire';
    case EnumFlightChecklistTemplateFieldOptionStatus.success:
      return 'Couleur réussi';
    case EnumFlightChecklistTemplateFieldOptionStatus.warning:
      return 'Couleur warning';
    case EnumFlightChecklistTemplateFieldOptionStatus.danger:
      return 'Couleur erreur';
    case EnumFlightChecklistTemplateFieldOptionStatus.info:
      return 'Couleur information';
  }
};
