import { EnumCurrency } from '../enums/currency.enum';
import { EnumEnquiryType } from '../enums/enquiry-type.enum';
import { EnumLanguage } from '../enums/language.enum';
import { IQuotationTrip } from './quotation-trip.interface';
import { IStoreData } from './store-data.interface';

export interface IQuotation extends IStoreData {
  title: string;
  enquiryId: string;
  enquiryType: EnumEnquiryType;
  date: string;
  versionNumber: number;
  language: EnumLanguage;
  clientId: string;
  clientTitle: string;
  contactId: string;
  contactFirstname: string;
  contactLastname: string;
  contactTitle: string;
  contactPhone: string;
  contactEmail: string;
  companyTitle: string;
  agentId: string;
  agentFirstname: string;
  agentLastname: string;
  agentTitle: string;
  agentPhone: string;
  agentEmail: string;
  trips: IQuotationTrip[];
  notes: string;
  aircraftCompiledId: string | null;
  aircraftModelId: string | null;
  aircraftId: string | null;
  aircraftInfos: IQuotationAircraftInfo[];
  priceInfos: IQuotationPriceInfo[];
  paymentConditions: string;
  paymentConditionsSettings: {
    firstDeposit: {
      percent: number;
      text: string;
    };
    lastDeposit: {
      percent: number;
      daysBefore: number;
      text: string;
    };
  };
  cancellingConditions: string;
  cancellingConditionsSettings: {
    firstStep: {
      percent: number;
      daysBefore: number;
      text: string;
    };
    secondStep: {
      percent: number;
      daysBeforeStart: number;
      daysBeforeEnd: number;
      text: string;
    };
    thirdStep: {
      percent: number;
      daysBeforeStart: number;
      hoursBeforeEnd: number;
      text: string;
    };
    fourthStep: {
      percent: number;
      hoursBefore: number;
      text: string;
    };
  };
  servicesSections: IQuotationSection[];
  googleMapStaticUrl: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  image1ZoomLevel: number;
  image2ZoomLevel: number;
  image3ZoomLevel: number;
  status: EnumQuotationStatus;
  garantiesAndEngagements: string;
  observationsTrips: string;
  totalAmountPayment: number;
  totalAmountPaymentCurrency: EnumCurrency;
}

export enum EnumQuotationStatus {
  draft = 'draft',
  rejected = 'rejected',
  confirmed = 'confirmed',
  sent = 'sent'
}

export const getQuotationStatusLabel = (status: EnumQuotationStatus): string => {
  switch (status) {
    default:
      return status;
    case EnumQuotationStatus.draft:
      return 'En cours';
    case EnumQuotationStatus.rejected:
      return 'Refusé';
    case EnumQuotationStatus.confirmed:
      return 'Confirmée';
    case EnumQuotationStatus.sent:
      return 'Envoyé';
  }
};

export interface IQuotationItemInfo {
  title?: string;
  subTitle?: string;
  hasTitle?: boolean;
  hasSubTitle?: boolean;
  type: EnumQuotationValueType;
  placeholder?: string;
  value?: string | number;
  translations: IQuotationInfoTranslationField;
}

export interface IQuotationAircraftInfo extends IQuotationItemInfo {}

export interface IQuotationPriceInfo extends IQuotationItemInfo {}

export interface IQuotationInfoTranslationField {
  title?: string;
  subTitle?: string;
  placeholder?: string;
  value?: string;
}

export enum EnumQuotationValueType {
  string = 'string',
  price = 'price',
  year = 'year',
  totalAmountPayment = 'totalAmountPayment',
  vat = 'vat',
  aircraftModelTitle = 'aircraftModelTitle',
  pax = 'pax',
  pricePerPax = 'pricePerPax',
  aircraftModelTotalSeat = 'aircraftModelTotalSeat'
}

export interface IQuotationSection {
  title?: string;
  translations: {
    title?: string;
  };
  services: IQuotationSectionService[];
}

export interface IQuotationSectionService {
  title?: string;
  checked: boolean;
  comment?: string;
  translations: {
    title?: string;
    comment?: string;
  };
}
