import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { AircraftsListForAirlineComponent } from './aircrafts-list-for-airline.component';
import { DataListModule } from '../data-list/data-list.module';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, RouterModule, DataListModule],
  declarations: [AircraftsListForAirlineComponent],
  exports: [AircraftsListForAirlineComponent]
})
export class AircraftsListForAirlineModule {}
