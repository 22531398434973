import { Component, OnInit } from '@angular/core';

import { PaginationService } from '../../../services/pagination.service';
import { AclService } from '../../../services/acl.service';

import { IFlightBriefCatering } from 'src/app/interfaces/flight-brief-catering.interface';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { getLanguageLabel } from 'src/app/enums/language.enum';
import { FlightBriefCateringService } from 'src/app/services/flight-brief-caterings/flight-brief-caterings.service';
import { DataList } from 'src/app/components/data-list/data-list.class';
import {
  EnumDataListColumnType,
  IDataListColumn,
  IDataListOrderBy
} from 'src/app/components/data-list/data-list.component';

@Component({
  selector: 'app-list-flight-brief-caterings',
  templateUrl: './list-flight-brief-caterings.component.html',
  styleUrls: ['./list-flight-brief-caterings.component.scss']
})
export class ListFlightBriefCateringsComponent
  extends DataList<IFlightBriefCatering>
  implements OnInit
{
  EnumAcl = EnumAcl;

  columns: IDataListColumn[] = [
    {
      title: 'Langue',
      field: 'language',
      type: EnumDataListColumnType.function,
      resolve: (item: IFlightBriefCatering): string => {
        return getLanguageLabel(item.language);
      }
    },
    {
      title: 'Texte',
      field: 'text'
    },
    {
      title: 'Position',
      field: 'position'
    }
  ];
  orderBy: IDataListOrderBy | null = { field: 'language', direction: 'asc' };

  constructor(
    public flightBriefCateringService: FlightBriefCateringService,
    private aclService: AclService
  ) {
    super();

    this.setDataService(this.flightBriefCateringService);
  }

  async ngOnInit(): Promise<void> {
    await this.aclService.checkAclAccess(EnumAcl.flightBriefCateringsList);

    this.resetAndLoad();
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }
}
