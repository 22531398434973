import firebase from 'firebase/compat/app';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';

import { Chart, Colors, registerables } from 'chart.js';
Chart.register(...registerables);

import moment from 'moment';
import { StatsService } from 'src/app/services/stats/stats.service';
import { Subscription } from 'rxjs';
import { IStats, IStatsBestAirline, IStatsBestClient } from 'src/app/interfaces/stats.interface';
import {
  EnumEnquiryCancelledReason,
  getEnumEnquiryCancelledReasonLabel
} from 'src/app/enums/enquiry-cancelled-reason.enum';
import { EnumEnquiryStatus, getEnumEnquiryStatusLabel } from 'src/app/enums/enquiry-status.enum';
import { EnumEnquiryType, getEnumEnquiryTypeLabel } from 'src/app/enums/enquiry-type.enum';
import { format, getMonth } from 'date-fns';

import * as XLSX from 'xlsx';
import { IEnquiry } from 'src/app/interfaces/enquiry.interface';
import {
  faFileExcel,
  faImage,
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/free-solid-svg-icons';
import { EnumAcl } from 'src/app/enums/acl.enum';
import { IUser, getUserFullname } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-statistics-users',
  templateUrl: './statistics-users.component.html',
  styleUrls: ['./statistics-users.component.scss']
})
export class StatisticsUsersComponent implements OnInit, OnDestroy {
  @ViewChild('enquiriesReceivedChart') enquiriesReceivedChartEl: any;

  getUserFullname = getUserFullname;

  faImage = faImage;
  faFileExcel = faFileExcel;
  faSort = faSort;
  faSortUp = faSortUp;
  faSortDown = faSortDown;

  EnumAcl = EnumAcl;

  sending: boolean = false;
  loading: boolean = false;
  loadingGraph: boolean = false;
  userId: string;
  user: IUser = null;
  loggedInUser: IUser;
  isLogged: boolean = false;
  enquiriesReceivedBy: Array<IEnquiry> = [];
  enquiriesProcessedBy: Array<IEnquiry> = [];
  filterTopNumber: {
    title: string;
    value: number;
  }[] = [
    {
      title: '5 premiers',
      value: 5
    },
    {
      title: '10 premiers',
      value: 10
    },
    {
      title: '25 premiers',
      value: 25
    },
    {
      title: '50 premiers',
      value: 50
    },
    {
      title: 'Tous',
      value: 9999999
    }
  ];
  bestClientsCount: number = 5;
  bestAirlinesCount: number = 5;
  sortClientsBy: {
    field: string;
    direction: firebase.firestore.OrderByDirection;
  } = {
    field: 'margeBrut',
    direction: 'desc'
  };
  sortAirlinesBy: {
    field: string;
    direction: firebase.firestore.OrderByDirection;
  } = {
    field: 'margeBrut',
    direction: 'desc'
  };
  start: string;
  end: string;
  today: string;
  filtersByPeriod: Array<{
    title: string;
    value: string;
  }> = [];
  filterByStatId: string = format(new Date(), 'yyyy-MM');
  currentYear: number = new Date().getFullYear();
  isMyStats: boolean = false;
  @ViewChild('modalUserGoals', { static: false }) modalUserGoalsElement: ElementRef;
  form: FormGroup = new FormGroup({
    q1: new FormGroup({
      goal: new FormControl(0, [Validators.required]),
      provisional: new FormControl(0, [Validators.required]),
      actual: new FormControl(0, [Validators.required])
    }),
    q2: new FormGroup({
      goal: new FormControl(0, [Validators.required]),
      provisional: new FormControl(0, [Validators.required]),
      actual: new FormControl(0, [Validators.required])
    }),
    q3: new FormGroup({
      goal: new FormControl(0, [Validators.required]),
      provisional: new FormControl(0, [Validators.required]),
      actual: new FormControl(0, [Validators.required])
    }),
    q4: new FormGroup({
      goal: new FormControl(0, [Validators.required]),
      provisional: new FormControl(0, [Validators.required]),
      actual: new FormControl(0, [Validators.required])
    })
  });
  users: IUser[] = [];
  usersBreadcrumbs: Array<{
    title: string;
    path: string;
  }> = [];
  startYear = 2021;

  stats: IStats[] = [];
  chartStats: IStats[] = [];
  currentStat: IStats;

  EnumEnquiryStatus = EnumEnquiryStatus;
  EnumEnquiryType = EnumEnquiryType;
  EnumEnquiryCancelledReason = EnumEnquiryCancelledReason;

  getEnumEnquiryTypeLabel = getEnumEnquiryTypeLabel;
  getEnumEnquiryCancelledReasonLabel = getEnumEnquiryCancelledReasonLabel;
  getEnumEnquiryStatusLabel = getEnumEnquiryStatusLabel;

  chart: Chart = null;

  chartTabs: {
    title: string;
    value: string;
  }[] = [
    {
      title: 'Demandes reçus',
      value: 'enquiriesReceived'
    },
    {
      title: 'Demandes confirmées',
      value: 'enquiriesConfirmed'
    },
    {
      title: 'Ratio demande/confirmation',
      value: 'ratioEnquiryConfirmed'
    },
    {
      title: 'CA',
      value: 'priceSell'
    },
    {
      title: 'Marge',
      value: 'margeBrut'
    }
  ];
  chartSelected: string = this.chartTabs[0].value;

  monthLabels: string[] = [
    'Janv.',
    'Fev.',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juil.',
    'Août',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.'
  ];

  private subscriptionsStats = new Subscription();
  private subscriptionsStatsGraph = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private remoteService: RemoteService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aclService: AclService,
    private formBuilder: FormBuilder,
    private statsService: StatsService
  ) {
    this.today = moment().format('YYYY-MM-DD');

    this.generateFilterPeriodOptions();

    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );
    this.remoteService.userObservable.subscribe(async (user: IUser) => {
      this.loggedInUser = user;

      if (this.loggedInUser && this.isMyStats) {
        if (!this.users.length) {
          this.users = [this.loggedInUser];
        }
        this.user = this.loggedInUser;
        this.userId = this.loggedInUser.id;

        this.updatedUser();
      }
    });
  }

  getQuarterGroup(i: number): FormGroup {
    return this.form.get('q' + i) as FormGroup;
  }

  getQuarterField(i: number, field: string): FormControl {
    return this.getQuarterGroup(i).get(field) as FormControl;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async () => {
      if (this.activatedRoute.snapshot.routeConfig.path !== 'current-user') {
        this.userId = this.activatedRoute.snapshot.paramMap.get('userId');

        await this.aclService.checkAclAccess(EnumAcl.usersStatistics);
      } else {
        this.isMyStats = true;

        if (this.loggedInUser) {
          this.user = this.loggedInUser;
          this.userId = this.user.id;
        }
      }
    });

    this.initEnquiriesReceivedChart();

    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.subscriptionsStats.unsubscribe();
    this.subscriptionsStatsGraph.unsubscribe();

    this.removeModal();
  }

  getEnumEnquiryStatus(): EnumEnquiryStatus[] {
    return Object.values(EnumEnquiryStatus);
  }
  getEnumEnquiryType(): EnumEnquiryType[] {
    return Object.values(EnumEnquiryType);
  }
  getEnumEnquiryCancelledReason(): EnumEnquiryCancelledReason[] {
    return Object.values(EnumEnquiryCancelledReason);
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      await this.loadUsers();
      this.refreshGraph();

      this.updatedUser();
    } else {
      setTimeout(async () => {
        await this.loadData();
      }, 500);
    }
  }

  async loadUsers(): Promise<void> {
    this.loading = true;

    const docs: Array<object> = await this.remoteService.getAllDocuments('users', {
      field: 'lastname',
      direction: 'asc'
    });

    this.users = [];
    for (const doc of docs) {
      this.users.push(doc as IUser);
    }

    this.users.sort((a, b) => {
      return getUserFullname(a) < getUserFullname(b) ? -1 : 1;
    });

    this.loading = false;
  }

  hasAclAccess(id: EnumAcl): boolean {
    return this.aclService.hasAclAccess(id);
  }

  editUserGoals(): void {
    window['$'](this.modalUserGoalsElement.nativeElement).modal('show');
  }

  removeModal(): void {
    window['$'](this.modalUserGoalsElement.nativeElement).modal('hide');
    window['$']('body').removeClass('modal-open');
    window['$']('.modal-backdrop').remove();
  }

  async saveUserGoals(): Promise<void> {
    if (this.user) {
      this.form.markAsTouched();

      if (this.form.valid) {
        const data = Object.assign({}, this.user.goals);

        let goals = Object.assign({}, this.form.value);

        for (const field in goals) {
          if (typeof goals[field] == 'undefined') {
            goals[field] = null;
          }
        }

        this.sending = true;

        this.form.disable();

        goals['year'] = {
          goal: 0,
          provisional: 0,
          actual: 0
        };

        for (let i = 1; i <= 4; i++) {
          for (const field of ['goal', 'provisional', 'actual']) {
            goals['year'][field] += goals['q' + i][field];
          }
        }

        data[this.currentYear] = goals;

        this.remoteService
          .updateDocumentToCollection('users', this.user.id, {
            goals: data
          })
          .then(() => {
            this.sending = false;
            this.form.enable();

            this.updatedUser();

            window['$'](this.modalUserGoalsElement.nativeElement).modal('hide');
          })
          .catch(err => {
            this.sending = false;
            this.form.enable();

            alert(err.message);
          });
      }
    }
  }

  getMaxFromGoalNumbers(goalNumbers: {
    goal: number;
    provisional: number;
    actual: number;
  }): number {
    return Math.max(...Object.values(goalNumbers));
  }

  getPercent(
    goalNumbers: {
      goal: number;
      provisional: number;
      actual: number;
    },
    field: string
  ): number {
    const maxValue: number = this.getMaxFromGoalNumbers(goalNumbers);

    return maxValue ? Math.round((goalNumbers[field] * 100) / maxValue) : 0;
  }

  getPercentFromGoal(
    goalNumbers: {
      goal: number;
      provisional: number;
      actual: number;
    },
    field: string
  ): number {
    return goalNumbers.goal ? Math.round((goalNumbers[field] * 100) / goalNumbers.goal) : 0;
  }

  formatPrice(value: number, minimumFractionDigits: number = 2): string {
    const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits
    });

    return formatter.format(value);
  }

  updateFilterByStat(): void {
    this.currentStat = null;

    const value: string =
      this.filterByStatId + (this.user ? '-' + this.user.id.toLowerCase() : '-allusers');

    if (this.stats?.length) {
      for (const stat of this.stats) {
        if (stat.id === value) {
          this.currentStat = stat;

          if (this.currentStat.year !== this.currentYear) {
            this.currentYear = this.currentStat.year;
            this.refreshGraph();
          }

          break;
        }
      }
    }
  }

  async loadStats(): Promise<void> {
    this.stats = [];
    this.loading = true;

    if (this.subscriptionsStats) {
      this.subscriptionsStats.unsubscribe();
      this.subscriptionsStats = new Subscription();
    }

    this.subscriptionsStats.add(
      this.statsService
        .getAllForUser(this.user ? this.user.id : null)
        .subscribe((stats: IStats[]) => {
          this.loading = true;

          this.stats = stats;

          if (!this.filterByStatId && this.stats.length) {
            setTimeout(() => {
              this.filterByStatId = format(new Date(), 'yyyy-MM');

              this.updateFilterByStat();

              this.loading = false;
            }, 300);
          } else {
            this.updateFilterByStat();

            this.loading = false;
          }
        })
    );
  }

  generateFilterPeriodOptions(): void {
    const beginningOfMonth = moment().startOf('month');

    const startDate = moment(beginningOfMonth.get('year') - 2 + '-12-31');

    for (var m = beginningOfMonth; m.isAfter(startDate); m.add(-1, 'month')) {
      let title: string = m.format('MMMM YY');

      title = title.charAt(0).toUpperCase() + title.slice(1);

      this.filtersByPeriod.push({
        title: title,
        value: m.format('YYYY-MM')
      });
    }

    this.currentYear = moment().get('year');

    for (let dec = 0; dec <= 1; dec++) {
      const lastQuarter: number = dec === 0 ? moment().quarter() : 4;
      const year: number = this.currentYear - dec;

      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'q' + i + '-' + year
        });
      }
    }

    for (let year = this.currentYear; year >= this.startYear; year--) {
      this.filtersByPeriod.push({
        title: 'Année ' + year.toString(),
        value: year.toString()
      });
    }
  }

  async updatedUser(): Promise<void> {
    this.user = null;

    if (this.userId) {
      for (const user of this.users) {
        if (user.id === this.userId) {
          this.user = user;
          break;
        }
      }
    }
    this.form.reset();

    this.loadStats();

    this.refreshEnquiriesReceivedChartData();

    if (this.user) {
      if (this.user.goals && this.user.goals[this.currentYear]) {
        for (let i = 1; i <= 4; i++) {
          for (const field of ['goal', 'provisional', 'actual']) {
            if (
              this.user.goals[this.currentYear]['q' + i] &&
              this.user.goals[this.currentYear]['q' + i][field]
            ) {
              this.form
                .get('q' + i)
                .get(field)
                .setValue(this.user.goals[this.currentYear]['q' + i][field]);
              this.form
                .get('q' + i)
                .get(field)
                .updateValueAndValidity();
            }
          }
        }
      }
    }
  }

  getMarginPercent(margeBrut: number = 0, priceNetTTC: number = 0): number {
    return margeBrut && priceNetTTC ? this.roundNumber((margeBrut / priceNetTTC) * 100) : 0;
  }

  roundNumber(value: number): number {
    return Math.round(value * 100 + Number.EPSILON) / 100;
  }

  getRatioEnquiryConfirmed(stat: IStats): number {
    return stat
      ? this.roundNumber(
          stat.countEnquiriesByStatus?.confirmed && stat.countEnquiries > 0
            ? (stat.countEnquiriesByStatus?.confirmed * 100) / stat.countEnquiries
            : 0
        )
      : 0;
  }

  getCountConfirmed(data: IStatsBestClient | IStatsBestAirline): number {
    return data?.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0;
  }

  sortStatBest(type: 'bestClients' | 'bestAirlines', field: string): void {
    let sortingByParameters = null;

    switch (type) {
      case 'bestClients':
        sortingByParameters = this.sortClientsBy;
        break;
      case 'bestAirlines':
        sortingByParameters = this.sortAirlinesBy;
        break;
    }

    if (sortingByParameters) {
      if (sortingByParameters.field === field) {
        sortingByParameters.direction = sortingByParameters.direction === 'asc' ? 'desc' : 'asc';
      } else {
        sortingByParameters.field = field;
        sortingByParameters.direction = sortingByParameters.field.match('Title') ? 'asc' : 'desc';
      }

      this.currentStat[type].sort((a, b) => {
        let valueA: any = null;
        let valueB: any = null;

        switch (sortingByParameters.field) {
          case 'countConfirmed':
            valueA = a.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0;
            valueB = b.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0;
            break;
          default:
            valueA = a[sortingByParameters.field];
            valueB = b[sortingByParameters.field];
            break;
        }

        switch (sortingByParameters.direction) {
          case 'asc':
            return valueA < valueB ? -1 : 1;
          case 'desc':
            return valueA > valueB ? -1 : 1;
          default:
            return 0;
        }
      });
    }
  }

  exportStatistics(): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let period: string = this.filterByStatId;
    for (const filterByPeriod of this.filtersByPeriod) {
      if (period === filterByPeriod.value) {
        period = filterByPeriod.title;
        break;
      }
    }

    let userSelected: string = '';
    if (this.userId) {
      for (const user of this.users) {
        if (user.id === this.userId) {
          userSelected = getUserFullname(user);
          break;
        }
      }
    }

    const fileName: string = this.slugify(userSelected ? period + ' ' + userSelected : period);

    wb.Props = {
      Title: fileName,
      Subject: fileName,
      Author: this.loggedInUser?.firstname + ' ' + this.loggedInUser.lastname,
      CreatedDate: new Date()
    };

    wb.SheetNames.push(fileName.slice(0, 31));

    const rows: any[] = this.getRowsForExport();
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rows);

    ws = this.fixWidth(ws);

    wb.Sheets[fileName] = ws;

    XLSX.writeFile(wb, 'simplyfly-stats-' + fileName + '.xlsx');
  }

  getRowsForExport(): any[] {
    const rows: any[] = [];

    if (this.currentStat) {
      rows.push(['Nombre total de demandes', this.currentStat?.countEnquiries || 0]);
      rows.push([]);

      rows.push(["Chiffre d'affaires", this.formatPrice(this.currentStat?.priceSell || 0)]);
      rows.push([]);

      rows.push([
        'Marge',
        this.formatPrice(this.currentStat?.margeBrut),
        (this.getMarginPercent(this.currentStat?.margeBrut, this.currentStat?.priceNetTTC) || 0) +
          '%'
      ]);
      rows.push([]);

      rows.push([
        'Ratio demande/confirmation',
        this.getRatioEnquiryConfirmed(this.currentStat) + '%'
      ]);
      rows.push([]);

      rows.push(['Nombre de demandes par status']);

      for (let enquiryStatus of this.getEnumEnquiryStatus()) {
        rows.push([
          getEnumEnquiryStatusLabel(enquiryStatus),
          this.currentStat?.countEnquiriesByStatus[enquiryStatus] || 0
        ]);
      }

      rows.push([]);

      rows.push(['Nombre de demandes par type']);

      for (let enquiryType of this.getEnumEnquiryType()) {
        rows.push([
          getEnumEnquiryTypeLabel(enquiryType),
          this.currentStat?.countEnquiriesByType[enquiryType] || 0
        ]);
      }

      rows.push(['Nombre de demandes confirmées par type']);

      for (let enquiryType of this.getEnumEnquiryType()) {
        rows.push([
          getEnumEnquiryTypeLabel(enquiryType),
          this.currentStat?.countEnquiriesConfirmedByType[enquiryType] || 0
        ]);
      }

      rows.push([]);

      rows.push(["Raisons d'annulation des demandes"]);

      for (let cancelledReason of this.getEnumEnquiryCancelledReason()) {
        rows.push([
          getEnumEnquiryCancelledReasonLabel(cancelledReason),
          this.currentStat?.countEnquiryesByCancelledReasons[cancelledReason] || 0
        ]);
      }

      rows.push([]);

      rows.push(['Meilleurs clients']);
      rows.push(['Nom', 'Marge', 'Marge %', "Chiffre d'affaire", 'Demandes confirmées']);

      for (const client of this.currentStat?.bestClients?.slice(0, this.bestClientsCount)) {
        rows.push([
          client.clientTitle,
          this.formatPrice(client.margeBrut),
          (this.getMarginPercent(client.margeBrut, client.priceNetTTC) || 0) + '%',
          this.formatPrice(client.priceSell || 0),
          this.getCountConfirmed(client)
        ]);
      }

      rows.push([]);

      rows.push(['Meilleures compagnies aériennes']);
      rows.push(['Nom', 'Marge', 'Marge %', "Chiffre d'affaire", 'Nombre de vols']);

      for (const airline of this.currentStat?.bestAirlines?.slice(0, this.bestAirlinesCount)) {
        rows.push([
          airline.airlineTitle,
          this.formatPrice(airline.margeBrut),
          (this.getMarginPercent(airline.margeBrut, airline.priceNetTTC) || 0) + '%',
          this.formatPrice(airline.priceSell || 0),
          this.getCountConfirmed(airline)
        ]);
      }

      rows.push([]);
    }

    return rows;
  }

  getRowsFromGraphForExport(): any[] {
    const rows: any[] = [];

    if (this.currentStat) {
      let row: any[] = ['Commercial'];

      for (const chartTab of this.chartTabs) {
        row.push(chartTab.title);

        if (chartTab.value === 'margeBrut') {
          row.push('Marge %');
        }
      }

      row.push('Période');

      rows.push(row);

      for (const user of this.users) {
        if (!this.userId || this.userId === user.id) {
          for (const stat of this.chartStats) {
            if (user.id === stat.userId) {
              row = [getUserFullname(user)];

              for (const chartTab of this.chartTabs) {
                switch (chartTab.value) {
                  case 'enquiriesReceived':
                    row.push(stat.countEnquiries);
                    break;
                  case 'enquiriesConfirmed':
                    row.push(stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0);
                    break;
                  case 'ratioEnquiryConfirmed':
                    row.push(this.getRatioEnquiryConfirmed(stat));
                    break;
                  case 'priceSell':
                    row.push(this.roundNumber(stat.priceSell));
                    break;
                  case 'margeBrut':
                    row.push(this.roundNumber(stat.margeBrut));

                    row.push(this.getMarginPercent(stat.margeBrut, stat.priceNetTTC) || 0);
                    break;
                  default:
                    row.push(0);
                    break;
                }
              }

              row.push(stat.title);

              rows.push(row);
            }
          }
        }
      }
    }

    if (!this.userId) {
      for (let i = rows.length - 1; i > 0; i--) {
        let isEmpty: boolean = true;

        for (let cell of rows[i]) {
          if (cell > 0) {
            isEmpty = false;
          }
        }

        if (isEmpty) {
          // We remove empty dataset from graph for better readability
          rows.splice(i, 1);
        }
      }
    }

    return rows;
  }

  initEnquiriesReceivedChart(): void {
    if (!this.enquiriesReceivedChartEl) {
      setTimeout(() => {
        this.initEnquiriesReceivedChart();
      }, 2000);
    } else if (this.enquiriesReceivedChartEl && !this.chart) {
      this.chart = new Chart(this.enquiriesReceivedChartEl.nativeElement, {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          animation: false,
          elements: {
            bar: {
              borderWidth: 0
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: '#E2E7E7'
              },
              ticks: {
                display: true,
                stepSize: 10,
                callback: (value: number, index: number) => {
                  switch (this.chartSelected) {
                    case 'ratioEnquiryConfirmed':
                      return value + '%';
                    case 'priceSell':
                    case 'margeBrut':
                      return this.formatPrice(value, 0);
                    default:
                      return value;
                  }
                }
              }
            },
            x: {
              grid: { display: false }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom'
            },
            tooltip: {
              callbacks: {
                label: context => {
                  let label = context.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }

                  switch (this.chartSelected) {
                    case 'enquiriesReceived':
                      label +=
                        context.parsed.y > 1
                          ? context.parsed.y + ' demandes'
                          : context.parsed.y + ' demande';
                      break;
                    case 'enquiriesConfirmed':
                      label +=
                        context.parsed.y > 1
                          ? context.parsed.y + ' demandes confirmées'
                          : context.parsed.y + ' demande confirmée';
                      break;
                    case 'ratioEnquiryConfirmed':
                      label += context.parsed.y + '%';
                      break;
                    case 'priceSell':
                      label += this.formatPrice(context.parsed.y, 0);
                      break;
                    case 'margeBrut':
                      label += this.formatPrice(context.parsed.y, 0);
                      break;
                    default:
                      label += context.parsed.y;
                      break;
                  }

                  return label;
                }
              }
            }
          }
        },
        plugins: []
      });

      this.refreshGraph();
    }
  }

  clearChartData(): void {
    if (this.chart) {
      this.chart.data.labels = [];

      this.chart.data.datasets = [];

      this.chart.update();
    }
  }

  refreshGraph(): void {
    this.clearChartData();

    if (this.currentYear) {
      this.loadingGraph = true;

      if (this.subscriptionsStatsGraph) {
        this.subscriptionsStatsGraph.unsubscribe();
        this.subscriptionsStatsGraph = new Subscription();
      }

      this.subscriptionsStatsGraph.add(
        this.statsService.getAllForYearAndMonthly(this.currentYear).subscribe((stats: IStats[]) => {
          this.chartStats = stats;

          setTimeout(() => {
            // Timeout to have time to display loading indicator

            this.refreshEnquiriesReceivedChartData();
          }, 300);
        })
      );
    }
  }

  async refreshEnquiriesReceivedChartData(): Promise<void> {
    this.loadingGraph = true;

    this.clearChartData();

    if (this.chart && this.chart.data) {
      for (const user of this.users) {
        if (!this.userId || this.userId === user.id) {
          const userColor: string = user.primaryColor || '#000000';
          this.chart.data.datasets.push({
            label: getUserFullname(user),
            data: [],
            borderColor: userColor,
            backgroundColor: userColor,
            hidden: true
          });
        }
      }

      if (!this.userId) {
        this.chart.data.datasets.push({
          label: 'Total équipe',
          data: [],
          borderColor: 'brown',
          backgroundColor: 'brown'
        });
        this.chart.data.datasets.push({
          label: 'Moyenne',
          data: [],
          borderColor: 'grey',
          backgroundColor: 'grey'
        });
      }

      for (const monthLabel of this.monthLabels) {
        this.chart.data.labels.push(monthLabel + ' ' + (this.currentYear - 2000));
        // eslint-disable-next-line guard-for-in
        for (const i in this.chart.data.datasets) {
          this.chart.data.datasets[i].data.push(0);
        }
      }

      for (const stat of this.chartStats) {
        const monthIndex: number = getMonth(stat.dateStart);

        if (!stat.userId) {
          if (!this.userId) {
            switch (this.chartSelected) {
              case 'enquiriesReceived':
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] =
                  stat.countEnquiries;
                break;
              case 'enquiriesConfirmed':
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] =
                  stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0;
                break;
              case 'ratioEnquiryConfirmed':
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] =
                  this.getRatioEnquiryConfirmed(stat);
                break;
              case 'priceSell':
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] =
                  this.roundNumber(stat.priceSell);
                break;
              case 'margeBrut':
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] =
                  this.roundNumber(stat.margeBrut);
                break;
              default:
                this.chart.data.datasets[this.chart.data.datasets.length - 2].data[monthIndex] = 0;
                break;
            }
          }
        } else {
          let users: IUser[] = [];
          if (this.userId) {
            for (const user of this.users) {
              if (this.userId === user.id) {
                users.push(user);
                break;
              }
            }
          } else {
            users = [...this.users];
          }

          for (let i = 0; i < users.length; i++) {
            if (users[i].id === stat.userId && this.chart.data.datasets[i]) {
              switch (this.chartSelected) {
                case 'enquiriesReceived':
                  this.chart.data.datasets[i].data[monthIndex] = stat.countEnquiries;

                  break;
                case 'enquiriesConfirmed':
                  this.chart.data.datasets[i].data[monthIndex] =
                    stat.countEnquiriesByStatus[EnumEnquiryStatus.confirmed] || 0;
                  break;
                case 'ratioEnquiryConfirmed':
                  this.chart.data.datasets[i].data[monthIndex] =
                    this.getRatioEnquiryConfirmed(stat);
                  break;
                case 'priceSell':
                  this.chart.data.datasets[i].data[monthIndex] = this.roundNumber(stat.priceSell);
                  break;
                case 'margeBrut':
                  this.chart.data.datasets[i].data[monthIndex] = this.roundNumber(stat.margeBrut);
                  break;
                default:
                  this.chart.data.datasets[i].data[monthIndex] = 0;
                  break;
              }

              if ((this.chart.data.datasets[i].data[monthIndex] as number) > 0) {
                this.chart.data.datasets[i].hidden = false;
              }
              break;
            }
          }
        }
      }

      if (!this.userId) {
        for (let i = this.chart.data.datasets.length - 1; i >= 0; i--) {
          if (this.chart.data.datasets[i].hidden) {
            // We remove empty dataset from graph for better readability
            this.chart.data.datasets.splice(i, 1);
          }
        }

        for (
          let monthIndex = 0;
          monthIndex < this.chart.data.datasets[this.chart.data.datasets.length - 1].data.length;
          monthIndex++
        ) {
          const total: number = this.chart.data.datasets[this.chart.data.datasets.length - 2].data[
            monthIndex
          ] as number;

          this.chart.data.datasets[this.chart.data.datasets.length - 1].data[monthIndex] =
            this.chart.data.datasets.length > 2
              ? this.roundNumber(total / (this.chart.data.datasets.length - 2))
              : 0;
        }
      }

      this.chart.update();
    }

    this.loadingGraph = false;
  }

  selectChart(chartId: string): void {
    this.chartSelected = chartId;

    this.refreshEnquiriesReceivedChartData();
  }

  downloadGraph(): void {
    const dataImg: string = this.chart.toBase64Image();

    if (dataImg) {
      for (const chartTab of this.chartTabs) {
        if (chartTab.value === this.chartSelected) {
          const download: HTMLAnchorElement = document.createElement('a');
          download.href = dataImg;
          download.download = 'graph-' + this.slugify(chartTab.title) + '.png';
          download.click();

          break;
        }
      }
    }
  }

  downloadGraphSpreadsheet(): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    let period: string = this.filterByStatId;
    for (const filterByPeriod of this.filtersByPeriod) {
      if (period === filterByPeriod.value) {
        period = filterByPeriod.title;
        break;
      }
    }

    let userSelected: string = '';
    if (this.userId) {
      for (const user of this.users) {
        if (user.id === this.userId) {
          userSelected = getUserFullname(user);
          break;
        }
      }
    }

    const fileName: string = this.slugify(
      userSelected ? this.currentYear + ' ' + userSelected : this.currentYear
    );

    wb.Props = {
      Title: fileName,
      Subject: fileName,
      Author: this.loggedInUser?.firstname + ' ' + this.loggedInUser.lastname,
      CreatedDate: new Date()
    };

    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.getRowsFromGraphForExport());
    ws = this.fixWidth(ws);

    XLSX.utils.book_append_sheet(wb, ws, fileName);

    XLSX.writeFile(wb, 'simplyfly-stats-' + fileName + '.xlsx');
  }

  private slugify(...args: (string | number)[]): string {
    const value = args.join(' ');

    return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 +]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-'); // separator
  }

  private fixWidth(worksheet: XLSX.WorkSheet): XLSX.WorkSheet {
    const data = XLSX.utils.sheet_to_json<any>(worksheet);
    const colLengths = Object.keys(data[0]).map(k => k.toString().length);

    colLengths[0] = 40;
    colLengths[1] = 15;
    colLengths[2] = 15;
    colLengths[3] = 15;
    colLengths[4] = 15;

    worksheet['!cols'] = colLengths.map(l => {
      return {
        wch: l
      };
    });

    return worksheet;
  }

  private getRandomColor(): string {
    const letters: string[] = '0123456789ABCDEF'.split('');
    let color: string = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
