import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RemoteService } from '../../../services/remote.service';
import { AclService } from '../../../services/acl.service';
import { EnumAcl } from 'src/app/enums/acl.enum';
import {
  EnumExportRequestFilterType,
  EnumExportRequestType,
  IExportRequest,
  getExportRequestFilerTypeLabel,
  getExportRequestLabel,
  getExportRequestTypeLabel
} from 'src/app/interfaces/export-request.interface';
import { Subscription } from 'rxjs';
import { ExportRequestsService } from 'src/app/services/export-requests/export-requests.service';
import {
  addMonths,
  addYears,
  endOfYear,
  format,
  getQuarter,
  getYear,
  isAfter,
  startOfMonth
} from 'date-fns';
import { fr } from 'date-fns/locale';

@Component({
  selector: 'app-export-request-edit',
  templateUrl: './export-request-edit.component.html',
  styleUrls: ['./export-request-edit.component.scss']
})
export class ExportRequestEditComponent implements OnInit, OnDestroy {
  EnumAcl = EnumAcl;
  EnumExportRequestFilterType = EnumExportRequestFilterType;

  getExportRequestLabel = getExportRequestLabel;
  getExportRequestTypeLabel = getExportRequestTypeLabel;
  getExportRequestFilerTypeLabel = getExportRequestFilerTypeLabel;

  isLogged: boolean = false;
  form: FormGroup;
  sending: boolean = false;
  exportRequest: IExportRequest;
  exportRequestId: string;

  filtersByPeriod: {
    title: string;
    value: string;
  }[] = [];

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private remoteService: RemoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private aclService: AclService,
    private exportRequestsService: ExportRequestsService
  ) {
    this.remoteService.isLoggedObservable.subscribe(
      (isLogged: boolean) => (this.isLogged = isLogged)
    );

    this.generateFilterPeriodOptions();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: [EnumExportRequestType.invoices, [Validators.required]],
      filterType: [EnumExportRequestFilterType.timePeriod, [Validators.required]],
      timePeriod: [format(new Date(), 'yyyy-MM'), [Validators.required]],
      enquiryId: [null],
      enquiryTitle: [null],
      clientId: [null],
      clientName: [null]
    });

    this.form.disable();

    this.activatedRoute.params.subscribe(async () => {
      this.exportRequestId = this.activatedRoute.snapshot.paramMap.get('exportRequestId');

      if (this.exportRequestId) {
        await this.aclService.checkAclAccess(EnumAcl.exportRequestsEdit);
        this.loadData();
      } else {
        await this.aclService.checkAclAccess(EnumAcl.exportRequestsAdd);

        this.updatedFilterType();

        this.form.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getExportRequestTypes(): EnumExportRequestType[] {
    return Object.values(EnumExportRequestType);
  }

  getExportRequestFilterTypes(): EnumExportRequestFilterType[] {
    return Object.values(EnumExportRequestFilterType);
  }

  generateFilterPeriodOptions(): void {
    let currentYear: number = getYear(new Date());

    const maxDec: number = currentYear - 2021;
    const beginningOfMonth: Date = startOfMonth(new Date());
    const startDate: Date = endOfYear(addYears(beginningOfMonth, -maxDec - 1));

    for (var m = beginningOfMonth; isAfter(m, startDate); m = addMonths(m, -1)) {
      let title: string = format(m, 'MMMM yy', {
        locale: fr
      });
      title = title.charAt(0).toUpperCase() + title.slice(1);
      this.filtersByPeriod.push({
        title: title,
        value: format(m, 'yyyy-MM')
      });
    }
    for (let dec = 0; dec <= maxDec; dec++) {
      const lastQuarter: number = dec === 0 ? getQuarter(new Date()) : 4;
      const year: number = currentYear - dec;
      for (let i = lastQuarter; i >= 1; i--) {
        this.filtersByPeriod.push({
          title: 'Q' + i + ' ' + year,
          value: 'quarter-' + year + '-' + i
        });
      }
    }
    // for (let year = currentYear; year >= 2021; year--) {
    //   this.filtersByPeriod.push({
    //     title: 'Année ' + year,
    //     value: 'year-' + year
    //   });
    // }
  }

  async loadData(): Promise<void> {
    if (this.isLogged) {
      this.loadExportRequest();
    } else {
      setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  loadExportRequest(): void {
    this.subscriptions.add(
      this.exportRequestsService
        .getFromId(this.exportRequestId)
        .subscribe((exportRequest: IExportRequest) => {
          this.exportRequest = exportRequest;

          this.setValue();
        })
    );
  }

  setValue(): void {
    if (this.form && this.exportRequest) {
      for (const field in this.form.value) {
        if (typeof this.exportRequest[field] !== 'undefined') {
          switch (field) {
            case 'dateStart':
            case 'dateEnd':
              if (this.exportRequest[field]) {
                this.form.get(field).setValue(format(this.exportRequest[field], 'yyyy-MM') + '-01');
              }
              break;
            default:
              this.form.get(field).setValue(this.exportRequest[field]);

              break;
          }
        }
      }

      this.updatedFilterType();

      this.form.enable();
    }
  }

  submitForm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      let data = Object.assign({}, this.form.value);

      for (const field in data) {
        if (typeof data[field] == 'undefined') {
          data[field] = null;
        }
      }

      this.sending = true;

      this.form.disable();

      let promise;
      if (this.exportRequestId) {
        data.id = this.exportRequestId;
        promise = () => this.exportRequestsService.update(data);
      } else {
        promise = () => this.exportRequestsService.create(data);
      }

      promise()
        .then(async id => {
          if (!this.exportRequestId) {
            this.exportRequestId = id;
          }

          this.form.reset();
          this.form.enable();
          this.sending = false;
          this.redirectAfterSaving();
        })
        .catch(err => {
          console.log(err);
          this.sending = false;

          alert(err.message);
        })
        .finally(() => {
          this.form.enable();
        });
    }
  }

  redirectAfterSaving(): void {
    this.router.navigate(['/admin/export-requests/' + this.exportRequestId]);
  }

  updatedFilterType(): void {
    let mandatoryField: string;
    let hiddenFields: string[] = [
      'timePeriod',
      'enquiryId',
      'enquiryTitle',
      'clientId',
      'clientName'
    ];

    switch (this.form.value.filterType) {
      case EnumExportRequestFilterType.timePeriod:
        mandatoryField = 'timePeriod';
        hiddenFields = ['enquiryId', 'enquiryTitle', 'clientId', 'clientName'];
        break;
      case EnumExportRequestFilterType.enquiry:
        mandatoryField = 'enquiryId';
        hiddenFields = ['timePeriod', 'clientId', 'clientName'];
        break;
      case EnumExportRequestFilterType.client:
        mandatoryField = 'clientId';
        hiddenFields = ['enquiryId', 'enquiryTitle', 'timePeriod'];
        break;
    }

    if (mandatoryField) {
      this.form.get(mandatoryField).setValidators(Validators.required);

      if (mandatoryField === 'timePeriod') {
        this.form.get(mandatoryField).setValue(format(new Date(), 'yyyy-MM'));
      }

      this.form.get(mandatoryField).updateValueAndValidity();
    }

    for (const field of hiddenFields) {
      this.form.get(field).clearValidators();
      this.form.get(field).setValue(null);
      this.form.get(field).updateValueAndValidity();
    }
  }

  setValueToFormControl($event: {
    fields: {
      name: string;
      value: string;
      label?: string;
    }[];
  }): void {
    for (let field of $event.fields) {
      if (field.name === 'enquiryId') {
        this.form.get('enquiryId').setValue(field.value);

        if (field.label) {
          this.form.get('enquiryTitle').setValue(field.label);
        }
      } else if (field.name === 'clientId') {
        this.form.get('clientId').setValue(field.value);

        if (field.label) {
          this.form.get('clientName').setValue(field.label);
        }
      }
    }
  }
}
