<form (submit)="submitForm()" [formGroup]="form" class="main-form">
    <div [class]="inModal ? 'modal-body' : 'row justify-content-center'">
        <div [class.col-md-6]="!inModal">
            <div [class.card]="!inModal">
                <div [class.card-body]="!inModal">
                    <div class="form-group">
                        <label for="airlineId">Compagnie aérienne</label>
                        <app-airline-autocomplete
                            (setValueToFormControl)="setValueToFormControl($event)"
                            inputName="airlineId"
                            [defaultValue]="
                                airlineId.value && airlinesObj[airlineId.value]
                                    ? airlinesObj[airlineId.value]
                                    : null
                            "
                            [disabled]="form.disabled || sending || defaultAirlineId"
                            placeholder="Recherchez une compagnie aérienne"
                        ></app-airline-autocomplete>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || airlineId.touched) &&
                                    airlineId.errors &&
                                    airlineId.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="type">Type</label>
                        <div
                            class="form-control fake-input disabled"
                            *ngIf="defaultCrewType && type.value"
                        >
                            <span
                                class="fake-input-text"
                                [innerText]="getCrewTypeLabel(type.value)"
                            ></span>
                        </div>
                        <select
                            class="form-select"
                            id="type"
                            formControlName="type"
                            [class.is-invalid]="
                                (form.touched || type.touched) && type.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                            *ngIf="!(defaultCrewType && type.value)"
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="item"
                                [innerText]="getCrewTypeLabel(item)"
                                *ngFor="let item of getCrewTypes()"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || type.touched) &&
                                    type.errors &&
                                    type.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="gender">Genre</label>
                        <select
                            class="form-select"
                            id="gender"
                            formControlName="gender"
                            [class.is-invalid]="
                                (form.touched || gender.touched) && gender.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        >
                            <option [ngValue]="null">-- Choisissez --</option>
                            <option
                                [value]="item"
                                [innerText]="getCrewGenderLabel(item)"
                                *ngFor="let item of getCrewGenders()"
                            ></option>
                        </select>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || gender.touched) &&
                                    gender.errors &&
                                    gender.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="firstname">Prénom</label>
                        <input
                            class="form-control"
                            id="firstname"
                            formControlName="firstname"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || firstname.touched) &&
                                firstname.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || firstname.touched) &&
                                    firstname.errors &&
                                    firstname.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="lastname">Nom</label>
                        <input
                            class="form-control"
                            id="lastname"
                            formControlName="lastname"
                            type="text"
                            [class.is-invalid]="
                                (form.touched || lastname.touched) && lastname.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || lastname.touched) &&
                                    lastname.errors &&
                                    lastname.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="phoneNumber">Numéro de téléphone</label>
                        <input
                            class="form-control"
                            id="phoneNumber"
                            formControlName="phoneNumber"
                            type="tel"
                            [class.is-invalid]="
                                (form.touched || phoneNumber.touched) &&
                                phoneNumber.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        />

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || phoneNumber.touched) &&
                                    phoneNumber.errors &&
                                    phoneNumber.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="comment">Observation</label>
                        <textarea
                            rows="3"
                            class="form-control"
                            id="comment"
                            formControlName="comment"
                            [class.is-invalid]="
                                (form.touched || comment.touched) && comment.status === 'INVALID'
                                    ? 'danger'
                                    : ''
                            "
                        ></textarea>

                        <div class="invalid-tooltip">
                            <div
                                *ngIf="
                                    (form.touched || comment.touched) &&
                                    comment.errors &&
                                    comment.errors['required']
                                "
                            >
                                Ce champ est obligatoire.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center text-center mt-4" *ngIf="!inModal">
                <div class="col-md-4">
                    <button
                        type="button"
                        [routerLink]="crewId ? '/admin/crews/' + crewId : '/admin/crews'"
                        class="btn btn-secondary btn-block mb-4"
                        [disabled]="form.disabled || sending"
                    >
                        Annuler
                    </button>
                </div>
                <div class="col-md-4">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block mb-4"
                        [innerHtml]="sending ? 'Sauvegarde ...' : 'Sauvegarder'"
                        [disabled]="form.disabled || !form.valid || sending"
                    ></button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="inModal">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Annuler</button>
        <button type="button" class="btn btn-primary" (click)="submitForm()">Sauvegarder</button>
    </div>
</form>
